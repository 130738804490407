import { createAsyncThunk } from '@reduxjs/toolkit';
import { isFunction } from 'lodash';
import { MediaType } from '../../utils/api/api-utils';
import { setErrorFeedback } from '../feedback/feedbackSlice';
import { contasReceberAPI } from './contasReceberAPI';

const fetchAllAsync = createAsyncThunk('contasReceber/fetchAll', async () => {
  const { data } = await contasReceberAPI.fetchAll();
  return { data };
});

const fetchAllViewAsync = createAsyncThunk('contasReceber/fetchAllView', async (params) => {
  const { data } = await contasReceberAPI.fetchAllView(params);
  return { data };
})

const generateReportAReceberByDateAsync = createAsyncThunk(
  'contasReceber/generateReportAReceberByDate',
  async (payload, { dispatch }) => {
    try {
      const response = await contasReceberAPI.generateReportAReceberByDate([
        {
          name: 'datainicial',
          value: payload?.vencimentoDe,
        },
        {
          name: 'datafinal',
          value: payload?.vencimentoAte,
        },
      ]);

      if (response?.status === 204) {
        const onNoContent = payload?.handlers?.onNoContent;
        if (isFunction(onNoContent)) {
          onNoContent();
          return;
        }
      }

      const xmlData = new Blob([response?.data], { type: MediaType.APPLICATION_PDF });

      const url = window.URL.createObjectURL(xmlData);
      window.open(url);
    } catch (e) {
      const onError = payload?.handlers?.onError;

      if (e?.response?.status === 404) {
        onError?.(e?.response?.data?.message ?? 'Não há solicitações de pagamento nesse período');

        return;
      }

      if (isFunction(onError)) {
        onError();
      }
    }
  }
);

const solicitacaoReceberThunks = {
  fetchAllAsync,
  fetchAllViewAsync,
  generateReportAReceberByDateAsync,
};

export { generateReportAReceberByDateAsync, solicitacaoReceberThunks, fetchAllAsync,fetchAllViewAsync };
