import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalDatePickerField from '../../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalAlternativeCurrencyField from '../../../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField'
import QCXSelectClienteAutocomplete from '../../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectDespesaReceitaAutocomplete from '../../../../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaAutocomplete';
import QCXSelectFornecedorAutocomplete from '../../../../../shared-components/select-fornecedor-favorecido-autocomplete/QCXSelectFornecedorAutocomplete.js';

export default function ContasPagarFilterComponent() {

    const { t } = useTranslation();

    return (
        <Box style={{ minWidth: '500px' }}>
        <Grid container title="Seção que contém informações do usuário" spacing={2}>
          <Grid item sm={12}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.solicitacaoPagamento.filter.data_solicitacao_pagamento')}
              name="data_solicitacao_pagamento"
              required={undefined}
              validate={undefined}
            />
          </Grid>
          <Grid item sm={12}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.contas_receber.filter.data_aprovacao')}
              name="data_aprovacao"
              required={undefined}
              validate={undefined}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
              <QCXSelectFornecedorAutocomplete
                id="select-field-fornecedor"
                key="select-field-fornecedor"
                name="fornecedor_id"
                label={t('com.muralis.qcx.fornecedor.label')}
                initialValues={undefined}
                disabled={undefined}
              />
            </Grid>
          <Grid item xs={12} sm={12} md={12}>
                <QCXFinalAlternativeCurrencyField
                  id="financeiro-valor-desconto-field"
                  key="financeiro-valor-desconto-field"
                  name="valor_pagar"
                  label={t('com.muralis.qcx.contas_receber.filter.valor_pagar')}
                  onBlur={undefined}
                  disabled={undefined}
                />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
                <QCXFinalAlternativeCurrencyField
                  id="financeiro-valor-desconto-field"
                  key="financeiro-valor-desconto-field"
                  name="saldo"
                  label={t('com.muralis.qcx.solicitacaoPagamento.filter.saldo')}
                  onBlur={undefined}
                  disabled={undefined}
                />
          </Grid>
          <Grid item sm={12}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.solicitacaoPagamento.filter.data_vencimento')}
              name="data_vencimento"
              required={undefined}
              validate={undefined}
            />
          </Grid>
  
        </Grid>
      </Box>
    );
}