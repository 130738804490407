import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import { required } from '../../utils/validators/field/validator';
import QCXFaturaTransferenciaDataGridManager from './QCXFaturaTransferenciaDataGridManager';
import { selectRelatedSelectionItemList } from '../../features/fatura/faturaSelectors';
import QCXSelectFollowUpImportacaoAutocompleteField from '../../shared-components/select-follow-up/QCXSelectFollowUpImportacaoAutocompleteField';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import { TipoContaFaturaUtils } from '../../shared-components/select-tipo-conta/tipoContaUtils';

export default function QCXFaturaTransferenciaForm({
  isBackgroundTransfer,
  initialValues,
  handleSubmit,
  selectionList,
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const relatedSelectionItemList = useSelector(selectRelatedSelectionItemList);

  useEffect(() => {
    const resetOnUnmount = () => {
      dispatch(followUpActions.resetList());
      dispatch(followUpActions.changeControlToAutoLoad());
    };

    return resetOnUnmount;
  }, []);

  const isEmptySelectionItemList = useMemo(
    () =>
      !relatedSelectionItemList ||
      (_.isArrayLikeObject(relatedSelectionItemList) && _.isEmpty(relatedSelectionItemList)),
    [relatedSelectionItemList]
  );

  const handleTipoContaFaturaChange = useCallback(
    (form) => (value) => {
      const isTipoContaFaturaLiOrDiDuimp =
        TipoContaFaturaUtils.isTipoFaturaLi(value) || TipoContaFaturaUtils.isTipoFaturaDiDuimp(value);

      if (isTipoContaFaturaLiOrDiDuimp) {
        form.change('referencia.id', undefined);
        return;
      }

      form.change('referencia', undefined);
    },
    []
  );

  const changeListeners = useMemo(
    () => [
      {
        name: 'conta.tipo',
        fn: handleTipoContaFaturaChange,
      },
    ],
    [handleTipoContaFaturaChange]
  );

  const controlButtons = useMemo(
    () => ({
      create: {
        description: t('com.muralis.qcx.acoes.transferir'),
        disabled: isEmptySelectionItemList,
      },
    }),
    [isEmptySelectionItemList]
  );

  return (
    <QCXFinalForm
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      onChangeListeners={changeListeners}
      controlButtons={controlButtons}
      {...restProps}
    >
      {({ values }) => (
        <>
          <Grid
            item
            container
            spacing={2}
            style={{
              marginTop: '0.5rem',
              marginBottom: '1rem',
            }}
          >
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <QCXSelectFollowUpImportacaoAutocompleteField
                  id="select-field-processo-follow-up"
                  key="select-field-processo-follow-up"
                  name="referencia.id"
                  label={t('com.muralis.qcx.numeroProcesso')}
                  initialValues={values}
                  fieldProps={{
                    validate: required,
                  }}
                  controlled
                  required
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <QCXFaturaTransferenciaDataGridManager isBackgroundTransfer={isBackgroundTransfer} rows={selectionList} />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
