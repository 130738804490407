import { Autocomplete, ListItem, TextField } from '@mui/material';
import { SyntheticEvent, useState } from 'react';

// The SelectStyled component is a React functional component that renders an Autocomplete component from the Material-UI library.
// The Autocomplete component is a form control that allows users to select an option from a list of predefined options or type
// to search for a specific option.

// The component takes in the following props:

// options: an array of objects representing the options for the select. Each object must have an id and a value properties.
// label: a string that will be used as the label for the select input.
// controlledValue: an optional object that represents the value of the select. This prop can be used to control the value of the select from the parent component.
// onChangeAction: an optional function that will be called when the value of the select changes. It will receive the event and the new value as arguments.
// key: an optional key that will be passed to the Autocomplete component.
// The component uses the React Hook useState to keep track of the value of the select. If controlledValue prop is not provided,
// the component will use the state to keep track of the value.

// The Autocomplete component is configured to:

// disablePortal: to keep the options list inside the parent component.
// selectOnFocus: to automatically select the first option when the input is focused.
// handleHomeEndKeys: to allow users to use the Home and End keys to navigate the options list.
// getOptionLabel: a function that takes an option and returns a string that will be used as the label for the option in the options list.
// renderOption: a function that takes an option and returns a JSX element that will be used to render the option in the options list.
// renderInput: a function that takes an object with some props and returns a JSX element that will be used to render the select input.
// When the select loses focus, the component will check if the value is null and if true, it will set the value to an object with the
// properties id: undefined and value: 'Selecione'.

const SelectStyled = ({
  options,
  label,
  required=true,
  controlledValue,
  onChangeAction,
  key,
  size,
  disablePortal = false,
}: {
  options: readonly {
    id: string | number | undefined;
    value: string | undefined;
  }[];
  label: string;
  required?: boolean;
  controlledValue?: {
    id: string | number | undefined;
    value: string | undefined;
  } | null;
  onChangeAction?: (
    event: SyntheticEvent<Element, Event>,
    value: { id: string | number | undefined; value: string | undefined } | null
  ) => void;
  key?: string;
  size?: 'small' | 'medium';
  disablePortal?: boolean;
}) => {
  const [value, setValue] = useState<{
    id: string | number | undefined;
    value: string | undefined;
  } | null>({ id: undefined, value: label });

  return (
    <Autocomplete
      key={key}
      disablePortal={disablePortal}
      size={size ?? 'small'}
      options={options}
      selectOnFocus
      handleHomeEndKeys
      value={controlledValue ?? value}
      onChange={(event, newValue) => {
        setValue(newValue);
        onChangeAction && onChangeAction(event, newValue);
      }}
      onBlur={(e) => {
        if (value == null) {
          setValue({ id: undefined, value: 'Selecione' });
        }
      }}
      getOptionLabel={(option: any) => option.value?.toUpperCase() ?? ''}
      renderOption={(props, option) => <ListItem {...props}>{option.value?.toUpperCase()}</ListItem>}
      renderInput={(params) => (
        <TextField
          required={required}
          {...params}
          defaultValue={'Selecione'}
          label={label.toUpperCase()}
          sx={{
            '& .MuiInputLabel-root': {
              color: '#312783',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#312783',
            },
          }}
        />
      )}
    />
  );
};

export default SelectStyled;
