import { IconButton, Tooltip } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  Block as BlockIcon,
  Loop as LoopIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Receipt as ReceiptIcon,
  GridOn as TableChartIcon,
  Timeline as TimelineIcon,
} from '@material-ui/icons';
import { isEmpty, isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import { userHasDeleteRole } from '../../../../components/perfil/perfil-utils';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import FollowUpContext from '../../../../contexts/import/follow-up/FollowUpContext';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { setQuery } from '../../../../features/controller/controllerSlice';
import { setErrorFeedback } from '../../../../features/feedback/feedbackSlice';
import {
  selectFollowUp,
  selectFollowUps,
  selectFollowUpsCount,
  selectHistoryModel,
  selectRefresh,
} from '../../../../features/follow-up/followUpSelectors';
import {
  changeToConsultMode,
  changeToCreateMode,
  resetBackgroundMode,
  resetHistoryModel,
  resetMode,
  resetModel,
  resetRefresh,
  resetSubMode,
} from '../../../../features/follow-up/followUpSlice';
import {
  clearStatusAsync,
  fetchAllAsync,
  fetchFollowUpImportacaoWithPaginationAsync,
  inactivateByIdAsync,
} from '../../../../features/follow-up/followUpThunks';
import { servicoActions } from '../../../../features/servico/servicoSlice';
import { transportadoraActions } from '../../../../features/transportadora/transportadoraSlice';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import SimpleConsultPageTemplate from '../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import useConfigurePopUp from '../../../../ts/common/hooks/popUps/useConfigurePopUp';
import FollowUpStatusUtils from '../../../../utils/general/follow-up/FollowUpStatusUtils';
import { formatDate } from '../../../../utils/general/general-utils';
import { formatCnpj } from '../../../../utils/hooks/form/field/formatters';
import { isAlternativeLoadingStatus, isRefresh } from '../../../../utils/store/store-utils';
import { required } from '../../../../utils/validators/field/validator';
import { selectDadosEmbarques } from '../dados-embarque/api/dadosEmbarqueSelectors';
import { fetchAllDadosEmbarqueImportacaoByFilterAsync } from '../dados-embarque/api/dadosEmbarqueThunks';
import FinalSelectMotivoCancelamentoFollowup from './Components/FinalSelectMotivoCancelamentoFollowup';
import FollowUpAdvancedFilterForm from './Components/FollowUpAdvancedFilterForm';

export default function FollowUpConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { status } = useContext(FollowUpContext);

  const dispatch = useDispatch();

  const refresh = useSelector(selectRefresh);
  const followUp = useSelector(selectFollowUp);
  const followUpList = useSelector(selectFollowUps);
  const dadosEmbarque = useSelector(selectDadosEmbarques);
  const historyModel = useSelector(selectHistoryModel);

  const filtredFollowUpList = useMemo(
    () => followUpList.filter((f) => f.numero && !f.numero.includes('-SEQ1')),
    [followUpList]
  );

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const fetchAll = useCallback(() => {
    dispatch(
      fetchFollowUpImportacaoWithPaginationAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
    dispatch(
      fetchAllDadosEmbarqueImportacaoByFilterAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetHistoryModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const configureDependencies = useCallback(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
    dispatch(servicoActions.changeControlTo(IDLE_CONTROL));
    dispatch(transportadoraActions.changeControlTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        dispatch(fetchAllAsync());
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.numero) {
        dispatch(setQuery(historyModel?.last?.numero));
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  const handleConsultarTimelineClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(changeToConsultMode());

        history.push(t('com.muralis.qcx.url.importacaoFollowUpTimeline', { id: data?.id }));
      }
    },
    [history]
  );

  const handleConsultarListClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(changeToConsultMode());

        history.push(`/importacao/follow-up/${data.id}/lista`);
      }
    },
    [history]
  );

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeToConsultMode());

        history.push(t('com.muralis.qcx.url.importacaoFollowUpDetalhes', { id: data?.id }));
      }
    },
    [history, configureDependencies]
  );

  const [handleActivateOrInactivateByIdClickMiddleware, operationOfActivateOrInactivateById] = useConfigurePopUp({
    primaryConfirmCallback: async (data) => {
      dispatch(inactivateByIdAsync(data));
    },
    initialOptions: {
      title: t('com.muralis.qcx.followup.desativarFollowUp'),
      message: t('com.muralis.qcx.followup.informeMotivoCancelamento'),
    },
  });

  const handleConfigureActivateOrInactivateByIdClick = useCallback(
    (row, popupState) => {
      const { id } = row;

      const handleActivateOrInactivateByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const dataOfOperationActivateOrInactivate = {
            id,
          };

          handleActivateOrInactivateByIdClickMiddleware({ args: dataOfOperationActivateOrInactivate });
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const handleButtonNewClick = useCallback(
    (event) => {
      event.stopPropagation();

      configureDependencies();

      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.importacaoFollowUpNovo'));
    },
    [history, followUp, configureDependencies]
  );

  const handleLimparStatus = useCallback((event, data) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();
    }

    dispatch(clearStatusAsync(data));
  }, []);

  const handleConsultarDadosEmbarqueClick = useCallback(
    (event, data) => {
      event.stopPropagation();

      const dadoEmbarque = (dadosEmbarque ?? []).find((dado) => dado?.followUp?.id === data?.id);

      if (dadoEmbarque) {
        dispatch(changeToConsultMode());
        history.push(t('com.muralis.qcx.url.dadosEmbarqueDetalhes', { id: dadoEmbarque?.id }));
      } else {
        dispatch(setErrorFeedback({ message: t('com.muralis.qcx.dadosEmbarque.naoEncontrado') }));
      }
    },
    [dadosEmbarque]
  );

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.processo'),
        headerAlign: 'center',
        align: 'center',
        flex: 200,
        valueGetter: ({ row }) => row?.numero || '-',
      },
      {
        field: 'referencia',
        headerName: t('com.muralis.qcx.referencia'),
        flex: 120,
        valueGetter: ({ row }) => row?.referencias?.[0] || '-',
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.importador.label'),
        flex: 120,
        valueGetter: ({ row }) => row?.importador?.pessoa?.nome?.split(' ')[0] || '-',
      },
      {
        field: 'cliente',
        headerName: t('com.muralis.qcx.cliente.label'),
        flex: 120,
        valueGetter: ({ row }) => row?.clienteFaturamento?.pessoa?.nome?.split(' ')[0] || '-',
      },
      {
        field: 'importador.cnpj',
        headerName: t('com.muralis.qcx.empresa.CNPJ'),
        flex: 140,
        valueGetter: ({ row }) => formatCnpj(row?.importador?.pessoa?.cnpj) || '-',
      },
      {
        field: 'servico',
        headerName: t('com.muralis.qcx.servico.labelSingular'),
        flex: 160,
        valueGetter: ({ row }) => row?.servico?.description || '-',
      },
      {
        field: 'insertionDate',
        headerName: t('com.muralis.qcx.abertura'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 100,
        valueGetter: ({ row }) => formatDate(row?.insertionDate, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY') || '-',
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        flex: 160,
        valueGetter: ({ row }) =>
          FollowUpStatusUtils.getLabelByValue(row?.status) ? t(FollowUpStatusUtils.getLabelByValue(row?.status)) : '-',
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 80,
        renderCell: ({ row: { id, atributosAdicionais, active, status: followUpStatus } }) => (
          <>
            <QCXPopupState popupId="popup-menu-follow-up">
              {(popupState) => (
                <>
                  <Tooltip title={t('com.muralis.qcx.maisOpcoes')} arrow>
                    <IconButton
                      key={`btn-more-options-${id}`}
                      name={`btn-more-options-${id}`}
                      {...bindTrigger(popupState)}
                    >
                      <MoreHorizIcon color="secondary" size={20} />
                    </IconButton>
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarListClick(event, {
                          id,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <TableChartIcon color="secondary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Visualizar Lista'.toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarTimelineClick(event, {
                          id,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <TimelineIcon color="secondary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.visualizarTimeline').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleLimparStatus(event, {
                          id,
                        });
                      }}
                      disabled={!FollowUpStatusUtils.canClearStatus(followUpStatus)}
                    >
                      <QCXListItemIcon>
                        <LoopIcon
                          fontSize="small"
                          color={FollowUpStatusUtils.canClearStatus(followUpStatus) ? 'green' : 'disabled'}
                        />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.limparStatus').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarClick(event, {
                          id,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    {userHasDeleteRole(authInfo, 'importacao-follow-up') && (
                      <>
                        <MenuItem
                          onClick={handleConfigureActivateOrInactivateByIdClick(
                            {
                              id,
                              atributosAdicionais,
                              active,
                            },
                            popupState
                          )}
                        >
                          <QCXListItemIcon>
                            <BlockIcon fontSize="small" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarDadosEmbarqueClick(event, {
                          id,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultarDadosEmbarque').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [
      handleConsultarClick,
      handleConsultarTimelineClick,
      handleConfigureActivateOrInactivateByIdClick,
      handleConsultarDadosEmbarqueClick,
    ]
  );

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloImportacao'),
        name: t('com.muralis.qcx.importacao.label'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.followup.label'),
      },
      default: true,
    },
  ];

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue, advancedFilter }) => {
    const referencia = advancedFilter?.referencia ? advancedFilter?.referencia : undefined;

    dispatch(
      fetchFollowUpImportacaoWithPaginationAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'processo', value: filterInputValue },
        { name: 'referencia', value: referencia },
      ])
    );
    dispatch(
      fetchAllDadosEmbarqueImportacaoByFilterAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
      ])
    );
  }, []);

  const rowsCount = useSelector(selectFollowUpsCount);

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.followup.label'),
      icon: <ReceiptIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.followup.novoRegistroFollowUp'),
        onClick: handleButtonNewClick,
      },
    },
    table: {
      columns,
      rowsCount,
      onConfigChangeCallback: handleConfigChange,
    },
  };

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={filtredFollowUpList}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['importacao-follow-up']}
      advancedFilterFormChildren={FollowUpAdvancedFilterForm}
      advancedFilterKeyAccessors={['referencia']}
    >
      <QCXFinalFormDialog
        key="form-dialog-inactive-follow-up"
        id="form-dialog-inactive-follow-up"
        title={operationOfActivateOrInactivateById.title}
        content={operationOfActivateOrInactivateById.message}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.excluir'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={operationOfActivateOrInactivateById.isPopUpOpen}
        initialValues={{}}
        onSubmit={operationOfActivateOrInactivateById.confirmPrimaryAction}
        onClose={operationOfActivateOrInactivateById.reset}
      >
        {() => (
          <QCXMoveFocusInside>
            <FinalSelectMotivoCancelamentoFollowup
              name="motivoCancelamento"
              label={t('com.muralis.qcx.followup.motivoCancelamento')}
              validate={required}
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
    </SimpleConsultPageTemplate>
  );
}
