import { createSlice } from '@reduxjs/toolkit';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  REGISTER_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  activateByIdAsync,
  fetchAllAsync,
  fetchByIdAsync,
  inactivateByIdAsync,
  registerAsync,
  saveAsync,
  sendDanfeAsync,
  baixarXmlAsync,
  printDanfeAsync,
  fetchByFilterAsync,
  fetchPaginateAsync,
  consultaStatusNfeAsync,
  fetchNotasDanfeByProcessoAsync,
  assinarDanfeAsync,
  cancelDanfeAsync,
  corrigirDanfeAsync,
  trocarNumeroDanfeAsync,
  inutilizarDanfeAsync,
} from './danfeThunks';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import { NO_REFRESH, REFRESH } from '../refresh';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
  },
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  count: 0,
  model: {
    // Campos que descrevem os inputs para os forms de embalagem e adições.
    ignorableFields: {
      adi: {},
      emb: {},
    },
    processo: '', // todo: remover inclusive no back
    di: {
      id: null,
    },
    numeroRandomico: null,
    dataEmissao: null,
    importador: {
      id: null,
    },
    transportador: {
      id: null,
    },
    serieDanfe: null,
    numeroDanfe: null,
    cfop: {
      id: null,
    },
    statusNfe: null,
    tipoDanfe: null,
    dataEntrada: null,
    frete: null,
    infoAdicional: null,
    infoAdicionalFisco: null,
    veiculoPlaca: null,
    veiculoUf: null,
    cst: null,
    cstIpi: null,
    cstPis: null,
    cstCofins: null,
    enquadramentoLegalIpi: null,
    codigoSeloControleIpi: null,
    quantidadeSeloControleIpi: null,
    icmsDiferido: null,
    percentualIcmsDiferido: null,
    valorIcmsDiferido: null,
    veiculoAntt: null, // todo: remover inclusive no back
    volumes: [], // embalagens
    despesas: [],
    adicoes: [],
    nfe: null, // Não é preenchido no front.
    numeroProtocoloAutorizacaoUso: null,
    dataHoraAutorizacao: null,
  },
  notas: [], // numeros das notas para um processo
  related: {
    list: {
      selectionAvailableItem: [],
      selectionChangedItem: [],
      selectionItem: [],
      selectionDanfe: [],
    },
    model: {
      transferenciaItem: {},
      danfeMae: {},
    },
  },
};

const danfeSlice = createSlice({
  name: 'danfe',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToRegisterModeDanfe: (state) => {
      state.mode.main = REGISTER_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    addToList: (state, action) => {
      if (Array.isArray(action.payload?.data)) {
        state.list = [...action.payload.data, ...state.list];
      } else {
        state.list = [action.payload.data, ...state.list];
      }
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    resetList: (state) => {
      state.list = [];
    },
    updateModelVolumes: (state, action) => {
      state.model.volumes = action.payload;
    },
    updateModelAdicoes: (state, action) => {
      state.model.adicoes = action.payload;
    },
    updateModelAdicao: (state, action) => {
      if (!state.model.ignorableFields) {
        state.model.ignorableFields = {};
      }
      state.model.ignorableFields.adi = action.payload;
    },
    // Atualiza uma adição na lista, e também no model do ignorableFields.
    // Recebe um { modelo, lista }. É equivalente a despachar um
    // updateModelAdicoes e um updateModel adição juntos, mas causa uma só
    // atualização na store. Qualquer campo pode ser null, e não causará atualização.
    updateModelAdicaoNaLista: (state, action) => {
      if (!state.model.ignorableFields) {
        state.model.ignorableFields = {};
      }
      if (action.payload.modelo) {
        state.model.ignorableFields.adi = action.payload.modelo;
      }
      if (action.payload.lista) {
        state.model.adicoes = action.payload.lista;
      }
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    setRelatedSelectionAvailableItemList: (state, action) => {
      state.related.list.selectionAvailableItem = action.payload;
    },
    resetRelatedSelectionAvailableItemList: (state) => {
      state.related.list.selectionAvailableItem = [];
    },
    setRelatedSelectionChangedItemList: (state, action) => {
      state.related.list.selectionChangedItem = action.payload;
    },
    resetRelatedSelectionChangedItemList: (state) => {
      state.related.list.selectionChangedItem = [];
    },
    setRelatedSelectionDanfeList: (state, action) => {
      state.related.list.selectionDanfe = action.payload;
    },
    setRelatedSelectionItemList: (state, action) => {
      state.related.list.selectionItem = action.payload;
    },
    resetRelatedSelectionItemList: (state) => {
      state.related.list.selectionItem = [];
    },
    setRelatedDanfeMae: (state, action) => {
      state.related.model.danfeMae = action.payload;
    },
    resetRelatedDanfeMae: (state) => {
      state.related.model.danfeMae = initialState.related.model.danfeMae;
    },
    setRelatedTransferenciaItem: (state, action) => {
      state.related.model.transferenciaItem = action.payload;
    },
    resetRelatedTransferenciaItem: (state) => {
      state.related.model.transferenciaItem = initialState.related.model.transferenciaItem;
    },
    addItemToChangedSelectionList: (state, action) => {
      const item = action.payload;

      if (state.related.list.selectionChangedItem?.indexOf(item) === -1) {
        state.related.list.selectionChangedItem = [...state.related.list.selectionChangedItem, item];
      }
    },
    removeItemFromChangedSelectionList: (state, action) => {
      state.related.list.selectionChangedItem = state.related.list.selectionChangedItem?.filter(
        (item) => item !== action.payload
      );
    },
    clearNotas: (state) => {
      state.notas = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.response.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregamentoDANFE', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.model = action.payload.response.data;
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroBuscaDANFE', { erro: action.error.message }),
        };
      })
      .addCase(fetchByFilterAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroFiltrarDANFE', { erro: action.error.message }),
        };
      })
      .addCase(fetchPaginateAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchPaginateAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data?.itens;
        state.count = action?.payload?.data?.totalNumberOfItens || 0;
      })
      .addCase(fetchPaginateAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroFiltrarDANFE', { erro: action.error.message }),
        };
      })
      .addCase(registerAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(registerAsync.fulfilled, (state) => {
        state.status = IDLE_STATUS;
      })
      .addCase(registerAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCadastroDANFE', { erro: action.error.message }),
        };
      })
      .addCase(saveAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(saveAsync.fulfilled, (state) => {
        state.status = IDLE_STATUS;
      })
      .addCase(saveAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroSalvarDANFEStatus', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DANFEAtivada', { codigo: description });

        if (state.response.status === 200) {
          state.list = state.list.map((current) => {
            if (current.id === action.payload.response.data.id) {
              return action.payload.response.data;
            }
            return current;
          });
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarDANFE', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DANFEInativado', { codigo: description });

        if (state.response.status === 200) {
          state.list = state.list.map((current) => {
            if (current.id === action.payload.response.data.id) {
              return action.payload.response.data;
            }
            return current;
          });
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarDANFE', { erro: action.error.message }),
        };
      })
      .addCase(sendDanfeAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(sendDanfeAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DANFEEmitidaSucesso');

        if (state.response.data.id) {
          // Update on list
          const idx = state.list?.findIndex((d) => d.id === state.response.data.id);
          const newList = state.list?.slice();
          if (Array.isArray(newList)) {
            newList[idx] = state.response.data;
            state.list = newList;
          }
        }
      })
      .addCase(sendDanfeAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroEmissaoDANFE', { erro: action.error.message }),
        };
      })
      .addCase(baixarXmlAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(baixarXmlAsync.fulfilled, (state) => {
        state.status = SUCCESS_STATUS;
      })
      .addCase(baixarXmlAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroRecuperarXML', { erro: action.error.message }),
        };
      })
      .addCase(printDanfeAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(printDanfeAsync.fulfilled, (state) => {
        state.status = SUCCESS_STATUS;
      })
      .addCase(printDanfeAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroGerarPDF', { erro: action.error.message }),
        };
      })
      .addCase(consultaStatusNfeAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(consultaStatusNfeAsync.fulfilled, (state) => {
        state.status = SUCCESS_STATUS;
      })
      .addCase(consultaStatusNfeAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroGerarPDF', { erro: action.error.message }),
        };
      })
      .addCase(fetchNotasDanfeByProcessoAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchNotasDanfeByProcessoAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.notas = action.payload.data;
      })
      .addCase(fetchNotasDanfeByProcessoAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregamentoDANFE', { erro: action.error.message }),
        };
      })
      .addCase(assinarDanfeAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(assinarDanfeAsync.fulfilled, (state) => {
        state.status = SUCCESS_STATUS;
      })
      .addCase(assinarDanfeAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAssinarDANFE', { erro: action.error.message }),
        };
      })
      .addCase(cancelDanfeAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(cancelDanfeAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
        const { id } = action.meta.arg;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.danfeCanceladaComSucesso', { id });

        if (state.list) {
          state.list = state.list.map((danfe) => {
            if (danfe.id === id) {
              return {
                ...danfe,
                ...action.payload.response.data,
              };
            }
            return danfe;
          });
        }
      })
      .addCase(cancelDanfeAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCancelarDANFE', { erro: action.error.message }),
        };
      })
      .addCase(corrigirDanfeAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(corrigirDanfeAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
        const { id } = action.meta.arg;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.danfeCorrigidaComSucesso', { id });

        if (state.list) {
          state.list = state.list.map((danfe) => {
            if (danfe.id === id) {
              return {
                ...danfe,
                ...action.payload.response.data,
              };
            }
            return danfe;
          });
        }
      })
      .addCase(trocarNumeroDanfeAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(trocarNumeroDanfeAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
        const { id, numero } = action.meta.arg;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.numeroDanfeAlteradoComSucesso', { id });

        if (state.list) {
          state.list = state.list.map((danfe) => {
            if (danfe.id === id) {
              return {
                ...danfe,
                numeroDanfe: Number(numero),
              };
            }
            return danfe;
          });
        }
      })
      .addCase(corrigirDanfeAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCorrigirDANFE', { erro: action.error.message }),
        };
      })
      .addCase(trocarNumeroDanfeAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroTrocarNumeroDANFE', { erro: action.error.message }),
        };
      })
      .addCase(inutilizarDanfeAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.danfeInutilizadaComSucesso');
      })
      .addCase(inutilizarDanfeAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inutilizarDanfeAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInutilizarDANFE', { erro: action.error.message }),
        };
      });
  },
});

const {
  addToList,
  changeStatusTo,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  clearError,
  clearResponse,
  failure,
  loading,
  resetList,
  resetMode,
  setModel,
  resetModel,
  resetStatus,
  setError,
  setResponse,
  success,
  updateOnList,
  updateModelVolumes,
  updateModelAdicoes,
  updateModelAdicao,
  updateModelAdicaoNaLista,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  preparingAction,
  refresh,
  resetRefresh,
  setRelatedSelectionAvailableItemList,
  resetRelatedSelectionAvailableItemList,
  setRelatedSelectionChangedItemList,
  resetRelatedSelectionChangedItemList,
  setRelatedSelectionItemList,
  resetRelatedSelectionItemList,
  setRelatedDanfeMae,
  resetRelatedDanfeMae,
  setRelatedTransferenciaItem,
  resetRelatedTransferenciaItem,
  addItemToChangedSelectionList,
  removeItemFromChangedSelectionList,
  setRelatedSelectionDanfeList,
  changeToRegisterModeDanfe,
  clearNotas,
} = danfeSlice.actions;

const danfeActions = {
  ...danfeSlice.actions,
};

export {
  danfeActions,
  addToList,
  changeStatusTo,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  clearError,
  clearResponse,
  failure,
  loading,
  resetList,
  resetMode,
  setModel,
  resetModel,
  resetStatus,
  setError,
  setResponse,
  success,
  updateOnList,
  updateModelVolumes,
  updateModelAdicoes,
  updateModelAdicao,
  updateModelAdicaoNaLista,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  preparingAction,
  refresh,
  resetRefresh,
  setRelatedSelectionAvailableItemList,
  resetRelatedSelectionAvailableItemList,
  setRelatedSelectionChangedItemList,
  resetRelatedSelectionChangedItemList,
  setRelatedSelectionDanfeList,
  changeToRegisterModeDanfe,
  setRelatedSelectionItemList,
  resetRelatedSelectionItemList,
  setRelatedDanfeMae,
  resetRelatedDanfeMae,
  setRelatedTransferenciaItem,
  resetRelatedTransferenciaItem,
  addItemToChangedSelectionList,
  removeItemFromChangedSelectionList,
  clearNotas,
};

export default danfeSlice.reducer;
