/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectClienteAutocomplete from '../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectSituacaoEspecialDespachoAutocomplete from '../../shared-components/select-situacao-especial-despacho/QCXSelectSituacaoEspecialDespachoAutocomplete';
import { selectDetalhamentoOperacaosSemNotaFiscal } from '../../features/detalhamento-operacao-sem-nota-fiscal/detalhamentoOperacaoSemNotaFiscalSlice';
import { selectSituacoesEspeciaisDespacho } from '../../features/situacao-especial-despacho/situacaoEspecialDespachoSlice';
import { selectTiposDocumentoFiscal } from '../../features/tipo-documento-fiscal/tipoDocumentoFiscalSlice';
import TipoDocumentoFiscalUtils from '../../shared-components/select-tipo-documento-fiscal/TipoDocumentoFiscalUtils';
import SituacaoEspecialDespachoUtils from '../../shared-components/select-situacao-especial-despacho/SituacaoEspecialDespachoUtils';
import QCXSelectTipoDocumentoFiscalAutocomplete from '../../shared-components/select-tipo-documento-fiscal/QCXSelectTipoDocumentoFiscalAutocomplete';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import { selectRelatedModelFollowUp } from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSelectors';
import QCXSelectDetalhamentoOperacaoSemNotaFiscalAutocomplete from '../../shared-components/select-detalhamento-operacao-sem-nota-fiscal/QCXSelectDetalhamentoOperacaoSemNotaFiscalAutocomplete';
import DetalhamentoOperacaoSemNotaFiscalUtils from '../../shared-components/select-detalhamento-operacao-sem-nota-fiscal/DetalhamentoOperacaoSemNotaFiscalUtils';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXSelectUnidadeLocalRFBAutocomplete from '../../shared-components/select-unidade-local-rfb/QCXSelectUnidadeLocalRFBAutocomplete';
import QCXFinalNumericDecimalFieldRender from '../fatura/QCXFinalNumericDecimalFieldRender';
import QCXSelectFormaExportacaoAutocomplete from '../../shared-components/select-forma-exportacao/QCXSelectFormaExportacaoAutocomplete';
import QCXSelectViaEspecialTransporteAutocomplete from '../../shared-components/select-via-especial-transporte/QCXSelectViaEspecialTransporteAutocomplete';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import { isCalculadaStatus } from '../../utils/general/operations/operationUtils';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import { paisActions } from '../../features/pais/paisSlice';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXOperacaoForaDoRecintoFormGroup from './QCXOperacaoForaDoRecintoFormGroup';
import QCXSelectRecintoAduaneiroAutocomplete from '../../shared-components/select-recinto-aduaneiro/QCXSelectRecintoAduaneiroAutocomplete';
import QCXSelectEmpresaEstrangeiraAutocomplete from '../../shared-components/final-select-empresa-estrangeira-field/QCXSelectEmpresaEstrangeiraAutocomplete';
import { selectUnidadesLocaisRfb } from '../../features/unidade-local-rfb/unidadeLocalRfbSlice';
import { fetchByFilterAsync as filterSetorLotacao } from '../../features/setor-lotacao/setorLotacaoSlice';
import { fetchAllAsync as fetchAllRecintoAduaneiro } from '../../features/recinto-aduaneiro/recintoAduaneiroSlice';
import { MatcherConfigureUtils } from '../../utils/general/general-utils';
import QCXControlBoxModeloInformacaoComplementarOld from '../../shared-components/modelo-informacao-complementar/QCXFinalControlBoxModeloInformacaoComplementarOld';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  checkboxGridField: {
    paddingBottom: '30px',
  },
}));

export default function QCXDeclaracaoUnicaExportacaoBasicasForm({
  initialValues,
  handleSubmit,
  isConsult,
  isCreate,
  isLocked,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllRecintoAduaneiro());
  }, []);

  const unidadesLocaisRfb = useSelector(selectUnidadesLocaisRfb);
  const situacoesEspeciaisDespacho = useSelector(selectSituacoesEspeciaisDespacho);
  const tiposDocumentosFiscais = useSelector(selectTiposDocumentoFiscal);
  const detalhamentosOperacoesSemNotaFiscal = useSelector(selectDetalhamentoOperacaosSemNotaFiscal);
  const relatedFollowUp = useSelector(selectRelatedModelFollowUp);
  const [recintosAlfandegadosDespacho, setRecintosAlfandegadosDespacho] = useState([]);
  const [recintosAlfandegadosEmbarque, setRecintosAlfandegadosEmbarque] = useState([]);

  dispatch(paisActions.changeControlTo(AUTO_LOAD_CONTROL));

  const followUpHasMetadata = useMemo(() => relatedFollowUp?.metadata, [relatedFollowUp]);

  const modeloInformacaoComplementarFieldProps = useMemo(
    () => ({
      rootName: 'atributosAdicionais',
      meta: {
        visible: {
          informacoesComplementaresCompleta: isCalculadaStatus(initialValues?.calculada),
        },
      },
      modeloInformacaoComplementar: {
        name: 'modeloInformacaoComplementar.id',
        label: t('com.muralis.qcx.modeloInformacaoComplementar.label'),
      },
      informacoesComplementares: {
        name: 'informacoesComplementares',
        label: t('com.muralis.qcx.importador.informacoesComplementares'),
        maxLength: 7800,
        rows: 15,
        helperText: t('com.muralis.qcx.mensagem.calculoDUE'),
        inputProps: {
          className: classes.informacoesComplementares,
        },
      },
      informacoesComplementaresCompleta: {
        name: 'informacoesComplementaresCompleta',
        label: t('com.muralis.qcx.importador.informacoesComplementares'),
        maxLength: 7800,
        rows: 15,
        helperText: t('com.muralis.qcx.mensagem.calculoDIDUIMP'),
        inputProps: {
          className: classes.informacoesComplementares,
        },
      },
      referenciaCliente: {
        id: 'checkbox-field-referencia-cliente',
        key: 'checkbox-field-referencia-cliente',
        name: 'atributosAdicionais.referenciaCliente',
        label: 'Referência do Cliente',
      },
      transportadora: {
        id: 'checkbox-field-transportadora',
        key: 'checkbox-field-transportadora',
        name: 'atributosAdicionais.transportadora',
        label: t('com.muralis.qcx.transportadora.labelPlural'),
      },
      quadroResumoAdicoes: {
        id: 'checkbox-field-quadro-resumo-adicoes',
        key: 'checkbox-field-quadro-resumo-adicoes',
        name: 'atributosAdicionais.quadroResumoAdicoes',
        label: t('com.muralis.qcx.quadroResumoAdicoes'),
      },
      recintoAlfandegado: {
        id: 'checkbox-field-recinto-alfandegado',
        key: 'checkbox-field-recinto-alfandegado',
        name: 'atributosAdicionais.recintoAlfandegado',
        label: t('com.muralis.qcx.recintoAlfandegado'),
      },
      fundamentoLegalIi: {
        id: 'checkbox-field-fundamento-legal-ii',
        key: 'checkbox-field-fundamento-legal-ii',
        name: 'atributosAdicionais.fundamentoLegalIi',
        label: t('com.muralis.qcx.legislacao.fundamentoLegalII'),
      },
      fundamentoLegalIpi: {
        id: 'checkbox-field-fundamento-legal-ipi',
        key: 'checkbox-field-fundamento-legal-ipi',
        name: 'atributosAdicionais.fundamentoLegalIpi',
        label: t('com.muralis.qcx.legislacao.fundamentoLegalIPI'),
      },
      fundamentoLegalIcms: {
        id: 'checkbox-field-fundamento-legal-icms',
        key: 'checkbox-field-fundamento-legal-icms',
        name: 'atributosAdicionais.fundamentoLegalIcms',
        label: t('com.muralis.qcx.legislacao.fundamentoLegalICMS'),
      },
      despachantesAduaneiros: {
        id: 'checkbox-field-despachantes-aduaneiros',
        key: 'checkbox-field-despachantes-aduaneiros',
        name: 'atributosAdicionais.despachantesAduaneiros',
        label: t('com.muralis.qcx.despachante.despachantesAduaneiros'),
      },
    }),
    [initialValues, t]
  );

  const operacaoForaRecintoDespachoFieldProps = useMemo(
    () => ({
      cpfCnpjResponsavelLocalDespacho: {
        id: 'masked-text-cpf-cnpj-responsavel-local-despacho-despacho-field',
        key: 'masked-text-cpf-cnpj-responsavel-local-despacho-despacho-field',
        name: 'atributosAdicionais.cnpjCpfResponsavelLocalDespacho',
        label: t('com.muralis.qcx.CPFCNPJResponsavelLocalDespacho'),
      },
      latitude: {
        id: 'text-field-latitude-despacho',
        key: 'text-field-latitude-despacho',
        name: 'atributosAdicionais.latitudeDespacho',
        label: t('com.muralis.qcx.CPFCNPJResponsavelLocalDespacho'),
      },
      longitude: {
        id: 'text-field-longitude-despacho',
        key: 'text-field-longitude-despacho',
        name: 'atributosAdicionais.longitudeDespacho',
      },
      endereco: {
        id: 'text-field-endereco-despacho',
        key: 'text-field-endereco-despacho',
        name: 'atributosAdicionais.enderecoDespacho',
      },
      despachoDomiciliar: {
        id: 'checkbox-despacho-domiciliar-despacho',
        key: 'checkbox-despacho-domiciliar-despacho',
        name: 'atributosAdicionais.despachoDomiciliarDespacho',
      },
    }),
    []
  );

  const operacaoForaRecintoEmbarqueFieldProps = useMemo(
    () => ({
      cpfCnpjResponsavelLocalDespacho: {
        id: 'masked-text-cpf-cnpj-responsavel-local-despacho-embarque-field',
        key: 'masked-text-cpf-cnpj-responsavel-local-despacho-embarque-field',
        name: 'atributosAdicionais.cnpjCpfResponsavelLocalEmbarque',
        label: t('com.muralis.qcx.CPFCNPJResponsavelLocalEmbarque'),
      },
      latitude: {
        id: 'text-field-latitude-embarque',
        key: 'text-field-latitude-embarque',
        name: 'atributosAdicionais.latitudeEmbarque',
      },
      longitude: {
        id: 'text-field-longitude-embarque',
        key: 'text-field-longitude-embarque',
        name: 'atributosAdicionais.longitudeEmbarque',
      },
      endereco: {
        id: 'text-field-endereco-embarque',
        key: 'text-field-endereco-embarque',
        name: 'atributosAdicionais.enderecoEmbarque',
      },
      despachoDomiciliar: {
        id: 'checkbox-despacho-domiciliar-embarque',
        key: 'checkbox-despacho-domiciliar-embarque',
        name: 'atributosAdicionais.despachoDomiciliarEmbarque',
      },
    }),
    []
  );

  const importadorFilterPredicate = useCallback(
    (empresaEstrangeira) => empresaEstrangeira?.importador && empresaEstrangeira?.active,
    []
  );

  const configureTipoDocumentoFiscalFilterPredicate = useCallback(() => {
    const tipoDocumentoFiscalFilterPredicate = (tipoDocumentoFiscal) =>
      followUpHasMetadata
        ? tipoDocumentoFiscal?.code !== TipoDocumentoFiscalUtils.NOTA_FISCAL_FORMULARIO
        : ![TipoDocumentoFiscalUtils.NOTA_FISCAL_FORMULARIO, TipoDocumentoFiscalUtils.NOTA_FISCAL_ELETRONICA].includes(
            tipoDocumentoFiscal?.code
          );

    return tipoDocumentoFiscalFilterPredicate;
  }, [followUpHasMetadata]);

  const onUnidadeLocalRfbOnchange = useCallback(
    () => (value) => {
      const unidadeLocalRfbSelected = unidadesLocaisRfb.find((item) => item?.id === value);

      dispatch(
        filterSetorLotacao([
          {
            name: 'unidadeLocalRfb',
            value,
          },
        ])
      );

      const recintosAduaneiros = unidadeLocalRfbSelected?.recintosAduaneiro || [];

      setRecintosAlfandegadosDespacho(recintosAduaneiros);

      // dispatch(
      //   setRecintoAduaneiroList(
      //     {
      //       data: recintosAduaneiros,
      //     }
      //   )
      // );
    },
    [unidadesLocaisRfb]
  );

  const onUnidadeLocalRfbEmbarqueChange = useCallback(
    () => (value) => {
      const unidadeLocalRfbSelected = unidadesLocaisRfb.find((item) => item?.id === value);

      const recintosAduaneiros = unidadeLocalRfbSelected?.recintosAduaneiro || [];

      setRecintosAlfandegadosEmbarque(recintosAduaneiros);
    },
    [unidadesLocaisRfb]
  );

  useEffect(() => {
    const idUrfDespachoOuEntrada = initialValues?.urfDespacho?.id || initialValues?.urfEntrada?.id;

    if (idUrfDespachoOuEntrada) {
      onUnidadeLocalRfbOnchange(idUrfDespachoOuEntrada);
    }
  }, [initialValues, onUnidadeLocalRfbOnchange]);

  const onOperacaoTriangularChange = useCallback(
    (form) => (value) => {
      if (!value) {
        form.change('atributosAdicionais.paisDestino.id', undefined);
      }
    },
    []
  );

  const onSituacaoEspecialDespachoChange = useCallback(
    (form) => (value) => {
      if (value && !followUpHasMetadata) {
        const embarqueAntecipado =
          SituacaoEspecialDespachoUtils.isEmbarqueAntecipado(situacoesEspeciaisDespacho)(value);

        if (embarqueAntecipado) {
          const documentoFiscalSemNotaFiscal = tiposDocumentosFiscais?.find(
            (documentoFiscal) => documentoFiscal?.code === TipoDocumentoFiscalUtils.SEM_NOTA_FISCAL
          );

          form.change('atributosAdicionais.tipoDocumentoFiscal.id', documentoFiscalSemNotaFiscal?.id);
        }
      }
    },
    [followUpHasMetadata, situacoesEspeciaisDespacho, tiposDocumentosFiscais]
  );

  const onOperacaoForaRecintoDespachoChange = useCallback(
    (form) => (value) => {
      if (value) {
        form.change('atributosAdicionais.recintoAlfandegadoDespacho.id', undefined);
        form.resetFieldState('urfDespacho.id');

        return;
      }

      form.change('atributosAdicionais.cnpjCpfResponsavelLocalDespacho', undefined);

      form.change('atributosAdicionais.urfDespachoDifereEmbarque', undefined);
      form.change('atributosAdicionais.latitudeDespacho', undefined);
      form.change('atributosAdicionais.longitudeDespacho', undefined);
      form.change('atributosAdicionais.enderecoDespacho', undefined);
      form.change('atributosAdicionais.despachoDomiciliarDespacho', undefined);
    },
    []
  );

  const onOperacaoForaRecintoEmbarqueChange = useCallback(
    (form) => (value) => {
      if (value) {
        form.change('atributosAdicionais.recintoAlfandegadoEmbarque.id', undefined);
        form.resetFieldState('urfEntrada.id');

        return;
      }

      form.change('atributosAdicionais.cnpjCpfResponsavelLocalEmbarque', undefined);

      form.change('atributosAdicionais.latitudeEmbarque', undefined);
      form.change('atributosAdicionais.longitudeEmbarque', undefined);
      form.change('atributosAdicionais.enderecoEmbarque', undefined);
      form.change('atributosAdicionais.despachoDomiciliarEmbarque', undefined);
    },
    []
  );

  const onUrfDespachoDifereEmbarqueChange = useCallback(
    (form) => (value) => {
      if (!value) {
        form.change('atributosAdicionais.cnpjCpfResponsavelLocalEmbarque', undefined);

        form.change('urfEntrada.id', undefined);
        form.resetFieldState('urfEntrada.id');

        form.change('atributosAdicionais.operacaoForaRecintoEmbarque', undefined);
        form.change('atributosAdicionais.latitudeEmbarque', undefined);
        form.change('atributosAdicionais.longitudeEmbarque', undefined);
        form.change('atributosAdicionais.enderecoEmbarque', undefined);
        form.change('atributosAdicionais.despachoDomiciliarEmbarque', undefined);
        form.change('atributosAdicionais.recintoAlfandegadoEmbarque.id', undefined);
      }
    },
    []
  );

  const urfDespachoDifereEmbarque = useCallback((values) => values?.atributosAdicionais?.urfDespachoDifereEmbarque, []);

  const operacaoForaRecintoDespacho = useCallback(
    (values) => values?.atributosAdicionais?.operacaoForaRecintoDespacho,
    []
  );

  const operacaoForaRecintoEmbarque = useCallback(
    (values) => values?.atributosAdicionais?.operacaoForaRecintoEmbarque,
    []
  );

  const operacaoTriangular = useCallback((values) => values?.atributosAdicionais?.operacaoTriangular, []);

  const situacaoEspecialDespachoSemSaidaDaMercadoriaPais = useCallback(
    (values) =>
      SituacaoEspecialDespachoUtils.isSemSaidaMercadoriaPais(situacoesEspeciaisDespacho)(
        values?.atributosAdicionais?.situacaoEspecialDespacho?.id
      ),
    [situacoesEspeciaisDespacho]
  );

  const tipoDocumentoFiscalNotaFiscalEletronica = useCallback(
    (values) =>
      TipoDocumentoFiscalUtils.isNotaFiscalEletronica(tiposDocumentosFiscais)(
        values?.atributosAdicionais?.tipoDocumentoFiscal?.id
      ),
    [tiposDocumentosFiscais]
  );

  const isSomeDetalhamentoOperacaoSemNotaFiscalBy = useCallback(
    (values, codesToCompare) =>
      MatcherConfigureUtils.getGeneralByCodes(detalhamentosOperacoesSemNotaFiscal)(
        values?.atributosAdicionais?.detalhamentoOperacaoSemNotaFiscal?.id,
        codesToCompare
      ),
    [detalhamentosOperacoesSemNotaFiscal]
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: 'urfDespacho.id',
        fn: onUnidadeLocalRfbOnchange,
      },
      {
        name: 'urfEntrada.id',
        fn: onUnidadeLocalRfbEmbarqueChange,
      },
      {
        name: 'atributosAdicionais.operacaoTriangular',
        fn: onOperacaoTriangularChange,
      },
      {
        name: 'atributosAdicionais.operacaoForaRecintoDespacho',
        fn: onOperacaoForaRecintoDespachoChange,
      },
      {
        name: 'atributosAdicionais.operacaoForaRecintoEmbarque',
        fn: onOperacaoForaRecintoEmbarqueChange,
      },
      {
        name: 'atributosAdicionais.urfDespachoDifereEmbarque',
        fn: onUrfDespachoDifereEmbarqueChange,
      },
      {
        name: 'atributosAdicionais.situacaoEspecialDespacho.id',
        fn: onSituacaoEspecialDespachoChange,
      },
    ],
    [
      onUnidadeLocalRfbOnchange,
      onOperacaoTriangularChange,
      onOperacaoForaRecintoDespachoChange,
      onOperacaoForaRecintoEmbarqueChange,
      onUrfDespachoDifereEmbarqueChange,
      onSituacaoEspecialDespachoChange,
    ]
  );

  const tipoDocumentoFiscalNotaEletronica = useMemo(
    () =>
      tiposDocumentosFiscais?.find(
        (tipoDocumentoFiscal) => tipoDocumentoFiscal?.code === TipoDocumentoFiscalUtils.NOTA_FISCAL_ELETRONICA
      ),
    [tiposDocumentosFiscais]
  );

  const initialValuesDue = useMemo(
    () =>
      isCreate && followUpHasMetadata
        ? {
            ...initialValues,
            atributosAdicionais: {
              ...initialValues?.atributosAdicionais,
              tipoDocumentoFiscal: tipoDocumentoFiscalNotaEletronica,
            },
          }
        : initialValues,
    [isCreate, initialValues, followUpHasMetadata, tipoDocumentoFiscalNotaEletronica]
  );

  return (
    <QCXFormStepWizard
      initialValues={initialValuesDue}
      onSubmit={handleSubmit}
      onChangeListeners={onChangeListeners}
      controlComponentProps={controlComponentProps}
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>
          <QCXFormTitle title={t('com.muralis.qcx.fichaBasicas')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid item container alignContent="flex-start" xs={12} sm={12} md={6} lg={6}>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectClienteAutocomplete
                  id="select-field-exportador"
                  key="select-field-exportador"
                  name="importador.id"
                  filter={{
                    exportador: true,
                  }}
                  label={t('com.muralis.qcx.exportador.label')}
                  initialValues={values}
                  disabled
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectSituacaoEspecialDespachoAutocomplete
                  id="select-field-situacao-especial-despacho"
                  key="select-field-situacao-especial-despacho"
                  name="atributosAdicionais.situacaoEspecialDespacho.id"
                  label={t('com.muralis.qcx.situacaoEspecialDespacho.label')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectTipoDocumentoFiscalAutocomplete
                  id="select-field-tipo-documento-fiscal"
                  key="select-field-tipo-documento-fiscal"
                  name="atributosAdicionais.tipoDocumentoFiscal.id"
                  label={t('com.muralis.qcx.tipoDocumentoFiscal.label')}
                  initialValues={values}
                  disabled={isConsult || followUpHasMetadata}
                  fieldProps={{ validate: requiredValidator }}
                  configureFilterPredicate={configureTipoDocumentoFiscalFilterPredicate}
                  required
                />
              </Grid>
              {!tipoDocumentoFiscalNotaFiscalEletronica(values) && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectDetalhamentoOperacaoSemNotaFiscalAutocomplete
                    id="select-field-detalhamento-operacao-sem-nota-fiscal"
                    key="select-field-detalhamento-operacao-sem-nota-fiscal"
                    name="atributosAdicionais.detalhamentoOperacaoSemNotaFiscal.id"
                    label={t('com.muralis.qcx.detalhamentoOperacaoSemNotaFiscal')}
                    disabled={isConsult}
                    initialValues={values}
                  />
                </Grid>
              )}
              {isSomeDetalhamentoOperacaoSemNotaFiscalBy(values, [
                DetalhamentoOperacaoSemNotaFiscalUtils.OUTRAS_EXPORTACOES_TEMPORARIAS_SEM_NOTA,
                DetalhamentoOperacaoSemNotaFiscalUtils.OUTRAS_SAIDAS_DEFINITIVAS_SEM_NOTA,
              ]) && (
                <Grid
                  item
                  {...(isConsult
                    ? {
                        style: {
                          paddingBottom: '20px',
                        },
                      }
                    : {})}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <QCXFinalMultilineTextField
                    id="text-justificativa-nota-fiscal-multiline-field"
                    key="text-justificativa-nota-fiscal-multiline-field"
                    name="atributosAdicionais.justificativaDispensaNotaFiscal"
                    label={t('com.muralis.qcx.justificativaDispensaNotaFiscal')}
                    disabled={isConsult}
                    rows={4}
                    maxLength={300}
                    remaningCharactersInfo
                  />
                </Grid>
              )}
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectUnidadeLocalRFBAutocomplete
                  id="select-field-urf-despacho"
                  key="select-field-urf-despacho"
                  name="urfDespacho.id"
                  label={t('com.muralis.qcx.fatura.URFdespacho')}
                  initialValues={values}
                  disabled={isConsult}
                  fieldProps={{ validate: requiredValidator }}
                  required
                />
              </Grid>
              {!isSomeDetalhamentoOperacaoSemNotaFiscalBy(values, [
                DetalhamentoOperacaoSemNotaFiscalUtils.OUTRAS_EXPORTACOES_TEMPORARIAS_SEM_NOTA,
                DetalhamentoOperacaoSemNotaFiscalUtils.OUTRAS_SAIDAS_DEFINITIVAS_SEM_NOTA,
              ]) && (
                <Grid item className={classes.checkboxGridField} xs={12} sm={12} md={12} lg={12}>
                  <QCXFinalCheckboxField
                    id="checkbox-operacao-fora-do-recinto-despacho"
                    key="checkbox-operacao-fora-do-recinto-despacho"
                    name="atributosAdicionais.operacaoForaRecintoDespacho"
                    label={t('com.muralis.qcx.operacaoForaRecinto')}
                    disabled={isConsult}
                  />
                </Grid>
              )}
              {operacaoForaRecintoDespacho(values) && (
                <QCXOperacaoForaDoRecintoFormGroup
                  modes={{ isConsult }}
                  fieldProps={operacaoForaRecintoDespachoFieldProps}
                />
              )}
              {!operacaoForaRecintoDespacho(values) && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectRecintoAduaneiroAutocomplete
                    id="select-field-recinto-alfandegado-despacho"
                    key="select-field-recinto-alfandegado-despacho"
                    name="atributosAdicionais.recintoAlfandegadoDespacho.id"
                    label={t('com.muralis.qcx.recintoAlfandegado')}
                    staticValues={recintosAlfandegadosDespacho}
                    initialValues={values}
                    disabled={isConsult}
                  />
                </Grid>
              )}
              {!isSomeDetalhamentoOperacaoSemNotaFiscalBy(values, [
                DetalhamentoOperacaoSemNotaFiscalUtils.OUTRAS_EXPORTACOES_TEMPORARIAS_SEM_NOTA,
                DetalhamentoOperacaoSemNotaFiscalUtils.OUTRAS_SAIDAS_DEFINITIVAS_SEM_NOTA,
              ]) &&
                !situacaoEspecialDespachoSemSaidaDaMercadoriaPais(values) && (
                  <Grid item className={classes.checkboxGridField} xs={12} sm={12} md={12} lg={12}>
                    <QCXFinalCheckboxField
                      id="checkbox-urf-despacho-difere-embarque"
                      key="checkbox-urf-despacho-difere-embarque"
                      name="atributosAdicionais.urfDespachoDifereEmbarque"
                      label={t('com.muralis.qcx.URFDespachoDifereEmbarque')}
                      disabled={isConsult}
                    />
                  </Grid>
                )}
              {urfDespachoDifereEmbarque(values) && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectUnidadeLocalRFBAutocomplete
                    id="select-field-urf-embarque"
                    key="select-field-urf-embarque"
                    name="urfEntrada.id"
                    label={t('com.muralis.qcx.URFEmbarque')}
                    initialValues={values}
                    disabled={isConsult}
                    fieldProps={{ validate: requiredValidator }}
                    required
                  />
                </Grid>
              )}
              {urfDespachoDifereEmbarque(values) && (
                <Grid item className={classes.checkboxGridField} xs={12} sm={12} md={6} lg={6}>
                  <QCXFinalCheckboxField
                    id="checkbox-operacao-fora-do-recinto-embarque"
                    key="checkbox-operacao-fora-do-recinto-embarque"
                    name="atributosAdicionais.operacaoForaRecintoEmbarque"
                    label={t('com.muralis.qcx.operacaoForaRecinto')}
                    disabled={isConsult}
                  />
                </Grid>
              )}

              {operacaoForaRecintoEmbarque(values) && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <QCXFinalTextField
                    {...operacaoForaRecintoEmbarqueFieldProps.endereco}
                    label="Referência de Endereço"
                    disabled={isConsult}
                  />
                </Grid>
              )}
              {urfDespachoDifereEmbarque(values) && !operacaoForaRecintoEmbarque(values) && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectRecintoAduaneiroAutocomplete
                    id="select-field-recinto-alfandegado-embarque"
                    key="select-field-recinto-alfandegado-embarque"
                    name="atributosAdicionais.recintoAlfandegadoEmbarque.id"
                    label={t('com.muralis.qcx.recintoAlfandegado')}
                    staticValues={recintosAlfandegadosEmbarque}
                    initialValues={values}
                    disabled={isConsult}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container alignContent="flex-start" xs={12} sm={12} md={6} lg={6}>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectEmpresaEstrangeiraAutocomplete
                  id="select-field-importador"
                  key="select-field-importador"
                  name="atributosAdicionais.importador.id"
                  label={t('com.muralis.qcx.importador.label')}
                  initialValues={values}
                  filterPredicate={importadorFilterPredicate}
                  fieldProps={{
                    validate: requiredValidator,
                  }}
                  disabled={isConsult}
                  required
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXFinalNumericDecimalFieldRender
                  id="numeric-decimal-peso-liquido-field"
                  name="pesoLiquido"
                  label={t('com.muralis.qcx.pesoLiquido')}
                  disabled={isConsult}
                  scale={7}
                />
              </Grid>
              <Grid
                item
                style={{
                  paddingBottom: '30px',
                }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXFinalCheckboxField
                  id="checkbox-rateio-peso-liquido-field"
                  key="checkbox-rateio-peso-liquido-field"
                  name="rateiaPesoLiquidoItem"
                  label={t('com.muralis.qcx.ratearPesoLiquidoItens')}
                  disabled={isConsult}
                />
              </Grid>
              <Grid
                item
                style={{
                  paddingBottom: '30px',
                }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXFinalCheckboxField
                  id="checkbox-exportacao-consorciada-field"
                  key="checkbox-exportacao-consorciada-field"
                  name="atributosAdicionais.exportacaoConsorciada"
                  label={t('com.muralis.qcx.exportacaoConsorciada')}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectFormaExportacaoAutocomplete
                  id="select-field-tipo-documento-fiscal"
                  key="select-field-tipo-documento-fiscal"
                  name="atributosAdicionais.formaExportacao.id"
                  label={t('com.muralis.qcx.formaExportacao.label')}
                  initialValues={values}
                  disabled={isConsult}
                  fieldProps={{ validate: requiredValidator }}
                  required
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXFinalTextField
                  id="text-field-referencia-ruc"
                  key="text-field-referencia-ruc"
                  name="atributosAdicionais.referenciaRuc"
                  label={t('com.muralis.qcx.referenciaRuc')}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectViaEspecialTransporteAutocomplete
                  id="select-field-via-especial-transporte"
                  key="select-field-via-especial-transporte"
                  name="atributosAdicionais.viaEspecialTransporte.id"
                  label={t('com.muralis.qcx.viaEspecialTransporte.label')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid
                item
                style={{
                  paddingBottom: '30px',
                }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXFinalCheckboxField
                  id="checkbox-operacao-triangular-field"
                  key="checkbox-operacao-triangular-field"
                  name="atributosAdicionais.operacaoTriangular"
                  label={t('com.muralis.qcx.operacaoTriangular')}
                  disabled={isConsult}
                />
              </Grid>
              {operacaoTriangular(values) && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectPaisAutocomplete
                    name="atributosAdicionais.paisDestino.id"
                    label={t('com.muralis.qcx.pais.paisDestino')}
                    disabled={isConsult}
                    initialValues={values}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.importador.informacoesComplementares')} />
            </Grid>
            <QCXControlBoxModeloInformacaoComplementarOld
              id="control-box-modelo-informacoes-complementares"
              key="control-box-modelo-informacoes-complementares"
              fieldProps={modeloInformacaoComplementarFieldProps}
              disabledFields={isConsult}
              renderMiddleFields={({ fieldProps, disabledFields }) => (
                <>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.referenciaCliente} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.transportadora} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.quadroResumoAdicoes} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.recintoAlfandegado} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.fundamentoLegalIi} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.fundamentoLegalIpi} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.fundamentoLegalIcms} disabled={disabledFields} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField {...fieldProps?.despachantesAduaneiros} disabled={disabledFields} />
                  </Grid>
                </>
              )}
            />
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid item xs={12}>
              <QCXFormSubtitle
                title="Contato para Justificativa"
                boxProps={{
                  pt: 1,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <QCXFinalTextField
                id="justificativa-text-field-nome"
                key="justificativa-text-field-nome"
                name="atributosAdicionais.nomeJustificativa"
                label="Nome"
                disabled={isConsult}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <QCXFinalTextField
                id="justificativa-text-field-email"
                key="justificativa-text-field-email"
                name="atributosAdicionais.emailJustificativa"
                label="E-mail"
                disabled={isConsult}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <QCXFinalTextField
                id="justificativa-text-field-telefone"
                key="justificativa-text-field-telefone"
                name="atributosAdicionais.telefoneJustificativa"
                label="Telefone"
                disabled={isConsult}
                maxLength={50}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
