import React, { useCallback, useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { get, isFunction } from 'lodash';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { fetchById } from '../../features/modelo-informacoes-complementares/modeloInformacoesComplementaresAPI';
import QCXAloneInlineBoxWrapper from '../alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXButton from '../button/QCXButton';
import QCXFinalMultilineTextField from '../final-text-field/QCXFinalMultilineTextField';
import QCXAutocompleteModeloInformacaoComplementar from './QCXAutocompleteModeloInformacaoComplementar';
import { WARNING_SEVERITY, ERROR_SEVERITY } from '../../features/severity';
import { setErrorFeedback, setWarningFeedback } from '../../features/feedback/feedbackSlice';
import i18n from '../../i18n';

const defaultFieldProps = {
  rootName: undefined,
  meta: {
    visible: {
      informacoesComplementaresCompleta: undefined,
    },
  },
  modeloInformacaoComplementar: {
    name: 'modeloInformacaoComplementar.id',
    label: i18n.t('com.muralis.qcx.modeloInformacaoComplementar.label'),
  },
  informacoesComplementares: {
    name: 'informacoesComplementares',
    label: i18n.t('com.muralis.qcx.modeloInformacaoComplementar.informacoesComplementares'),
    maxLength: 3900,
    rows: 15,
    helperText: undefined,
  },
  informacoesComplementaresCompleta: {
    name: 'informacoesComplementaresCompleta',
    label: i18n.t('com.muralis.qcx.modeloInformacaoComplementar.informacoesComplementares'),
    maxLength: 3900,
    rows: 15,
    helperText: undefined,
  },
};

export default function QCXControlBoxModeloInformacaoComplementarOld({
  fieldProps = {
    ...defaultFieldProps,
  },
  renderMiddleFields,
  disabledFields,
}) {
  const dispatch = useDispatch();

  const form = useForm();
  const { values } = useFormState();

  const modeloInformacaoComplementarName = useMemo(() => {
    const rootName = fieldProps?.rootName;
    const fieldName =
      fieldProps?.modeloInformacaoComplementar?.name || defaultFieldProps.modeloInformacaoComplementar.name;

    if (!rootName) {
      return fieldName;
    }

    const fullFieldName = `${rootName}.${fieldName}`;

    return fullFieldName;
  }, [fieldProps, defaultFieldProps]);

  const informacoesComplementaresName = useMemo(() => {
    const rootName = fieldProps?.rootName;
    const fieldName = fieldProps?.informacoesComplementares?.name || defaultFieldProps.informacoesComplementares.name;

    if (!rootName) {
      return fieldName;
    }

    const fullFieldName = `${rootName}.${fieldName}`;

    return fullFieldName;
  }, [fieldProps, defaultFieldProps]);

  const informacoesComplementaresCompletaName = useMemo(() => {
    const rootName = fieldProps?.rootName;
    const fieldName =
      fieldProps?.informacoesComplementaresCompleta?.name || defaultFieldProps.informacoesComplementaresCompleta.name;

    if (!rootName) {
      return fieldName;
    }

    const fullFieldName = `${rootName}.${fieldName}`;

    return fullFieldName;
  }, [fieldProps, defaultFieldProps]);

  const informacoesComplementaresMaxLength = useMemo(
    () => fieldProps?.informacoesComplementares?.maxLength || defaultFieldProps.informacoesComplementares.maxLength,
    [fieldProps, defaultFieldProps]
  );

  const isInformacoesComplementaresCompletaVisible = useMemo(
    () => fieldProps?.meta?.visible?.informacoesComplementaresCompleta,
    [fieldProps]
  );

  const selectedModeloInformacaoComplementarId = useMemo(
    () => get(values, modeloInformacaoComplementarName),
    [values, modeloInformacaoComplementarName]
  );

  const currentInformacaoComplementar = useMemo(
    () => get(values, informacoesComplementaresName),
    [values, informacoesComplementaresName]
  );

  const hasReachedLimitOfInformacoesComplementaresField = useMemo(
    () => currentInformacaoComplementar?.length === informacoesComplementaresMaxLength,
    [currentInformacaoComplementar, informacoesComplementaresMaxLength]
  );

  const handleImpediment = useCallback((impediment) => {
    if (impediment?.severity === WARNING_SEVERITY) {
      dispatch(
        setWarningFeedback({
          message: impediment?.message,
        })
      );
      return;
    }
    if (impediment?.severity === ERROR_SEVERITY) {
      dispatch(
        setErrorFeedback({
          message: impediment?.message,
        })
      );
    }
  }, []);

  const handleAdd = useCallback(
    async (event) => {
      event?.stopPropagation();

      try {
        if (!selectedModeloInformacaoComplementarId) {
          const emptySelectionErrorMessage = i18n.t(
            'com.muralis.qcx.mensagem.nenhumModeloInformacoesComplementaresSelecionado'
          );

          handleImpediment({
            severity: WARNING_SEVERITY,
            message: emptySelectionErrorMessage,
          });

          return;
        }

        if (hasReachedLimitOfInformacoesComplementaresField) {
          const maxLengthErrorMessage = i18n.t(
            'com.muralis.qcx.validacao.limiteMaximoCaracteresInformacoesComplementaresAtingido'
          );

          handleImpediment({
            severity: WARNING_SEVERITY,
            message: maxLengthErrorMessage,
          });

          return;
        }

        const modeloInformacaocomplementarResponse = await fetchById(selectedModeloInformacaoComplementarId);

        if (modeloInformacaocomplementarResponse.status === 200) {
          const foundModeloInformacaoComplementar = modeloInformacaocomplementarResponse?.data;
          const foundModeloComplemento = foundModeloInformacaoComplementar?.complemento || '';

          const formattedModeloInformacaoComplementar = foundModeloComplemento;

          const newLengthPredictedOfInformacoesComplementares =
            currentInformacaoComplementar?.length + formattedModeloInformacaoComplementar.length;

          if (newLengthPredictedOfInformacoesComplementares > informacoesComplementaresMaxLength) {
            const errorMessage = i18n.t('com.muralis.qcx.mensagem.naoPossivelAdicionarModeloInformacoesComplementares');
            const errorReason = i18n.t(
              'com.muralis.qcx.validacao.limiteMaximoCaracteresInformacoesComplementaresExcedido'
            );
            const errorDetails = `(${newLengthPredictedOfInformacoesComplementares} / ${informacoesComplementaresMaxLength})`;
            const exceddedLengthErrorMessage = `${errorMessage}: ${errorReason} ${errorDetails}.`;

            throw new Error(exceddedLengthErrorMessage);
          }

          const concatenatedInformacaoComplementar = currentInformacaoComplementar
            ? `${currentInformacaoComplementar}\n\n${formattedModeloInformacaoComplementar}`
            : formattedModeloInformacaoComplementar;

          form.change(informacoesComplementaresName, concatenatedInformacaoComplementar);
          form.change(modeloInformacaoComplementarName, null);

          if (fieldProps?.rootName) {
            const referenciaCliente =
              values[fieldProps.rootName]?.referenciaCliente || foundModeloInformacaoComplementar?.referenciaCliente;
            const transportadora =
              values[fieldProps.rootName]?.transportadora || foundModeloInformacaoComplementar?.transportadora;
            const quadroResumoAdicoes =
              values[fieldProps.rootName]?.quadroResumoAdicoes ||
              foundModeloInformacaoComplementar?.quadroResumoAdicoes;
            const recintoAlfandegado =
              values[fieldProps.rootName]?.recintoAlfandegado || foundModeloInformacaoComplementar?.recintoAlfandegado;
            const fundamentoLegalIi =
              values[fieldProps.rootName]?.fundamentoLegalIi || foundModeloInformacaoComplementar?.fundamentoLegalIi;
            const fundamentoLegalIpi =
              values[fieldProps.rootName]?.fundamentoLegalIpi || foundModeloInformacaoComplementar?.fundamentoLegalIpi;
            const fundamentoLegalIcms =
              values[fieldProps.rootName]?.fundamentoLegalIcms ||
              foundModeloInformacaoComplementar?.fundamentoLegalIcms;
            const despachantesAduaneiros =
              values[fieldProps.rootName]?.despachantesAduaneiros ||
              foundModeloInformacaoComplementar?.despachantesAduaneiros;

            form.change(`${fieldProps.rootName}.referenciaCliente`, referenciaCliente);
            form.change(`${fieldProps.rootName}.transportadora`, transportadora);
            form.change(`${fieldProps.rootName}.quadroResumoAdicoes`, quadroResumoAdicoes);
            form.change(`${fieldProps.rootName}.recintoAlfandegado`, recintoAlfandegado);
            form.change(`${fieldProps.rootName}.fundamentoLegalIi`, fundamentoLegalIi);
            form.change(`${fieldProps.rootName}.fundamentoLegalIpi`, fundamentoLegalIpi);
            form.change(`${fieldProps.rootName}.fundamentoLegalIcms`, fundamentoLegalIcms);
            form.change(`${fieldProps.rootName}.despachantesAduaneiros`, despachantesAduaneiros);
          }
        }
      } catch (error) {
        const errorMessage = error?.response?.message
          ? i18n.t('com.muralis.qcx.erro.erroCarregarModeloInformacoesComplementares', {
              erro: error?.response?.message,
            })
          : error?.message;

        handleImpediment({
          severity: ERROR_SEVERITY,
          message: errorMessage,
        });
      }
    },
    [form, selectedModeloInformacaoComplementarId, currentInformacaoComplementar, handleImpediment]
  );

  return (
    <>
      <QCXAloneInlineBoxWrapper>
        {(boxProps) => (
          <Grid item container spacing={2} {...boxProps}>
            <Grid item xs={12} sm={9} md={9}>
              <QCXAutocompleteModeloInformacaoComplementar
                {...fieldProps?.modeloInformacaoComplementar}
                id="autocomplete-field-modelo-informacao-complementar"
                key="autocomplete-field-modelo-informacao-complementar"
                name={modeloInformacaoComplementarName}
                label={defaultFieldProps.modeloInformacaoComplementar.label}
                disabled={disabledFields || hasReachedLimitOfInformacoesComplementaresField}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <QCXButton
                id="control-box-modelo-informacao-complementar-add-button"
                key="control-box-modelo-informacao-complementar-add-button"
                color="secondary"
                onClick={handleAdd}
                fullWidth
                disabled={disabledFields || hasReachedLimitOfInformacoesComplementaresField}
              >
                {i18n.t('com.muralis.qcx.acoes.adicionar')}
              </QCXButton>
            </Grid>
          </Grid>
        )}
      </QCXAloneInlineBoxWrapper>
      {isFunction(renderMiddleFields) && renderMiddleFields({ fieldProps, disabledFields })}
      {hasReachedLimitOfInformacoesComplementaresField && (
        <Grid
          item
          style={{
            padding: '0px 8px 8px 8px',
            margin: '-4px 14px 0px 14px',
          }}
          xs={12}
        >
          <Typography variant="caption" color="textSecondary">
            {i18n.t('com.muralis.qcx.validacao.limiteMaximoCaracteresInformacoesComplementaresAtingido')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {(!disabledFields || !isInformacoesComplementaresCompletaVisible) && (
          <QCXFinalMultilineTextField
            {...fieldProps?.informacoesComplementares}
            id="text-informacoes-complementares-multiline-field"
            key="text-informacoes-complementares-multiline-field"
            name={informacoesComplementaresName}
            label={defaultFieldProps.informacoesComplementares.label}
            disabled={disabledFields}
            rows={defaultFieldProps.informacoesComplementares.rows}
            helperText={fieldProps?.informacoesComplementares?.helperText}
            remaningCharactersInfo
          />
        )}
        {disabledFields && isInformacoesComplementaresCompletaVisible && (
          <QCXFinalMultilineTextField
            {...fieldProps?.informacoesComplementaresCompleta}
            id="text-informacoes-complementares-completa-multiline-field"
            key="text-informacoes-complementares-completa-multiline-field"
            name={informacoesComplementaresCompletaName}
            label={defaultFieldProps.informacoesComplementaresCompleta.label}
            disabled={disabledFields}
            rows={defaultFieldProps.informacoesComplementares.rows}
            maxLength={defaultFieldProps.informacoesComplementares.maxLength}
            remaningCharactersInfo
            helperText={fieldProps?.informacoesComplementaresCompleta?.helperText}
          />
        )}
      </Grid>
    </>
  );
}
