/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router';
import QCXSelectCFOPOperacoesFiscaisAutocomplete from '../../shared-components/final-autocomplete-select-cfop-operacoes-fiscais-field/QCXSelectCFOPOperacoesFiscaisAutocomplete';
import {
  cfopOperacaoFiscalActions,
  selectCFOPOperacoesFiscais,
} from '../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import { IDLE_CONTROL } from '../../features/config-control';
import { selectAdicoes, selectDanfe } from '../../features/danfe/danfeSelectors';
import { fetchByIdFromFaturaAsync as fetchDiById } from '../../features/declaracao-importacao/declaracaoImportacaoThunks';
import { selectDeclaracaoImportacao } from '../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import { PAIS_BRASIL_CODE } from '../../shared-components/select-pais/paisUtils';
import { updateModelAdicao, updateModelAdicaoNaLista, updateModelAdicoes } from '../../features/danfe/danfeSlice';
import QCXRegistrationOneToManyPageTemplate from '../../templates/registration-one-to-many-page/QCXRegistrationOneToManyPageTemplate';
import { OPTANTE_SIMPLES_NACIONAL } from '../../utils/general/cliente/clienteUtils';
import {
  REGIME_TRIBUTACAO_IMUNIDADE_CODE,
  REGIME_TRIBUTACAO_ISENCAO_CODE,
  REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
  REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
  REGIME_TRIBUTACAO_REDUCAO_CODE,
  REGIME_TRIBUTACAO_SUSPENSAO_CODE,
} from '../../shared-components/select-regime-tributacao/regimeTributacaoUtils';
import { numberOnly } from '../../utils/hooks/form/field/parsers';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXSelectMotivoDesoneracaoICMSAutocomplete from '../../shared-components/select-motivo-desoneracao-icms/QCXSelectMotivoDesoneracaoICMSAutocomplete';
import QCXSelectICMSDiferidoAutocomplete from '../../shared-components/select-icms-diferido/QCXSelectICMSDiferidoAutocomplete';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import { isValid, normalizeNumeral, unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import QCXAloneInlineBoxWrapper from '../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
/**
 * Calcula automaticamente o ncm para preencher no campo dentro da tabela.
 * O usuário precisa poder alterar este valor, então o retorno desta função
 * é só uma sugestão.
 */
function calcularCstIcms(adicao, cliente) {
  /** @type {boolean} */
  const optanteSimplesNacional = cliente?.optanteSimplesNacional === OPTANTE_SIMPLES_NACIONAL;
  /** @type {Object} */
  const regimeTributacao = adicao?.icms?.regimeTributacao;
  /** @type {boolean} */
  const paisOrigemBrasil =
    adicao?.exportador?.endereco?.cidade?.estado?.pais?.code === PAIS_BRASIL_CODE ||
    adicao?.paisOrigem?.code === PAIS_BRASIL_CODE; // 105 Brasil

  if (optanteSimplesNacional) {
    switch (regimeTributacao?.code) {
      case REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE:
        return '101';
      case REGIME_TRIBUTACAO_REDUCAO_CODE:
        return '102';
      case REGIME_TRIBUTACAO_ISENCAO_CODE:
        return '203';
      case REGIME_TRIBUTACAO_IMUNIDADE_CODE:
        return '300';
      default:
        // Demais regimes de tributação
        return '400';
    }
  } else if (paisOrigemBrasil) {
    switch (regimeTributacao?.code) {
      case REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE:
        return '000';
      case REGIME_TRIBUTACAO_REDUCAO_CODE:
        return '020';
      case REGIME_TRIBUTACAO_ISENCAO_CODE:
        return '040';
      case REGIME_TRIBUTACAO_SUSPENSAO_CODE:
        return '050';
      case REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE:
        return '041';
      default:
        // Demais regimes de tributação
        return '041';
    }
  } else {
    switch (regimeTributacao?.code) {
      case REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE:
        return '100';
      case REGIME_TRIBUTACAO_REDUCAO_CODE:
        if (adicao?.icms?.regimeTributacao?.tipoReducao === 'PORTARIA_CAT') return '120';
        return '100';
      case REGIME_TRIBUTACAO_ISENCAO_CODE:
        return '140';
      case REGIME_TRIBUTACAO_SUSPENSAO_CODE:
        return '150';
      default:
        // Demais regimes de tributação
        return '141';
    }
  }
}

function calcularCstPis(adicao) {
  /** @type {Object} */
  const regimeTributacao = adicao?.pisCofins?.regimeTributacao;

  switch (regimeTributacao?.code) {
    case REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE:
      return '01';
    case REGIME_TRIBUTACAO_REDUCAO_CODE:
      return '02';
    case REGIME_TRIBUTACAO_ISENCAO_CODE:
      return '07';
    case REGIME_TRIBUTACAO_SUSPENSAO_CODE:
      return '09';
    default:
      // Demais regimes de tributação
      return '08';
  }
}

function calcularCstCofins(adicao) {
  /** @type {Object} */
  const regimeTributacao = adicao?.pisCofins?.regimeTributacao;

  switch (regimeTributacao?.code) {
    case REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE:
      return '01';
    case REGIME_TRIBUTACAO_REDUCAO_CODE:
      return '02';
    case REGIME_TRIBUTACAO_ISENCAO_CODE:
      return '07';
    case REGIME_TRIBUTACAO_SUSPENSAO_CODE:
      return '09';
    default:
      // Demais regimes de tributação
      return '08';
  }
}

function calcularCstIpi(adicao) {
  /** @type {Object} */
  const regimeTributacao = adicao?.ipi?.regimeTributacao;
  /** @type {boolean} */
  const ipiNaoTributavel = adicao?.ipi?.ipiNaoTributavel;

  switch (regimeTributacao?.code) {
    case REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE:
      return '00';
    case REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE && adicao?.ipi?.aliquota <= 0:
      return '01';
    case REGIME_TRIBUTACAO_ISENCAO_CODE:
      return '02';
    case REGIME_TRIBUTACAO_SUSPENSAO_CODE:
      return '05';
    case ipiNaoTributavel:
      return '03';
    case REGIME_TRIBUTACAO_IMUNIDADE_CODE:
      return '04';
    default:
      // Demais regimes de tributação
      return '49';
  }
}

const DEFAULT_CODIGO_ENQUADRAMENTO_LEGAL_IPI = '999';

export default function QCXDanfeAdicoesForm({
  isConsult,
  isUpdate,
  isCreate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [numeroAdicaoInicial, setNumeroAdicaoInicial] = useState('');
  const [numeroAdicaoFinal, setNumeroAdicaoFinal] = useState('');
  const params = useParams();

  const cstICMS = useMemo(() => initialValues?.cst, [initialValues]);

  const cstIPI = useMemo(() => initialValues?.cstIpi, [initialValues]);

  const cstPIS = useMemo(() => initialValues?.cstPis, [initialValues]);

  const cstCOFINS = useMemo(() => initialValues?.cstCofins, [initialValues]);

  const enquadramentoLegalIpi = useMemo(() => initialValues?.enquadramentoLegalIpi, [initialValues]);

  const codigoSeloControleIpi = useMemo(() => initialValues?.codigoSeloControleIpi, [initialValues]);

  const quantidadeSeloControleIpi = useMemo(() => initialValues?.quantidadeSeloControleIpi, [initialValues]);

  const motivoDesoneracaoIcms = useMemo(() => initialValues?.motivoDesoneracaoIcms, [initialValues]);

  const icmsDiferido = useMemo(() => initialValues?.icmsDiferido, [initialValues]);

  const declaracaoImportacao = useMemo(() => initialValues?.di, [initialValues]);

  const unnormalizeRowData = useCallback(
    (row) => ({
      ...row,
      percentualIcmsDiferido: isValid(row?.percentualIcmsDiferido)
        ? unnormalizeNumeral(row?.percentualIcmsDiferido, formatBrazilianNumericDecimal(4))
        : '',
      valorIcmsDiferido: isValid(row?.valorIcmsDiferido)
        ? unnormalizeNumeral(row?.valorIcmsDiferido, formatBrazilianNumericDecimal(2))
        : '',
      adicaoDi: {
        ...row?.adicaoDi,
        icms: {
          ...row?.adicaoDi?.icms,
          aliquota: isValid(row?.adicaoDi?.icms?.aliquota)
            ? unnormalizeNumeral(row?.adicaoDi?.icms?.aliquota, formatBrazilianNumericDecimal(4))
            : '',
        },
      },
    }),
    []
  );

  // Aqui, estamos confiando que a lista de declarações de importação vai existir,
  // porque há um dropdown que busca essa lista na etapa anterior do formulário
  // de danfe.
  const di = useSelector(selectDeclaracaoImportacao);
  // Esta flag inicializa como false para não acidentalmente inicializarmos
  // a tabela usando dados de uma di que já estava carregada por outro motivo.
  const [flgPodeExtrairAdicoesDi, setFlgPodeExtrairAdicoesDi] = useState(false);

  // A lista de cfop também foi buscada na etapa anterior do formulário e confiamos
  // que estará na store ainda.
  const cfopList = useSelector(selectCFOPOperacoesFiscais);

  /** @type {Array} */
  const adicoesList = useSelector(selectAdicoes);

  // State to control the modal visibility
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false); // Track if filters are applied

  // Handle opening the modal
  const handleOpenFilterModal = () => {
    setOpenFilterModal(true);
  };

  // Handle closing the modal
  const handleCloseFilterModal = () => {
    setOpenFilterModal(false);
  };

  const handleApplyFilters = () => {
    setFilterApplied(true); // Mark that filters have been applied
    setOpenFilterModal(false); // Close the modal after applying
  };

  const adicoesFiltradas = useMemo(() => {
    // Se o filtro não foi definido ou os valores são inválidos, retorna todas as adições
    if (!numeroAdicaoInicial || !numeroAdicaoFinal) {
      return adicoesList;
    }

    const numeroInicial = parseInt(numeroAdicaoInicial, 10);
    const numeroFinal = parseInt(numeroAdicaoFinal, 10);

    // Filtra as adições com base no valor de numeroAdicao
    return adicoesList.filter((adicao) => {
      const numeroAdicao = parseInt(adicao.numeroAdicao, 10);
      return numeroAdicao >= numeroInicial && numeroAdicao <= numeroFinal;
    });
  }, [numeroAdicaoInicial, numeroAdicaoFinal, adicoesList]);

  const handleRepassar = useCallback(
    (form) => {
      if (!filterApplied) return;
      const formValues = form.getState().values;

      console.log('formValues', formValues);
      const updatedAdicoes = adicoesFiltradas.map((adicao) => ({
        ...adicao,
        cst: formValues?.ignorableFields.adi?.cst || adicao.cst,
        cfop: formValues?.ignorableFields.adi?.cfop || adicao.cfop,
        cstPis: formValues?.ignorableFields.adi?.cstPis || adicao.cstPis,
        cstCofins: formValues?.ignorableFields.adi?.cstCofins || adicao.cstCofins,
        cstIpi: formValues?.ignorableFields.adi?.cstIpi || adicao.cstIpi,
        enquadramentoLegalIpi: formValues?.ignorableFields.adi?.enquadramentoLegalIpi || adicao.enquadramentoLegalIpi,
        codigoSeloControleIpi: formValues?.ignorableFields.adi?.codigoSeloControleIpi || adicao.codigoSeloControleIpi,
        quantidadeSeloControleIpi:
          formValues?.ignorableFields.adi?.quantidadeSeloControleIpi || adicao.quantidadeSeloControleIpi,
        motivoDesoneracaoIcms: formValues?.ignorableFields.adi?.motivoDesoneracaoIcms || adicao.motivoDesoneracaoIcms,
        icmsDiferido: formValues?.ignorableFields.adi?.icmsDiferido || adicao.icmsDiferido,
        percentualIcmsDiferido:
          formValues?.ignorableFields.adi?.percentualIcmsDiferido || adicao.percentualIcmsDiferido,
        valorIcmsDiferido: formValues?.ignorableFields.adi?.valorIcmsDiferido || adicao.valorIcmsDiferido,
        regime: formValues?.ignorableFields.adi?.regime || adicao.regime,
        percentualReducao: formValues?.ignorableFields.adi?.percentualReducao || adicao.percentualReducao,
        percentualBeneficio: formValues?.ignorableFields.adi?.percentualBeneficio || adicao.percentualBeneficio,
        exTarifario: formValues?.ignorableFields.adi?.exTarifario || adicao.exTarifario,
        quebraAdicional: formValues?.ignorableFields.adi?.quebraAdicional || adicao.quebraAdicional,
      }));

      const mergedAdicoes = adicoesList.map((adicao) => {
        const updatedAdicao = updatedAdicoes.find(
          (updated) => updated.id === adicao.id // You can also use 'id' or any other identifier here
        );
        return updatedAdicao || adicao;
      });
      dispatch(updateModelAdicoes(mergedAdicoes)); // Dispatch the updated values to Redux
    },
    [adicoesFiltradas, filterApplied, dispatch]
  );

  const listenerList = useMemo(
    () => [
      {
        // Listener que automaticamente atualiza o source da tabela (no redux)
        // quando algum valor editável é alterado no form acima da tabela.
        // Isso faz os valores dentro da tabela atualizem automaticamente ao
        // editar no form.
        name: 'ignorableFields.adi',
        fn: (/** form */) => (value) => {
          // Primeiro, procuramos o item correspondente na lista de adições para
          // comparar.
          // const tableItemIndex = adicoesList
          //   ?.findIndex(
          //     (adi) => adi.numeroAdicao === value.numeroAdicao
          //       && adi.numeroSeqAdicao === value.numeroSeqAdicao
          //   );
          const tableItemIndex = adicoesList?.findIndex((adi) => adi?.id === value?.id);
          if (tableItemIndex === -1 || typeof tableItemIndex === 'undefined') {
            // Isso não deve acontecer, pois não há como adicionar itens na tabela.
            return;
          }
          const tableItem = adicoesList[tableItemIndex];
          // Se cst ou cfop mudaram, vamos atualizar.
          if (
            tableItem?.cst !== value?.cst ||
            tableItem?.cfop?.id !== value?.cfop?.id ||
            tableItem?.cstPis !== value?.cstPis ||
            tableItem?.cstCofins !== value?.cstCofins ||
            tableItem?.cstIpi !== value?.cstIpi ||
            tableItem?.enquadramentoLegalIpi !== value?.enquadramentoLegalIpi ||
            tableItem?.codigoSeloControleIpi !== value?.codigoSeloControleIpi ||
            tableItem?.quantidadeSeloControleIpi !== value?.quantidadeSeloControleIpi ||
            tableItem?.motivoDesoneracaoIcms !== value?.motivoDesoneracaoIcms ||
            tableItem?.icmsDiferido !== value?.icmsDiferido ||
            tableItem?.percentualIcmsDiferido !== value?.percentualIcmsDiferido ||
            tableItem?.valorIcmsDiferido !== value?.valorIcmsDiferido ||
            tableItem?.quebraAdicional !== value?.quebraAdicional
          ) {
            const fullCfop = cfopList?.find((c) => c?.id === value?.cfop?.id);
            const newTableItems = adicoesList?.slice(); // copy
            newTableItems[tableItemIndex] = {
              ...newTableItems[tableItemIndex],
              cst: value?.cst,
              cfop: fullCfop,
              cstPis: value?.cstPis,
              cstCofins: value?.cstCofins,
              cstIpi: value?.cstIpi,
              enquadramentoLegalIpi: value?.enquadramentoLegalIpi,
              codigoSeloControleIpi: value?.codigoSeloControleIpi,
              quantidadeSeloControleIpi: value?.quantidadeSeloControleIpi,
              motivoDesoneracaoIcms: value?.motivoDesoneracaoIcms,
              icmsDiferido: value?.icmsDiferido,
              percentualIcmsDiferido: value?.percentualIcmsDiferido,
              valorIcmsDiferido: value?.valorIcmsDiferido,
              quebraAdicional: value?.quebraAdicional,
              adicaoDi: { ...tableItem.adicaoDi },
            };
            // Por algum motivo, atualizar somente a lista faz este listener ser
            // chamado novamente com o valor original, e o resultado é que o valor
            // não muda na tela.
            //
            // dispatch(updateModelAdicoes(newTableItems));
            //
            // Esta solução me parece improvisada, mas foi o que funcionou.
            // Por mim não há problema se outro dev quiser implementar estas
            // atualizações de um jeito melhor.
            dispatch(
              updateModelAdicaoNaLista({
                modelo: value,
                lista: newTableItems,
              })
            );
          }
        },
      },
    ],
    [adicoesList, adicoesFiltradas]
  );

  const [editandoAdicao, setEditandoAdicao] = useState(false);

  const formState = useSelector(selectDanfe);

  useEffect(() => {
    dispatch(cfopOperacaoFiscalActions.changeControlTo(IDLE_CONTROL));

    // Durante o carregamento para a inserção de uma nova danfe:
    // 1. Buscamos as adições de uma di pelo id, que foi preenchido no
    // formulário do passo anterior. Para isso preenchemos os dados de uma di
    // no redux.
    // 2. Usamos esses itens para criar as adições da danfe, que são objetos
    // bem parecidos mas também possuem um código cst e um cfop.

    if (isEmpty(params?.id) && declaracaoImportacao?.id) {
      dispatch(fetchDiById(declaracaoImportacao?.id));
      setFlgPodeExtrairAdicoesDi(true);
    }

    // Se estamos editando uma adição existente, só precisamos atualizar o
    // modelo com as adições que existem no próprio modelo.
    dispatch(updateModelAdicoes(formState?.adicoes)); // sepa
  }, [declaracaoImportacao, params]);

  useEffect(() => {
    let adicoes = initialValues?.adicoes;
    let importador;

    if (flgPodeExtrairAdicoesDi && di?.id) {
      adicoes = di?.atributosAdicionais?.adicoes;
      importador = di?.importador;
    } else if (flgPodeExtrairAdicoesDi && di['0']) {
      // eslint-disable-next-line no-unused-vars
      for (const [_, value] of Object.entries(di)) {
        if (`${value?.id}` === `${formState?.di?.id}`) {
          adicoes = value?.atributosAdicionais?.adicoes;
          importador = value?.importador;
          break;
        }
      }
    }

    if (Array.isArray(adicoes)) {
      const cfop = cfopList?.find((c) => c?.id === formState?.cfop?.id);
      // Primeiro, a lista de adições (di) vira uma lista de adições (danfe).
      let localAdicoesDanfe = adicoes?.map((ad) => {
        const adicao = isCreate ? { adicaoDi: ad } : ad;
        return {
          ...adicao,
          numeroAdicao: ad?.item || ad?.numeroAdicao || ad?.numeroSeqAdicao,
          cst: cstICMS || ad?.cst || calcularCstIcms(ad, importador),
          motivoDesoneracaoIcms: motivoDesoneracaoIcms || ad?.motivoDesoneracaoIcms || undefined,
          cstPis: cstPIS || ad?.cstPis || calcularCstPis(ad),
          cstCofins: cstCOFINS || ad?.cstCofins || calcularCstCofins(ad),
          cstIpi: cstIPI || ad?.cstIpi || calcularCstIpi(ad),
          cfop: cfop || ad?.operacaoFiscal || { id: null },
          regime: ad?.adicaoDi?.icms?.regimeTributacao || undefined,
          percentualReducao: ad?.adicaoDi?.icms?.percentualReducao || undefined,
          percentualBeneficio: ad?.adicaoDi?.icms?.percentualBeneficio || undefined,
          exTarifario: ad?.adicaoDi?.icms?.anoExTarifario || undefined,
          enquadramentoLegalIpi:
            enquadramentoLegalIpi || ad?.enquadramentoLegalIpi || DEFAULT_CODIGO_ENQUADRAMENTO_LEGAL_IPI,
          codigoSeloControleIpi: codigoSeloControleIpi || ad?.codigoSeloControleIpi || undefined,
          quantidadeSeloControleIpi: quantidadeSeloControleIpi || ad?.quantidadeSeloControleIpi || undefined,
          icmsDiferido: icmsDiferido || ad?.icmsDiferido || 'NAO_DIFERIDO',
          percentualIcmsDiferido:
            (ad?.percentualIcmsDiferido &&
              (isValid(ad?.percentualIcmsDiferido)
                ? unnormalizeNumeral(ad?.percentualIcmsDiferido, formatBrazilianNumericDecimal(4))
                : '')) ||
            '',
          valorIcmsDiferido:
            (ad?.valorIcmsDiferido &&
              (isValid(ad?.valorIcmsDiferido)
                ? unnormalizeNumeral(ad?.valorIcmsDiferido, formatBrazilianNumericDecimal(2))
                : '')) ||
            normalizeNumeral(ad?.valorIcmsDiferido) ||
            '',
        };
      });
      // O próximo passo é separar por mercadoria. Cada uma dessas adições (danfe)
      // ainda precisa de um campo para a mercadoria, mas cada adição (di) pode
      // ter várias mercadorias. Então agora, cada adição (danfe) vai ser separada
      // de acordo com as mercadorias.
      if (isCreate) {
        localAdicoesDanfe = localAdicoesDanfe?.flatMap((ad, j) => {
          const { mercadorias } = ad?.adicaoDi;
          if (!Array.isArray(mercadorias)) {
            return [];
          }

          return mercadorias?.map((m) => ({
            ...ad,
            // Este id é para a atabela e será removido antes do envio.
            id: uuid(),
            mercadoria: m,
            // Sequencial dentro de um produto (mercadoria)
            numeroSeqAdicao: j + 1,
          }));
        });
      }
      dispatch(updateModelAdicoes(localAdicoesDanfe));
    }
  }, [
    isCreate,
    di,
    cstICMS,
    cstIPI,
    cstPIS,
    cstCOFINS,
    enquadramentoLegalIpi,
    codigoSeloControleIpi,
    quantidadeSeloControleIpi,
    motivoDesoneracaoIcms,
    icmsDiferido,
    flgPodeExtrairAdicoesDi,
  ]);

  const adicoesTableColumns = useMemo(
    () => [
      {
        field: 'numeroAdicao',
        headerName: t('com.muralis.qcx.adicao.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        valueGetter: ({ row }) => row?.numeroAdicao || '-',
      },
      {
        field: 'exportador',
        headerName: t('com.muralis.qcx.exportador.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 120,
        valueGetter: ({ row }) => row?.adicaoDi?.exportador?.pessoaJuridica?.nome || '-',
      },
      {
        field: 'fabricante',
        headerName: t('com.muralis.qcx.fabricante.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 120,
        valueGetter: ({ row }) => row?.adicaoDi?.fabricante?.pessoaJuridica?.nome || '-',
      },
      {
        field: 'ncm',
        headerName: t('com.muralis.qcx.NCM.NCMSH'),
        headerAlign: 'center',
        align: 'center',
        flex: 140,
        valueGetter: ({ row }) => row?.adicaoDi?.ncm?.code || '-',
      },
      {
        field: 'aliquotaIcms',
        headerName: t('com.muralis.qcx.aliquotaICMS'),
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        valueGetter: ({ row }) => (row?.adicaoDi?.icms?.aliquota ? `${row?.adicaoDi?.icms?.aliquota}%` : '-'),
      },
      {
        field: 'cst',
        headerName: t('com.muralis.qcx.CST.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        valueGetter: ({ row }) => row?.cst || '-',
      },
      {
        field: 'cfop',
        headerName: t('com.muralis.qcx.CFOP.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        valueGetter: ({ row }) => row?.cfop?.code || '-',
      },
      {
        field: 'regime',
        headerName: 'Regime',
        headerAlign: 'center',
        align: 'center',
        flex: 130,
        valueGetter: ({ row }) => row?.adicaoDi?.icms?.regimeTributacao.description || '-',
      },
      {
        field: 'percentualReducao',
        headerName: '%Red',
        headerAlign: 'center',
        align: 'center',
        flex: 130,
        valueGetter: ({ row }) => row?.adicaoDi?.icms?.percentualReducao || '-',
      },
      {
        field: 'percentualBeneficio',
        headerName: '%Ben',
        headerAlign: 'center',
        align: 'center',
        flex: 130,
        valueGetter: ({ row }) => row?.adicaoDi?.icms?.percentualBeneficio || '-',
      },
      {
        field: 'exTarifario',
        headerName: 'EX',
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        valueGetter: ({ row }) => row?.exTarifario || '-',
      },
    ],
    [t]
  );

  const localeTableText = useMemo(
    () => ({
      noRowsLabel: t('com.muralis.qcx.validacao.nenhumaAdicaoEncontrada'),
    }),
    [t]
  );

  return (
    <>
      <QCXFormStepWizard
        initialValues={initialValues}
        onSubmit={handleSubmit}
        disablePristine
        controlComponentProps={controlComponentProps}
        onChangeListeners={listenerList}
        isConsult={isConsult}
        isUpdate={isUpdate}
        isCreate={isCreate}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        {...restProps}
      >
        {({ values, form, dirty }) => (
          <>
            <QCXRegistrationOneToManyPageTemplate
              title={t('com.muralis.qcx.adicoes')}
              tableProps={{
                title: t('com.muralis.qcx.tabelaAdicoes'),
                columns: adicoesTableColumns,
              }}
              isConsult={isConsult}
              isUpdate // Esta tabela sempre permite edição somente.
              tableData={adicoesFiltradas}
              localeTableText={localeTableText}
              submitSucceeded={() => {}}
              handleNew={() => {}}
              handleCancelCreate={() => {}}
              handleCancelUpdate={() => {}}
              handleRemove={() => {}}
              handleRestartForm={() => form.restart({ percentualIcmsDiferido: '', valorIcmsDiferido: '' })}
              handleRowClick={({ row }) => {
                const newRow = unnormalizeRowData(row);

                setEditandoAdicao(true);
                dispatch(updateModelAdicao(newRow));
              }}
            >
              <QCXAloneInlineBoxWrapper>
                {(fieldProps) => (
                  <QCXFinalTextField
                    id="numero-adicao"
                    key="numero-adicao"
                    name="ignorableFields.adi.numeroAdicao"
                    label={t('com.muralis.qcx.numeroAdicao')}
                    disabled
                    {...fieldProps}
                  />
                )}
              </QCXAloneInlineBoxWrapper>
              <Grid item xs={12} sm={6} md={6}>
                <QCXFinalTextField
                  id="exportador-adicao"
                  key="exportador-adicao"
                  name="ignorableFields.adi.adicaoDi.exportador.pessoaJuridica.nome"
                  label={t('com.muralis.qcx.exportador.label')}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <QCXFinalTextField
                  id="fabricante-adicao"
                  key="fabricante-adicao"
                  name="ignorableFields.adi.adicaoDi.fabricante.pessoaJuridica.nome"
                  label={t('com.muralis.qcx.fabricante.label')}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <QCXFinalTextField
                  id="ncm-adicao"
                  key="numero-adicao"
                  name="ignorableFields.adi.adicaoDi.ncm.code"
                  label={t('com.muralis.qcx.NCM.NCMSH')}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <QCXFinalPercentualField
                  id="aliquota-icms-adicao"
                  key="aliquota-icms-adicao"
                  name="ignorableFields.adi.adicaoDi.icms.aliquota"
                  label={t('com.muralis.qcx.alioquoteICMSPercentual')}
                  disabled
                  scale={4}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <QCXSelectCFOPOperacoesFiscaisAutocomplete
                  id="select-cfop-adicao"
                  key="select-cfop-adicao"
                  name="ignorableFields.adi.cfop"
                  label={t('com.muralis.qcx.CFOP.label')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <QCXFinalTextField
                  id="adi-quebra-adicional"
                  key="adi-quebra-adicional"
                  name="ignorableFields.adi.quebraAdicional"
                  label="Quebra Adicional"
                  disabled={isConsult}
                  placeholder="Apenas Valores Numéricos"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <QCXFinalMultilineTextField
                  id="text-especificacao-multiline-field"
                  key="text-especificacao-multiline-field"
                  name="ignorableFields.adi.mercadoria.adicionais.especificacaoCompleta"
                  label={t('com.muralis.qcx.especificacao.especificacaoMercadoria')}
                  disabled
                  readOnly
                  rows={5}
                  maxLength={3900}
                />
              </Grid>
              <Grid item xs={12}>
                <QCXFormSubtitle title={t('com.muralis.qcx.ICMS.ICMSPISCOFINSIPI')} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalNumericIntegerField
                  id="cst-adicao"
                  key="cst-adicao"
                  name="ignorableFields.adi.cst"
                  label={t('com.muralis.qcx.ICMS.CSTICMS')}
                  maxLength={3}
                  initialValues={values}
                  disabled={isConsult}
                  allowNegative={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXSelectMotivoDesoneracaoICMSAutocomplete
                  id="select-field-motivo-desoneracao-icms"
                  key="select-field-motivo-desoneracao-icms"
                  name="ignorableFields.adi.motivoDesoneracaoIcms"
                  label={t('com.muralis.qcx.ICMS.motivoDesoneracaoICMS')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalNumericIntegerField
                  id="text-field-cst-pis"
                  key="text-field-cst-pis"
                  name="ignorableFields.adi.cstPis"
                  label={t('com.muralis.qcx.CST.CSTPIS')}
                  disabled={isConsult}
                  allowNegative={false}
                  maxLength={2}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalNumericIntegerField
                  id="text-field-cst-cofins"
                  key="text-field-cst-cofins"
                  name="ignorableFields.adi.cstCofins"
                  label={t('com.muralis.qcx.CST.CSTCOFINS')}
                  disabled={isConsult}
                  allowNegative={false}
                  maxLength={2}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalNumericIntegerField
                  id="text-field-cst-ipi"
                  key="text-field-cst-ipi"
                  name="ignorableFields.adi.cstIpi"
                  label={t('com.muralis.qcx.CST.CSTIPI')}
                  disabled={isConsult}
                  allowNegative={false}
                  maxLength={2}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalTextField
                  id="text-field-codigo-enquadramento-legal-ipi"
                  key="text-field-codigo-enquadramento-legal-ipi"
                  name="ignorableFields.adi.enquadramentoLegalIpi"
                  label={t('com.muralis.qcx.IPI.enquadramentoLegalIPI')}
                  disabled={isConsult}
                  maxLength={3}
                  parse={numberOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalTextField
                  id="text-field-codigo-selo-de-controle-ipi"
                  key="text-field-codigo-selo-de-controle-ipi"
                  name="ignorableFields.adi.codigoSeloControleIpi"
                  label={t('com.muralis.qcx.IPI.codigoSeloControleIPI')}
                  disabled={isConsult}
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalNumericIntegerField
                  id="text-field-quantidade-selo-de-controle-ipi"
                  key="text-field-quantidade-selo-de-controle-ipi"
                  name="ignorableFields.adi.quantidadeSeloControleIpi"
                  label={t('com.muralis.qcx.IPI.QuantidadeSeloControleIPI')}
                  disabled={isConsult}
                  allowNegative={false}
                  maxLength={8}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXSelectICMSDiferidoAutocomplete
                  id="select-field-icms-diferido"
                  key="select-field-icms-diferido"
                  name="ignorableFields.adi.icmsDiferido"
                  label={t('com.muralis.qcx.ICMS.ICMSDiferido')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalPercentualField
                  id="percentual-icms-diferido"
                  key="percentual-icms-diferido"
                  name="ignorableFields.adi.percentualIcmsDiferido"
                  label={t('com.muralis.qcx.ICMS.ICMSDiferido')}
                  disabled={isConsult}
                  scale={4}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalAlternativeCurrencyField
                  id="currency-valor-icms-diferido"
                  key="currency-valor-icms-diferido"
                  name="ignorableFields.adi.valorIcmsDiferido"
                  label={t('com.muralis.qcx.ICMS.valorICMSDiferido')}
                  intl={BRAZILIAN}
                  disabled={isConsult}
                  adorned
                />
              </Grid>
              {/* Place the button in a new grid row */}
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenFilterModal}
                  style={{ marginRight: '10px' }}
                >
                  FILTRAR REPASSE
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleRepassar(form)}
                  disabled={!filterApplied || isConsult} // Disable the button until filters are applied
                >
                  REPASSAR
                </Button>
              </Grid>

              {/* Modal for Filters */}
              <Dialog open={openFilterModal} onClose={handleCloseFilterModal}>
                <DialogTitle>Filtros</DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <QCXFinalTextField
                        id="numero-adicao-inicial"
                        name="numero-adicao-inicial"
                        label="Número Adição Inicial"
                        initialValue={numeroAdicaoInicial}
                        onChange={(e) => setNumeroAdicaoInicial(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <QCXFinalTextField
                        id="numero-adicao-final"
                        name="numero-adicao-final"
                        label="Número Adição Final"
                        initialValue={numeroAdicaoFinal}
                        onChange={(e) => setNumeroAdicaoFinal(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseFilterModal} color="primary">
                    FECHAR
                  </Button>
                  <Button onClick={handleApplyFilters} color="secondary">
                    APLICAR
                  </Button>
                </DialogActions>
              </Dialog>
            </QCXRegistrationOneToManyPageTemplate>
          </>
        )}
      </QCXFormStepWizard>
    </>
  );
}
