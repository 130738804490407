/* eslint-disable eqeqeq */
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  fetchUnidadesDeNegocioAsync,
  selectConfigControl,
  selectStatus,
  selectUnidadesDeNegocioAssociadas,
} from '../../features/usuario-logado/usuarioLogadoSlice';
import { isAutoLoadControl, isFailureStatus } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectUnidadeNegocioAssociadaAutocomplete({
  label,
  name,
  initialValues,
  textFieldProps,
  defaultAdditionalDelay = true,
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const configControl = useSelector(selectConfigControl);
  const list = useSelector(selectUnidadesDeNegocioAssociadas);

  const [internalLoading, setInternalLoading] = useState(defaultAdditionalDelay);
  const [idInterval, setIdInterval] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(list) || isFailureStatus(status)) {
      _.debounce(() => setInternalLoading(false), 2000)();
    }

    // tenta se recuperar de um erro do back
    if (isFailureStatus(status) && idInterval == null) {
      
      const intervalId = setInterval(() => {
        dispatch(fetchUnidadesDeNegocioAsync());
      }, 1000);

      setIdInterval(intervalId);

    } else if (!isFailureStatus(status) && idInterval != null) {
      clearInterval(idInterval);
      setIdInterval(null);
    }
  }, [list, status]);

  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchUnidadesDeNegocioAsync());
    }
  }, [configControl]);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = list.find((item) => item?.id == _.get(initialValues, name));
    setDefaultValue(value || null);
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.pessoa?.nome}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      textFieldProps={{ variant: 'standard', ...textFieldProps }}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.code && item?.pessoa?.nome ? `${item?.code} - ${item?.pessoa?.nome}` : '')}
      {...restProps}
      placeholder={internalLoading ? t('com.muralis.qcx.carregando') : t('com.muralis.qcx.selecione')}
      loading={internalLoading}
      loadingText={t('com.muralis.qcx.carregando')}
      disabled={internalLoading}
    />
  );
}
