import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { AUTO_LOAD_CONTROL } from '../config-control';
import {
  BACKGROUND_CREATE_MODE,
  BATCH_APPROVAL_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
  UPDATE_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  ALTERNATIVE_LOADING_STATUS,
} from '../status';
import {
  fetchAllAsync,
  fetchAllViewAsync,
  generateReportAPagarByDateAsync,
} from './contasPagarThunks';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  type: '',
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: AUTO_LOAD_CONTROL,
  },
  list: [],
  view: [],
  model: {
  },
  related: {
    model: {
      report: {},
      contasPagarItem: {},
    },
    list: {
      selectionContasPagar: [],
    },
    view: {
      selectionContasPagar:[],
    },
  },
};

const contasPagarSlice = createSlice({
  name: 'contasPagar',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeToBatchApprovalMode: (state) => {
      state.mode.main = BATCH_APPROVAL_MODE;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
      state.related.model.contato = {};
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    resetControl: (state) => {
      state.config.control = initialState.config.control;
    },
    setModel: (state, action) => {
      const updatedContatosList = action.payload.contatos.map(
        (current) => ((current?.id === null)
          ? {
            ...current,
            id: uuid(),
          }
          : current
        )
      );

      state.model = {
        ...action.payload,
        contatos: updatedContatosList,
      };
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    resetList: (state) => {
      state.list = [];
    },
    setRelatedSelectionContasPagarList: (state, action) => {
      state.related.list.selectionContasPagar = action.payload;
    },
    setRelatedSelectionContasPagarView: (state, action) => {
      state.related.view.selectionContasPagar = action.payload;
    },
    resetRelatedSelectionContasPagarList: (state) => {
      state.related.list.selectionContasPagar = [];
    },
    resetRelatedSelectionContasPagarView: (state) => {
      state.related.view.selectionContasPagar = [];
    },
    setRelatedContasPagarItemModel: (state, action) => {
      state.related.model.contasPagarItem = action.payload;
    },
    resetRelatedContasPagarItemModel: (state) => {
      state.related.model.contasPagarItem = initialState.related.model.contasPagarItem;
    },
    setRelatedReportModel: (state, action) => {
      state.related.model.report = {
        ...action.payload,
      };
    },
    resetRelatedReportModel: (state) => {
      state.related.model.report = initialState.related.model.report;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaContasPagar', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(generateReportAPagarByDateAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(generateReportAPagarByDateAsync.fulfilled, (state) => {
        state.status = SUCCESS_STATUS;
      })
      .addCase(generateReportAPagarByDateAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroGerarRelatorioContasPagar', { mensagem: action.error.message }),
        };
      })
      .addCase(fetchAllViewAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllViewAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.view = action.payload.data;
      })
      .addCase(fetchAllViewAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaContasPagar', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToBatchApprovalMode,
  resetSubMode,
  changeControlTo,
  resetControl,
  setModel,
  resetModel,
  resetList,
  addToList,
  setList,
  refresh,
  resetRefresh,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  setRelatedReportModel,
  resetRelatedReportModel,
  setRelatedSelectionContasPagarList,
  setRelatedSelectionContasPagarView,
  resetRelatedSelectionContasPagarList,
  resetRelatedSelectionContasPagarView,
  setRelatedContasPagarItemModel,
  resetRelatedContasPagarItemModel,
} = contasPagarSlice.actions;

const contasPagarActions = {
  ...contasPagarSlice.actions,
};

export {
  contasPagarSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  refresh,
  resetRefresh,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToBatchApprovalMode,
  resetSubMode,
  changeControlTo,
  resetControl,
  setModel,
  resetModel,
  resetList,
  addToList,
  setList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  contasPagarActions,
  setRelatedReportModel,
  resetRelatedReportModel,
  setRelatedSelectionContasPagarList,
  setRelatedSelectionContasPagarView,
  resetRelatedSelectionContasPagarList,
  resetRelatedSelectionContasPagarView,
  setRelatedContasPagarItemModel,
  resetRelatedContasPagarItemModel,
};

export default contasPagarSlice.reducer;
