import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import { Delete as DeleteIcon } from '@material-ui/icons';
import {
  selectRelatedFaturamentoCapaId,
  selectRelatedPagosRecebidos,
} from '../../features/faturamento/faturamentoSelectors';
import QCXDataGrid from '../../shared-components/data-grid/QCXDataGrid';
import { unnormalizeNumeral } from '../../utils/general/general-utils';
import NumerarioImportacaoUtils from '../../utils/general/numerario-importacao/NumerarioImportacaoUtils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXButton from '../../shared-components/button/QCXButton';
import { buscarNovosLancamentosAsync, removeDespesaAsync } from '../../features/faturamento/faturamentoThunks';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '16px 0',
  },
}));

export default function QCXDespesaReceitaNumerario(formValues) {
  const dispatch = useDispatch();

  const form = useForm();
  const classes = useStyles();
  const status = formValues?.formValues?.status;
  const isSolicitado = status === 'SOLICITADO'

  const { t } = useTranslation();

  const faturamentoCapaId = useSelector(selectRelatedFaturamentoCapaId);
  const pagosRecebidos = useSelector(selectRelatedPagosRecebidos);

  const pagosRecebidosRows = useMemo(
    () => pagosRecebidos.filter((pagoRecebido) => pagoRecebido.origem !== 'NUMERARIO'),
    [pagosRecebidos]
  );

  const handleBuscarLancamentosClick = async () => {
    const { id } = form.getState().values;

    dispatch(buscarNovosLancamentosAsync({ id, capaId: faturamentoCapaId }));
  };

  const removeDespesa = (id) => {
    dispatch(removeDespesaAsync(id));
  };

  const columns = useMemo(
    () => [
      {
        field: 'numerario',
        headerName: t('com.muralis.qcx.numerario.label'),
        flex: 90,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => NumerarioImportacaoUtils.formatIdentificacao(row?.numerario?.identificacao) || '-',
      },
      {
        field: 'despesaReceita.description',
        headerName: t('com.muralis.qcx.despesa.despesaReceita'),
        headerAlign: 'center',
        align: 'center',
        flex: 170,
        valueGetter: ({ row }) =>
          row?.solicitacaoPagamento?.despesaReceita?.description || row?.titulo?.description || '-',
      },
      {
        field: 'origem',
        headerName: t('com.muralis.qcx.origem'),
        flex: 140,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => row?.origem?.replace('_', ' ') || '-',
      },
      {
        field: 'valor',
        headerName: t('com.muralis.qcx.valor'),
        flex: 80,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row: { totalMoeda } }) =>
          unnormalizeNumeral(totalMoeda, formatBrazilianNumericDecimal(2)) || '-',
      },
      {
        field: 'valorReais',
        headerName: t('com.muralis.qcx.moeda.valorReais'),
        flex: 90,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row: { totalReal } }) => unnormalizeNumeral(totalReal, formatBrazilianNumericDecimal(2)) || '-',
      },
      {
        field: 'acoes',
        headerName: t('com.muralis.qcx.acoes.label'),
        flex: 50,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => (
              <IconButton onClick={() => removeDespesa(row.id)}>
                <DeleteIcon color="error" />
              </IconButton>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle className={classes.subtitle} title={t('com.muralis.qcx.despesa.labelPluralImpostos')} />
      </Grid>
      <Grid item xs={12}>
        <QCXDataGrid columns={columns} rows={pagosRecebidosRows} />
      </Grid>
      {((faturamentoCapaId) && (isSolicitado || formValues?.isCreate)) ? (
        <Grid item xs={12}>
          <QCXButton
            onClick={handleBuscarLancamentosClick}
            id="button-trazer-despesas"
            key="button-trazer-despesas"
            color="secondary"
          >
            TRAZER NUMERÁRIOS E NOVAS DESPESAS
          </QCXButton>
        </Grid>
      ) : null}
    </>
  );
}
