import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  Cancel as CancelIcon,
  CancelOutlined as CancelOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  MoreHoriz as MoreHorizIcon,
  Payment as PaymentIcon,
  Receipt as ReceiptIcon,
} from '@material-ui/icons';
import _, { isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXApplyJurosDialogForm from '../../../../components/contas-pagar-receber/QCXApplyJurosDialogForm';
import QCXPartialValuesDialogForm from '../../../../components/contas-pagar-receber/QCXPartialValueDialogForm';
import QCXRelatoriosContasDialogForm from '../../../../components/contas-pagar-receber/QCXRelatoriosContasDialogForm';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import ContasReceberContext from '../../../../contexts/financial/contas-receber/ContasReceberContext';
import { contasReceberAPI } from '../../../../features/contas-receber/contasReceberAPI';
import {
  selectContasReceber,
  selectContasReceberView,
  selectMode,
  selectRefresh,
  selectRelatedContasReceberItemModel,
  selectRelatedReportModel,
  selectRelatedSelectionContasReceberList,
  selectRelatedSelectionContasReceberView,
} from '../../../../features/contas-receber/contasReceberSelectors';
import {
  changeToBatchApprovalMode,
  failure,
  loading,
  refresh as refreshAction,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRefresh,
  resetRelatedSelectionContasReceberList,
  setRelatedContasReceberItemModel,
  setRelatedReportModel,
  setRelatedSelectionContasReceberView,
  success,
} from '../../../../features/contas-receber/contasReceberSlice';
import {
  fetchAllAsync,
  fetchAllViewAsync,
  generateReportAReceberByDateAsync,
} from '../../../../features/contas-receber/contasReceberThunks';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import QCXJustifiedActionFormDialog from '../../../../shared-components/dialog/QCXJustifiedActionFormDialog';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import TableCellWithTooltip from '../../../../shared-components/tooltip-cell/TableCellWithTooltip';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import contasReceberUtils from '../../../../utils/general/contas-receber/ContasReceberUtils';
import { convertLocalDateTimeStringToUTC } from '../../../../utils/general/dateTime-utils';
import {
  formatDate,
  normalizeData,
  normalizeNumeral,
  unnormalizeNumeral,
} from '../../../../utils/general/general-utils';
import TituloUtils from '../../../../utils/general/titulo/TituloUtils';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  SUBMITTING_STATUS,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';
import useFormDialogAsync from '../../../../utils/hooks/form/dialog/useFormDialogAsync';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import {
  isAlternativeLoadingStatus,
  isApprovalBatchMode,
  isNoneMode,
  isRefresh,
} from '../../../../utils/store/store-utils';
import SimpleConsultPageTemplate from '../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import ContasReceberFilterComponent from './components/ContasReceberFilterComponent';

export default function ContasReceberConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { status } = useContext(ContasReceberContext);

  const mode = useSelector(selectMode);
  const dispatch = useDispatch();

  const refresh = useSelector(selectRefresh);
  const contasReceber = useSelector(selectContasReceberView);
  const relatedContasReceberItem = useSelector(selectRelatedContasReceberItemModel);
  const relatedSelectionContasReceberList = useSelector(selectRelatedSelectionContasReceberList);
  const relatedSelectionContasReceberView = useSelector(selectRelatedSelectionContasReceberView);
  const reportRelatedModel = useSelector(selectRelatedReportModel);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isApprovalBatch = useMemo(() => isApprovalBatchMode(mode), [mode]);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const [pageConfig, setPageConfig] = useState({
    currentPage: 0,
    pageSize: 10,
    filterInputValue: '',
  }); 

  const [fetchFilterParams, setFetchFilterParams] = useState([]);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionContasReceberView ||
      (_.isArrayLikeObject(relatedSelectionContasReceberView) && _.isEmpty(relatedSelectionContasReceberView)),
    [relatedSelectionContasReceberView]
  );

  const fetchAll = useCallback(() => {
    dispatch(fetchAllViewAsync(
      [
        {
          name: 'page',
          value: pageConfig.currentPage,
        },
        {
          name: 'size',
          value: pageConfig.pageSize,
        },
        {
          name: 'filter',
          value: pageConfig.filterInputValue,
        },
      ]
    ));
  }, [pageConfig]);

  useEffect(() => {
    resetModes();
    resetModels();
  }, []);

  useEffect( () => {
    fetchAll();
  }, [ fetchAll ])

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    if (isNone && !isEmptySelectionList) {
      dispatch(resetRelatedSelectionContasReceberList());
    }
  }, [isNone, isEmptySelectionList]);

  const handleEnableBatchApprovalMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToBatchApprovalMode());
  }, []);

  const handleSelectionModelChange = useCallback(
    ({ selectionModel }) => {
      if (isApprovalBatch) {
        dispatch(setRelatedSelectionContasReceberView(selectionModel));
      }
    },
    [contasReceber, isApprovalBatch]
  );

  const [
    handleFormDialogConfirmBatchApprovalStatus,
    formDialogConfirmBatchApprovalStatus,
    setFormDialogConfirmBatchApprovalStatus,
  ] = useFormDialogSync();

  const handleCloseFormDialogNew = useCallback(() => {
    setFormDialogConfirmBatchApprovalStatus(CLOSED_STATUS);
  }, [setFormDialogConfirmBatchApprovalStatus]);

  const [openValorParcialDialog, valorParcialDialogStatus, setValorParcialDialogStatus] = useFormDialogAsync();

  const [openApplyJurosDialog, applyJurosDialogStatus, setApplyJurosDialogStatus] = useFormDialogAsync();

  const handleOpenModal = () => {
    handleFormDialogConfirmBatchApprovalStatus('OPEN_STATUS');
  };

  const handleCancelBatchApproval = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const [handleImprimirClick, formDialogImpressaoStatus, handleFormDialogImpressaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: contasReceberUtils.CONTAS_RECEBER,
          })
        );
      }
    },
    []
  );

  const isSingleItemSelected = useMemo(
    () => relatedSelectionContasReceberView.length === 1,
    [relatedSelectionContasReceberView]
  );

  const handleConfirmBatchApproval = useCallback(
    async (values) => {
      try {
        dispatch(loading());

        handleCloseFormDialogNew();

        const contasReceberFiltrado = contasReceber?.content.map((conta) => ({
          id: conta?.id,
          contaBancaria: values?.contaBancariaId?.id || conta?.conta_bancaria_id,
          valor:
            isSingleItemSelected && values?.valorParcial && values?.valorParcial !== 0
              ? normalizeNumeral(values?.valorParcial)
              : conta?.valorReceber, // Usa valorParcial apenas se for um item selecionado
          dataPagamento: values?.dataPagamento,
          solicitante: conta?.emailSolicitante,
          followUpId: conta?.followUpId,
          despesaReceitaId: conta?.despesaReceitaId,
          origem: conta?.origem,
          tipo: conta?.tipo,
          contaComissaria: conta?.controlarSaldo,
        }));

        const selectionModelContasReceberList = relatedSelectionContasReceberView.map((itemModel) => {
          const [contasReceberSelecionadas] = contasReceberFiltrado.filter((conta) => conta?.id === itemModel);
          return contasReceberSelecionadas;
        });

        const payload = selectionModelContasReceberList.map((contaReceber) => ({
          conta: {
            tipo: {
              description: contasReceberUtils.TITULO_TRANSFERENCIA,
            },
            proprietario: {
              id: contaReceber?.contaBancaria,
            },
          },
          referencias: [
            {
              id: contaReceber?.id,
            },
          ],
          operacao: {
            description: contasReceberUtils.TRANSFERENCIA,
          },
          valor: contaReceber?.valor,
          contaBancariaId: contaReceber?.contaBancaria,
          dataPagamento: contaReceber?.dataPagamento,
          solicitante: contaReceber?.solicitante,
          followUpId: contaReceber?.followUpId,
          despesaReceitaId: contaReceber?.despesaReceitaId,
          origem: contaReceber?.origem,
          tipo: contaReceber?.tipo,
          contaComissaria: contaReceber?.contaComissaria,
        }));

        console.log('Payload enviado:', JSON.stringify(payload, null, 2));

        const response = await contasReceberAPI.transferencia(payload);

        if (response?.status === 201) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.loteTransferenciaContasReceber');

          setValorParcialDialogStatus(CLOSED_STATUS);
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
          dispatch(resetMode());
        }
      } catch (error) {
        console.log(error);
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.transferirLoteContasReceberEspecifico', { erro: error?.response?.data })
          : t('com.muralis.qcx.erro.transferirContasReceberLote');
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    },
    [contasReceber, relatedSelectionContasReceberView]
  );

  const handleImprimirByDateSubmit = async (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    const vencimentoDe = normalizeData(event?.data?.vencimentoDe);
    const vencimentoAte = normalizeData(event?.data?.vencimentoAte);

    const handlers = {
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = t('com.muralis.qcx.mensagem.nenhumRelatorioGerar');

        dispatch(
          setWarningFeedback({
            message: infoMessage,
          })
        );
      },
      onError: (message) => {
        dispatch(failure());

        const errorMessage =
          message ??
          t('com.muralis.qcx.mensagem.naoFoiPossivelGerarRelatorioContas', {
            tipoRelatorio: t('com.muralis.qcx.financeiro.contasReceber'),
          });

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    dispatch(
      generateReportAReceberByDateAsync({
        vencimentoDe,
        vencimentoAte,
        handlers,
      })
    );

    handleFormDialogImpressaoStatus(DONE_STATUS);
  };

  const [handleOpenCancel, dialogCancelStatus, handleConsultCancelStatus] = useFormDialogSync(async (event, data) => {
    if (_.isFunction(event?.stopPropagation)) {
      event.stopPropagation();
      dispatch(setRelatedContasReceberItemModel(data));
    }
  }, []);

  const handleCancelSubmit = useCallback(
    async ({ ...values }) => {
      try {
        dispatch(loading());
        handleConsultCancelStatus(SUBMITTING_STATUS);

        const response = await contasReceberAPI.cancelar({
          id: values?.id,
          justificativa: values?.justificativa,
        });

        if (response?.status === 200) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.contaReceberCancelada');

          handleConsultCancelStatus(DONE_STATUS);
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
        }
      } catch (error) {
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.cancelarContasReceberEspecifico', { erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.cancelarContasReceber');
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        dispatch(failure());
        handleConsultCancelStatus(FAIL_STATUS);
      }
    },
    [handleConsultCancelStatus]
  );

  function calculateDesconto({ row }) {
    const desconto = (row?.desconto ?? 0) / 100;
    const valorDescontado = row?.valor + row?.valor * desconto;

    const valorFormatado = unnormalizeNumeral(valorDescontado, formatBrazilianNumericDecimal(2)) || 0;

    return valorFormatado;
  }

  function calculateDescontoView({ row }) {

    const desconto = (row?.desconto ?? 0) / 100;

    const valorDescontado = row?.valorReceber + row?.valorReceber * desconto;

    const valorFormatado = unnormalizeNumeral(valorDescontado, formatBrazilianNumericDecimal(2)) || 0;

    return valorFormatado; 
  }

  function calculoValorRecebido({row}) {

    let valorRecebido = 0;

    if (row.valorMovimentacoesEntrada > row.valorMovimentacoesSaida) {
      valorRecebido = (row.valorMovimentacoesEntrada - row.valorMovimentacoesSaida);
    } else {
      valorRecebido = (row.valorMovimentacoesSaida - row.valorMovimentacoesEntrada);
    }

    const valorFormatado = unnormalizeNumeral(valorRecebido, formatBrazilianNumericDecimal(2)) || 0;

    return valorFormatado; 

  }

  function calculoValorRecebidoNumber(row) {

    let valorRecebido = 0;

    if (row.valorMovimentacoesEntrada > row.valorMovimentacoesSaida) {
      valorRecebido = (row.valorMovimentacoesEntrada - row.valorMovimentacoesSaida);
    } else {
      valorRecebido = (row.valorMovimentacoesSaida - row.valorMovimentacoesEntrada);
    }

    return valorRecebido; 

  }

  function calculoSaldoContasReceber({row}) {

    const desconto = (( row?.desconto ?? 0) / 100);
    const valorDescontado = row?.valorReceber + (row?.valorReceber * desconto);
  
    const valorRecebido = calculoValorRecebidoNumber(row);

    const valorSaldo = valorDescontado - valorRecebido;

    const valorFormatado = unnormalizeNumeral(valorSaldo, formatBrazilianNumericDecimal(2)) || 0;

    return valorFormatado; 
  };

  const [rowToApplyJuros, setRowToApplyJuros] = useState();

  function handleOpenApplyJuros(event, row) {
    setRowToApplyJuros(row);
    openApplyJurosDialog();
  }

  async function handleApplyJuros(values) {
    
    try {

      dispatch(loading());

      const response = await contasReceberAPI.atualizarJurosView(
        rowToApplyJuros?.id, {
        tipo : values?.tipo,
        formato :values?.formato,
        desconto : normalizeNumeral(values?.desconto)});

      if (response?.status === 200) {
        const feedbackMessage = 'Juros Atualizados com sucesso';

        setApplyJurosDialogStatus(CLOSED_STATUS);
        dispatch(success());
        dispatch(
          setSuccessFeedback({
            message: feedbackMessage,
          })
        );
        dispatch(refreshAction());
      }
    } catch (error) {
      const errorMessage = 'Falha ao atualizar os juros!';
      dispatch(
        setErrorFeedback({
          message: errorMessage,
        })
      );
      dispatch(failure());
      setApplyJurosDialogStatus(FAIL_STATUS);
    }
  }

  const [valorParcial, setValorParcial] = useState(0);

  useEffect(() => {
    const calculatedValue = relatedSelectionContasReceberView.reduce((acc, id) => {
      const conta = contasReceber?.content.find((c) => c.id === id);
      return acc + (conta?.valorReceber || 0);
    }, 0);
    const formattedValue = unnormalizeNumeral(calculatedValue, formatBrazilianNumericDecimal(2));
    setValorParcial(formattedValue);
  }, [relatedSelectionContasReceberView, contasReceber]);

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.NumeroProcesso'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.numeroProcesso || '-',
        renderCell: ({ row }) => <TableCellWithTooltip value={row?.numeroProcesso || '-'} />,
      },
      {
        field: 'origem',
        headerName: t('com.muralis.qcx.origem'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 180,
        valueGetter: ({ row }) => row?.origem || '-',
      },
      {
        field: 'solicitacao',
        headerName: t('com.muralis.qcx.solicitacao'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 150,
        valueGetter: ({ row }) => formatDate(row?.solicitacao) || '-',
      },
      {
        field: 'cliente',
        headerName: t('com.muralis.qcx.cliente.label'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 120,
        valueGetter: ({ row }) => row?.cliente || '-',
        renderCell: ({ row }) => <TableCellWithTooltip value={row?.cliente || '-'} />,
      },
      {
        field: 'valorReceber',
        headerName: t('com.muralis.qcx.valorReceber'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 150,
        valueGetter: ({ row }) => row?.valorReceber,
        valueFormatter: ({ row }) => unnormalizeNumeral(row?.valorReceber, formatBrazilianNumericDecimal(2)) || '-',
      },
      {
        field: 'desconto',
        headerName: 'Desconto / Juros',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 150,
        valueGetter: ({ row }) => row?.desconto,
        valueFormatter: ({ row }) => unnormalizeNumeral(row?.desconto, formatBrazilianNumericDecimal(2, true)) || 0,
      },
      {
        field: 'valorDescontado',
        headerName: 'Valor Real',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 150,
        valueGetter: calculateDescontoView,
      },
      {
        field: 'valorRecebido',
        headerName: t('com.muralis.qcx.valorRecebido'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 150,
        valueGetter: calculoValorRecebido,
      },
      {
        field: 'saldo',
        headerName: t('com.muralis.qcx.saldo'),
        headerAlign: 'center',
        align: 'center',
        type: 'numeric',
        flex: 150,
        valueGetter:calculoSaldoContasReceber,
      },
      {
        field: 'dataPagamento',
        headerName: 'Data Pagamento',
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) =>
          row?.dataPagamento ? formatDate(convertLocalDateTimeStringToUTC(row?.dataPagamento)) : '-',
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.status || '-',
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        renderCell: ({ row }) => (
          <>
            <QCXPopupState popupId="popup-menu-contas-receber">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${row?.id}`}
                    name={`btn-more-options-${row?.id}`}
                    {...bindTrigger(popupState)}
                    disabled={isApprovalBatch}
                  >
                    <MoreHorizIcon color={isApprovalBatch ? 'disabled' : 'secondary'} size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        handleOpenCancel(event, row);
                      }}
                      disabled={
                        contasReceberUtils.isRecebido(row?.status) ||
                        contasReceberUtils.isCancelado(row?.status) ||
                        TituloUtils.isOrigemTransferencia(row?.origem)
                      }
                    >
                      <QCXListItemIcon>
                        <CancelIcon fontSize="small" color="error" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleOpenApplyJuros(event, row);
                      }}
                    >
                      <QCXListItemIcon>
                        <PaymentIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Aplicar Juros'.toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [isApprovalBatch, handleOpenCancel]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.financeiro.contasReceber'),
        },
        default: true,
      },
    ],
    []
  );

  function isFullPayed(valor, movimentacoes) {
    const valorRecebido = contasReceberUtils.calculoValorRecebido(movimentacoes);

    return valorRecebido >= valor;
  }

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue, advancedFilter }) => {
      

    const { 
      data_solicitacao_pagamento: dataSolicitacaoPagamento, 
      cliente,
      valor_receber: valorReceber, 
      desconto, 
      data_vencimento: dataVencimento,
    } = advancedFilter;
    
    const filter = filterInputValue;

    const pageParams = [
      { name: 'page', value: currentPage },
      { name: 'size', value: pageSize },
    ];
    
    const filterParams = [
      { name: 'filter', value: filterInputValue },
      { name: 'data_solicitacao_pagamento', value: dataSolicitacaoPagamento },
      { name: 'cliente', value: cliente },
      { name: 'valor_receber', value: valorReceber },
      { name: 'desconto', value: desconto },
      { name: 'data_vencimento', value: dataVencimento },
    ];

    const params = pageParams.concat(filterParams);

    setFetchFilterParams(filterParams);

    dispatch(fetchAllViewAsync(params));

  }, []);

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.financeiro.contasReceber'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        others: [
          ...(isNone
            ? [
                {
                  description: t('com.muralis.qcx.relatorios.label'),
                  disabled: false,
                  color: 'primary',
                  onClick: handleImprimirClick,
                },
                {
                  description: <CheckCircleIcon size={20} color="white" />,
                  ...(_.isEmpty(contasReceber || [])
                    ? {
                        disabled: true,
                        color: 'disabled',
                      }
                    : {
                        disabled: false,
                        style: {
                          backgroundColor: 'green',
                        },
                      }),
                  onClick: handleEnableBatchApprovalMode,
                },
              ]
            : []),
          ...(isApprovalBatch
            ? [
                {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  startIcon: <CheckCircleIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  },
                  onClick: handleOpenModal,
                  disabled: isEmptySelectionList,
                },
                {
                  description: <CancelOutlinedIcon />,
                  variant: 'outlined',
                  color: 'default',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.cancelar'),
                  },
                  onClick: handleCancelBatchApproval,
                },
              ]
            : []),
        ],
      },
      table: {
        columns,
        onConfigChangeCallback: handleConfigChange,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        rowsCount: contasReceber.totalElements,
        checkboxSelection: isApprovalBatch,
        selectionModel: relatedSelectionContasReceberView,
        onSelectionModelChange: handleSelectionModelChange,
        isRowSelectable: (table) =>
          (table?.row?.status === contasReceberUtils.PENDENTE ||
            !isFullPayed(table?.row?.valor, table?.row?.movimentacoes)) &&
          !TituloUtils.isOrigemTransferencia(table?.row?.origem),
      },
    }),
    [
      breadcrumbs,
      columns,
      isApprovalBatch,
      isEmptySelectionList,
      contasReceber,
      handleCancelBatchApproval,
      handleConfirmBatchApproval,
      handleSelectionModelChange,
      handleEnableBatchApprovalMode,
      relatedSelectionContasReceberView,
      handleConfigChange,
    ]
  );


  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={contasReceber.content}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['conta-receber']}
      advancedFilterFormChildren={ContasReceberFilterComponent}
      advancedFilterKeyAccessors={['data_solicitacao_pagamento','cliente','valor_receber','desconto','data_vencimento']}
      clearSelection
    >
      <QCXJustifiedActionFormDialog
        handleSubmit={handleCancelSubmit}
        status={dialogCancelStatus}
        handleStatus={handleConsultCancelStatus}
        type={contasReceberUtils.CANCELAR}
        initialValues={relatedContasReceberItem}
      />
      <QCXRelatoriosContasDialogForm
        handleFormStatus={handleFormDialogImpressaoStatus}
        handleOnSubmit={(event) => handleImprimirByDateSubmit(event)}
        formDialogStatus={formDialogImpressaoStatus}
        initialValues={reportRelatedModel}
        typeReport={contasReceberUtils.CONTAS_RECEBER}
      />
      <QCXPartialValuesDialogForm
        handleFormStatus={setFormDialogConfirmBatchApprovalStatus}
        handleOnSubmit={(formData) => handleConfirmBatchApproval(formData)}
        formDialogStatus={formDialogConfirmBatchApprovalStatus}
        initialValues={{
          ...reportRelatedModel,
          valorParcial,
        }}
        isSingleItemSelected={isSingleItemSelected}
      />
      <QCXApplyJurosDialogForm
        handleFormStatus={setApplyJurosDialogStatus}
        handleOnSubmit={(values) => handleApplyJuros(values)}
        formDialogStatus={applyJurosDialogStatus}
      />
    </SimpleConsultPageTemplate>
  );
}
