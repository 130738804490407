import { createAsyncThunk } from '@reduxjs/toolkit';
import { solicitacaoPagamentoAPI } from './solicitacaoPagamentoAPI';

const fetchAllAsync = createAsyncThunk(
  'solicitacaoPagamento/fetchAll',
  async () => {
    const { data } = await solicitacaoPagamentoAPI.fetchAll();
    return { data };
  }
);

const fetchAllNonFaturadoAndLiquidadoAsync = createAsyncThunk(
  'solicitacaoPagamento/listagem',
  async () => {
    const { data } = await solicitacaoPagamentoAPI.fetchAllNonFaturadoAndLiquidado();
    return { data };
  }
);

const fetchAllSolicitadasAsync = createAsyncThunk(
  'solicitacaoPagamento/solicitadas',
  async () => {
    const { data } = await solicitacaoPagamentoAPI.fetchAllSolicitadas();
    return { data };
  }
);

const fetchAllViewAsync = createAsyncThunk(
  'solicitacaoPagamento/view',
  async (params) => {

    const { data } = await solicitacaoPagamentoAPI.fetchAllView(params);

    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'solicitacaoPagamento/fetchById',
  async (data) => {
    const response = await solicitacaoPagamentoAPI.fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const solicitacaoPagamentoThunks = {
  fetchAllAsync,
  fetchByIdAsync,
};

export {
  solicitacaoPagamentoThunks,
  fetchAllAsync,
  fetchByIdAsync,
  fetchAllNonFaturadoAndLiquidadoAsync,
  fetchAllSolicitadasAsync,
  fetchAllViewAsync,
};
