import _ from 'lodash';
import { isStrictEquals, normalizeNumeral } from '../general-utils';

const TIPO_SOLICITACAO_PAGAMENTO_OPERACIONAL = 'OPERACIONAL';
const TIPO_SOLICITACAO_PAGAMENTO_ADMINISTRATIVO = 'ADMINISTRATIVA';

const STATUS_SOLICITACAO_PAGAMENTO_AG_RECEBIMENTO_DE_NUMERARIO = 'AG_RECEBIMENTO_DE_NUMERARIO';
const STATUS_SOLICITACAO_PAGAMENTO_AG_SOLICITACAO = 'AG_SOLICITACAO';
const STATUS_SOLICITACAO_PAGAMENTO_AG_APROVACAO = 'AG_APROVACAO';
const STATUS_SOLICITACAO_PAGAMENTO_APROVADO = 'APROVADO';
const STATUS_SOLICITACAO_PAGAMENTO_CANCELADO = 'CANCELADO';
const STATUS_SOLICITACAO_PAGAMENTO_FATURADO = 'FATURADO';
const STATUS_SOLICITACAO_PAGAMENTO_LIQUIDADO = 'LIQUIDADO';
const STATUS_SOLICITACAO_PAGAMENTO_PAGO = 'PAGO';
const STATUS_SOLICITACAO_PAGAMENTO_PROCESSO_COM_SALDO_INSUFICIENTE = 'PROCESSO_COM_SALDO_INSUFICIENTE';
const STATUS_SOLICITACAO_PAGAMENTO_PROCESSO_COM_SALDO_INSUFICIENTE_VIEW = 'PROCESSO COM SALDO INSUFICIENTE';
const STATUS_SOLICITACAO_PAGAMENTO_RECUSADO_PELO_APROVADOR = 'RECUSADO_PELO_APROVADOR';
const STATUS_SOLICITACAO_PAGAMENTO_SOLICITACAO_PENDENTE = 'SOLICITACAO_PENDENTE';
const STATUS_SOLICITACAO_PAGAMENTO_SOLICITACAO_PENDENTE_VIEW = 'SOLICITACAO PENDENTE';

const GANHO_PROCESSO_KEY = 'ganhoProcesso';
const PERDA_PROCESSO_KEY = 'perdaProcesso';

const mapValorMoedaAndValorPercentualFieldNames = [
  {
    valorField: 'valorDescontoMoeda',
    percentualField: 'percentualDesconto',
  },
  {
    valorField: 'valorMultaMoeda',
    percentualField: 'percentualMulta',
  },
  {
    valorField: 'valorJurosMoeda',
    percentualField: 'percentualJuros',
  },
];

const isTipoSolicitacaoPagamentoOperacional = (code) => (
  isStrictEquals(code, TIPO_SOLICITACAO_PAGAMENTO_OPERACIONAL)
);

const isTipoSolicitacaoPagamentoAdministrativa = (code) => (
  isStrictEquals(code, TIPO_SOLICITACAO_PAGAMENTO_ADMINISTRATIVO)
);

const isSolicitacaoPagamentoGanhoProcesso = (key) => (
  isStrictEquals(key, GANHO_PROCESSO_KEY)
);

const isSolicitacaoPagamentoPerdaProcesso = (key) => (
  isStrictEquals(key, PERDA_PROCESSO_KEY)
);

const isSolicitacaoPagamentoStatusSolicitacaoPendente = (code) => (
  isStrictEquals(code, STATUS_SOLICITACAO_PAGAMENTO_SOLICITACAO_PENDENTE)
);

const isSolicitacaoPagamentoStatusSolicitacaoPendenteView = (code) => (
  isStrictEquals(code, STATUS_SOLICITACAO_PAGAMENTO_SOLICITACAO_PENDENTE_VIEW)
)

const isSolicitacaoPagamentoStatusProcessoSaldoInsuficiente = (code) => (
  isStrictEquals(code, STATUS_SOLICITACAO_PAGAMENTO_PROCESSO_COM_SALDO_INSUFICIENTE)
);

const isSolicitacaoPagamentoStatusProcessoSaldoInsuficienteView = (code) => (
  isStrictEquals(code, STATUS_SOLICITACAO_PAGAMENTO_PROCESSO_COM_SALDO_INSUFICIENTE_VIEW)
);

const isSolicitacaoPagamentoStatusAgAprovacao = (code) => (
  isStrictEquals(code, STATUS_SOLICITACAO_PAGAMENTO_AG_APROVACAO)
);

const isSolicitacaoPagamentoStatusCancelado = (code) => (
  isStrictEquals(code, STATUS_SOLICITACAO_PAGAMENTO_CANCELADO)
);

const isSolicitacaoPagamentoStatusAprovado = (code) => (
  isStrictEquals(code, STATUS_SOLICITACAO_PAGAMENTO_APROVADO)
);

const isSolicitacaoPagamentoStatusRecusado = (code) => (
  isStrictEquals(code, STATUS_SOLICITACAO_PAGAMENTO_RECUSADO_PELO_APROVADOR)
);

const calculaValorTotal = (values) => {
  const fields = [
    'valorOriginalMoeda',
    'valorMultaMoeda',
    'valorJurosMoeda'
  ];

  const valoresSoma = fields.map((field) => (
    normalizeNumeral(
      _.get(values, field)
    ) || 0
  ));

  const valorDescontoMoeda = normalizeNumeral(
    _.get(values, 'valorDescontoMoeda')
  ) || 0;
  const resultadoValoresSoma = valoresSoma.reduce((accum, curr) => accum + curr);

  const valorTotalMoeda = resultadoValoresSoma
    ? resultadoValoresSoma - valorDescontoMoeda
    : 0;

  return valorTotalMoeda;
};

const calculaPercentuaisByValorMoeda = (values) => {
  const valorOriginalMoeda = normalizeNumeral(
    _.get(values, 'valorOriginalMoeda')
  ) || 0;

  if (valorOriginalMoeda) {
    const mapValorPercentualFieldsWithValues = mapValorMoedaAndValorPercentualFieldNames.map(
      ({ valorField, percentualField }) => ({
        valorField: {
          name: valorField,
          value: normalizeNumeral(
            _.get(values, valorField)
          ) || 0,
        },
        percentualField: {
          name: percentualField,
          value: normalizeNumeral(
            _.get(values, percentualField)
          ) || 0,
        },
      })
    );

    const updatedPercentuaisFields = mapValorPercentualFieldsWithValues.map(
      ({ valorField, percentualField }) => {
        const valorMoeda = valorField.value;
        const percentual = percentualField?.value;

        if (valorMoeda && !percentual) {
          const updatedPercentual = {
            name: percentualField?.name,
            value: (valorMoeda * 100) / valorOriginalMoeda,
          };

          return updatedPercentual;
        }

        return undefined;
      }
    );

    return updatedPercentuaisFields;
  }

  return undefined;
};

const calculaValorMoedaByPercentual = ({
  valorOriginalMoeda,
  values,
  fields,
}) => {
  const valorMoeda = normalizeNumeral(
    _.get(values, fields?.valorField)
  ) || 0;

  const percentual = normalizeNumeral(
    _.get(values, fields?.percentualField)
  ) || 0;

  if (percentual && !valorMoeda) {
    const updatedValorMoeda = {
      name: fields?.valorField,
      value: (valorOriginalMoeda * percentual) / 100,
    };

    return updatedValorMoeda;
  }

  return {
    name: fields?.valorField,
    value: valorMoeda,
  };
};

const SolicitacaoPagamentoUtils = Object.freeze({
  OPERACIONAL: (
    TIPO_SOLICITACAO_PAGAMENTO_OPERACIONAL
  ),
  ADMINISTRATIVA: (
    TIPO_SOLICITACAO_PAGAMENTO_ADMINISTRATIVO
  ),
  AG_RECEBIMENTO_DE_NUMERARIO: (
    STATUS_SOLICITACAO_PAGAMENTO_AG_RECEBIMENTO_DE_NUMERARIO
  ),
  AG_SOLICITACAO: (
    STATUS_SOLICITACAO_PAGAMENTO_AG_SOLICITACAO
  ),
  AG_APROVACAO: (
    STATUS_SOLICITACAO_PAGAMENTO_AG_APROVACAO
  ),
  APROVADO: (
    STATUS_SOLICITACAO_PAGAMENTO_APROVADO
  ),
  CANCELADO: (
    STATUS_SOLICITACAO_PAGAMENTO_CANCELADO
  ),
  FATURADO: (
    STATUS_SOLICITACAO_PAGAMENTO_FATURADO
  ),
  LIQUIDADO: (
    STATUS_SOLICITACAO_PAGAMENTO_LIQUIDADO
  ),
  PAGO: (
    STATUS_SOLICITACAO_PAGAMENTO_PAGO
  ),
  PROCESSO_COM_SALDO_INSUFICIENTE: (
    STATUS_SOLICITACAO_PAGAMENTO_PROCESSO_COM_SALDO_INSUFICIENTE
  ),
  RECUSADO_PELO_APROVADOR: (
    STATUS_SOLICITACAO_PAGAMENTO_RECUSADO_PELO_APROVADOR
  ),
  SOLICITACAO_PENDENTE: (
    STATUS_SOLICITACAO_PAGAMENTO_SOLICITACAO_PENDENTE
  ),
  GANHO_PROCESSO: (
    GANHO_PROCESSO_KEY
  ),
  PERDA_PROCESSO: (
    PERDA_PROCESSO_KEY
  ),
  isOperacional: (
    isTipoSolicitacaoPagamentoOperacional
  ),
  isAdministrativa: (
    isTipoSolicitacaoPagamentoAdministrativa
  ),
  isGanhoProcesso: (
    isSolicitacaoPagamentoGanhoProcesso
  ),
  isPerdaProcesso: (
    isSolicitacaoPagamentoPerdaProcesso
  ),
  isSolicitacaoPendente: (
    isSolicitacaoPagamentoStatusSolicitacaoPendente
  ),
  isSolicitacaoPendenteView: (
    isSolicitacaoPagamentoStatusSolicitacaoPendenteView
  ),  
  isSolicitacaoProcessoSaldoInsuficiente: (
    isSolicitacaoPagamentoStatusProcessoSaldoInsuficiente
  ),
  isSolicitacaoProcessoSaldoInsuficienteView: (
    isSolicitacaoPagamentoStatusProcessoSaldoInsuficienteView
  ),
  isAgAprovacao: (
    isSolicitacaoPagamentoStatusAgAprovacao
  ),
  isCancelado: (
    isSolicitacaoPagamentoStatusCancelado
  ),
  isAprovado: (
    isSolicitacaoPagamentoStatusAprovado
  ),
  isRecusado: (
    isSolicitacaoPagamentoStatusRecusado
  ),
  calculaPercentuaisByValorMoeda,
  calculaValorTotal,
  calculaValorMoedaByPercentual,
  mapValorMoedaAndValorPercentualFieldNames,
});

export default SolicitacaoPagamentoUtils;
