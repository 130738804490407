import { doGetQCXRequest, doPostQCXRequest, doPutQCXRequest } from '../../utils/api/domain/domain-api-utils';
import { validateQueryParams } from '../../utils/api/api-utils';
import {
  QCX_TITULOS_MOVIMENTACAO_ENDPOINT,
  QCX_CONCILIACAO_ENDPOINT,
  QCX_PENDENTE_ENDPOINT,
  QCX_CONCILIAR_ENDPOINT,
  QCX_DESCONCILIAR_ENDPOINT,
  QCX_CONCILIACAO_BANCARIA_ENDPOINT,
  QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
  QCX_RELATORIO_ENDPOINT,
  QCX_VIEW_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchConciliacaoPendenteAll() {
  return doGetQCXRequest([QCX_TITULOS_MOVIMENTACAO_ENDPOINT, QCX_CONCILIACAO_ENDPOINT, QCX_PENDENTE_ENDPOINT]);
}

async function fetchAll() {
  return doGetQCXRequest([QCX_TITULOS_MOVIMENTACAO_ENDPOINT, QCX_CONCILIACAO_ENDPOINT]);
}

async function fetchAllView(params = []) {
  return doGetQCXRequest([QCX_TITULOS_MOVIMENTACAO_ENDPOINT,QCX_VIEW_ENDPOINT], params)
}

async function conciliarEmLote(data) {
  return doPutQCXRequest([QCX_TITULOS_MOVIMENTACAO_ENDPOINT, QCX_CONCILIAR_ENDPOINT], data);
}

async function conciliar(id) {
  return doPutQCXRequest([QCX_TITULOS_MOVIMENTACAO_ENDPOINT, id, QCX_CONCILIAR_ENDPOINT]);
}

async function desconciliar(id) {
  return doPutQCXRequest([QCX_TITULOS_MOVIMENTACAO_ENDPOINT, id, QCX_DESCONCILIAR_ENDPOINT]);
}

async function generateReportByDate(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest(
      [QCX_SOLICITACAO_PAGAMENTO_ENDPOINT, QCX_RELATORIO_ENDPOINT, QCX_CONCILIACAO_BANCARIA_ENDPOINT, ''],
      validatedParams,
      {
        responseType: 'blob',
      }
    );
  }
  return { data: [] };
}

async function efetuarTransferenciaBancaria(data = {}) {
  return doPostQCXRequest([QCX_TITULOS_MOVIMENTACAO_ENDPOINT, 'transferencia-bancaria'], data);
}

async function criarConciliacaoBancaria(data = {}) {
  return doPostQCXRequest([QCX_TITULOS_MOVIMENTACAO_ENDPOINT, 'conciliacao'], data);
}

const conciliacaoBancariaAPI = {
  fetchConciliacaoPendenteAll,
  fetchAll,
  fetchAllView,
  conciliarEmLote,
  conciliar,
  desconciliar,
  generateReportByDate,
  efetuarTransferenciaBancaria,
  criarConciliacaoBancaria,
};

export {
  conciliacaoBancariaAPI,
  fetchConciliacaoPendenteAll,
  fetchAll,
  fetchAllView,
  conciliarEmLote,
  conciliar,
  desconciliar,
  generateReportByDate,
  efetuarTransferenciaBancaria,
  criarConciliacaoBancaria,
};
