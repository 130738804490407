import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectDespachantes } from '../../features/despachante/despachanteSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectDespachanteAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectDespachantes);
  // TODO
  // const configControl = useSelector(selectConfigControl);
  useEffect(() => {
    // TODO
    // if (isAutoLoadControl(configControl)) {
    //   dispatch(fetchAllAsync());
    // }
    dispatch(fetchAllAsync());
  }, []);

  const [defaultValue, setDefaultValue] = useState('');
  useEffect(() => {
    const value = list.find((item) => item.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.id} - ${option.nome}`,
  });
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.id && item?.nome ? `${item?.id} - ${item?.nome}` : '')}
      {...restProps}
    />
  );
}
