import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchAll,
  fetchById,
  activateById,
  inactivateById,
} from './modeloTabelaSdaAPI';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  NONE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CREATE_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
} from '../mode';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  model: {
    id: '',
    description: '',
    active: null,
    insertionDate: null,
    tipo: '',
    informacoes: '',
  },
};

const fetchAllAsync = createAsyncThunk(
  'ModeloTabelaSda/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'ModeloTabelaSda/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const activateByIdAsync = createAsyncThunk(
  'ModeloTabelaSda/activateById',
  async (id) => {
    const { status, data } = await activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'ModeloTabelaSda/inactivateById',
  async (id) => {
    const { status, data } = await inactivateById(id);
    return { response: { status, data } };
  }
);

const ModeloTabelaSdaSlice = createSlice({
  name: 'ModeloTabelaSda',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    resetList: (state) => {
      state.list = [];
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setDespachantes: (state, action) => {
      state.model.despachantes = [...action.payload];
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: 'Erro ao carregar modelos SDA',
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: 'Erro ao carregar dados dos modelos SDA',
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        state.response.message = 'modelo tabela SDA ativada com sucesso';

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: 'Erro ao ativar Modelo de Tabela SDA',
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        state.response.message = 'Erro ao inativar Modelo de Tabela SDA';

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: 'Erro ao inativar Modelo de Tabela SDA',
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  changeToBackgroundCreateMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  clearError,
  preparingAction,
  resetBackgroundMode,
  resetMode,
  resetSubMode,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetStatus,
  resetList,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  resetModel,
  setModel,
} = ModeloTabelaSdaSlice.actions;

const selectModelosTabelasSda = (state) => state.modeloTabelaSda.list;
const selectStatus = (state) => state.modeloTabelaSda.status;
const selectError = (state) => state.modeloTabelaSda.error;
const selectResponse = (state) => state.modeloTabelaSda.response;
const selectModeloTabelaSda = (state) => state.modeloTabelaSda.model;
const selectMode = (state) => state.modeloTabelaSda.mode.main;
const selectSubMode = (state) => state.modeloTabelaSda.mode.sub;
const selectBackgroundMode = (state) => state.modeloTabelaSda.mode.background;

export {
  ModeloTabelaSdaSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  changeToBackgroundCreateMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  clearError,
  preparingAction,
  resetBackgroundMode,
  resetMode,
  resetSubMode,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetStatus,
  resetList,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  resetModel,
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  setModel,
  selectModelosTabelasSda,
  selectModeloTabelaSda,
  selectStatus,
  selectError,
  selectResponse,
  selectMode,
  selectSubMode,
  selectBackgroundMode,
};

export default ModeloTabelaSdaSlice.reducer;
