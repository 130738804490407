import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalDatePickerField from '../../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalAlternativeCurrencyField from '../../../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField'
import QCXSelectClienteAutocomplete from '../../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectDespesaReceitaAutocomplete from '../../../../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaAutocomplete';
import QCXSelectFornecedorAutocomplete from '../../../../../shared-components/select-fornecedor-favorecido-autocomplete/QCXSelectFornecedorAutocomplete.js';

export default function ContasReceberFilterComponent() {

    const { t } = useTranslation();

    return (
        <Box style={{ minWidth: '500px' }}>
        <Grid container title="Seção que contém informações do usuário" spacing={2}>
          <Grid item sm={12}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.solicitacaoPagamento.filter.data_solicitacao_pagamento')}
              name="data_solicitacao_pagamento"
              required={undefined}
              validate={undefined}
            />
          </Grid>
           <Grid item sm={12}>
            <QCXSelectClienteAutocomplete
                id="text-field-cliente"
                key="text-field-cliente"
                name="cliente"
                label={t('com.muralis.qcx.contas_receber.filter.cliente')}
                required={false}
                initialValues={undefined}
            />
            </Grid>
          <Grid item xs={12} sm={12} md={12}>
                <QCXFinalAlternativeCurrencyField
                  id="financeiro-valor-desconto-field"
                  key="financeiro-valor-desconto-field"
                  name="valor_receber"
                  label={t('com.muralis.qcx.contas_receber.filter.valor_receber')}
                  onBlur={undefined}
                  disabled={undefined}
                />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
                <QCXFinalAlternativeCurrencyField
                  id="financeiro-valor-desconto-field"
                  key="financeiro-valor-desconto-field"
                  name="desconto"
                  label={t('com.muralis.qcx.contas_receber.filter.desconto')}
                  onBlur={undefined}
                  disabled={undefined}
                />
          </Grid>
          <Grid item sm={12}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.contas_receber.filter.data_pagamento')}
              name="data_vencimento"
              required={undefined}
              validate={undefined}
            />
          </Grid>
  
        </Grid>
      </Box>
    );
}