import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import moment from "moment";

import { selectDocumentoAnexacao } from '../../features/documento-anexacao/documentoAnexacaoSlice';

import QCXSelectDocumentoAnexacao from '../select-documento-anexacao/QCXSelectDocumentoAnexacao';
import QCXPalavraChaveInput       from '../palavra-chave-input/QCXPalavraChaveInput';
import QCXFormTitle from '../../components/form-title/QCXFormTitle';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import QCXFinalBondManager from '../final-gerenciador-relacionamento/QCXFinalBondManager';


export default function QCXFormDocumentoAnexacaoPalavraChave({
  initialValues,
  handleSubmit,
  isConsult,
  isCreate,
  isLocked,
  controlComponentProps,
  ...restProps
}) {

  const { t } = useTranslation();
  const form = useForm();
  const { values } = useFormState();

  const [idDocumentoAtual, setIdDocumentoAtual] = useState(null);
  const [palavrasChaveDocumento, setPalavrasChaveDocumento] = useState([]);
  const listDocumentos = useSelector(selectDocumentoAnexacao);

  useEffect(() => {
    if (idDocumentoAtual && listDocumentos) {
      const documentoSelecionado = listDocumentos.find(documento => documento.id === idDocumentoAtual);
      const palavrasChaveArr = documentoSelecionado.palavrasChave;
      setPalavrasChaveDocumento(palavrasChaveArr)
    } else if (!idDocumentoAtual) {
      setPalavrasChaveDocumento([]);
    }
  }, [idDocumentoAtual])

  useEffect(() => {
    const ignorableForm = form.getState().values?.ignorableFields?.documentoAnexacao;
    if (!ignorableForm) return ;
    if (ignorableForm?.id && ignorableForm?.documento && ignorableForm?.valoresPalavrasChave && !ignorableForm?.palavrasChave) {
      if (!_.isArray(ignorableForm.valoresPalavrasChave)) return ;

      // inicializar campos com valores informados anteriormente.
      const palavrasValues = {};
      ignorableForm.valoresPalavrasChave.forEach(palavra => {
        const key = `cod_${palavra.idPalavraChave}`;
        const value = palavra.valor
        palavrasValues[key] = value;
      });

      form.change("ignorableFields.documentoAnexacao.palavrasChave",  {...palavrasValues})
    }
  }, [values])

  const atualizaEstadoIdDocumentoAtual = (id) => {
    setIdDocumentoAtual(id);
  }

  const getRowPalavrasChave = (row) => {
    if (!row) return '';
    if (!row.valoresPalavrasChave || !_.isArray(row.valoresPalavrasChave)) return '';

    return row.valoresPalavrasChave.map(palavra => {
      const nomeCampo = `${palavra.nomePalavraChave}`;
      const valorCampo = `${palavra.valor}`;
      return `${nomeCampo}: ${valorCampo}`.toUpperCase();
    }).join(', ');

  }

  /* RENDERIZA A TABELA */
  const palavraChaveProps = useMemo(() => ({
    name: "atributosAdicionais.documentosAnexacaoValor",
    columns: [
      {
        field: 'documento.id',
        headerName: t('com.muralis.qcx.codigo'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 70,
        valueGetter: ({ row }) => row?.documento?.id,
      },
      {
        field: 'documento.nomeTipoDocumento',
        headerName: 'Tipo de Documento',
        type: 'string',
        flex: 200,
        valueGetter: ({ row }) => row?.documento?.nomeTipoDocumento,
      },
      {
        field: 'valoresPalavrasChave',
        headerName: "Palavras-chaves",
        type: 'string',
        flex: 400,
        valueGetter: ({ row }) => getRowPalavrasChave(row),
      },
    ],
  }), []);
  
  const palavraChaveFormProps = useMemo(() => ({
    rootName: `ignorableFields.documentoAnexacao`,
    fields: [
      {
        name: 'documento.id',
        label:'Tipo de Documento',
        required: false,
      }
    ],
  }), []);

  const resetFormAddPalavraChave = () => {
    form.change("ignorableFields.documentoAnexacao.documento.id",  null)
    form.change("ignorableFields.documentoAnexacao.palavrasChave",  {})
  }

  const handleAddPalavraChave= useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
  
    const documentoSelecionado = listDocumentos.find(doc => doc.id === currentVinculo?.documento?.id);
    
    if (!documentoSelecionado) {
      //! INSERIR VALIDACAO DE DOCUMENTO SELECIONADO NAO ENCONTRADO
      return ;
    }

    if (!currentVinculo || !currentVinculo?.documento?.id || !currentVinculo?.palavrasChave) {
      //! INSERIR VALIDACAO PARA ESSE CASO
      return ;
    }

    const valoresPalavras = []
    const objKeys = Object.keys(currentVinculo.palavrasChave);
   
    objKeys.forEach(key => {
      const codigoPortal = key.match(/\d+$/)[0];
      const palavraChave = [...documentoSelecionado.palavrasChave].find(palavra => `${palavra.idPalavraChave}` === codigoPortal);

      if (!palavraChave) {
        //! INSERIR VALIDACAO PARA ESSE CASO
        return ;
      }
      
      let valor = currentVinculo.palavrasChave[key];
      if (palavraChave.tipoDado && palavraChave.tipoDado.includes('DATA')) {
        valor = moment(valor).format('DD/MM/YYYY')
      }

      valoresPalavras.push({
        idPalavraChave: palavraChave.idPalavraChave,
        nomePalavraChave: palavraChave.nomePalavraChave,
        tipoDado : palavraChave.tipoDado,
        valor,
      });


    })

    const novoDoc = {
      id: uuid(),
      documento : {
        id: currentVinculo.documento.id,
        nomeTipoDocumento: documentoSelecionado.nomeTipoDocumento,
      },
      valoresPalavrasChave: [...valoresPalavras],
    }

    const documentosAnexacaoDuimp = form.getState().values?.atributosAdicionais?.documentosAnexacaoValor;
    if (documentosAnexacaoDuimp && _.isArray(documentosAnexacaoDuimp)) {
      const documentosClone = _.cloneDeep(documentosAnexacaoDuimp)
      form.change("atributosAdicionais.documentosAnexacaoValor", [...documentosClone,  novoDoc])
    }

    resetFormAddPalavraChave()

   
  }, [listDocumentos]);

  const handleUpdatePalavrasChave = useCallback( async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    console.log({...currentVinculo})
  }, []);



  return (
    <Grid item container spacing={2}>
      
        <Grid item xs={12}>
          <QCXFormTitle title="Relação de Documentos Instrutivos do Despacho" />
        </Grid>


        <QCXFinalBondManager
          isParentConsult={isConsult}
          listProps={palavraChaveProps}
          formProps={palavraChaveFormProps}
          handleAdd={handleAddPalavraChave}
          handleUpdate={handleUpdatePalavrasChave}
          forceShowDelete
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <QCXSelectDocumentoAnexacao
              name="ignorableFields.documentoAnexacao.documento.id"
              label="Tipo de Documento"
              required={false}
              initialValues={initialValues}
              disabled={isConsult}
              fnAction={atualizaEstadoIdDocumentoAtual}
            />
          </Grid>

          {
            palavrasChaveDocumento && Array.isArray(palavrasChaveDocumento) && palavrasChaveDocumento.length ? (
              <>
            
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <QCXFormSubtitle title="Palavras Chave" />
                  </Grid>
        
                  {
                    palavrasChaveDocumento.map(palavraChave => 
                        <QCXPalavraChaveInput
                          palavraChave={palavraChave}
                          disabled={isConsult}
                        />
                    )
                  }
                
              </>
            ) : <></>
          }
        </QCXFinalBondManager>
        
    </Grid>
  );
}
