import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { despesaReceitaAPI } from '../../features/despesa-receita/despesaReceitaAPI';
import { setErrorFeedback } from '../../features/feedback/feedbackSlice';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import { vinculoFollowUpCapaFaturamentoActions } from '../../features/vinculo-followup-capa-faturamento/vinculoFollowUpCapaFaturamentoSlice';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXCapaFaturamentoForm from './QCXCapaFaturamentoForm';
import QCXDadosBancariosForm from './QCXDadosBancariosForm';
import QCXDespesaReceitaModeloFaturamentoFormTable from './QCXDespesaReceitaModeloFaturamentoFormTable';
import QCXResumoSolicitacaoCards from './QCXResumoSolicitacaoCards';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import { taxaConversaoCambioAPI } from '../../features/taxa-conversao-cambio/taxaConversaoCambioAPI';
import { faturamentoSelectors } from '../../features/faturamento/faturamentoSelectors';
import { isPerformingActionStatus, isPreparingActionStatus, isSubUpdateMode } from '../../utils/store/store-utils';
import { faturamentoAPI } from '../../features/faturamento/faturamentoAPI';
import { vinculoDespesaReceitaActions } from '../../features/vinculo-despesa-receita/vinculoDespesaReceitaSlice';
import { unnormalizeNumeral } from '../../utils/general/general-utils';
import QCXDespesaReceitaNumerario from './QCXDespesaReceitaNumerario';
import FaturamentoUtils from '../../utils/general/faturamento/FaturamentoUtils';
import QCXErrorAlert from '../../shared-components/alert/QCXErrorAlert';
import QCXSuccessAlert from '../../shared-components/alert/QCXSuccessAlert';
import QCXNumerario from './QCXNumerario';
import QCXFaturamentoImpostos from './QCXFaturamentoImpostos';
import QCXFaturamentoPagosPeloCliente from './QCXFaturamentoPagosPeloCliente';
import QCXPagosPeloClienteFormTable from './QCXPagosPeloClienteFormTable';

export default function QCXFaturamentoFinanceiroForm({
  isLoading,
  isCreate,
  isUpdate,
  isConsult,
  isExternal,
  initialValues,
  normalize,
  unnormalize,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  authInfo = {},
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const status = useSelector(faturamentoSelectors.selectStatus);
  const subMode = useSelector(faturamentoSelectors.selectSubMode);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isPerformingAction = useMemo(() => isPerformingActionStatus(status), [status]);

  const isFaturado = useMemo(
    () =>
      FaturamentoUtils.isFaturado(initialValues?.status) ||
      FaturamentoUtils.isAguardandoValidacaoNota(initialValues?.status),
    [initialValues]
  );

  const isCancelado = useMemo(() => FaturamentoUtils.isCancelado(initialValues?.status), [initialValues]);

  const isEdicaoBloqueada = useMemo(() => isFaturado || isCancelado, [isFaturado, isCancelado]);

  const resetRelatedModes = useCallback(() => {
    dispatch(vinculoFollowUpCapaFaturamentoActions.resetMode());
    dispatch(vinculoDespesaReceitaActions.resetMode());
  }, []);

  const resetRelatedModels = useCallback(() => {
    dispatch(vinculoFollowUpCapaFaturamentoActions.resetModel());
    dispatch(vinculoDespesaReceitaActions.resetModel());
  }, []);

  useEffect(() => {
    const clearOnUnmount = () => {
      resetRelatedModes();
      resetRelatedModels();
    };

    return clearOnUnmount;
  }, []);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const fetchFollowUpsBy = useCallback(async (payload) => {
    const query = payload?.faturamento?.id
      ? [
          {
            name: 'faturamento',
            value: payload?.faturamento?.id,
          },
          {
            name: 'filterImportAndExport',
            value: 1,
          },
        ]
      : [
          {
            name: 'filterImportAndExport',
            value: 1,
          },
        ];

    try {
      const response = await faturamentoAPI.filterFollowUpsBy(
        {
          importador: {
            id: payload?.importador?.id,
          },
        },
        query
      );
      if (response?.status === 200) {
        const { data } = response;
        if (_.isArrayLikeObject(data)) {
          dispatch(
            followUpActions.setList({
              data,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setErrorFeedback({
          message: t('com.muralis.qcx.erro.erroFiltroProcessosImportador'),
        })
      );
    }
  }, []);

  const isEmptyCapas = useMemo(
    () => _.isUndefined(initialValues?.capas) || _.isEmpty(initialValues?.capas),
    [initialValues]
  );

  const handleImportadorCapaIdChange = useCallback(
    (form, values) => async (value) => {
      if (!value) {
        form.change('draftFields.current.followUp.id', undefined);

        return;
      }

      const payload = {
        faturamento: {
          id: values?.id,
        },
        importador: {
          id: value,
        },
      };

      await fetchFollowUpsBy(payload);
    },
    [fetchFollowUpsBy]
  );

  const handleMoedaCapaIdChange = useCallback(
    (form, values) => async (value, previous) => {
      if ((previous && value && value !== previous) || (value && !values?.draftFields?.current?.taxaCambio)) {
        const response = await taxaConversaoCambioAPI.fetchByFilter([
          {
            name: 'moeda',
            value,
          },
        ]);

        if (response?.status === 200) {
          const taxaConversaoCambio = _.get(response, 'data[0]');
          const taxaConversao = taxaConversaoCambio?.taxaConversao;

          if (taxaConversao) {
            const formattedTaxaConversao = unnormalizeNumeral(taxaConversao, formatBrazilianNumericDecimal(5));

            form.change('draftFields.current.taxaCambio', formattedTaxaConversao);
          }
        }
      }
    },
    []
  );

  const handleDespesaReceitaIdChange = useCallback(
    (form) => async (value) => {
      if (!value) {
        form.change('draftFields.current.lancamento', undefined);
        form.change('draftFields.current.lancamento.totalMoeda', '');
        form.change('draftFields.current.lancamento.totalReal', '');
        form.change('draftFields.current.lancamento.despesaReceita.tipo', undefined);

        form.resetFieldState('draftFields.current.lancamento.totalMoeda');
        form.resetFieldState('draftFields.current.lancamento.totalReal');
        form.resetFieldState('draftFields.current.lancamento.despesaReceita.tipo');

        return;
      }

      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(value);

      if (despesaReceitaResponse?.status === 200) {
        const despesaReceitaFound = despesaReceitaResponse?.data;

        form.change('draftFields.current.lancamento.despesaReceita.tipo', despesaReceitaFound?.tipo);
      }
    },
    []
  );

  const handleValorSolicitadoDespesaReceitaChange = useCallback(
    (form) => async (value) => {
      if (!value) {
        form.change('draftFields.current.lancamento.totalReal', '');
      }
    },
    []
  );

  const listeners = useMemo(
    () => [
      {
        name: 'importador.id',
        fn: handleImportadorCapaIdChange,
      },
      {
        name: 'draftFields.current.lancamento.moeda.id',
        fn: handleMoedaCapaIdChange,
      },
      {
        name: 'draftFields.current.lancamento.despesaReceita.id',
        fn: handleDespesaReceitaIdChange,
      },
      {
        name: 'draftFields.current.lancamento.totalMoeda',
        fn: handleValorSolicitadoDespesaReceitaChange,
      },
    ],
    [
      handleImportadorCapaIdChange,
      handleMoedaCapaIdChange,
      handleDespesaReceitaIdChange,
      handleValorSolicitadoDespesaReceitaChange,
    ]
  );

  const formControlButtons = useMemo(
    () => ({
      create: {
        description: t('com.muralis.qcx.acoes.solicitar'),
        disabled: isEmptyCapas || isSubUpdate,
        hidden: !isCreate || isEdicaoBloqueada,
      },
      edit: {
        hidden: true,
      },
      save: {
        disabled: isEmptyCapas || isSubUpdate,
        hidden: !isUpdate || isEdicaoBloqueada,
      },
      cancel: {
        hidden: true,
      },
    }),
    [isCreate, isUpdate, isConsult, isSubUpdate, isEmptyCapas, isEdicaoBloqueada]
  );

  return (
    <QCXFinalForm
      isCreate={isCreate}
      isUpdate={isUpdate}
      isConsult={isConsult}
      initialValues={initialValues}
      onChangeListeners={listeners}
      controlButtons={formControlButtons}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
      {...restProps}
    >
      {({ form, values }) => (
        <>
          <Grid item container spacing={3}>
            {isFaturado && (
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: '0.5rem',
                  paddingBottom: '!important 0px',
                }}
              >
                <QCXSuccessAlert>
                  <Trans i18nKey="com.muralis.qcx.faturamento.avisoFaturamentoRealizado">
                    <b />
                  </Trans>
                </QCXSuccessAlert>
              </Grid>
            )}
            {isCancelado && (
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: '0.5rem',
                  paddingBottom: '!important 0px',
                }}
              >
                <QCXErrorAlert>
                  <Trans i18nKey="com.muralis.qcx.faturamento.avisoFaturamentoCancelado">
                    <b />
                  </Trans>
                </QCXErrorAlert>
              </Grid>
            )}
          </Grid>
          <QCXCapaFaturamentoForm
            isConsult={isConsult}
            isCreate={isCreate}
            isEdicaoBloqueada={isEdicaoBloqueada}
            isPreparingAction={isPreparingAction}
            isPerformingAction={isPerformingAction}
            initialValues={values}
            normalize={normalize}
            unnormalize={unnormalize}
          />
          <QCXDadosBancariosForm
            isConsult={isConsult}
            initialValues={values}
            isEdicaoBloqueada={isEmptyCapas || isEdicaoBloqueada}
            isPreparingAction={isPreparingAction}
            isPerformingAction={isPerformingAction}
          />
          <QCXDespesaReceitaModeloFaturamentoFormTable
            isConsult={isConsult}
            isCreate={isCreate}
            isEdicaoBloqueada={isEdicaoBloqueada}
            isPreparingAction={isPreparingAction}
            isPerformingAction={isPerformingAction}
            isSubUpdate={isSubUpdate}
            formValues={values}
            formState={form}
          />
          <QCXDespesaReceitaNumerario 
            formValues={values}
          />
          <QCXPagosPeloClienteFormTable
            isEdicaoBloqueada={isEdicaoBloqueada}
            isCreate={isCreate}
            isPreparingAction={isPreparingAction}
            isPerformingAction={isPerformingAction}
            isSubUpdate={isSubUpdate}
            formValues={values}
            formState={form}
          />
          <QCXFaturamentoImpostos />
          <QCXNumerario 
            formValues={values}
            isCreate={isCreate}
          />
          <QCXResumoSolicitacaoCards
            initialValues={values}
            isLoading={isLoading}
            isPreparingAction={isPreparingAction}
            isPerformingAction={isPerformingAction}
          />
        </>
      )}
    </QCXFinalForm>
  );
}
