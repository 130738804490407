import { useSelector } from 'react-redux';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import { useForm } from 'react-final-form';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { Grid, Box } from '@material-ui/core';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalBondManager from '../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import { selectCliente } from '../../../features/cliente/clienteSlice';
import QCXAutocompleteSelect from '../../../shared-components/autocomplete-select/QCXAutocompleteSelect';
import { isStrictEquals } from '../../../utils/general/general-utils';

export default function QCXClienteServicoTabelaSdaBondManager({
  tabelasSda,
  servicos,
  modes,
  handleAdd,
  handleUpdate,
  handleAlreadyExists,
}) {
  const form = useForm();
  const { values } = form.getState();
  const { t } = useTranslation();

  const { isConsult, isSubConsult } = modes;

  const listRootName = 'clienteServicoTabelaSdaByServicoId';
  const servicoId = _.get(values, 'servico.id');
  const servico = servicos?.find((s) => s.id === servicoId);
  const listName = `${listRootName}.${servico?.code}`;

  const cliente = useSelector(selectCliente);

  useEffect(() => {
    const defaultRelacoes = cliente?.relacaoClienteServicoTabelasSda ?? [];

    const reduced = defaultRelacoes.reduce((acc, curr) => {
      const key = curr.servico.code;

      if (key in acc) acc[key].push(curr);
      else acc[key] = [curr];

      return acc;
    }, {});

    setTimeout(() => {
      form.change(listRootName, reduced);
    }, 150);
  }, [form, cliente]);

  const handleInternalAdd = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      if (currentVinculos.length > 0) {
        throw new Error('Só é permitido um vínculo de Tabela SDA por serviço.');
      }

      const tabelaSda = tabelasSda?.find((m) => m.id === currentVinculo.id);

      const vinculo = {
        id: uuid(),
        servico,
        tabelaSda,
      };

      const updatedVinculos = [...currentVinculos, vinculo];

      handleSuccessAddVinculo(updatedVinculos);
    },
    [form, tabelasSda, servico]
  );

  const handleInternalUpdate = useCallback(async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const tabelaSda = tabelasSda?.find((m) => m.id === currentVinculo.tabelaSda.id);

    const updatedVinculo = {
      ...currentVinculo,
      tabelaSda,
    };
    const updatedVinculos =
      currentVinculos?.map((existingVinculo) =>
        isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
      ) || currentVinculos;
    handleSuccessUpdateVinculo(updatedVinculos);
  }, []);

  const handleInternalAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.tabelaSda?.id, currentVinculo?.id) && !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  const handlers = useMemo(
    () => ({
      handleAdd: _.isFunction(handleAdd) ? handleAdd : handleInternalAdd,
      handleUpdate: _.isFunction(handleUpdate) ? handleUpdate : handleInternalUpdate,
      handleAlreadyExists: _.isFunction(handleAlreadyExists) ? handleAlreadyExists : handleInternalAlreadyExists,
    }),
    [handleAdd, handleAlreadyExists, handleInternalAdd, handleInternalUpdate, handleInternalAlreadyExists]
  );

  const clienteServicoTabelasSdaListProps = useMemo(
    () => ({
      name: listName,
      columns: [
        {
          field: 'tabela-sda-description',
          headerName: t('com.muralis.qcx.codigo'),
          headerAlign: 'left',
          align: 'left',
          flex: 200,
          valueGetter: ({ row }) => row?.tabelaSda?.id ?? '-',
        },
        {
          field: 'description',
          headerName: t('com.muralis.qcx.descricao'),
          flex: 200,
          valueGetter: ({ row }) => row?.tabelaSda?.description,
        },
        {
          field: 'viaTransporte',
          headerName: t('com.muralis.qcx.transporte.viaTransporte'),
          flex: 200,
          valueGetter: ({
            row: {
              tabelaSda: {
                viaTransporte: { description },
              },
            },
          }) => description,
        },
      ],
    }),
    [listName]
  );

  const clienteServicoTabelasSdaFormProps = useMemo(
    () => ({
      rootName: `ignorableFields.tabelaSda`,
      fields: [
        {
          name: 'id',
          label: 'TABELA SDA',
        },
      ],
    }),
    []
  );

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.id} - ${option.description}`,
  });

  const getOptionLabel = (item) => (item?.id && item?.description ? `${item?.id} - ${item?.description}` : '');

  return (
    <Box style={{ pointerEvents: modes?.isConsult ? 'none' : '' }}>
      <QCXFinalBondManager
        isParentConsult={isConsult || isSubConsult}
        listProps={clienteServicoTabelasSdaListProps}
        formProps={clienteServicoTabelasSdaFormProps}
        controlButtonsGridProps={{
          add: {
            xs: 12,
            sm: 2,
            md: 2,
          },
          update: {
            xs: 6,
            sm: 6,
            md: 1,
          },
          clear: {
            xs: 6,
            sm: 6,
            md: 1,
          },
        }}
        {...handlers}
      >
        <Grid item sm={8}>
          <QCXAutocompleteSelect
            key={JSON.stringify(values?.ignorableFields?.tabelaSda)}
            filterOptions={filterOptions}
            defaultValue=""
            getOptionLabel={getOptionLabel}
            options={tabelasSda}
            id="text-field-tabela-sda"
            name="ignorableFields.tabelaSda.id"
            label="Tabela SDA"
            required={undefined}
            disabled={isConsult}
          />
        </Grid>
      </QCXFinalBondManager>
    </Box>
  );
}
