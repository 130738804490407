// src/pages/.../ModeloTabelaSDAConsultPage.jsx

import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Search as PageviewIcon, Delete as DeleteIcon } from '@material-ui/icons';

import ModeloTabelaSdaContext from '../../../../../contexts/registrations/modelo-tabela-sda/ModeloTabelaSdaContext';
import { fetchAllAsync, resetModel, activateByIdAsync, inactivateByIdAsync, selectModelosTabelasSda } from '../../../../../features/modelo-tabela-sda/modeloTabelaSdaSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { ALTERNATIVE_LOADING_STATUS } from '../../../../../features/status';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function ModeloTabelaSDAConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { status } = useContext(ModeloTabelaSdaContext);
  const modelosTabelasSda = useSelector(selectModelosTabelasSda);

  const isAlternativeLoading = useMemo(
    () => status === ALTERNATIVE_LOADING_STATUS,
    [status]
  );

  useEffect(() => {
    dispatch(fetchAllAsync());
    dispatch(resetModel());
  }, []);

  const handleActivateInactivate = useOperationController(async (event, id) => {
    event.preventDefault();
    const item = modelosTabelasSda.find((x) => x.id === id);
    if (!item) return;
    if (item.active) {
      dispatch(inactivateByIdAsync(id));
    } else {
      dispatch(activateByIdAsync(id));
    }
  });

  const handleClickOnViewButton = useCallback(
    (event, id) => {
      event.stopPropagation();
      history.push(t('com.muralis.qcx.url.modulosGeraisModeloTabelaSDADetalhes', { id }));
    },
    [history]
  );

  const breadcrumbs = [
    { link: { to: '/', name: t('com.muralis.qcx.inicio') } },
    { text: { name: t('com.muralis.qcx.cadastros') } },
    { link: { to: t('com.muralis.qcx.url.moduloCadastrosGerais'), name: t('com.muralis.qcx.gerais') } },
    { text: { name: t('com.muralis.qcx.servico.labelPlural') } },
    { text: { name: 'Modelos de Tabela SDA' } },
  ];

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 100,
      valueGetter: ({ row }) => row.id,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      flex: 200,
      valueGetter: ({ row }) => row.description,
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 130,
      valueGetter: ({ row }) => row.tipo,
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      flex: 150,
      align: 'center',
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'tabela-sda') && (
            <IconButton onClick={(e) => handleActivateInactivate(e, id)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton onClick={(e) => handleClickOnViewButton(e, id)}>
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleClickOnNew = useCallback(() => {
    history.push(t('com.muralis.qcx.url.modulosGeraisModeloTabelaSDANovo'));
  }, [history]);

  const templateProps = {
    page: {
      title: 'Modelos de Tabela SDA',
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: 'Novo Modelo de Tabela SDA',
        onClick: handleClickOnNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback((item) => item.description, []);

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={modelosTabelasSda}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['tabela-sda']}
    />
  );
}
