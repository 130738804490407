import axios from 'axios';

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + '/' + process.env.REACT_APP_RELATORIOS_API_ICMS_PAGO!; 

const generateSpreadsheet = async (
  dataInicio: string,
  dataFim: string,
  token: string,
  unidadeId: number,
  unidadesNegocioIDs: number[], 
) => {
  const params = new URLSearchParams();
  params.set('tenentId', unidadesNegocioIDs.join(',')); 
  params.set('dataInicial', dataInicio);
  params.set('dataFim', dataFim);

  let message = null;

  console.log(baseUrl)

  try {
    const response = await axios.get(`${baseUrl}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
      responseType: 'blob',
    });

    if (response.status === 200) {
      // Successful response
      const file = new File([response.data], 'relatorioIcmsPago.xlsx', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      a.click();
    } else {
      message = await response.data.text();
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        message = await error.response.data.text();
      } else {
        message = 'Erro inesperado';
      }
    } else {
      message = 'Erro inesperado';
    }
  }
  return message;
};

export { generateSpreadsheet };
