import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
  doPutQCXCalculateRequest,
  doPutQCXConferirRequest,
  doPutQCXDuplicarRequest,
  doPutQCXLiberarRequest,
  doPutQCXRetransmitirRequest,
  doPutQCXRetificarRequest,
} from '../../utils/api/domain/domain-api-utils';
import { FATURA_ENDPOINT, FILTER_ENDPOINT, FIND_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([FATURA_ENDPOINT]);
}

async function fetchByFilter(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  return doGetQCXRequest([FATURA_ENDPOINT, FILTER_ENDPOINT], validatedQueryParams);
}

async function fetchFilterPaginateAsync(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  return doGetQCXRequest([FATURA_ENDPOINT, 'filter', 'pagination'], validatedQueryParams);
}

async function fetchByFilterPaginate(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  if (validatedQueryParams.length) {
    return doGetQCXRequest([FATURA_ENDPOINT, FILTER_ENDPOINT, 'pagination'], validatedQueryParams);
  }
  return {
    data: {
      itens: [],
      totalNumberOfItens: 0,
    },
  };
}

async function fetchDuesByFilter(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  validatedQueryParams.push({ name: 'tipo', value: 'DUE' });

  return doGetQCXRequest([FATURA_ENDPOINT, FILTER_ENDPOINT, 'pagination'], validatedQueryParams);
}

async function fetchFaturasByFilter(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  return doGetQCXRequest([FATURA_ENDPOINT, FILTER_ENDPOINT, 'pagination'], validatedQueryParams);
}

async function fetchById(id) {
  return doGetQCXRequest([FATURA_ENDPOINT, id]);
}

async function fetchCapaDiDuimpById(id) {
  return doGetQCXRequest([FATURA_ENDPOINT, 'capa-diduimp', id]);
}

async function fetchMercadoriasByFaturaId(id) {
  return doGetQCXRequest(['mercadoria', FATURA_ENDPOINT, id]);
}

async function findByParams(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  return doGetQCXRequest([FATURA_ENDPOINT, FIND_ENDPOINT], validatedQueryParams);
}

async function register(data, queryParams = []) {
  return doPostQCXRequest([FATURA_ENDPOINT], data, queryParams);
}

async function save(data, queryParams = []) {
  return doPutQCXRequest([FATURA_ENDPOINT], data, queryParams);
}

async function activateById(id) {
  return doPutQCXActivateRequest([FATURA_ENDPOINT], id);
}

async function inactivateById(id) {
  return doPutQCXInactivateRequest([FATURA_ENDPOINT], id);
}

async function calculateById(id, subPath = [], data = null) {
  const { shouldZeroFields, ...restData } = data ?? { shouldZeroFields: false };

  return doPutQCXCalculateRequest([FATURA_ENDPOINT], id, subPath, restData, [
    { name: 'shouldZeroFields', value: shouldZeroFields },
  ]);
}

async function conferirBy(id, subPath = []) {
  return doPutQCXConferirRequest([FATURA_ENDPOINT], id, subPath);
}

async function conferirIgnoreAliquotasBy(id) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'conferir', id], {}, [{ name: 'shouldValidateAliquotaNcms', value: false }]);
}

async function duplicarBy(id, subPath = [], data = null) {
  return doPutQCXDuplicarRequest([FATURA_ENDPOINT], id, subPath, data);
}

async function liberarBy(id, subPath = []) {
  return doPutQCXLiberarRequest([FATURA_ENDPOINT], id, subPath);
}

async function retificarById(id, subPath = [], data = null) {
  return doPutQCXRetificarRequest([FATURA_ENDPOINT], id, subPath, data);
}

async function reabrirById(id) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'reabrir', id], {});
}

async function retransmitirBy(id, subPath = []) {
  return doPutQCXRetransmitirRequest([FATURA_ENDPOINT], id, subPath);
}

async function gerarSubstitutiva({ id, faturaId }) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'substitutiva', faturaId, 'li', id], {});
}

// essa função e a repasseFatura podem ser refatoradas para a mesma, não fiz por tempo
async function repasseByProcessos(data, queryParams = [], config = {}) {
  const { unidadeProcesso, processos } = queryParams;

  const queryParamsArray = [{ name: 'unidadeProcesso', value: unidadeProcesso }];

  processos?.forEach((element) => {
    queryParamsArray.push({ name: `processos`, value: element });
  });

  return doPutQCXRequest([FATURA_ENDPOINT, 'diduimp', 'repasse'], data, queryParamsArray, config);
}

async function repasseFaturaByProcessos(data, queryParams = [], config = {}) {
  const { unidadeProcesso, processos } = queryParams;

  const queryParamsArray = [{ name: 'unidadeProcesso', value: unidadeProcesso }];

  processos?.forEach((element) => {
    queryParamsArray.push({ name: `processos`, value: element });
  });

  return doPutQCXRequest([FATURA_ENDPOINT, 'repasse'], data, queryParamsArray, config);
}

async function limparAcrescimosDeclaracaoImportacao(id) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'limpar-acrescimos', id]);
}

async function integrarBlueRoute(id) {
  return doPostQCXRequest([FATURA_ENDPOINT, 'integrar-blue-route', id]);
}

const faturaAPI = {
  fetchAll,
  fetchByFilter,
  fetchById,
  fetchCapaDiDuimpById,
  fetchFilterPaginateAsync,
  findByParams,
  register,
  save,
  activateById,
  inactivateById,
  calculateById,
  conferirBy,
  duplicarBy,
  liberarBy,
  retransmitirBy,
  retificarById,
  reabrirById,
  gerarSubstitutiva,
  repasseByProcessos,
  conferirIgnoreAliquotasBy,
  fetchDuesByFilter,
  fetchFaturasByFilter,
  fetchByFilterPaginate,
  limparAcrescimosDeclaracaoImportacao,
  repasseFaturaByProcessos,
  fetchMercadoriasByFaturaId,
  integrarBlueRoute,
};

export {
  faturaAPI,
  reabrirById,
  fetchAll,
  fetchByFilter,
  fetchById,
  fetchCapaDiDuimpById,
  fetchFilterPaginateAsync,
  findByParams,
  register,
  save,
  activateById,
  inactivateById,
  calculateById,
  conferirBy,
  duplicarBy,
  liberarBy,
  retificarById,
  gerarSubstitutiva,
  repasseByProcessos,
  conferirIgnoreAliquotasBy,
  fetchDuesByFilter,
  fetchFaturasByFilter,
  fetchByFilterPaginate,
  limparAcrescimosDeclaracaoImportacao,
  repasseFaturaByProcessos,
  fetchMercadoriasByFaturaId,
  integrarBlueRoute,
};
