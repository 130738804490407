const DB_NAME = 'DeclaracaoImportacaoDB';
const STORE_NAME = 'Adicoes';

function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
}

async function readAdicoesFromStorage(faturaId) {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(String(faturaId));

    request.onsuccess = () => resolve(request.result || null);
    request.onerror = () => reject(request.error);
  });
}

async function writeAdicoesToStorage(faturaId, adicoes) {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.put(adicoes, String(faturaId));

    request.onsuccess = () => resolve();
    request.onerror = () => reject(request.error);
  });
}

const declaracaoImportacaoStorage = { readAdicoesFromStorage, writeAdicoesToStorage };

export default declaracaoImportacaoStorage;
