import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectBoolean from '../../shared-components/select-boolean/QCXSelectBoolean';
import QCXSelectFormatoImpressaoDanfeAutocomplete from '../../shared-components/select-formato-impressao-danfe/QCXSelectFormatoImpressaoDanfeAutocomplete';
import QCXFormTitle from '../form-title/QCXFormTitle';
import CSTBondManager from './CSTBondManager';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import QCXSelectTipooBaseReduzida from './QCXFinalSelectTipoBaseReduzida';

const GridContainer = ({ children }) => (
  <Grid container spacing={2} style={{ marginTop: '1rem' }}>
    {children}
  </Grid>
);

export default function QCXModeloDanfeBasicasForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  controlComponentProps,
}) {
  const { t } = useTranslation();
  const listenerList = [];

  return (
    <QCXFormStepWizard
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      onChangeListeners={listenerList}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
    >
      {({ values }) => (
        <>
          <QCXFormTitle title={t('com.muralis.qcx.impressao')} />
          <GridContainer>
            <Grid item md={6}>
              <QCXFinalTextField
                id="nome-modelo"
                key="nome-modelo"
                name="nome"
                label="Nome do Modelo"
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectFormatoImpressaoDanfeAutocomplete
                id="select-formato-impressao"
                key="select-formato-impressao"
                name="formatoImpressao"
                label={t('com.muralis.qcx.formatoImpressao.label')}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title="Versão" />
          <GridContainer>
            <Grid item md={6}>
              <QCXFinalTextField
                id="versao"
                key="versao"
                name="versaoXML"
                label="Versão"
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="uf-terceiro"
                key="uf-terceiro"
                name="ufTerceiro"
                label='Gerar UF de Terceiro no arquivo TXT'
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
          <Grid item xs={12}>
              <QCXInfoAlert>
                Se a versão do processo de emissão for deixada em branco, o sistema irá considerar a versão PADRÃO 4.01_sebrae_b045.
              </QCXInfoAlert>
            </Grid>
            <Grid item md={6}>
              <QCXFinalTextField
                id="versaoProcessoEmissao"
                key="versaoProcessoEmissao"
                name="versaoProcessoEmissao"
                label="Versão do Processo de emissão"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title={t('com.muralis.qcx.agrupamentos')} />
          <GridContainer>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-exportador"
                key="select-agrupar-exportador"
                name="agruparExportador"
                label='Agrupar por Exportador'
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-fabricante"
                key="select-agrupar-fabricante"
                name="agruparFabricante"
                label='Agrupar por Fabricante'
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-cfop"
                key="select-agrupar-cfop"
                name="agruparCfop"
                label={t('com.muralis.qcx.agruparCFOP')}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-aliquota-icms"
                key="select-agrupar-aliquota-icms"
                name="agruparAliquotaIcms"
                label={t('com.muralis.qcx.agruparAlioquotaICMS')}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-regime-tributacao-icms"
                key="select-agrupar-regime-tributacao-icms"
                name="agruparRegimeTributacaoIcms"
                label="Agrupar por Regime Tributação ICMS"
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-regime-tributacao-icms"
                key="select-agrupar-regime-tributacao-icms"
                name="agruparPorQuebraAdicional"
                label="Agrupar por Quebra Adicional"
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-regime-tributacao-icms"
                key="select-agrupar-regime-tributacao-icms"
                name="agruparPorAuxiliarInvoice"
                label="Agrupar por Auxiliar Invoice"
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-regime-tributacao-icms"
                key="select-agrupar-regime-tributacao-icms"
                name="agruparPorArea"
                label="Agrupar por Area"
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-regime-tributacao-icms"
                key="select-agrupar-regime-tributacao-icms"
                name="agruparPorCstIcms"
                label="Agrupar por CST ICMS"
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXFinalNumericIntegerField
                id="itens-por-nota"
                key="itens-por-nota"
                name="itensPorNota"
                label="Itens por Nota"
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title={t('com.muralis.qcx.valores')} />
          <GridContainer>
            <Grid item md={6}>
              <QCXFinalCheckboxField
                id="inclui-despesas-assessorias"
                key="inclui-despesas-assessorias"
                name="exibeMleUnitario"
                label="Exibir MLE no valor Unitário"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={6}>
              <QCXFinalCheckboxField
                id="exibir-valor-icms-devido"
                key="exibir-valor-icms-devido"
                name="exibirValorIcmsDevido"
                label="Exibir Valor Icms Devido"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={6}>
              <QCXFinalCheckboxField
                id="exibir-valor-icms-menos-fecp"
                key="exibir-valor-icms-menos-fecp"
                name="exibirValorIcmsMenosFECP"
                label="Exibir Valor Icms - FECP"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            {!values?.exibeMLEUnitario && (
              <Grid item md={6}>
                <QCXSelectBoolean
                  id="select-exibir-cif"
                  key="select-exibir-cif"
                  name="exibirCif"
                  label={t('com.muralis.qcx.exibirCIFIICampoUnitario')}
                  initialValues={values}
                  disabled={isConsult}
                  required
                />
              </Grid>
            )}
            {!values?.exibeMLEUnitario && (
              <Grid item md={6}>
                <QCXSelectBoolean
                  id="select-exibir-cif-icms"
                  key="select-exibir-cif-icms"
                  name="exibirCifIcms"
                  label="EXIBIR VALOR CIF + ICMS NO CAMPO DE VALOR UNITÁRIO"
                  initialValues={values}
                  disabled={isConsult}
                  required
                />
              </Grid>
            )}
            <Grid item md={6}>
              <QCXFinalNumericIntegerField
                id="quantidade-casas"
                key="quantidade-casas"
                name="quantidadeCasasDecimais"
                label={t('com.muralis.qcx.quantidadeCasasDecimaisValorUnitario')}
                initialValues={values}
                disabled={isConsult}
                maxLength={1}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXFinalCheckboxField
                id="acrescimo-deducao-cif"
                key="acrescimo-deducao-cif"
                name="acrescimoDeducaoCif"
                label="Acrescimos e Deduções no CIF"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="base-reduzida-icms"
                key="base-reduzida-icms"
                name="considerarBaseReduzidaICMS"
                label={t('com.muralis.qcx.considerarBaseReduzidaICMS')}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6} />
            {values?.considerarBaseReduzidaICMS === 'SIM' && (
              <Grid item md={6}>
                <QCXSelectTipooBaseReduzida
                  id="tipo-base-reduzida"
                  key="tipo-base-reduzida"
                  name="tipoBaseReduzida"
                  label="Tipo de Base Reduzida"
                  initialValues={values}
                  disabled={isConsult}
                  required
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <QCXInfoAlert>
                Se Valor Total dos Produtos for igual ao Valor Aduaneiro, selecionar opção Acréscimos e Deduções no CIF.
                Se não selecionar, o sistema irá considerar apenas mercadoria + frete + seguro.
              </QCXInfoAlert>
            </Grid>
          </GridContainer>
          <QCXFormTitle title={t('com.muralis.qcx.valorDespesasAcessorias')} />
          <GridContainer>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="despesa-pis"
                key="despesa-pis"
                name="despesasAcessoriasPis"
                label={t('com.muralis.qcx.PISPlus')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="despesa-cofins"
                key="despesa-cofins"
                name="despesasAcessoriasCofins"
                label={t('com.muralis.qcx.COFINSPlus')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="despesa-siscomex"
                key="despesa-siscomex"
                name="despesasAcessoriasTaxaSiscomex"
                label={t('com.muralis.qcx.taxaSISCOMEXPLus')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="despesa-afrmm"
                key="despesa-afrmm"
                name="despesasAcessoriasAfrmm"
                label={t('com.muralis.qcx.AFRMMPlus')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="despesa-outras"
                key="despesa-outras"
                name="despesasAcessoriasOutrasDespesasAduaneiras"
                label={t('com.muralis.qcx.outrasDespesasAduaneiras')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="despesas-acrescimo-deducao"
                key="despesas-acrescimo-deducao"
                name="despesasAcessoriasAcrescimoDeducao"
                label={`${t('com.muralis.qcx.acrescimosDeducoes')} +`}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="ii"
                key="ii"
                name="despesasAcessoriasIi"
                label={`${t('com.muralis.qcx.ii')} +`}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="ipi"
                key="ipi"
                name="despesasAcessoriasIpi"
                label={`${t('com.muralis.qcx.ipi')} +`}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="icms"
                key="icms"
                name="despesasAcessoriasIcms"
                label="Icms Recolhido +"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="exibir-valor-icms-devido"
                key="exibir-valor-icms-devido"
                name="despesasAcessoriasDiffIcms"
                label="Diferença do Icms (Devido - Recolhido) +"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="fecp"
                key="fecp"
                name="despesasAcessoriasFecp"
                label={t('com.muralis.qcx.fecp')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title="Não Exibir Campos:" />
          <GridContainer>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="oculta-base-imposto"
                key="oculta-base-imposto"
                name="baseImpostoEhOculto"
                label="Base/Impostos com valor zero"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="oculta-frete-internacional"
                key="oculta-frete-internacional"
                name="freteInternacionalEhOculto"
                label="Frete Internacional"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            {/* <Grid item md={2}>
              <QCXFinalCheckboxField
                id="oculta-frete"
                key="oculta-frete"
                name="freteEhOculto"
                label="Frete"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid> */}
            <Grid item md={2}>
              <QCXFinalCheckboxField
                id="oculta-seguro"
                key="oculta-seguro"
                name="seguroEhOculto"
                label="Seguro"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={2}>
              <QCXFinalCheckboxField
                id="oculta-ipi"
                key="oculta-ipi"
                name="ipiEhOculto"
                label="IPI"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title="Total da Nota Igual:" />
          <GridContainer>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="valor-total-produtos"
                key="valor-total-produtos"
                name="totalIncluiValorProdutos"
                label="Valor Total dos Produtos"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-ii"
                key="inclui-ii"
                name="totalIncluiII"
                label="+ II"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-ipi"
                key="inclui-ipi"
                name="totalIncluiIPI"
                label="+ IPI"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-pis"
                key="inclui-pis"
                name="totalIncluiPIS"
                label="+ PIS"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-cofins"
                key="inclui-cofins"
                name="totalIncluiCOFINS"
                label="+ COFINS"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-taxa-siscomex"
                key="inclui-taxa-siscomex"
                name="totalIncluiTaxaSiscomex"
                label="+ Taxa Siscomex"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-afrmm"
                key="inclui-afrmm"
                name="totalIncluiAFRMM"
                label="+ AFRMM"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-icms"
                key="inclui-icms"
                name="totalIncluiICMS"
                label="+ ICMS"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-fecp"
                key="inclui-fecp"
                name="totalIncluiFecp"
                label="+ FECP"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-despesas-assessorias"
                key="inclui-despesas-assessorias"
                name="totalIncluiDespesasAssessorias"
                label="+ Despesas Acessórias"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-acrescimo-deducao"
                key="inclui-acrescimo-deducao"
                name="totalIncluiAcrescimoDeducao"
                label={`+ ${t('com.muralis.qcx.acrescimosDeducoes')}`}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-frete"
                key="inclui-frete"
                name="totalIncluiFrete"
                label="+ Frete"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-seguro"
                key="inclui-seguro"
                name="totalIncluiSeguro"
                label="+ Seguro"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title="Preenchimento CST:" />
          <GridContainer>
            <Grid item xs={12}>
              <QCXInfoAlert>
                Se a natureza da operação for deixada em branco, o sistema irá considerar a descrição do CFOP por padrão.
              </QCXInfoAlert>
            </Grid>
            <Grid item md={6}>
              <QCXFinalTextField
                id="naturezaOperacao"
                key="naturezaOperacao"
                name="naturezaOperacao"
                label="Natureza da operação"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <CSTBondManager isParentConsult={isConsult} />
          </GridContainer>
        </>
      )}
    </QCXFormStepWizard>
  );
}
