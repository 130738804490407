import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalDatePickerField from '../../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalAlternativeCurrencyField from '../../../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField'
import QCXSelectClienteAutocomplete from '../../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectDespesaReceitaAutocomplete from '../../../../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaAutocomplete';

export default function SolicitacaoPagamentoFilterComponent() {

    const { t } = useTranslation();

    return (
        <Box style={{ minWidth: '500px' }}>
          <Grid container title="Seção que contém informações do usuário" spacing={2}>
            <Grid item sm={12}>
                <QCXSelectClienteAutocomplete
                    id="text-field-cliente"
                    key="text-field-cliente"
                    name="importador"
                    label={t('com.muralis.qcx.solicitacaoPagamento.filter.importador')}
                    required={false}
                    filter={{
                      importador: true,
                    }}
                    initialValues={undefined}
                />
            </Grid>
            <Grid item sm={12}>
              <QCXFinalDatePickerField
                id="date-picker-field-insertion-date"
                key="date-picker-field-insertion-date"
                label={t('com.muralis.qcx.solicitacaoPagamento.filter.data_solicitacao_pagamento')}
                name="data_solicitacao_pagamento"
                required={undefined}
                validate={undefined}
              />
            </Grid>
            <Grid item sm={12}>
              <QCXFinalDatePickerField
                id="date-picker-field-insertion-date"
                key="date-picker-field-insertion-date"
                label={t('com.muralis.qcx.solicitacaoPagamento.filter.data_pagamento')}
                name="data_pagamento"
                required={undefined}
                validate={undefined}
              />
            </Grid>
            <Grid item sm={12}>
              <QCXSelectDespesaReceitaAutocomplete
                  id="autocomplete-select-field-despesa"
                  key="autocomplete-select-field-despesa"
                  name="despesa_receita_id"
                  label={t('com.muralis.qcx.despesa.labelPlural')}
                  initialValues={undefined}
                  validate={undefined}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                  <QCXFinalAlternativeCurrencyField
                    id="financeiro-valor-desconto-field"
                    key="financeiro-valor-desconto-field"
                    name="valor_solicitado"
                    label={t('com.muralis.qcx.solicitacaoPagamento.filter.valor_solicitado')}
                    onBlur={undefined}
                    disabled={undefined}
                  />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                  <QCXFinalAlternativeCurrencyField
                    id="financeiro-valor-desconto-field"
                    key="financeiro-valor-desconto-field"
                    name="valor_receber"
                    label={t('com.muralis.qcx.solicitacaoPagamento.filter.valor_receber')}
                    onBlur={undefined}
                    disabled={undefined}
                  />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                  <QCXFinalAlternativeCurrencyField
                    id="financeiro-valor-desconto-field"
                    key="financeiro-valor-desconto-field"
                    name="saldo"
                    label={t('com.muralis.qcx.solicitacaoPagamento.filter.saldo')}
                    onBlur={undefined}
                    disabled={undefined}
                  />
            </Grid>
            <Grid item sm={12}>
              <QCXFinalDatePickerField
                id="date-picker-field-insertion-date"
                key="date-picker-field-insertion-date"
                label={t('com.muralis.qcx.solicitacaoPagamento.filter.data_vencimento')}
                name="data_vencimento"
                required={undefined}
                validate={undefined}
              />
            </Grid>
    
          </Grid>
        </Box>
      );

}