import { Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { fetchById as fetchAcrescimoValoracaoAduaneiraById } from '../../../../features/acrescimo-valoracao-aduaneira/acrescimoValoracaoAduaneiraAPI';
import { fetchById as fetchDeducaoValoracaoAduaneiraById } from '../../../../features/deducao-valoracao-aduaneira/deducaoValoracaoAduaneiraAPI';
import { fetchById as fetchMoedaById } from '../../../../features/moeda/moedaAPI';
import QCXFinalAlternativeCurrencyField from '../../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXSelectMoedaAutocomplete from '../../../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXSelectAcrescimoValoracaoAduaneiraAutocomplete from '../../../../shared-components/select-acrescimo-valoracao-aduaneira/QCXSelectAcrescimoValoracaoAduaneiraAutocomplete';
import QCXSelectTipoAcrescimoDeducaoAutocomplete from '../../../../shared-components/select-tipo-acrescimo-deducao/QCXSelectTipoAcrescimoDeducaoAutocomplete';
import { TIPO_ACRESCIMO } from '../../../../shared-components/select-tipo-acrescimo-deducao/tipoAcrescimoDeducaoUtils';
import { isStrictEquals } from '../../../../utils/general/general-utils';
import { BRAZILIAN } from '../../../../utils/hooks/form/field/mask-types';
import QCXSelectTipoRateioAutocomplete, { getLabelTipoRateio } from './QCXSelectTipoRateioAutocomplete';
import QCXFinalBondManager from '../../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';

function AcrescimoDeducaoBondManager({
  name,
  hasAdicoesAcrescimosDeducoes,
  classes,
  isConsult,
  onBlurObjectHandlers,
  disableRemove,
  hideDeleteButton,
}) {
  const { t } = useTranslation();

  const { values, initialValues } = useFormState();
  const form = useForm();

  const handleAddAcrecimosDeducoes = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    let acrescimoValoracaoAduaneiraResponse;

    if (currentVinculo.tipo === 'DEDUCAO') {
      acrescimoValoracaoAduaneiraResponse = await fetchDeducaoValoracaoAduaneiraById(
        currentVinculo?.acrescimoValoracaoAduaneira?.id
      );
    } else {
      acrescimoValoracaoAduaneiraResponse = await fetchAcrescimoValoracaoAduaneiraById(
        currentVinculo?.acrescimoValoracaoAduaneira?.id
      );
    }

    const moedaResponse = await fetchMoedaById(currentVinculo?.moeda?.id);

    if (acrescimoValoracaoAduaneiraResponse?.status === 200 && moedaResponse?.status === 200) {
      const foundAcrescimoValoracaoAduaneira = acrescimoValoracaoAduaneiraResponse?.data;
      const foundMoeda = moedaResponse?.data;

      const vinculo = {
        id: uuid(),
        tipo: currentVinculo?.tipo,
        acrescimoValoracaoAduaneira: foundAcrescimoValoracaoAduaneira,
        moeda: foundMoeda,
        valorMoeda: currentVinculo?.valorMoeda,
        valorReal: currentVinculo?.valorReal,
        rateio: currentVinculo?.rateio,
      };

      const updatedVinculos = [...currentVinculos, vinculo];

      handleSuccessAddVinculo(updatedVinculos);
    }
  }, []);

  const handleUpdateAcrecimosDeducoes = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      let acrescimoValoracaoAduaneiraResponse;

      if (currentVinculo.tipo === 'DEDUCAO') {
        acrescimoValoracaoAduaneiraResponse = await fetchDeducaoValoracaoAduaneiraById(
          currentVinculo?.acrescimoValoracaoAduaneira?.id
        );
      } else {
        acrescimoValoracaoAduaneiraResponse = await fetchAcrescimoValoracaoAduaneiraById(
          currentVinculo?.acrescimoValoracaoAduaneira?.id
        );
      }

      const moedaResponse = await fetchMoedaById(currentVinculo?.moeda?.id);

      if (acrescimoValoracaoAduaneiraResponse?.status === 200 && moedaResponse?.status === 200) {
        const foundAcrescimoValoracaoAduaneira = acrescimoValoracaoAduaneiraResponse?.data;
        const foundMoeda = moedaResponse?.data;

        const updatedVinculo = {
          ...currentVinculo,
          tipo: currentVinculo?.tipo,
          acrescimoValoracaoAduaneira: foundAcrescimoValoracaoAduaneira,
          moeda: foundMoeda,
          valorMoeda: currentVinculo?.valorMoeda,
          valorReal: currentVinculo?.valorReal,
          rateio: currentVinculo?.rateio,
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleAcrecimosDeducoesAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.tipo, currentVinculo?.tipo) &&
          isStrictEquals(vinculo?.acrescimoValoracaoAduaneira?.id, currentVinculo?.acrescimoValoracaoAduaneira?.id) &&
          isStrictEquals(vinculo?.moeda?.id, currentVinculo?.moeda?.id) &&
          isStrictEquals(vinculo?.valorMoeda, currentVinculo?.valorMoeda) &&
          isStrictEquals(vinculo?.valorReal, currentVinculo?.valorReal) &&
          !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  const acrescimoDeducaoFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.acrescimoDeducao',
      fields: [
        {
          name: 'rateio',
          label: t('com.muralis.qcx.rateio'),
        },
        {
          name: 'tipo',
          label: t('com.muralis.qcx.tipo'),
        },
        {
          name: 'acrescimoValoracaoAduaneira.id',
          label: t('com.muralis.qcx.denominacao'),
        },
        {
          name: 'moeda.id',
          label: t('com.muralis.qcx.moeda.label'),
        },
        {
          name: 'valorReal',
          label: t('com.muralis.qcx.moeda.valorReais'),
        },
        {
          name: 'valorMoeda',
          label: t('com.muralis.qcx.moeda.valorMoeda'),
        },
      ],
    }),
    []
  );

  const acrescimoDeducaoListProps = useMemo(
    () => ({
      name,
      columns: [
        {
          field: 'rateio',
          headerName: t('com.muralis.qcx.rateio'),
          headerAlign: 'left',
          align: 'left',
          flex: 90,
          valueGetter: ({ row }) => getLabelTipoRateio(row?.rateio) || '',
        },
        {
          field: 'tipo',
          headerName: t('com.muralis.qcx.tipo'),
          headerAlign: 'left',
          align: 'left',
          flex: 90,
          valueGetter: ({ row }) => row?.tipo || '',
        },
        {
          field: 'acrescimoValoracaoAduaneira.description',
          headerName: t('com.muralis.qcx.denominacao'),
          headerAlign: 'left',
          align: 'left',
          flex: 90,
          valueGetter: ({ row }) => row?.acrescimoValoracaoAduaneira?.description || '',
        },
        {
          field: 'moeda.id',
          headerName: t('com.muralis.qcx.moeda.label'),
          headerAlign: 'left',
          align: 'left',
          flex: 90,
          valueGetter: ({ row }) => row?.moeda?.description || '',
        },
        {
          field: 'valorMoeda',
          headerName: t('com.muralis.qcx.moeda.valorMoeda'),
          headerAlign: 'left',
          align: 'left',
          flex: 80,
          valueGetter: ({ row }) => row?.valorMoeda || '',
        },
        {
          field: 'valorReal',
          headerName: t('com.muralis.qcx.moeda.valorReais'),
          headerAlign: 'left',
          align: 'left',
          flex: 80,
          valueGetter: ({ row }) => row?.valorReal || '',
        },
      ],
    }),
    []
  );

  return (
    <QCXFinalBondManager
      isParentConsult={isConsult}
      listProps={acrescimoDeducaoListProps}
      formProps={acrescimoDeducaoFormProps}
      handleAdd={handleAddAcrecimosDeducoes}
      handleUpdate={handleUpdateAcrecimosDeducoes}
      handleAlreadyExists={handleAcrecimosDeducoesAlreadyExists}
      readOnlyMode={hasAdicoesAcrescimosDeducoes}
      disableRemove={disableRemove}
      hideDeleteButton={hideDeleteButton}
    >
      <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
        <QCXSelectTipoRateioAutocomplete
          id="autocomplete-select-tipo-rateio-totais-field"
          key="autocomplete-select-tipo-rateio-totais-field"
          name="ignorableFields.acrescimoDeducao.rateio"
          label={t('com.muralis.qcx.rateio')}
          initialValues={values}
          disabled={hasAdicoesAcrescimosDeducoes || isConsult}
        />
      </Grid>

      <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
        <QCXSelectTipoAcrescimoDeducaoAutocomplete
          id="autocomplete-select-tipo-acrescimo-deducao-totais-field"
          key="autocomplete-select-tipo-acrescimo-deducao-totais-field"
          name="ignorableFields.acrescimoDeducao.tipo"
          label={t('com.muralis.qcx.tipo')}
          initialValues={values}
          disabled={hasAdicoesAcrescimosDeducoes || isConsult}
        />
      </Grid>

      <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
        <QCXSelectAcrescimoValoracaoAduaneiraAutocomplete
          id="autocomplete-select-acrescimo-valoracao-aduaneira-totais-field"
          key="autocomplete-select-acrescimo-valoracao-aduaneira-totais-field"
          name="ignorableFields.acrescimoDeducao.acrescimoValoracaoAduaneira.id"
          label={t('com.muralis.qcx.denominacao')}
          initialValues={values}
          isAcrescimo={initialValues?.ignorableFields?.acrescimoDeducao?.tipo === TIPO_ACRESCIMO}
          disabled={hasAdicoesAcrescimosDeducoes || isConsult}
        />
      </Grid>

      <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
        <QCXSelectMoedaAutocomplete
          id="autocomplete-select-moeda-totais-field"
          key="autocomplete-select-moeda-totais-field"
          name="ignorableFields.acrescimoDeducao.moeda.id"
          label={t('com.muralis.qcx.moeda.label')}
          initialValues={values}
          disabled={hasAdicoesAcrescimosDeducoes || isConsult}
        />
      </Grid>
      <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-moeda-totais-field"
          key="currency-valor-moeda-totais-field"
          name="ignorableFields.acrescimoDeducao.valorMoeda"
          label={t('com.muralis.qcx.moeda.valorMoeda')}
          onBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur(
            form,
            values,
            'ignorableFields.acrescimoDeducao.moeda',
            'ignorableFields.acrescimoDeducao.valorMoeda',
            'ignorableFields.acrescimoDeducao.valorReal'
          )}
          disabled={hasAdicoesAcrescimosDeducoes || isConsult || !values?.ignorableFields?.acrescimoDeducao?.moeda?.id}
          required
        />
      </Grid>
      <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-real-moeda-totais-field"
          key="currency-valor-real-moeda-totais-field"
          name="ignorableFields.acrescimoDeducao.valorReal"
          label={t('com.muralis.qcx.moeda.valorReais')}
          intl={BRAZILIAN}
          adorned
          disabled
        />
      </Grid>
    </QCXFinalBondManager>
  );
}

export default AcrescimoDeducaoBondManager;
