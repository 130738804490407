/* eslint-disable no-unused-vars */
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchAllAsync, selectContasBancarias } from '../../features/conta-bancaria/contaBancariaSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectContaBancariaAutocompleteField({
  label,
  name,
  initialValues,
  controlled = false,
  filtraControlaSaldo = false,
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const contasBancarias = useSelector(selectContasBancarias);
  const list = useMemo(
    () => { 
      
      if(filtraControlaSaldo) {
        return contasBancarias.filter((c) => !!c.controlarSaldo && !!c.active);
      }

      return contasBancarias.filter( (c) => !!c.active)

    },
    [filtraControlaSaldo, contasBancarias]
  );

  const [defaultValue, setDefaultValue] = useState(undefined);

  useEffect(() => {
    if (!controlled) {
      dispatch(fetchAllAsync());
    }
  }, [controlled]);

  useEffect(() => {
    const value = list.find((item) => item.id === _.get(initialValues, name)?.id);

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      t('com.muralis.qcx.bancoAgenciaContaCorrente', {
        nome: option?.description,
        nomeBanco: option?.banco?.description,
        banco: option?.banco?.code,
        agencia: option?.agencia,
        contaCorrente: option?.contaCorrente,
      }),
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionValue={(value) => value}
      getOptionLabel={(item) =>
        item?.agencia && item?.contaCorrente && item?.banco?.code && item?.banco?.description
          ? t('com.muralis.qcx.bancoAgenciaContaCorrente', {
              nome: item?.description,
              nomeBanco: item?.banco?.description,
              banco: item?.banco?.code,
              agencia: item?.agencia,
              contaCorrente: item?.contaCorrente,
            })
          : ''
      }
      {...restProps}
    />
  );
}
