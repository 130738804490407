/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, IconButton, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import _, { debounce, get, isEmpty, isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import QCXPopupState from '../../components/popup-state/QCXPopupState';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../features/feedback/feedbackSlice';
import { ERROR_SEVERITY, SUCCESS_SEVERITY, WARNING_SEVERITY } from '../../features/severity';
import useConfigurePopUp from '../../ts/common/hooks/popUps/useConfigurePopUp';
import useRequiredValidator from '../../utils/hooks/form/field/useRequiredValidator';
import QCXButton from '../button/QCXButton';
import QCXDataGrid from '../data-grid/QCXDataGrid';
import QCXConfirmDialog from '../dialog/QCXConfirmDialog';
import QCXListItemIcon from '../list-item-icon/QCXListItemIcon';
import QCXCircularProgress from '../progress/QCXCircularProgress';
import useBondManagerState from './QCXFinalBondManagerState';
import { BondManagerColumn, BondManagerProps } from './QCXFinalBondManagerTypes';

const useStyles = makeStyles(() => ({
  dataGridDiv: {
    width: 'calc(100% - 0px) !important',
  },
}));

const QCXFinalBondManager = <TableEntity,>({
  isParentConsult,
  listProps,
  formProps,
  generalConfig = {},
  handleAdd,
  handleUpdate,
  handleRemove = undefined,
  handleAlreadyExists,
  onClearDecorator,
  onConsultDecorator,
  onAddDecorator,
  disableMultipleFields = false,
  disableConsult = false,
  disableRemove = false,
  disableDefaultRemove = false,
  disableButtons = false,
  hideAddButton = false,
  onlyShowFieldOnEdit = false,
  readOnlyMode = false,
  forceShowDelete = false,
  controlButtonsGridProps,
  dataGridDivProps = {},
  hideDeleteButton = false,
  children,
}: BondManagerProps<TableEntity>) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const requiredValidator = useRequiredValidator({
    options: {
      showFeedback: true,
    },
  });

  const internalControlButtonsGridProps = useMemo(
    () =>
      isEmpty(controlButtonsGridProps)
        ? {
            add: {
              xs: 12,
              sm: 4,
              md: 4,
            },
            update: {
              xs: 12,
              sm: 2,
              md: 2,
            },
            clear: {
              xs: 6,
              sm: readOnlyMode ? 4 : 2,
              md: readOnlyMode ? 4 : 2,
            },
          }
        : controlButtonsGridProps,
    [controlButtonsGridProps, readOnlyMode]
  );

  const actionConfig = useMemo(() => generalConfig?.actionConfig, [generalConfig]);

  const feedbackConfig = useMemo(() => generalConfig?.feedbackConfig, [generalConfig]);

  const form = useForm();
  const { values } = useFormState();

  const {
    isLoading,
    isCreate,
    isConsult,
    isUpdate,
    changeToLoading,
    changeToFinnishLoading,
    changeToConsult,
    changeToCreate,
    changeToUpdate,
  } = useBondManagerState();

  // Essa variavel é usada para disponibilizar o estado e o formulario nas callbacks
  const callbackTools = useMemo(
    () => ({
      formUtils: { form, values },
      stateUtils: {
        isLoading,
        isCreate,
        isConsult,
        isUpdate,
        changeToLoading,
        changeToFinnishLoading,
        changeToConsult,
        changeToCreate,
        changeToUpdate,
      },
    }),
    [
      form,
      values,
      isLoading,
      isCreate,
      isConsult,
      isUpdate,
      changeToLoading,
      changeToFinnishLoading,
      changeToConsult,
      changeToCreate,
      changeToUpdate,
    ]
  );

  const vinculoListName = useMemo(() => listProps?.name, [listProps]);

  const fields = useMemo(() => formProps?.fields || [], [formProps]);

  const currentVinculo = useMemo(() => get(values, formProps?.rootName), [values]);

  const currentVinculosList = useMemo(() => get(values, vinculoListName) || [], [values, vinculoListName]);

  const isNoSameThatCurrent = useCallback(
    (current: any, data: any) =>
      fields.some((prop) => {
        const currentVinculoPropValue = get(current, prop?.name);
        const dataPropValue = get(data, prop?.name);
        return !!(currentVinculoPropValue !== dataPropValue);
      }),
    [fields]
  );

  const handleFeedback = useCallback((impediment: any) => {
    if (impediment?.severity === WARNING_SEVERITY) {
      dispatch(
        setWarningFeedback({
          message: t('com.muralis.qcx.atencaoMensagem', { mensagem: impediment?.message }),
        })
      );
      return;
    }

    if (impediment?.severity === ERROR_SEVERITY) {
      dispatch(
        setErrorFeedback({
          message: t('com.muralis.qcx.erroMensagem', { mensagem: impediment?.message }),
        })
      );
      return;
    }

    if (impediment?.severity === SUCCESS_SEVERITY) {
      dispatch(
        setSuccessFeedback({
          message: impediment?.message,
        })
      );
    }
  }, []);

  const handleSuccessAddVinculo = useCallback(
    (updatedVinculos: any) => {
      const successAddAction = actionConfig?.add?.success;

      if (!successAddAction?.disableUpdateList) {
        form.change(vinculoListName, updatedVinculos);
      }

      if (!successAddAction?.disableResetRootValues) {
        form.change(formProps?.rootName, undefined);
      }

      if (isFunction(onAddDecorator)) {
        onAddDecorator(callbackTools);
      }
    },
    [form, actionConfig, feedbackConfig, setSuccessFeedback, vinculoListName, onAddDecorator]
  );

  const handleSuccessUpdateVinculo = useCallback(
    (updatedVinculos: any) => {
      const successUpdateAction = actionConfig?.update?.success;

      if (!successUpdateAction?.disableUpdateList) {
        form.change(vinculoListName, updatedVinculos);
      }

      const successUpdateFeedback = feedbackConfig?.update?.success;

      const message = successUpdateFeedback?.message
        ? successUpdateFeedback?.message
        : t('com.muralis.qcx.mensagem.vinculoAtualizado');

      handleFeedback({
        severity: SUCCESS_SEVERITY,
        message,
      });
    },
    [form, actionConfig, feedbackConfig, setSuccessFeedback, vinculoListName, currentVinculosList]
  );

  const handleSuccessRemoveVinculo = useCallback(
    (vinculo: any, options: any) => {
      const updatedVinculos = currentVinculosList?.filter(
        (existingVinculo: any) => vinculo?.id !== existingVinculo?.id
      );

      form.change(vinculoListName, updatedVinculos);

      const successRemoveFeedback = feedbackConfig?.remove?.success;

      const message = successRemoveFeedback?.message
        ? successRemoveFeedback?.message
        : t('com.muralis.qcx.mensagem.itemRemovido');

      handleFeedback({
        severity: SUCCESS_SEVERITY,
        message,
      });

      if (isFunction(options?.onRemoveDecorator)) {
        options.onRemoveDecorator();
      }
    },
    [form, feedbackConfig, handleFeedback, vinculoListName, currentVinculosList]
  );

  const handleError = useCallback(
    (error: any) => {
      const errorMessage = error?.response?.message
        ? t('com.muralis.qcx.erro.erroRealizarVinculo', { mensagem: error?.response?.message })
        : error?.message;

      handleFeedback({
        severity: ERROR_SEVERITY,
        message: errorMessage,
      });
    },
    [handleFeedback]
  );

  const requiredFormFields = useMemo(
    () =>
      formProps?.fields
        ?.filter(({ required }) => required !== false)
        ?.map(({ name, label }) => ({
          name,
          label,
        })),
    [formProps]
  );

  const handleVincular = useCallback(
    async (event: any) => {
      event?.stopPropagation();

      const validationResult = requiredValidator.validate(requiredFormFields, currentVinculo);

      if (!validationResult?.valid) {
        return;
      }

      changeToLoading();

      const handleDebouncedVincular = debounce(async () => {
        try {
          const alreadyExistsVinculo = isFunction(handleAlreadyExists)
            ? handleAlreadyExists(currentVinculo, currentVinculosList)
            : undefined;

          if (
            (alreadyExistsVinculo && !disableMultipleFields) ||
            (alreadyExistsVinculo && disableMultipleFields && !isUpdate)
          ) {
            const message = t('com.muralis.qcx.mensagem.vinculoInformacoesExiste');
            handleFeedback({
              severity: ERROR_SEVERITY,
              message,
            });

            changeToFinnishLoading();

            return;
          }

          if (isUpdate) {
            await handleUpdate(currentVinculo, currentVinculosList, handleSuccessUpdateVinculo, callbackTools);

            return;
          }

          await handleAdd(currentVinculo, currentVinculosList, handleSuccessAddVinculo, callbackTools);
        } catch (error) {
          handleError(error);
        } finally {
          changeToFinnishLoading();
        }
      }, 500);

      handleDebouncedVincular();
    },
    [
      form,
      callbackTools,
      isUpdate,
      disableMultipleFields,
      currentVinculo,
      currentVinculosList,
      handleFeedback,
      requiredValidator,
      requiredFormFields,
    ]
  );

  const handleConsultVinculo = useCallback(
    (event: any, data: any) => {
      if (event && isFunction(event?.stopPropagation)) {
        event?.stopPropagation();
      }

      if (isNoSameThatCurrent(currentVinculo, data)) {
        if (isFunction(onConsultDecorator)) {
          onConsultDecorator(callbackTools);
        }

        changeToUpdate();
        changeToLoading();

        form.change(formProps?.rootName, undefined);

        _.debounce(() => {
          form.change(formProps?.rootName, data);

          changeToFinnishLoading();
        }, 400)();
      }
    },
    [form, callbackTools, fields, formProps, currentVinculo, onConsultDecorator, isNoSameThatCurrent]
  );

  const handleClearCurrentVinculo = useCallback(
    (event?: any) => {
      if (isFunction(event?.stopPropagation)) {
        event?.stopPropagation();
      }

      form.change(formProps?.rootName, undefined);

      if (isFunction(onClearDecorator)) {
        onClearDecorator(callbackTools);
      }

      if (isParentConsult) {
        changeToConsult();
        return;
      }

      changeToCreate();
    },
    [form, callbackTools, formProps, isParentConsult, onClearDecorator]
  );

  const [openRemoveVinculoPopUp, removeVinculoPopUpConfig] = useConfigurePopUp({
    primaryConfirmCallback: async (vinculo: any) => {
      try {
        if (disableDefaultRemove && isFunction(handleRemove)) {
          await handleRemove({
            target: vinculo,
            list: currentVinculosList,
            handleSuccess: handleSuccessRemoveVinculo,
            callbackTools,
          });

          return;
        }

        handleSuccessRemoveVinculo(vinculo, {
          onRemoveDecorator: () => {
            if (isUpdate) {
              handleClearCurrentVinculo();
            }
          },
        });
      } catch (error) {
        handleError(error);
      }
    },
  });

  const handleRemoveVinculoByRowClick = useCallback(
    (event: any, data: any) => {
      if (event && isFunction(event?.stopPropagation)) {
        event?.stopPropagation();
      }

      if (!isEmpty(data)) {
        if (isFunction(event?.stopPropagation)) {
          const titleOfOperation = actionConfig?.remove?.confirm?.title || t('com.muralis.qcx.acoes.confirmarOperacao');

          const messageOfOperation =
            actionConfig?.remove?.confirm?.message || t('com.muralis.qcx.mensagem.confirmarOperacaoMensagem');

          const configureOperation = () => ({
            options: {
              title: titleOfOperation,
              message: messageOfOperation,
            },
          });

          openRemoveVinculoPopUp({ onOpenCallback: configureOperation, args: data });
        }
      }
    },
    [currentVinculo, openRemoveVinculoPopUp]
  );

  useEffect(() => {
    const handleChangeMode = () => {
      if (isParentConsult) {
        changeToConsult();
      } else {
        changeToCreate();
      }
    };

    handleChangeMode();
  }, [isParentConsult]);

  // TODO: Entender como isso funciona e onde deveria ficar
  // const renderOtherMenuItems = useMemo(() => listProps?.renderOtherMenuItems, [listProps]);

  const controlBoxColumns: BondManagerColumn<any>[] = useMemo(
    () => [
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 90,
        renderCell: ({ row }: { row: any }) => (
          <QCXPopupState popupId={`popup-menu-bond-manager-${row?.id}`}>
            {(popupState: any) => (
              <>
                <IconButton
                  key={`btn-more-options-${row?.id}`}
                  name={`btn-more-options-${row?.id}`}
                  {...bindTrigger(popupState)}
                >
                  <MoreHorizIcon color="secondary" />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  {!disableConsult && (
                    <MenuItem
                      key={`btn-consulta-${row?.id}`}
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultVinculo(event, row);
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  )}
                  {/* Aparentemente é uma função que renderiza mais itens no menu
                  {isFunction(renderOtherMenuItems) && renderOtherMenuItems(row, popupState, isParentConsult, callbackTools)} */}
                  {!hideDeleteButton && (forceShowDelete || !readOnlyMode) && (
                    <MenuItem
                      key={`btn-excluir-${row?.id}`}
                      onClick={(event) => {
                        popupState.close(event);

                        handleRemoveVinculoByRowClick(event, row);
                      }}
                      disabled={disableRemove || isParentConsult}
                    >
                      <QCXListItemIcon>
                        <DeleteIcon fontSize="small" color={isParentConsult ? 'disabled' : 'error'} />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.excluir').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </QCXPopupState>
        ),
      },
    ],
    [callbackTools, isParentConsult, handleRemoveVinculoByRowClick, handleConsultVinculo]
  );

  const dataGridColumns = useMemo(() => listProps?.columns.concat(controlBoxColumns), [listProps, controlBoxColumns]);

  const renderChildren = () =>
    isFunction(children)
      ? children({
          loading: isLoading,
          creating: isCreate,
          consulting: isConsult,
          updating: isUpdate,
        })
      : children;

  return (
    <>
      <Grid item container spacing={2}>
        {(onlyShowFieldOnEdit && isUpdate) || !onlyShowFieldOnEdit ? renderChildren() : <></>}
        {!readOnlyMode && !isUpdate && !hideAddButton ? (
          <Grid item xs={12} sm={4} md={4} {...internalControlButtonsGridProps?.add}>
            <QCXButton
              id={`control-box-add-button_${String(Math.random())}`}
              key={`control-box-add-button_${String(Math.random())}`}
              color="secondary"
              onClick={handleVincular}
              disabled={disableButtons || isParentConsult || isConsult || isLoading}
              fullWidth
              tooltip
              tooltipDescription={t('com.muralis.qcx.acoes.adicionar')}
              startIcon={!isLoading && <AddCircleIcon />}
            >
              {isLoading ? <QCXCircularProgress color="disabled" size={20} /> : t('com.muralis.qcx.acoes.adicionar')}
            </QCXButton>
          </Grid>
        ) : (
          <></>
        )}
        {!readOnlyMode && isUpdate && (
          <Grid item xs={6} sm={2} md={2} {...internalControlButtonsGridProps?.update}>
            <QCXButton
              color="secondary"
              onClick={handleVincular}
              disabled={disableButtons || isParentConsult || isLoading}
              fullWidth
              tooltip
              tooltipDescription={t('com.muralis.qcx.acoes.atualizar')}
            >
              {isLoading ? <QCXCircularProgress color="disabled" size={20} /> : <SaveIcon />}
            </QCXButton>
          </Grid>
        )}
        {isUpdate && (
          <Grid
            item
            xs={6}
            sm={readOnlyMode ? 4 : 2}
            md={readOnlyMode ? 4 : 2}
            {...internalControlButtonsGridProps?.clear}
          >
            <QCXButton
              variant="outlined"
              onClick={handleClearCurrentVinculo}
              tooltip
              tooltipDescription={t('com.muralis.qcx.acoes.limpar')}
              fullWidth
              disabled={(disableButtons && !isParentConsult) || !isUpdate || isLoading}
            >
              <ClearIcon />
            </QCXButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <QCXDataGrid
            columns={dataGridColumns}
            rows={currentVinculosList}
            divProps={{
              ...dataGridDivProps,
              className: classes?.dataGridDiv,
            }}
          />
        </Grid>
      </Grid>
      <QCXConfirmDialog
        open={removeVinculoPopUpConfig?.isPopUpOpen}
        title={removeVinculoPopUpConfig?.title}
        content={removeVinculoPopUpConfig?.message}
        endContent={removeVinculoPopUpConfig?.endMessage}
        onConfirm={removeVinculoPopUpConfig?.confirmPrimaryAction}
        onClose={removeVinculoPopUpConfig?.reset}
      />
    </>
  );
};

export default QCXFinalBondManager;
