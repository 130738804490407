import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import QCXModeloTabelaSdaForm from './QCXModeloTabelaSdaForm';

export default function QCXModeloTabelaSdaWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  handleChangeModel,
  model,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const wizardSteps = ['Dados do Modelo'];

  const checkIfIsAlternativeSaveByStep = () => false;

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      handleSubmit={handleSubmit}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleAlternativeSave={handleAlternativeSave}
      {...restProps}
    >
      {[(formProps) => (
        <QCXModeloTabelaSdaForm
          key="dados-tecnicos-e-informacoes-modelo-tabela-sda"
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
          handleInformacoesChange={(updatedList) => {
            const newModel = { ...model, informacoes: updatedList };
            handleChangeModel(newModel);
          }}
        />
      )]}
    </QCXFinalFormWizard>
  );
}
