import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectModelosTabelasSda } from '../../features/modelo-tabela-sda/modeloTabelaSdaSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectModeloTabelaSdautocomplete({
  label,
  name,
  initialValues,
  tipo,
  ...restProps
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectModelosTabelasSda);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, [dispatch]);

  const filteredList = useMemo(() => {
    if (tipo) {
      return list.filter(item => item.tipo && item.tipo.toUpperCase() === tipo.toUpperCase());
    }
    return list;
  }, [list, tipo]);

  const [defaultValue, setDefaultValue] = useState('');
  useEffect(() => {
    const value = filteredList.find((item) => item.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [filteredList, initialValues, name]);

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.id} - ${option.description}`,
  });
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={filteredList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) =>
        item?.id && item?.description ? `${item.id} - ${item.description}` : ''
      }
      {...restProps}
    />
  );
}
