import { Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import {
  AcUnit as AcUnitIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Code as CodeIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Delete as DeleteIcon,
  DoneAll as DoneAllIcon,
  Event as EventIcon,
  FileCopy as FileCopyIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Print as PrintIcon,
  Schedule as ScheduleIcon,
  CallMissed as CallMissedIcon,
} from '@material-ui/icons';
import { FaCalculator as FaCalculatorIcon } from 'react-icons/fa';
import ReplayIcon from '@mui/icons-material/Replay';
import QCXPopupState from '../../../../../components/popup-state/QCXPopupState';
import QCXListItemIcon from '../../../../../shared-components/list-item-icon/QCXListItemIcon';
import {
  isCalculadaStatus,
  isConferidaStatus,
  isLiberadaStatus,
  isNaoCalculadaStatus,
  isNaoConferidaStatus,
} from '../../../../../utils/general/operations/operationUtils';
import useDeclaracaoImportacaoActionsMenu from './useDeclaracaoImportacaoActionsMenu';
import QCXGenericDownloader from '../../../../../components/downloader/QCXGenericDownloader';
import QCXFinalFormDialog from '../../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXFinalRadioField from '../../../../../shared-components/final-radio-field/QCXFinalRadioField';
import { ReportUtils } from '../../../../../utils/general/report/reportUtils';
import DeclaracaoImportacaoContext from '../../../../../contexts/import/declaracao-importacao/DeclaracaoImportacaoContext';
import { userHasCreateRole, userHasDeleteRole, userHasUpdateRole } from '../../../../../components/perfil/perfil-utils';

export default function DeclaracaoImportacaoActionsMenu({
  authInfo = {},
  id,
  atributosAdicionais,
  calculada,
  active,
  metaFields,
  showLessOptions = false,
}) {
  const { t } = useTranslation();
  const {
    handleConsultarClick,
    handleImprimirClick,
    handleGerarXMLDiagnosticoById,
    handleGerarXMLRegistroById,
    handleDiagnosticoCrawlerClick,
    canGenerateDIDiag,
    canGenerateTransmissaoDiagnosticoDI,
    canGenerateDIReg,
    canGenerateTransmissaoRegistroDI,
    handleTransmissaoDuimp,
    setModelDuimpSelecionada,
    setExibirModalTransmissaoDuimp,
    handleDiagnosticarDuimp,
    handleCalcularClick,
    handleConfigureConferirByIdClick,
    handleConfigureLiberarByIdClick,
    handleConfigureRetransmitirByIdClick,
    handleReabrirClick,
    handleDadosRegistroSiscomexClick,
    handleDuplicarClick,
    handleIncluirMultaClick,
    handleEmissaoIcmsClick,
    handleConfigureActivateOrInactivateByIdClick,
  } = useContext(DeclaracaoImportacaoContext);

  return (
    <>
      <QCXPopupState popupId="popup-menu-li">
        {(popupState) => (
          <>
            <IconButton key={`btn-more-options-${id}`} name={`btn-more-options-${id}`} {...bindTrigger(popupState)}>
              <MoreHorizIcon color="secondary" size={20} />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              {!showLessOptions && (
                <MenuItem
                  onClick={(event) => {
                    popupState.close(event);

                    handleConsultarClick(event, {
                      id,
                    });
                  }}
                >
                  <QCXListItemIcon>
                    <PageviewIcon fontSize="small" />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                  </Typography>
                </MenuItem>
              )}
              <MenuItem
                onClick={(event) => {
                  popupState.close(event);
                  handleImprimirClick(event, {
                    declaracaoImportacao: {
                      id,
                      processo: atributosAdicionais?.processo,
                    },
                  });
                }}
              >
                <QCXListItemIcon>
                  <PrintIcon color="primary" fontSize="small" />
                </QCXListItemIcon>
                <Typography
                  variant="inherit"
                  style={{
                    fontSize: 12,
                  }}
                >
                  {t('com.muralis.qcx.acoes.imprimir').toUpperCase()}
                </Typography>
              </MenuItem>

              {!showLessOptions && !isDuimp(atributosAdicionais) ? (
                <>
                  <MenuItem
                    disabled={
                      !canGenerateDIDiag ||
                      !(
                        isCalculadaStatus(calculada) &&
                        isConferidaStatus(atributosAdicionais?.conferida) &&
                        isLiberadaStatus(atributosAdicionais?.liberada) &&
                        !atributosAdicionais.registro
                      )
                    }
                    onClick={(event) => {
                      handleGerarXMLDiagnosticoById(event, {
                        declaracaoImportacao: {
                          id,
                          processo: atributosAdicionais?.processo,
                          tipo: 'DIAGNOSTICO',
                        },
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <CodeIcon color="primary" fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {'Gerar XML de Diagnóstico'.toUpperCase()}
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    disabled={
                      !canGenerateDIReg ||
                      !(
                        isCalculadaStatus(calculada) &&
                        isConferidaStatus(atributosAdicionais?.conferida) &&
                        isLiberadaStatus(atributosAdicionais?.liberada) &&
                        !atributosAdicionais.registro
                      )
                    }
                    onClick={(event) => {
                      handleGerarXMLRegistroById(event, {
                        declaracaoImportacao: {
                          id,
                          processo: atributosAdicionais?.processo,
                          tipo: 'REGISTRO',
                        },
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <CodeIcon color="primary" fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {'Gerar XML de Registro'.toUpperCase()}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    disabled={
                      !canGenerateDIDiag ||
                      !canGenerateTransmissaoDiagnosticoDI ||
                      !(
                        isCalculadaStatus(calculada) &&
                        isConferidaStatus(atributosAdicionais?.conferida) &&
                        isLiberadaStatus(atributosAdicionais?.liberada) &&
                        !atributosAdicionais.registro
                      )
                    }
                    onClick={(event) => {
                      popupState.close(event);
                      handleDiagnosticoCrawlerClick({
                        declaracaoImportacao: {
                          id,
                          processo: atributosAdicionais?.processo,
                          tipo: 'DIAGNOSTICO',
                        },
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <ScheduleIcon color="primary" fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.transmissaoDiagnostico').toUpperCase()}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    disabled={
                      !canGenerateDIReg ||
                      !canGenerateTransmissaoRegistroDI ||
                      !(
                        isCalculadaStatus(calculada) &&
                        isConferidaStatus(atributosAdicionais?.conferida) &&
                        isLiberadaStatus(atributosAdicionais?.liberada) &&
                        !atributosAdicionais.registro
                      )
                    }
                    onClick={(event) => {
                      popupState.close(event);
                      handleDiagnosticoCrawlerClick({
                        declaracaoImportacao: {
                          id,
                          processo: atributosAdicionais?.processo,
                          tipo: 'REGISTRO',
                        },
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <ScheduleIcon color="primary" fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.transmissaoRegistro').toUpperCase()}
                    </Typography>
                  </MenuItem>
                </>
              ) : null}

              {!showLessOptions && isDuimp(atributosAdicionais) ? (
                <>
                  <MenuItem
                    disabled={
                      !canGenerateDIDiag ||
                      !(
                        isCalculadaStatus(calculada) &&
                        isConferidaStatus(atributosAdicionais?.conferida) &&
                        isLiberadaStatus(atributosAdicionais?.liberada) &&
                        !atributosAdicionais.registro
                      )
                    }
                    onClick={(event) => {
                      popupState.close(event);
                      handleTransmissaoDuimp({
                        declaracaoImportacao: {
                          id,
                          processo: atributosAdicionais?.processo,
                          tipo: 'REGISTRO',
                          transmitirComErros: false,
                        },
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <ScheduleIcon color="primary" fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.transmissaoDuimp').toUpperCase()}
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    disabled={
                      !canGenerateDIDiag ||
                      !(
                        isCalculadaStatus(calculada) &&
                        isConferidaStatus(atributosAdicionais?.conferida) &&
                        isLiberadaStatus(atributosAdicionais?.liberada) &&
                        !atributosAdicionais.registro
                      )
                    }
                    onClick={(event) => {
                      popupState.close(event);
                      // guarda as informacoes da linha atual selecionada
                      setModelDuimpSelecionada({
                        id,
                        processo: atributosAdicionais?.processo,
                      });
                      // exibe modal transmissao
                      setExibirModalTransmissaoDuimp(true);
                    }}
                  >
                    <QCXListItemIcon>
                      <ScheduleIcon color="primary" fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.transmissaoRegistroDuimp').toUpperCase()}
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    disabled={
                      !canGenerateDIDiag ||
                      !(
                        isCalculadaStatus(calculada) &&
                        isConferidaStatus(atributosAdicionais?.conferida) &&
                        isLiberadaStatus(atributosAdicionais?.liberada) &&
                        !atributosAdicionais.registro
                      )
                    }
                    onClick={(event) => {
                      popupState.close(event);
                      handleDiagnosticarDuimp({
                        declaracaoImportacao: {
                          id,
                          processo: atributosAdicionais?.processo,
                          tipo: 'DIAGNOSTICO',
                        },
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <ScheduleIcon color="primary" fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {'Diagnosticar DUIMP'.toUpperCase()}
                    </Typography>
                  </MenuItem>
                </>
              ) : null}

              {userHasUpdateRole(authInfo, 'importacao-di-duimp') && (
                <>
                  <MenuItem
                    onClick={(event) => {
                      popupState.close(event);
                      handleCalcularClick(event, {
                        id,
                        calculada,
                        atributosAdicionais,
                        active,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <FaCalculatorIcon color="green" size={20} />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {isNaoCalculadaStatus(calculada)
                        ? t('com.muralis.qcx.acoes.calcular').toUpperCase()
                        : t('com.muralis.qcx.acoes.recalcular').toUpperCase()}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={handleConfigureConferirByIdClick(
                      {
                        id,
                        atributosAdicionais,
                        metaFields,
                      },
                      popupState
                    )}
                    disabled={isNaoCalculadaStatus(calculada) || isConferidaStatus(atributosAdicionais?.conferida)}
                  >
                    <QCXListItemIcon>
                      <DoneAllIcon
                        fontSize="small"
                        htmlColor={
                          isNaoCalculadaStatus(calculada) || isConferidaStatus(atributosAdicionais?.conferida)
                            ? 'rgba(0, 0, 0, 0.38)'
                            : 'green'
                        }
                      />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.conferir').toUpperCase()}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={handleConfigureLiberarByIdClick(
                      {
                        id,
                        atributosAdicionais,
                        metaFields,
                      },
                      popupState
                    )}
                    disabled={
                      isNaoCalculadaStatus(calculada) ||
                      isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                      isLiberadaStatus(atributosAdicionais?.liberada)
                    }
                  >
                    <QCXListItemIcon>
                      <AssignmentTurnedInIcon
                        fontSize="small"
                        htmlColor={
                          isNaoCalculadaStatus(calculada) ||
                          isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                          isLiberadaStatus(atributosAdicionais?.liberada)
                            ? 'rgba(0, 0, 0, 0.38)'
                            : 'orange'
                        }
                      />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.liberarRegistro').toUpperCase()}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={handleConfigureRetransmitirByIdClick(
                      {
                        id,
                      },
                      popupState
                    )}
                    disabled={
                      atributosAdicionais?.dataRegistroSiscomex ||
                      atributosAdicionais?.registro ||
                      !isLiberadaStatus(atributosAdicionais?.liberada)
                    }
                  >
                    <QCXListItemIcon>
                      <ReplayIcon
                        fontSize="small"
                        htmlColor={
                          atributosAdicionais?.dataRegistroSiscomex ||
                          atributosAdicionais?.registro ||
                          !isLiberadaStatus(atributosAdicionais?.liberada)
                            ? 'rgba(0, 0, 0, 0.38)'
                            : 'green'
                        }
                      />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      RETRANSMITIR
                    </Typography>
                  </MenuItem>
                </>
              )}

              {!showLessOptions &&
                isCalculadaStatus(calculada) &&
                isConferidaStatus(atributosAdicionais?.conferida) &&
                isLiberadaStatus(atributosAdicionais?.liberada) && (
                  <MenuItem
                    onClick={(event) => {
                      popupState.close(event);
                      handleDadosRegistroSiscomexClick(event, {
                        id,
                        processo: atributosAdicionais?.processo,
                        registro: atributosAdicionais?.registro,
                        numeroTransmissao: atributosAdicionais?.numeroTransmissao,
                        dataRegistroSiscomex: atributosAdicionais?.dataRegistroSiscomex,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <ConfirmationNumberIcon fontSize="small" color="secondary" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.alterarRegistro').toUpperCase()}
                    </Typography>
                  </MenuItem>
                )}

              <MenuItem
                onClick={(event) => {
                  popupState.close(event);
                  handleReabrirClick({ id });
                }}
                disabled={!atributosAdicionais?.registro}
              >
                <QCXListItemIcon>
                  <AcUnitIcon
                    fontSize="small"
                    htmlColor={
                      isNaoCalculadaStatus(calculada) ||
                      isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                      !isLiberadaStatus(atributosAdicionais?.liberada)
                        ? 'rgba(0, 0, 0, 0.38)'
                        : 'orange'
                    }
                  />
                </QCXListItemIcon>
                <Typography
                  variant="inherit"
                  style={{
                    fontSize: 12,
                  }}
                >
                  {t('com.muralis.qcx.acoes.reabrir').toUpperCase()}
                </Typography>
              </MenuItem>

              {!showLessOptions && userHasCreateRole(authInfo, 'importacao-di-duimp') && (
                <>
                  <MenuItem
                    onClick={(event) => {
                      popupState.close(event);
                      handleDuplicarClick(event, {
                        id,
                        processo: atributosAdicionais?.processo,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <FileCopyIcon fontSize="small" color="primary" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.duplicar').toUpperCase()}
                    </Typography>
                  </MenuItem>
                </>
              )}

              {!showLessOptions && (
                <>
                  <MenuItem
                    disabled={!atributosAdicionais?.registro}
                    onClick={(event) => {
                      popupState.close(event);
                      handleIncluirMultaClick(event, {
                        id,
                        multa: atributosAdicionais?.multa,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <CallMissedIcon
                        fontSize="small"
                        color={!atributosAdicionais?.registro ? 'disabled' : 'primary'}
                      />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      INCLUIR MULTA APÓS REGISTRO
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    disabled={
                      !(
                        isCalculadaStatus(calculada) &&
                        isConferidaStatus(atributosAdicionais?.conferida) &&
                        isLiberadaStatus(atributosAdicionais?.liberada) &&
                        !!atributosAdicionais?.registro
                      )
                    }
                    onClick={(event) => {
                      popupState.close(event);
                      handleEmissaoIcmsClick(event, {
                        id,
                        processo: atributosAdicionais?.processo,
                        registro: atributosAdicionais?.registro,
                        dataVencimentoIcms: atributosAdicionais?.dataVencimentoIcms,
                        numeroConvenioProtocoloEspecificacaoMercadoria:
                          atributosAdicionais?.numeroConvenioProtocoloEspecificacaoMercadoria,
                        geracaoGareEmissaoIcms: atributosAdicionais?.geracaoGareEmissaoIcms,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <EventIcon
                        fontSize="small"
                        color={
                          !(
                            isCalculadaStatus(calculada) &&
                            isConferidaStatus(atributosAdicionais?.conferida) &&
                            isLiberadaStatus(atributosAdicionais?.liberada) &&
                            !!atributosAdicionais?.registro
                          )
                            ? 'disabled'
                            : 'primary'
                        }
                      />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.imposto.emissaoICMS').toUpperCase()}
                    </Typography>
                  </MenuItem>

                  {userHasDeleteRole(authInfo, 'importacao-di-duimp') && (
                    <>
                      <MenuItem
                        onClick={handleConfigureActivateOrInactivateByIdClick(
                          {
                            id,
                            atributosAdicionais,
                            active,
                          },
                          popupState
                        )}
                        disabled={!active}
                      >
                        <QCXListItemIcon>
                          <DeleteIcon fontSize="small" color={active ? 'error' : 'disabled'} />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.excluir').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    </>
                  )}
                </>
              )}
            </Menu>
          </>
        )}
      </QCXPopupState>
    </>
  );
}

function isDuimp(atributosAdicionais) {
  return atributosAdicionais?.tipoDeclaracao?.code === '50';
}
