/* eslint-disable indent */
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  Cancel as CancelIcon,
  CancelOutlined as CancelOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Receipt as ReceiptIcon,
  RotateLeft as RotateLeftIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
} from '@material-ui/icons';
import _, { isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  SUBMITTING_STATUS,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';

import QCXRelatoriosContasDialogForm from '../../../../components/contas-pagar-receber/QCXRelatoriosContasDialogForm';
import QCXLink from '../../../../components/link/QCXLink';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import ContasPagarContext from '../../../../contexts/financial/contas-pagar/ContasPagarContext';
import { selectConciliacoesBancarias } from '../../../../features/conciliacao-bancaria/conciliacaoBancariaSelectors';
import { fetchConciliacaoPendenteAllAsync } from '../../../../features/conciliacao-bancaria/conciliacaoBancariaThunks';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { contasPagarAPI } from '../../../../features/contas-pagar/contasPagarAPI';
import {
  selectContasPagar,
  selectContasPagarView,
  selectMode,
  selectRefresh,
  selectRelatedContasPagarItemModel,
  selectRelatedReportModel,
  selectRelatedSelectionContasPagarList,
  selectRelatedSelectionContasPagarListView,
} from '../../../../features/contas-pagar/contasPagarSelectors';
import {
  changeToBatchApprovalMode,
  changeToConsultMode,
  failure,
  loading,
  refresh as refreshAction,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRefresh,
  resetRelatedSelectionContasPagarView,
  setRelatedContasPagarItemModel,
  setRelatedReportModel,
  setRelatedSelectionContasPagarView,
  success,
} from '../../../../features/contas-pagar/contasPagarSlice';
import { fetchAllAsync, fetchAllViewAsync, generateReportAPagarByDateAsync } from '../../../../features/contas-pagar/contasPagarThunks';
import { estadoActions } from '../../../../features/estado/estadoSlice';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import QCXWarningAlert from '../../../../shared-components/alert/QCXWarningAlert';
import QCXJustifiedActionFormDialog from '../../../../shared-components/dialog/QCXJustifiedActionFormDialog';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import SimpleConsultPageTemplate from '../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate'
import contasPagarUtils from '../../../../utils/general/contas-pagar/ContasPagarUtils';
import { formatDate, normalizeData, unnormalizeNumeral } from '../../../../utils/general/general-utils';
import TituloUtils from '../../../../utils/general/titulo/TituloUtils';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import {
  isAlternativeLoadingStatus,
  isApprovalBatchMode,
  isNoneMode,
  isRefresh,
} from '../../../../utils/store/store-utils';
import QCXConfirmPaymentDateForm from '../../../../components/contas-pagar-receber/QCXConfirmPaymentDateForm';
import QCXDocumentosFollowUpDialogManager from '../../../../components/follow-up/QCXDocumentosFollowUpDialogManager';
import ConfirmationModal from '../../../../ts/pages/integracao/planilhas/components/confirmationModal';
import TableCellWithTooltip from '../../../../shared-components/tooltip-cell/TableCellWithTooltip';
import ContasPagarFilterComponent from './components/ContasPagarFilterComponent';

export default function ContasPagarConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const { status } = useContext(ContasPagarContext);

  const mode = useSelector(selectMode);
  const dispatch = useDispatch();

  const [pageConfig, setPageConfig] = useState({
    currentPage: 0,
    pageSize: 10,
    filterInputValue: '',
  });

  const [fetchFilterParams, setFetchFilterParams] = useState([]);

  const refresh = useSelector(selectRefresh);
  const contasPagar = useSelector(selectContasPagarView);
  const conciliacoesBancariasPendentes = useSelector(selectConciliacoesBancarias);
  const relatedContasPagarItem = useSelector(selectRelatedContasPagarItemModel);
  const reportRelatedModel = useSelector(selectRelatedReportModel);
  const relatedSelectionContasPagarList = useSelector(selectRelatedSelectionContasPagarList);
  const relatedSelectionContasPagarListView = useSelector(selectRelatedSelectionContasPagarListView);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [decisionPromiseResolver, setDecisionPromiseResolver] = useState(null);
  const [selectedContaPagarId, setSelectedContaPagarId] = useState(null);
  const selectedContaPagar = contasPagar?.content?.find((c) => c.id === selectedContaPagarId);

  const isConciliacoesBancariasPendentes = useMemo(
    () => conciliacoesBancariasPendentes.length > 0,
    [conciliacoesBancariasPendentes]
  );

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isApprovalBatch = useMemo(() => isApprovalBatchMode(mode), [mode]);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const requestConciliacaoBancariaPendente = useCallback(async () => {
    dispatch(fetchConciliacaoPendenteAllAsync());
  }, []);

  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionContasPagarListView ||
      (
        _.isArrayLikeObject(relatedSelectionContasPagarListView) &&
        _.isEmpty(relatedSelectionContasPagarListView)
      ),
    [relatedSelectionContasPagarListView]
  );

  const fetchAll = useCallback(() => {
    dispatch(fetchAllViewAsync(
      [
        {
          name: 'page',
          value: pageConfig.currentPage,
        },
        {
          name: 'size',
          value: pageConfig.pageSize,
        },
        {
          name: 'filter',
          value: pageConfig.filterInputValue,
        },
      ]
    ));
  }, [pageConfig]);

  useEffect(() => {
    requestConciliacaoBancariaPendente();
    resetModes();
    resetModels();
  }, []);

  useEffect(() => {
    fetchAll();
  }, [fetchAll])

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    console.log({
      isNone,
      isEmptySelectionList,
    });

    if (isNone && !isEmptySelectionList) {
      dispatch(resetRelatedSelectionContasPagarView());
    }
  }, [isNone, isEmptySelectionList]);

  const showConfirmationModal = (message) => {
    setConfirmationMessage(message);
    setConfirmModalOpen(true);

    return new Promise((resolve) => {
      setDecisionPromiseResolver(() => resolve);
    });
  };

  const handleConfirm = () => {
    setConfirmModalOpen(false);
    decisionPromiseResolver(true);
  };

  const handleCancel = () => {
    setConfirmModalOpen(false);
    decisionPromiseResolver(false);
  };

  const handleEnableBatchApprovalMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToBatchApprovalMode());
  }, []);

  const handleSelectionModelChange = useCallback(
    ({ selectionModel }) => {
      if (isApprovalBatch) {
        dispatch(setRelatedSelectionContasPagarView(selectionModel));
      }
    },
    [contasPagar, isApprovalBatch]
  );

  const handleConfirmBatchApproval = useCallback(
    async (event) => {

      try {
        dispatch(loading());

        const dataPagamento = event?.dataPagamento;
        const contaBancariaid = event?.contaBancariaId?.id;

        const contasPagarFiltrado = contasPagar?.content.map((conta) => ({
          id: conta?.id,
          contaBancaria: conta?.contaBancariaId,
          valor: conta?.valorPagar,
          solicitante: conta?.emailSolicitante,
          followUpId: conta?.followUpId,
          despesaReceitaId: conta?.despesaReceitaId,
          origem: conta?.origem,
          tipo: conta?.tipo,
          contaComissaria: conta?.controlarSaldo,
        }));

        const selectionModelContasPagarList = relatedSelectionContasPagarListView.map((itemModel) => {
          const [contasPagarSelecionadas] = contasPagarFiltrado.filter((conta) => conta?.id === itemModel);
          return contasPagarSelecionadas;
        });

        const idsContasSelecionadasList = selectionModelContasPagarList.map((conta) => ({
          id: conta?.id,
        }));

        const contasPagasSelecionadasTamanho = selectionModelContasPagarList.length;

        const contasPagasSelecionadasLastItem = selectionModelContasPagarList[contasPagasSelecionadasTamanho - 1];
        const { contaBancaria } = contasPagasSelecionadasLastItem;

        const somaValoresPagar = selectionModelContasPagarList.reduce(
          (acumulador, objetoAtual) => acumulador + objetoAtual.valor,
          0
        );


        const createPayload = selectionModelContasPagarList.map((item) => ({
          conta: {
            tipo: {
              description: contasPagarUtils.TITULO_TRANSFERENCIA,
            },
            proprietario: {
              id: contaBancariaid || contaBancaria,
            },
          },
          contaBancariaId: contaBancariaid || contaBancaria,
          referencias: [{ id: item?.id }],
          operacao: {
            description: contasPagarUtils.TRANSFERENCIA,
          },
          valor: item?.valor,
          dataPagamento,
          solicitante: item?.solicitante,
          followUpId: item?.followUpId,
          despesaReceitaId: item?.despesaReceitaId,
          origem: item?.origem,
          tipo: item?.tipo,
          contaComissaria: item?.contaComissaria,
        }
        ));


        const payload = createPayload;

        const response = await contasPagarAPI.transferencia(payload);

        if (response?.status === 201) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.loteTransferenciaContasPagar');

          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
          dispatch(resetMode());
        }
      } catch (error) {
        console.log(error);
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.transferirLoteContasPagarEspecifico', { erro: error?.response?.data })
          : t('com.muralis.qcx.erro.transferirContasPagarLote');
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    },
    [contasPagar, relatedSelectionContasPagarListView]
  );

  const handleCancelBatchApproval = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const [handleImprimirClick, formDialogImpressaoStatus, handleFormDialogImpressaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: contasPagarUtils.CONTAS_PAGAR,
          })
        );
      }
    },
    []
  );

  const [
    handleConfirmBatchApprovalClick,
    formDialogConfirmBatchApprovalStatus,
    handleFormDialogonfirmBatchApprovalStatus,
  ] = useFormDialogSync(async (event, data) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();

      dispatch(
        setRelatedReportModel({
          ...data,
          tipo: contasPagarUtils.CONTAS_PAGAR_2,
        })
      );
    }
  }, []);

  const handleImprimirByDateSubmit = async (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    const vencimentoDe = normalizeData(event?.data?.vencimentoDe);
    const vencimentoAte = normalizeData(event?.data?.vencimentoAte);

    const handlers = {
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = t('com.muralis.qcx.mensagem.nenhumRelatorioGerar');

        dispatch(
          setWarningFeedback({
            message: infoMessage,
          })
        );
      },
      onError: () => {
        dispatch(failure());

        const errorMessage = t('com.muralis.qcx.mensagem.naoFoiPossivelGerarRelatorioContas', {
          tipoRelatorio: t('com.muralis.qcx.financeiro.contasPagar'),
        });

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    dispatch(
      generateReportAPagarByDateAsync({
        vencimentoDe,
        vencimentoAte,
        handlers,
      })
    );

    handleFormDialogImpressaoStatus(DONE_STATUS);
  };

  const [handleOpenCancel, dialogCancelStatus, handleConsultCancelStatus] = useFormDialogSync(async (event, data) => {
    if (_.isFunction(event?.stopPropagation)) {
      event.stopPropagation();
      dispatch(setRelatedContasPagarItemModel(data));
    }
  }, []);

  const handleCancelSubmit = useCallback(
    async ({ ...values }) => {
      try {
        const userDecision = await showConfirmationModal(`Ao cancelar este
          lançamento, também será cancelada a
          Solicitação de Pagamento deseja continuar?`);

        if (!userDecision) {
          return;
        }

        dispatch(loading());
        handleConsultCancelStatus(SUBMITTING_STATUS);

        const response = await contasPagarAPI.cancelar({
          id: values?.id,
          justificativa: values?.justificativa,
        });

        if (response?.status === 200) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.contaPagarCancelada');

          handleConsultCancelStatus(DONE_STATUS);
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
        }
      } catch (error) {
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.cancelarContasPagarEspecifico', { erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.cancelarContasPagar');
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        dispatch(failure());
        handleConsultCancelStatus(FAIL_STATUS);
      }
    },
    [handleConsultCancelStatus]
  );

  const handleEstornarClick = useCallback(
    async (row) => {
      try {
        const userDecision = await showConfirmationModal('Você deseja realmente estornar este lançamento?');

        if (!userDecision) {
          return;
        }
        const response = await contasPagarAPI.estornar({ id: row?.id });

        if (response?.status === 200 || response?.status === 204) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.contaPagarEstornada');

          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
        }
      } catch (error) {
        const errorMessage = error?.response
          ? t('com.muralis.qcx.erro.estornarContasPagarEspecifico', { erro: error?.response?.data })
          : t('com.muralis.qcx.erro.estornarContasPagar');
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        dispatch(failure());
      }
    },
    [dispatch, t]
  );

  const decideId = useCallback((row) => {
    if (row?.origem === 'OPERACIONAL' || row?.origem === 'ADMINISTRATIVA') {
      return row?.solicitacaoPagamentoId;
    }
    if (row?.origem === 'FATURAMENTO') {
      return row?.faturamentoId;
    }
    return row?.faturaDiDuimpId;

  }, []);

  const conciliacaoPendenteMessage = useCallback(
    () => (
      <>
        {isConciliacoesBancariasPendentes > 0 && (
          <QCXWarningAlert style={{ margin: '1rem 0' }} closable>
            <Trans i18nKey="com.muralis.qcx.mensagem.alertaConciliacoesPendentes">
              <QCXLink
                to={t('com.muralis.qcx.url.moduloFinanceiroConciliacaoBancaria')}
                style={{ fontWeight: 'bold', color: '#663C00', textDecoration: 'none' }}
              >
                Conciliação Bancária
              </QCXLink>
            </Trans>
          </QCXWarningAlert>
        )}
      </>
    ),
    [conciliacoesBancariasPendentes]
  );

  const configureDependencies = useCallback(() => {
    dispatch(estadoActions.changeControlTo(IDLE_CONTROL));
  }, []);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeToConsultMode());
        if (data?.id === null) {
          dispatch(
            setErrorFeedback({
              message: 'Não foi possível consultar o item selecionado',
            })
          );
        }
        else if (data?.origem === 'OPERACIONAL' || data?.origem === 'ADMINISTRATIVA') {
          history.push(t('com.muralis.qcx.url.financeiroContasPagarDetalhes', { id: data?.id }));
        } else if (data?.origem === 'FATURAMENTO') {
          history.push(t('com.muralis.qcx.url.financeiroFaturamentoDetalhes', { id: data?.id }));
        } else if (data?.origem === 'REGISTRO_DI') {
          history.push(t('com.muralis.qcx.url.importacaoDeclaracoesImportacaoDetalhes', { id: data?.id-1 }));
        }

      }
    },
    [configureDependencies, history, t]
  );

  const [handleOpenModalDocumentos, formDialogDocumentosStatus, handleFormDialogDocumentosStatus] = useFormDialogSync();

  const [valorParcial, setValorParcial] = useState(0);

  useEffect(() => {


    const calculatedValue = relatedSelectionContasPagarListView.reduce((acc, id) => {
      const conta = contasPagar?.content.find((c) => c.id === id);
      return acc + (conta?.valorPagar || 0);
    }, 0);
    const formattedValue = unnormalizeNumeral(calculatedValue, formatBrazilianNumericDecimal(2));
    setValorParcial(formattedValue);

  }, [relatedSelectionContasPagarListView, contasPagar]);

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.NumeroProcesso'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.numeroProcesso || '-',
        renderCell: ({ row }) => <TableCellWithTooltip value={row?.numeroProcesso || '-'} />,
      },
      {
        field: 'origem',
        headerName: t('com.muralis.qcx.origem'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 180,
        valueGetter: ({ row }) => row?.origem || '-',
      },
      {
        field: 'solicitacao',
        headerName: t('com.muralis.qcx.solicitacao'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 150,
        valueGetter: ({ row }) => formatDate(row?.solicitacao) || '-',
      },
      {
        field: 'aprovacao',
        headerName: t('com.muralis.qcx.aprovacao'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 180,
        valueGetter: ({ row }) => formatDate(row?.aprovacao) || '-',
      },
      {
        field: 'fornecedor',
        headerName: t('com.muralis.qcx.fornecedor.label'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 120,
        valueGetter: ({ row }) => row?.fornecedor || '-',
        renderCell: ({ row }) => <TableCellWithTooltip value={row?.fornecedor || '-'} />,
      },
      {
        field: 'solicitante',
        headerName: t('com.muralis.qcx.solicitante'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.solicitacaoPagamentante || '-',
        renderCell: ({ row }) => <TableCellWithTooltip value={row?.solicitacaoPagamentante || '-'} />,
      },
      {
        field: 'valorPagar',
        headerName: t('com.muralis.qcx.valorPagar'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        sortable: 'true',
        flex: 150,
        valueGetter: ({ row }) => row?.valorPagar ?? 0,
        valueFormatter: ({ value }) => (value ? unnormalizeNumeral(value, formatBrazilianNumericDecimal(2)) : '-'),
      },
      {
        field: 'saldo',
        headerName: t('com.muralis.qcx.saldo'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        sortable: 'true',
        flex: 150,
        valueGetter: ({ row }) => row?.valorPagar - row?.valorRecebido,
        valueFormatter: ({ value }) => (value ? unnormalizeNumeral(value, formatBrazilianNumericDecimal(2)) : '-'),
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.status || '-',
      },
      {
        field: 'dataVencimento',
        headerName: t('com.muralis.qcx.dataVencimento'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.vencimento || '-',
      },
      {
        field: 'numeroDocumento',
        headerName: t('com.muralis.qcx.documento.numeroDocumento'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.numeroDocumento || '-',
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        renderCell: ({ row }) => (
          <>
            <QCXPopupState popupId="popup-contas-pagar">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${row?.id}`}
                    name={`btn-more-options-${row?.id}`}
                    {...bindTrigger(popupState)}
                    disabled={isApprovalBatch}
                  >
                    <MoreHorizIcon color={isApprovalBatch ? 'disabled' : 'secondary'} size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarClick(event, {
                          id: decideId(row),
                          origem: row?.origem,
                          status,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        setSelectedContaPagarId(row.id);
                        handleOpenModalDocumentos(event);
                      }}
                    >
                      <QCXListItemIcon>
                        <DescriptionOutlinedIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        DOCUMENTOS
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleEstornarClick(row)}
                      disabled={
                        contasPagarUtils.isPago(row?.status) ||
                        contasPagarUtils.isCancelado(row?.status) ||
                        TituloUtils.isOrigemTransferencia(row?.origem)
                      }
                    >
                      <QCXListItemIcon>
                        <RotateLeftIcon htmlColor="green" size="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        ESTORNAR
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleOpenCancel(event, row);
                      }}
                      disabled={
                        contasPagarUtils.isPago(row?.status) ||
                        contasPagarUtils.isCancelado(row?.status) ||
                        TituloUtils.isOrigemTransferencia(row?.origem)
                      }
                    >
                      <QCXListItemIcon>
                        <CancelIcon fontSize="small" color="error" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [isApprovalBatch, handleOpenCancel]
  );

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue, advancedFilter }) => {

    const {
      data_solicitacao_pagamento: dataSolicitacaoPagamento,
      data_aprovacao: dataAprovacao,
      fornecedor_id: fornecedorId,
      valor_pagar: valorPagar,
      saldo,
      data_vencimento: dataVencimento,
    } = advancedFilter;

    const filter = filterInputValue;

    const pageParams = [
      { name: 'page', value: currentPage },
      { name: 'size', value: pageSize },
    ];

    const filterParams = [
      { name: 'filter', value: filter },
      { name: 'data_solicitacao', value: dataSolicitacaoPagamento },
      { name: 'data_aprovacao', value: dataAprovacao },
      { name: 'fornecedor', value: fornecedorId },
      { name: 'valor_pagar', value: valorPagar },
      { name: 'saldo', value: saldo },
      { name: 'data_vencimento', value: dataVencimento },
    ];

    const params = pageParams.concat(filterParams);

    setFetchFilterParams(filterParams);

    console.log(params);

    dispatch(fetchAllViewAsync(params));

  }, [])

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.financeiro.contasPagar'),
        },
        default: true,
      },
    ],
    []
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.financeiro.contasPagar'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        others: [
          ...(isNone
            ? [
              {
                description: t('com.muralis.qcx.relatorios.label'),
                disabled: false,
                color: 'primary',
                onClick: handleImprimirClick,
              },
              {
                description: <CheckCircleIcon size={20} color="white" />,
                ...(_.isEmpty(contasPagar || [])
                  ? {
                    disabled: true,
                    color: 'disabled',
                  }
                  : {
                    disabled: false,
                    style: {
                      backgroundColor: 'green',
                    },
                  }),
                onClick: handleEnableBatchApprovalMode,
              },
            ]
            : []),
          ...(isApprovalBatch
            ? [
              {
                description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                startIcon: <CheckCircleIcon />,
                color: 'secondary',
                tooltip: {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                },
                onClick: handleConfirmBatchApprovalClick,
                disabled: isEmptySelectionList,
              },
              {
                description: <CancelOutlinedIcon />,
                variant: 'outlined',
                color: 'default',
                tooltip: {
                  description: t('com.muralis.qcx.acoes.cancelar'),
                },
                onClick: handleCancelBatchApproval,
              },
            ]
            : []),
        ],
      },
      table: {
        columns,
        onConfigChangeCallback: handleConfigChange,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        rowsCount: contasPagar.totalElements,
        checkboxSelection: isApprovalBatch,
        selectionModel: relatedSelectionContasPagarListView?.content,
        onSelectionModelChange: handleSelectionModelChange,
        isRowSelectable: (table) =>
          table?.row?.status === contasPagarUtils.PENDENTE && !TituloUtils.isOrigemTransferencia(table?.row?.origem),
      },
    }),
    [
      breadcrumbs,
      columns,
      isApprovalBatch,
      isEmptySelectionList,
      handleConfigChange,
      contasPagar,
      handleCancelBatchApproval,
      handleConfirmBatchApproval,
      handleSelectionModelChange,
      handleEnableBatchApprovalMode,
      relatedSelectionContasPagarListView,
    ]
  );


  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={contasPagar.content}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['conta-pagar']}
      alertMessage={conciliacaoPendenteMessage()}
      advancedFilterFormChildren={ContasPagarFilterComponent}
      advancedFilterKeyAccessors={['data_solicitacao_pagamento', 'data_aprovacao', 'fornecedor_id', 'valor_pagar', 'saldo', 'data_vencimento']}
      clearSelection
    >
      <QCXJustifiedActionFormDialog
        handleSubmit={handleCancelSubmit}
        status={dialogCancelStatus}
        handleStatus={handleConsultCancelStatus}
        type={contasPagarUtils.CANCELAR}
        initialValues={relatedContasPagarItem}
      />
      <QCXRelatoriosContasDialogForm
        handleFormStatus={handleFormDialogImpressaoStatus}
        handleOnSubmit={(event) => handleImprimirByDateSubmit(event)}
        formDialogStatus={formDialogImpressaoStatus}
        initialValues={reportRelatedModel}
        typeReport={contasPagarUtils.CONTAS_PAGAR}
      />

      <QCXConfirmPaymentDateForm
        handleFormStatus={handleFormDialogonfirmBatchApprovalStatus}
        handleOnSubmit={(event) => handleConfirmBatchApproval(event)}
        formDialogStatus={formDialogConfirmBatchApprovalStatus}
        initialValues={{
          ...reportRelatedModel,
          valorParcial,
        }}
        isSingleItemSelected={false}
      />
      <QCXDocumentosFollowUpDialogManager
        model={selectedContaPagar?.followUp}
        status={formDialogDocumentosStatus}
        handleStatus={handleFormDialogDocumentosStatus}
      />

      <ConfirmationModal
        open={confirmModalOpen}
        message={confirmationMessage}
        singleAction={false}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title="Confirmação"
      />
    </SimpleConsultPageTemplate>
  );
}
