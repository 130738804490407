import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { 
  HighlightOff as CancelIcon,
  Delete as Trash,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState, useForm } from 'react-final-form';
import _ from 'lodash';

import useOperationMiddleware from '../../../utils/hooks/operation/middleware/useOperationMiddleware';
import QCXButton from '../../../shared-components/button/QCXButton';
import {
  selectIsLimparCamposActive,
  selectSizeFieldGrid,
  setModelLimparCamposGrid,
} from '../../../features/limpar-campos-state/limparCamposSlice';
import { 
  loading, 
  setModel, 
  resetStatus,
} from '../../../features/declaracao-importacao/declaracaoImportacaoSlice';

import {
  selectDeclaracaoImportacao,
  selectMode,
} from '../../../features/declaracao-importacao/declaracaoImportacaoSelectors';

import {
  isConsultMode,
} from '../../../utils/store/store-utils';

import QCXConfirmDialog from '../../../shared-components/dialog/QCXConfirmDialog';

import { setErrorFeedback, setSuccessFeedback } from '../../../features/feedback/feedbackSlice';


const useStyles = makeStyles((theme) => ({
  gridField: {
    paddingBottom: '16px',
  },
  checkboxGridField: {
    paddingBottom: '8px',
  },
  leftSiblingGridField: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
  rightSiblingGridField: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '8px',
    },
  },
  sibling: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
      marginTop: '8px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
}));


export default function InputWithCleanButton({children}) {

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const mode = useSelector(selectMode);
  const isConsult = useMemo(() => isConsultMode(mode), [mode]);


  useEffect(() => {
    console.log("====> ", isConsult)
  }, [isConsult])

  const isLimparCamposActive = useSelector(selectIsLimparCamposActive);
  const sizeFieldGrid = useSelector(selectSizeFieldGrid);
  const nameComponente = children?.props?.name;
  const label = children?.props?.label;

  const form = useForm();

  const faturaDiDuimp = useSelector(selectDeclaracaoImportacao);
  const limparCampo = async () => {
    const currentStateForm = form.getState();

    let nameField = nameComponente;
    const nameSplited = nameComponente.split('.');
    
    if (nameSplited.length > 0 && nameSplited[nameSplited.length - 1] === 'id') {
      nameSplited.pop();
      nameField = nameSplited.join('.');
    }

    const {
      meta : selecionadas,
    } = currentStateForm?.values;

    if (!selecionadas) {
      dispatch(
        setErrorFeedback({
          message: "Nenhuma adição selecionada !!",
        })
      );
      return ;
    }

    const selectedAdicoesId = [...selecionadas?.selected].map((selectedAdicao) => selectedAdicao.id);
    const adicoesSelecionadas = faturaDiDuimp?.atributosAdicionais?.adicoes?.filter((adicao) => selectedAdicoesId.includes(adicao.id));
    
    if (!adicoesSelecionadas || !_.isArray(adicoesSelecionadas)) return ; //! TRATAR CASO

    const adicoesAtualizadas = adicoesSelecionadas.map(adicaoBase => {
      
      if (!selectedAdicoesId.includes(adicaoBase.id)) {
        return adicaoBase;
      }

      const clonedData = _.cloneDeep(adicaoBase);

      
      if(_.get(clonedData, nameField)) {
        _.set(clonedData, nameField, null);
      } else {
        _.set(clonedData, nameField, null);
      }

      return clonedData;
    })

    dispatch(setModel({
      ...faturaDiDuimp,

      atributosAdicionais : {
        ...faturaDiDuimp.atributosAdicionais,
        adicoes: [...adicoesAtualizadas],
      },
    }));

    dispatch(
      setSuccessFeedback({
        message: "Operação realizada com sucesso !!",
      })
    );
    
  }

  const [handleReplicateOperation, operationOfReplicate] = useOperationMiddleware(limparCampo, []);

  const handleReplicateOnSubmitMiddleware = useCallback(
    (data) => {
      const configureOperation = () => ({
        options: {
          title: "Deseja limpar o campo ?",
          message: `Ao confirmar a operação sera repassado vazio para o campo: ${label}`,
        },
      });

      handleReplicateOperation(configureOperation, data);
    },
    [handleReplicateOperation]
  );

  return (
    <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: 12 }}>
      <Box width="100%">{children}</Box>

      { !isConsult && isLimparCamposActive && (
          <QCXButton
            variant="outlined"
            onClick={() => handleReplicateOnSubmitMiddleware()}
            startIcon={<Trash />}
            disabled={false}
            style={{ width: "90px", flexShrink: 0 }}
          >
            {`Limpar`.toUpperCase()}
          </QCXButton>
        
      )}   

      <QCXConfirmDialog
        key="confirm-dialog-remove-adicao"
        id="confirm-dialog-remove-adicao"
        open={operationOfReplicate?.active}
        title={operationOfReplicate?.title}
        content={operationOfReplicate?.message}
        endContent={operationOfReplicate?.endMessage}
        onConfirm={operationOfReplicate.confirm}
        onClose={operationOfReplicate?.reset}
      />
    </Box>
  )

}