import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';

import {
  register,
  save,
} from '../../../../../features/modelo-tabela-sda/modeloTabelaSdaAPI';
import {
  fetchByIdAsync,
  addToList,
  updateOnList,
  resetModel,
  setModel,
  setError,
  setResponse,
  loading,
  success,
  failure,
  preparingAction,
  selectModeloTabelaSda,
  selectMode,
  selectStatus,
  selectBackgroundMode,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetBackgroundMode,
  resetMode,
  resetSubMode,
} from '../../../../../features/modelo-tabela-sda/modeloTabelaSdaSlice';
import {
  isNoneMode,
  isCreateMode,
  isConsultMode,
  isUpdateMode,
  isBackgroundCreateMode,
  isPreparingActionStatus,
} from '../../../../../utils/store/store-utils';

import QCXModeloTabelaSdaWizardFinalForm from '../../../../../components/modelo-tabela-sda/QCXModeloTabelaSdaWizardFinalForm';

export default function ModeloTabelaSDARegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const status = useSelector(selectStatus);
  const modeloTabelaSda = useSelector(selectModeloTabelaSda);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);
  const isCreate = useMemo(() => isCreateMode(mode), [mode]);
  const isConsult = useMemo(() => isConsultMode(mode), [mode]);
  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);
  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);
  const isPreparing = useMemo(() => isPreparingActionStatus(status), [status]);

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      dispatch(changeToConsultMode());
    } else {
      dispatch(changeToCreateMode());
    }
  }, [id, dispatch]);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, [dispatch]);
  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, [dispatch]);
  const handleChangeToUpdate = useCallback(() => {
    dispatch(changeToUpdateMode());
  }, [dispatch]);
  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, [dispatch]);
  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(preparingAction());
  }, [dispatch]);

  const handleCancelUpdate = useCallback(() => {
    const currentId = isBackgroundCreate ? modeloTabelaSda?.id : id;
    if (currentId) dispatch(fetchByIdAsync(currentId));
    handleChangeToConsult();
  }, [id, modeloTabelaSda, isBackgroundCreate, dispatch, handleChangeToConsult]);

  const normalize = useCallback((data) => {
    const { informacoes, ...others } = data || {};
    if (!informacoes || !Array.isArray(informacoes)) {
      return { ...others, informacoes: '' };
    }
    const sorted = [...informacoes].sort((a, b) => a.ordem - b.ordem);
    const descs = sorted.map((item) => item.descricao);
    const joined = descs.join(',');
    return { ...others, informacoes: joined };
  }, []);

  const unnormalize = (data) => {
    if (!data) return {};
    const { informacoes, ...others } = data;
    if (!informacoes) {
      return { ...others, informacoes: [] };
    }
    let splitted = [];
    if (!Array.isArray(informacoes)) {
      splitted = informacoes.split(',');
    }
    const arr = splitted.map((desc, idx) => ({
      id: `temp-${idx}-${uuid()}`,
      ordem: idx + 1,
      descricao: desc.trim(),
    }));
    return { ...others, informacoes: arr };
  };

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(data));
  }, [dispatch]);

  const createSingleStep = async (formData) => {
    try {
      const response = await register(formData);
      if (response?.status === 201) {
        dispatch(resetModel());
        dispatch(success());
        const created = response?.data;
        handleDispatchSetModel(unnormalize(created));
        dispatch(addToList({ data: created }));
        setIsCreated(true);
        history.push(t('com.muralis.qcx.url.modulosGeraisModeloTabelaSDA'));
      }
    } catch (err) {
      dispatch(failure());
      dispatch(setError({
        message: err?.response?.data?.message || err.message,
      }));
    }
  };

  const update = async (formData, forceRefresh = false) => {
    try {
      const response = await save(formData);
      if (response?.status === 200) {
        dispatch(success());
        const saved = response?.data;
        dispatch(setResponse({
          status: response.status,
          data: saved,
          message: 'Modelo Tabela SDA atualizado com sucesso!',
        }));
        if (forceRefresh) {
          dispatch(fetchByIdAsync(formData?.id));
        } else {
          dispatch(setModel(unnormalize(saved)));
        }
        dispatch(updateOnList({ data: saved }));
        handleChangeToConsult();
        history.push(t('com.muralis.qcx.url.modulosGeraisModeloTabelaSDA'));
      }
    } catch (err) {
      dispatch(failure());
      dispatch(setError({
        message: err?.response?.data?.message || err.message,
      }));
    }
  };

  const handleSubmitFinal = async (values) => {
    const normalizedData = normalize(values);
    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
    } else {
      await createSingleStep(normalizedData);
    }
  };

  const model = useMemo(() => {
    if (isCreated || !isCreate) {
      return unnormalize(modeloTabelaSda);
    }
    const initialModel = {
      id: '',
      description: '',
      active: null,
      insertionDate: null,
      tipo: '',
      informacoes: [], 
    };
    return initialModel;
  }, [isCreated, isCreate, modeloTabelaSda]);

  const actionName = useMemo(() => {
    if (isNone || isCreate) return t('com.muralis.qcx.acoes.nova');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const pageTitle = useMemo(() => {
    if (isNone || isCreate || isBackgroundCreate) {
      return 'Novo Modelo de Tabela SDA';
    }
    return `Modelo de Tabela SDA: ${modeloTabelaSda?.description || modeloTabelaSda?.nome || modeloTabelaSda?.id || '-'}`;
  }, [isNone, isCreate, isBackgroundCreate, modeloTabelaSda]);

  const breadcrumbs = useMemo(() => ([
    { link: { to: '/', name: t('com.muralis.qcx.inicio') } },
    { text: { name: t('com.muralis.qcx.cadastros') } },
    { link: { to: t('com.muralis.qcx.url.moduloCadastrosGerais'), name: t('com.muralis.qcx.gerais') } },
    { text: { name: t('com.muralis.qcx.servico.labelPlural') } },
    { link: { to: t('com.muralis.qcx.url.modulosGeraisModeloTabelaSDA'), name: 'Modelos de Tabela SDA' } },
    { text: { name: actionName } },
  ]), [actionName, t]);

  return (
    <Form onSubmit={handleSubmitFinal} initialValues={model}>
      {({ handleSubmit, form, values }) => (
        <QCXRegistrationFormPageTemplate
          pageTitle={pageTitle}
          breadcrumbs={breadcrumbs}
          breadcrumbsProps={{ maxItems: 6 }}
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isBackgroundCreate={isBackgroundCreate}
          isPreparingAction={isPreparing}
          handleAlternativeSave={(e) => handleSubmitFinal(values)}
          handleChangeToPreparingAction={handleChangeToPreparingAction}
          handleChangeToCreate={handleChangeToCreate}
          handleChangeToConsult={handleChangeToConsult}
          handleChangeToUpdate={handleChangeToUpdate}
          handleCancelUpdate={handleCancelUpdate}
          handleResetBackgroundMode={handleResetBackgroundMode}
          showSubtitle={false}
          authInfo={authInfo}
        >
          {(formProps) => (
            <QCXModeloTabelaSdaWizardFinalForm
              model={values}
              handleChangeModel={(updatedModel) => {
                const newModel = {
                  id: updatedModel.informacoes.id,
                  active: updatedModel.informacoes.active,
                  insertionDate: updatedModel.informacoes.insertionDate,
                  description: updatedModel.informacoes.description,
                  tipo: updatedModel.informacoes.tipo,
                  informacoes: updatedModel.informacoes.informacoes,
                };
                form.change('informacoes', newModel.informacoes);
                form.change('id', newModel.id);
                form.change('active', newModel.active);
                form.change('insertionDate', newModel.insertionDate);
                form.change('description', newModel.description);
                form.change('tipo', newModel.tipo);
                console.log('Modelo atualizado:', newModel);
              }}
              handleSubmit={handleSubmit}
              authInfo={authInfo}
              requiredRoles={['tabela-sda']}
              {...formProps}
            />
          )}
        </QCXRegistrationFormPageTemplate>
      )}
    </Form>
  );
}
