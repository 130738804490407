import React, { useContext, useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Receipt as ReceiptIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import ImportSdaContext from '../../../../contexts/registrations/import-sda/ImportSdaContext';
import { fetchAllAsync, selectImportSdaList } from '../../../../features/import-sda/importSdaSlice';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { formatCnpj } from '../../../../utils/hooks/form/field/formatters';
import { formatDate } from '../../../../utils/general/general-utils';
import QCXDatePicker from '../../../../shared-components/date-picker/QCXDatePicker';
import QCXSelectDespachanteAutocomplete from '../../../../shared-components/select-despachante/QCXSelectDespachanteAutocomplete';
import QCXSelectModeloTabelaSdautocomplete from '../../../../shared-components/select-modelo-tabela-sda/QCXSelectModeloTabelaSdaAutocomplete';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { useKeycloak } from '@react-keycloak/web';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import { selectUnidadeSelecionada } from '../../../../features/usuario-logado/usuarioLogadoSlice';
import QCXFinalTextField from '../../../../shared-components/final-text-field/QCXFinalTextField';
import { focusLockActions } from '../../../../features/focus-lock/focusLockSlice';

export default function SDAImportConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const KEYCLOAK_TOKEN_TIMEOUT = 10;

  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const tenant = useSelector(selectUnidadeSelecionada);
  useUnidadeNegocioGuard();


  const handleFocusInput = () => {
    dispatch(focusLockActions.unlock()); 
  };

  const handleBlurInput = () => {
    dispatch(focusLockActions.lock()); 
  };

  const importSdaList = useSelector(selectImportSdaList);
  const { status } = useContext(ImportSdaContext);
  const isLoading = useMemo(() => status === 'ALTERNATIVE_LOADING_STATUS', [status]);

  const breadcrumbs = [
    { link: { to: '/', name: t('com.muralis.qcx.inicio') } },
    { text: { name: 'Listagem SDA Import' }, default: true },
  ];

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.processo'),
        align: 'center',
        headerAlign: 'center',
        flex: 150,
        valueGetter: ({ row }) => row?.processo || '-',
      },
      {
        field: 'cliente',
        headerName: t('com.muralis.qcx.importador.label'),
        align: 'center',
        headerAlign: 'center',
        flex: 150,
        valueGetter: ({ row }) => row?.cliente || '-',
      },
      {
        field: 'cnpj',
        headerName: t('com.muralis.qcx.empresa.CNPJ'),
        align: 'center',
        headerAlign: 'center',
        flex: 120,
        valueGetter: ({ row }) => (row?.cnpj ? formatCnpj(row.cnpj) : '-'),
      },
      {
        field: 'valorSda',
        headerName: 'Valor SDA',
        align: 'center',
        headerAlign: 'center',
        flex: 100,
        valueGetter: ({ row }) =>
          row?.valorSda != null ? row.valorSda.toFixed(2) : '-',
      },
      {
        field: 'dataAbertura',
        headerName: t('com.muralis.qcx.dataAbertura'),
        align: 'center',
        headerAlign: 'center',
        flex: 100,
        valueGetter: ({ row }) =>
          row?.dataAbertura ? row.dataAbertura : '-',
      },
      {
        field: 'dataRegistro',
        headerName: t('com.muralis.qcx.dataRegistro'),
        align: 'center',
        headerAlign: 'center',
        flex: 100,
        valueGetter: ({ row }) =>
          row?.dataRegistro ? row.dataRegistro : '-',
      },
      {
        field: 'transmitida',
        headerName: 'Transmitida',
        align: 'center',
        headerAlign: 'center',
        flex: 80,
        valueGetter: ({ row }) => (row?.transmitida ? 'Sim' : 'Não'),
      },
      {
        field: 'id',
        headerName: 'Código',
        align: 'center',
        headerAlign: 'center',
        flex: 150,
        valueGetter: ({ row }) => row?.id || '-',
      },
    ],
    [t]
  );

  const filterPropGetter = useCallback(
    (row) => `${row.processo} ${row.cliente} ${row.cnpj}`,
    []
  );

  const [batchTransmitMode, setBatchTransmitMode] = useState(false);
  const [selectedSdaIds, setSelectedSdaIds] = useState([]);
  const [retransmitir, setRetransmitir] = useState(false);
  const [dataValidade, setDataValidade] = useState(null);
  const [despachante, setDespachante] = useState(null);
  const [modeloTabelaSda, setModeloTabelaSda] = useState(null);
  const [formValues, setFormValues] = useState({});

  const handleSelectionModelChange = useCallback((newSelection) => {
    setSelectedSdaIds(newSelection);
  }, []);

  const handleTransmitSelected = useCallback(async (values) => {
    if (!selectedSdaIds || (Array.isArray(selectedSdaIds) && selectedSdaIds.length === 0)) {
      alert("Selecione pelo menos um processo para gerar o TXT.");
      return;
    }
    if (!despachante) {
      dispatch(
        setErrorFeedback({
          message: "O campo 'Despachante' é obrigatório.",
        })
      );
      return;
    }
    if (!modeloTabelaSda) {
      dispatch(
        setErrorFeedback({
          message: "O campo 'Modelo de Tabela SDA' é obrigatório.",
        })
      );
      return;
    }

    const ids =
      selectedSdaIds && selectedSdaIds.selectionModel
        ? selectedSdaIds.selectionModel
        : selectedSdaIds;

    const formattedDate = dataValidade ? dataValidade.format("DD/MM/YYYY") : null;

    const payload = {
      idModeloSda: modeloTabelaSda?.id,
      dataValidade: formattedDate,
      idDespachante: despachante?.id,
      idProcessos: ids,
      tipo: "IMPORTACAO"
    };

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          quickcomexTenant: tenant,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      };
      const baseUrl = `${process.env.REACT_APP_QCX_API_BASE_URL}/tabelasda/gerar-txt`;
      const response = await axios.post(`${baseUrl}`, payload, config);
      console.log('TXT gerado com sucesso', response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${values.nomeArquivo}.txt`);
      document.body.appendChild(link);
      link.click();

      dispatch(
        setSuccessFeedback({
          message: "Arquivo gerado com sucesso!",
        })
      );
      dispatch(fetchAllAsync('IMPORTACAO'));
    } catch (error) {
      console.error('Erro ao gerar TXT', error);
      dispatch(
        setErrorFeedback({
          message: "Erro ao gerar arquivo TXT.",
        })
      );
    }
  }, [dataValidade, despachante, modeloTabelaSda, selectedSdaIds, dispatch, token, tenant]);


  const controlButtons = useMemo(() => {
    const baseButtons = batchTransmitMode
      ? [
        {
          description: 'Confirmar Transmitir',
          color: 'secondary',
          onClick: () => handleTransmitSelected(formValues),
          disabled: selectedSdaIds.length === 0,
        },
        {
          description: 'Cancelar',
          variant: 'outlined',
          onClick: () => {
            setBatchTransmitMode(false);
          },
        },
      ]
      : [
        {
          description: 'TRANSMITIR',
          color: 'primary',
          onClick: () => {
            setBatchTransmitMode(true);
          },
        },
      ];
    return baseButtons;
  }, [batchTransmitMode, handleTransmitSelected, selectedSdaIds.length, formValues]);

  const filteredSdaList = useMemo(() => {
    return retransmitir
      ? importSdaList.filter(item => item.transmitida)
      : importSdaList.filter(item => !item.transmitida);
  }, [importSdaList, retransmitir]);

  const templateProps = {
    page: {
      title: 'Listagem SDA Import',
      icon: <ReceiptIcon />,
      breadcrumbs,
    },
    control: {
      others: controlButtons,
    },
    table: {
      columns,
      rowsCount: filteredSdaList.length,
      pageSize: 10,
      pagination: true,
      checkboxSelection: batchTransmitMode,
      onSelectionModelChange: handleSelectionModelChange,
    },
  };

  useEffect(() => {
    dispatch(fetchAllAsync('IMPORTACAO'));
  }, [dispatch]);

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={filteredSdaList}
      isLoading={isLoading}
      filterPropGetter={filterPropGetter}
      authInfo={authInfo}
      requiredRoles={['importacao-sda']}
    >
      <Form onSubmit={(values) => { handleTransmitSelected(values) }} initialValues={{ despachante: null, dataValidade: null, modeloTabelaSda: null, nomeArquivo: "SDA" }}>
        {() => (
          <form>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                React.useEffect(() => {
                  setFormValues(values);
                }, [values]);
                return null;
              }}
            </FormSpy>
            <div style={{ marginTop: 30, padding: '0 20px' }}>
              {/* Primeira linha: Data de Validade e Despachante */}
              <div style={{ display: 'flex', gap: 20 }}>
                <div style={{ flex: 1 }}>
                  <QCXDatePicker
                    label="Data de Vencimento"
                    name="dataValidade"
                    value={dataValidade}
                    onChange={(date) => setDataValidade(date)}
                    format="DD/MM/YYYY"
                    placeholder="DD/MM/YYYY"
                    disablePast={false}
                    disableFuture={false}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <QCXSelectDespachanteAutocomplete
                    label="Despachante"
                    name="despachante"
                    value={despachante}
                    onChange={(event, newValue) => setDespachante(newValue)}
                  />
                </div>
              </div>
              {/* Segunda linha: Modelo de Tabela SDA e RETRANSMITIR */}
              <div style={{ display: 'flex', gap: 20, marginTop: 20 }}>
                <div style={{ flex: 1 }}>
                  <QCXSelectModeloTabelaSdautocomplete
                    label="Modelo de Tabela SDA"
                    name="modeloTabelaSda"
                    value={modeloTabelaSda}
                    onChange={(event, newValue) => setModeloTabelaSda(newValue)}
                    tipo="IMPORTACAO"
                  />
                </div>
                <div style={{ marginTop: 0 }}>
                  <QCXFinalTextField
                    name="nomeArquivo"
                    label="Nome do Arquivo"
                    onFocus={handleFocusInput}
                    onBlur={handleBlurInput}
                    placeholder="Digite o nome do arquivo"
                  />
                </div>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={retransmitir}
                        onChange={(e) => setRetransmitir(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="RETRANSMITIR"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </QCXSimpleConsultPageTemplate>
  );
}
