// src/features/vinculo-pagos-pelo-cliente/vinculoPagosPeloClienteSlice.js

import { createSlice } from '@reduxjs/toolkit';
import {
  NONE_MODE,
  CREATE_MODE,
  CONSULT_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  IDLE_STATUS,
  SUCCESS_STATUS,
  LOADING_STATUS,
  FAILURE_STATUS,
} from '../status';

// Estado inicial
const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
  },
  model: {},
};

const vinculoPagosPeloClienteSlice = createSlice({
  name: 'vinculoPagosPeloCliente',
  initialState,
  reducers: {
    // status
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },

    // modo
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },

    // model
    setModel: (state, action) => {
      state.model = action.payload;
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
  },
});

export const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  setModel,
  resetModel,
} = vinculoPagosPeloClienteSlice.actions;

export const vinculoPagosPeloClienteActions = {
  ...vinculoPagosPeloClienteSlice.actions,
};

// Selectors
export const selectStatus = (state) => state.vinculoPagosPeloCliente.status;
export const selectMode = (state) => state.vinculoPagosPeloCliente.mode.main;
export const selectModel = (state) => state.vinculoPagosPeloCliente.model;

export const vinculoPagosPeloClienteSelectors = {
  selectStatus,
  selectMode,
  selectModel,
};

export default vinculoPagosPeloClienteSlice.reducer;
