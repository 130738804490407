/* eslint-disable react/destructuring-assignment */
import { Grid, makeStyles } from '@material-ui/core';
import { Block as BlockIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TabManagerContext from '../../contexts/components/tab-manager/TabManagerContext';
import { SUB_CONSULT_MODE } from '../../features/mode';
import QCXButton from '../../shared-components/button/QCXButton';
import useReadOnlyForm from '../../utils/hooks/form/mode/useReadOnlyForm';

const useStyles = makeStyles((theme) => ({
  controlButton: {
    marginRight: theme.spacing(1),
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
}));

export const DEFAULT_BUTTON_GROUP_OPTIONS = {
  remove: {
    visibleOn: [SUB_CONSULT_MODE],
  },
};

export default function QCXCustomFormTabControlButtonsGroup({
  isCreate,
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  submitting,
  pristine,
  options = DEFAULT_BUTTON_GROUP_OPTIONS,
  handleEdit,
  handleCancelUpdate,
  handleCancelCreate,
  handleRemoveByForm,
  disableUpdate: disableUpdateProp,
  disabled,
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const tabManager = useContext(TabManagerContext);
  const { disableUpdate } = useReadOnlyForm();

  const defaultButtonGroupOptions = useMemo(
    () => (options && !isEmpty(options) ? options : DEFAULT_BUTTON_GROUP_OPTIONS),
    [options]
  );

  const isRemoveButtonVisibleOnlySubNoneOrSubConsultMode = useMemo(
    () => (isSubNone || isSubConsult) && defaultButtonGroupOptions?.remove?.visibleOn?.includes(SUB_CONSULT_MODE),
    [isSubNone, isSubConsult, defaultButtonGroupOptions]
  );

  const handleChangeToNextTab = useCallback(() => {
    tabManager.changeToNext();
  }, [tabManager]);

  const handleChangeToPreviousTab = useCallback(() => {
    tabManager.changeToPrevious();
  }, [tabManager]);

  return (
    <Grid container spacing={2} justify={disableUpdate ? 'flex-end' : 'space-between'} style={{ marginTop: '1.5rem' }}>
      {!disableUpdate && (
        <Grid item>
          {isSubCreate && !isConsult && (
            <QCXButton
              className={classes.controlButton}
              variant="outlined"
              startIcon={<BlockIcon />}
              disabled={disabled || isConsult}
              onClick={handleCancelCreate}
            >
              {t('com.muralis.qcx.acoes.cancelar')}
            </QCXButton>
          )}
          {(isCreate || isConsult || isUpdate) && isSubConsult && (
            <QCXButton
              className={classes.controlButton}
              color="secondary"
              onClick={handleEdit}
              disabled={disableUpdateProp || disableUpdate || disabled || !(isCreate || isConsult || isUpdate)}
            >
              {t('com.muralis.qcx.acoes.alterar')}
            </QCXButton>
          )}
          {isSubUpdate && (
            <QCXButton
              className={classes.controlButton}
              variant="outlined"
              startIcon={<BlockIcon />}
              onClick={handleCancelUpdate}
              disabled={disabled}
            >
              {t('com.muralis.qcx.acoes.cancelar')}
            </QCXButton>
          )}
          {isSubUpdate && (
            <QCXButton
              className={classes.controlButton}
              color="secondary"
              type="submit"
              disabled={disabled || submitting || pristine}
            >
              {t('com.muralis.qcx.acoes.salvar')}
            </QCXButton>
          )}
          {tabManager.activeTab > 0 && isSubCreate && !isConsult && (
            <QCXButton
              className={classes.controlButton}
              color="secondary"
              type="submit"
              disabled={disabled || submitting || isConsult}
            >
              {t('com.muralis.qcx.acoes.adicionar')}
            </QCXButton>
          )}
          {isRemoveButtonVisibleOnlySubNoneOrSubConsultMode && (
            <QCXButton
              className={classes.controlButton}
              variant="outlined"
              startIcon={<DeleteIcon color="inherit" />}
              style={{
                fontWeight: 550,
              }}
              disabled={disabled || !isSubConsult}
              onClick={handleRemoveByForm}
            >
              {t('com.muralis.qcx.acoes.remover')}
            </QCXButton>
          )}
        </Grid>
      )}
      <Grid item>
        {tabManager.activeTab > 1 && (isSubCreate || isSubConsult || isSubUpdate) && (
          <QCXButton
            className={classes.controlButton}
            variant="outlined"
            onClick={handleChangeToPreviousTab}
            disabled={disabled || tabManager.onlyActiveTabEnabled}
          >
            {t('com.muralis.qcx.acoes.voltar')}
          </QCXButton>
        )}
        {!tabManager.lastTab && (isSubCreate || isSubConsult || isSubUpdate) && (
          <QCXButton
            className={classes.controlButton}
            color="secondary"
            onClick={handleChangeToNextTab}
            disabled={disabled || tabManager.onlyActiveTabEnabled}
          >
            {t('com.muralis.qcx.acoes.proximo')}
          </QCXButton>
        )}
      </Grid>
    </Grid>
  );
}
