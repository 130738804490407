import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalCheckboxField from '../final-checkbox-field/QCXFinalCheckboxField';
import InputWithCleanButton from '../../components/declaracao-importacao/inputWithCleanButton/InputWithCleanButton'

export default function QCXAFRMMFormGroup({ isConsult, isSubConsult, isSubNone }) {
  const { t } = useTranslation();
  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalAlternativeCurrencyField
          id="valor-devido-reais-field-icms"
          key="valor-devido-reais-field-icms"
          name="afrmm.valorDevidoReal"
          label={t('com.muralis.qcx.valorDevidoReais')}
          disabled
          adorned
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalAlternativeCurrencyField
          id="valor-recolher-reais-field-icms"
          key="valor-recolher-reais-field-icms"
          name="afrmm.valorRecolherReal"
          label={t('com.muralis.qcx.valorRecolherReais')}
          disabled
          adorned
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <InputWithCleanButton>
          <QCXFinalCheckboxField
            id="checkbox-ipi-nao-tributavel-field-ipi"
            key="checkbox-ipi-nao-tributavel-field-ipi"
            name="afrmm.afrmmIsento"
            label={t('com.muralis.qcx.AFRMMIsento')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </InputWithCleanButton>
      </Grid>
    </>
  );
}
