import { useState } from 'react';
import { Grid, Box, FormControl, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalDatePickerField from '../../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalAlternativeCurrencyField from '../../../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField'
import QCXSelectClienteAutocomplete from '../../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectContaBancariaAutocompleteField from '../../../../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField.js';
import QCXTextField from '../../../../../shared-components/text-field/QCXTextField';

export default function ConciliacaoBancariaFilterComponent({
  origem,handleOrigemChange,
  extrato,handleExtratoChange,
  consolidada,handleConsolidadaChange}) {

 const { t } = useTranslation();

    return (  
        <Box style={{ minWidth: '500px' }}>
        <Grid container title="Seção que contém informações do usuário" spacing={2}>

        <Grid item xs={12} sm={12} md={12}>
            <QCXTextField
            select
            label="Origem"
            name="origem"
            value={origem}
            onChange={handleOrigemChange}
            fullWidth
            maxLength={255}
            helperText=""
            meta={{}}
            disabled={false}
            >
            <MenuItem value="TODOS">TODOS</MenuItem>
            <MenuItem value="NUMERARIO">NUMERARIO</MenuItem>
            <MenuItem value="FATURAMENTO">FATURAMENTO</MenuItem>
            <MenuItem value="OPERACIONAL">OPERACIONAL</MenuItem>
            <MenuItem value="ADMINISTRATIVA">ADMINISTRATIVA</MenuItem>
            <MenuItem value="TRANSFERENCIA_SALDO">TRANSFERENCIA_SALDO</MenuItem>
            <MenuItem value="REGISTRO_DI">REGISTRO_DI</MenuItem>
            </QCXTextField>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
                <QCXSelectContaBancariaAutocompleteField
                  id="autocomplete-select-conta-bancaria-pagamento-field"
                  key="autocomplete-select-conta-bancaria-pagamento-field"
                  name="contaBancariaPagamento"
                  label="CONTA PAGAMENTO"
                  initialValues={undefined}
                />
          </Grid>
           <Grid item sm={12}>
            <QCXSelectClienteAutocomplete
                id="text-field-cliente"
                key="text-field-cliente"
                name="cliente"
                label={t('com.muralis.qcx.contas_receber.filter.cliente')}
                required={false}
                initialValues={undefined}
            />
            </Grid>
          <Grid item xs={12} sm={12} md={12}>
                <QCXFinalAlternativeCurrencyField
                  id="financeiro-valor-desconto-field"
                  key="financeiro-valor-desconto-field"
                  name="valor_receber"
                  label={t('com.muralis.qcx.contas_receber.filter.valor_receber')}
                  onBlur={undefined}
                  disabled={undefined}
                />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <QCXTextField
                  select
                  label="Extrato"
                  value={extrato}
                  name="extrato"
                  onChange={handleExtratoChange}
                  fullWidth
                  maxLength={255}
                  helperText=""
                  meta={{}}
                  disabled={false}
                >
                  <MenuItem value="TODOS">TODOS</MenuItem>
                  <MenuItem value="SAIDA">DÉBITO</MenuItem>
                  <MenuItem value="ENTRADA">CREDITO</MenuItem>
                </QCXTextField>
              </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <QCXTextField
                  select
                  label="Status"
                  name="status"
                  value={consolidada}
                  onChange={handleConsolidadaChange}
                  fullWidth
                  maxLength={255}
                  helperText=""
                  meta={{}}
                  disabled={false}
                >
                  <MenuItem value="TODOS">TODOS</MenuItem>
                  <MenuItem value="CONCILIADA">CONSOLIDADA</MenuItem>
                  <MenuItem value="NAO_CONCILIADA">NÃO CONSOLIDADA</MenuItem>                  
                </QCXTextField>
              </FormControl>
          </Grid>
          <Grid item sm={12}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.contas_receber.filter.data_pagamento')}
              name="data_vencimento"
              required={undefined}
              validate={undefined}
            />
          </Grid>
        </Grid>
      </Box>
    );
}