import { Box, Grid, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { useForm, useFormState } from 'react-final-form';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import SelectTipoRateioDespesasAcessorias from './SelectTipoRateio';

export default function QCXDespesasAcessoriasFormGroup({ isConsult, valorValidators, handleValorEmReaisOnBlur, intl }) {
  const form = useForm();
  const { values } = useFormState();
  return (
    <>
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box display="flex" alignItems="center" style={{ gap: 12 }}>
          <QCXFormSubtitle title="Despesas Acessórias" />
          <Box display="flex" alignItems="center" style={{ gap: 4 }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <InfoIcon verticalAlign="middle" color="secondary" />
            </Box>
            <Box>
              <Typography color="secondary" style={{ textTransform: 'uppercase', fontSize: 12 }}>
                Serão incluídas na base de cálculo do ICMS
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <SelectTipoRateioDespesasAcessorias
          id="select-tipo-rateio-despesas-acessorias-field"
          key="select-tipo-rateio-despesas-acessorias-field"
          name="despesasAcessorias.tipoRateioDespesasAcessorias"
          label="Tipo de Rateio das Despesas Acessórias"
          disabled={isConsult}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-moeda-despesasAcessorias-field"
          key="currency-valor-moeda-despesasAcessorias-field"
          name="despesasAcessorias.valorMoeda"
          label="Valor das Despesas Acessórias na Moeda"
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-real-moeda-despesasAcessorias-field"
          key="currency-valor-real-moeda-despesasAcessorias-field"
          name="despesasAcessorias.valorReal"
          label="Valor das Despesas Acessórias em Reais"
          onBlur={handleValorEmReaisOnBlur(
            form,
            values,
            'mle.moeda',
            'despesasAcessorias.valorMoeda',
            'despesasAcessorias.valorReal'
          )}
          intl={intl}
          adorned
          disabled={isConsult}
          validate={valorValidators}
        />
      </Grid>
    </>
  );
}
