// src/components/modelo-tabela-sda/QCXModeloTabelaSdaForm.jsx
import React from 'react';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXBox from '../../shared-components/box/QCXBox';
import { required } from '../../utils/validators/field/validator';
import QCXModeloTabelaSdaInformacoesFinalFormTable from './QCXModeloTabelaSdaInformacoesFinalFormTable';
import QCXFinalTextFieldAlt from '../../shared-components/final-text-field/QCXFinalTextFieldAlt';

export default function QCXModeloTabelaSdaForm({
  initialValues,
  handleSubmit,
  isCreate,
  isConsult,
  isUpdate,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
  handleInformacoesChange,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      {...restProps}
    >
      {({ form }) => (
        <>
          <Typography variant="h6" color="primary">
            <QCXBox fontWeight="fontWeightBold" pt={3}>
              {t('com.muralis.qcx.dadosTecnicos').toUpperCase()}
            </QCXBox>
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {/* Se não for create, mostra o ID */}
            {!isCreate && (
              <Grid item xs={12} sm={6} md={3}>
                <QCXFinalTextField
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            {/* Descrição */}
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalTextField
                name="description"
                label={t('com.muralis.qcx.descricao')}
                disabled={isConsult}
                validate={required}
                onChange={(e) => {
                  const currentValues = form.getState().values;
                  const newModel = { ...currentValues, description: e.target.value };
                  if (handleInformacoesChange) {
                    handleInformacoesChange(newModel);
                  }
                }}
                required
              />
            </Grid>
            {/* Tipo */}
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalTextFieldAlt
                name="tipo"
                label="Tipo"
                select
                disabled={isConsult}
                validate={required}
                onChange={(e) => {
                  const currentValues = form.getState().values;
                  const newModel = { ...currentValues, tipo: e.target.value };
                  if (handleInformacoesChange) {
                    handleInformacoesChange(newModel);
                  }
                }}
                required
              >
                <MenuItem value="IMPORTACAO">IMPORTACAO</MenuItem>
                <MenuItem value="EXPORTACAO">EXPORTACAO</MenuItem>
              </QCXFinalTextFieldAlt>
            </Grid>
          </Grid>
          {/* Seção de Informações */}
          <Grid container spacing={2} style={{ marginTop: '2rem' }}>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                <QCXBox fontWeight="fontWeightBold" pt={2}>
                  {'INFORMAÇÕES'.toUpperCase()}
                </QCXBox>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <QCXModeloTabelaSdaInformacoesFinalFormTable
                list={initialValues.informacoes || []}
                isConsult={isConsult}
                handleChange={(updatedList) => {
                  const currentValues = form.getState().values;
                  const newModel = { ...currentValues, informacoes: updatedList };
                  if (handleInformacoesChange) {
                    handleInformacoesChange(newModel);
                  }
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
