import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Grid, makeStyles,
} from '@material-ui/core';
import { Box } from '@mui/material';
import {
  Label as LabelIcon,
  Business as BusinessIcon,
  AccountCircle as AccountCircleIcon,
  ListAlt as ListAltIcon,
} from '@material-ui/icons';
import {
  TableView as TableViewIcon,
  Paid as PaidIcon,
  AddTask as AddTaskIcon,
  AltRoute as AltRouteIcon,
  ModelTraining as ModelTrainingIcon,
  Assignment as AssignmentIcon,
  TableChart as TableChartIcon,
  Addchart as AddchartIcon,
} from '@mui/icons-material';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { HiOutlineUserGroup } from 'react-icons/hi';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useDispatch } from 'react-redux';
import QCXMainLayout from '../../../../components/main-layout/QCXMainLayout';
import QCXFocusLock from '../../../../components/focus-lock/QCXFocusLock';
import { focusLockActions } from '../../../../features/focus-lock/focusLockSlice';
import { CADASTROS_GERAIS_MODULE } from '../../../../utils/general/modules';
import QCXCardMenu from '../../../../shared-components/card-menu/QCXCardMenu';
import QCXCardMenuWithSubmenu from '../../../../shared-components/card-menu/QCXCardMenuWithSubmenu';

const useStyles = makeStyles((theme) => ({
  cardMenuContainer: {
    marginTop: theme.spacing(0),
  },
  cardMenu: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subNested: {
    paddingLeft: theme.spacing(8),
  },
  active: {
    display: 'flex!important',
  },
  noActive: {
    display: 'none!important',
    transformOrigin: '0 0 0!important',
  },

}));

export default function GeneralRegistrationsPage({
  authInfo = {},
}) {
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.gerais'),
      },
    },
  ];
  const userHasAnyRole = (mainRoles = []) => authInfo.roles.some(
    (role) => mainRoles.find((item) => role.startsWith(item))
  );
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    location: {
      pathname: currentLocationPath,
    },
  } = useHistory();

  const [open, setOpen] = React.useState(false);
  const [openService, setOpenService] = React.useState(false);
  const toggleServicoSubMenu = () => {
    setOpenService((old) => !old);
    setOpen((old) => !old);
  };

  useEffect(() => {
    const handleFocusLock = () => {
      const currentPaths = currentLocationPath.split('/');

      const match = (modulePaths) => (
        modulePaths.every(
          (path) => currentPaths.includes(path)
        )
      );

      if (match(CADASTROS_GERAIS_MODULE)) {
        dispatch(focusLockActions.lock());
      }
    };

    handleFocusLock();
  }, [currentLocationPath]);

  return (
    <QCXMainLayout
      pageIcon={<LabelIcon />}
      pageTitle={t('com.muralis.qcx.cadastrosGerais')}
      breadcrumbs={breadcrumbs}
      authInfo={authInfo}
    >
      <Grid
        item
        container
        justify="flex-start"
        xs={12}
        sm={12}
        md={12}
        xl={12}
        className={classes.cardMenuContainer}
      >
        <QCXFocusLock>
          <Grid container style={{ maxWidth: '1920px' }}>
            {userHasAnyRole(['grupo']) && (
              <Grid
                item
                justify="flex-start"
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={`${open ? classes.noActive : classes.active}`}
              >
                <QCXCardMenu
                  title={t('com.muralis.qcx.gruposUnidadeNegocio')}
                  link={t('com.muralis.qcx.url.moduloGeraisGrupoUnidadeNegocio')}
                  icon={HiOutlineUserGroup}
                  openMenu={open}
                />

              </Grid>
            )}

            {userHasAnyRole(['unidade-de-negocio']) && (
              <Grid
                item
                justify="center"
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={`${open ? classes.noActive : classes.active}`}
              >
                <QCXCardMenu
                  title={t('com.muralis.qcx.unidadeNegocio.label')}
                  link={t('com.muralis.qcx.url.moduloGeraisUnidadeNegocio')}
                  icon={BusinessIcon}
                  openMenu={open}
                />
              </Grid>
            )}

            {userHasAnyRole(['usuario']) && (
              <Grid
                item
                justify="center"
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={`${open ? classes.noActive : classes.active}`}
              >
                <QCXCardMenu
                  title={t('com.muralis.qcx.usuarios')}
                  link={t('com.muralis.qcx.url.moduloGeraisUsuario')}
                  icon={AccountCircleIcon}
                  openMenu={open}
                />
              </Grid>
            )}

            {userHasAnyRole(['perfil']) && (
              <Grid
                item
                justify="center"
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={`${open ? classes.noActive : classes.active}`}
              >
                <QCXCardMenu
                  title={t('com.muralis.qcx.perfis.labelPlural')}
                  link={t('com.muralis.qcx.url.moduloGeraisPerfis')}
                  icon={BsFillPersonLinesFill}
                  openMenu={open}
                />
              </Grid>
            )}

            {userHasAnyRole(['tipo-documento']) && (
              <Grid
                item
                justify="center"
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={`${open ? classes.noActive : classes.active}`}
              >
                <QCXCardMenu
                  title={t('com.muralis.qcx.documento.tiposDocumentos')}
                  link={t('com.muralis.qcx.url.moduloGeraisTipoDocumentos')}
                  icon={LibraryBooksIcon}
                  openMenu={open}
                />
              </Grid>
            )}

            {openService ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  width: '100% !important',
                }}
              >
                {userHasAnyRole(['servico', 'modelo-numerario', 'modelo-faturamento', 'etapa', 'modelo-follow-up', 'tabela-sda']) && (
                  <Grid
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    item
                  >
                    <QCXCardMenuWithSubmenu
                      title={t('com.muralis.qcx.servico.labelPlural')}
                      icon={ListAltIcon}
                      isActiveOpenMenu={open}
                      onClick={toggleServicoSubMenu}
                    />
                  </Grid>
                )}

                <Grid
                  container
                  justify="flex-start"
                  className={classes.active}
                >
                  <Grid
                    item
                    justify="center"
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    className={classes.active}
                  >
                    <QCXCardMenu
                      title={t('com.muralis.qcx.servico.labelPlural')}
                      icon={ListAltIcon}
                      timeout={500}
                      link={t('com.muralis.qcx.url.moduloGeraisServicos')}
                      openMenu={openService}
                      isSubmenu
                    />
                  </Grid>
                  {userHasAnyRole(['modelo-numerario']) && (
                    <Grid
                      item
                      justify="center"
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      className={classes.active}
                    >
                      <QCXCardMenu
                        title={t('com.muralis.qcx.modelosNumerario')}
                        icon={TableViewIcon}
                        timeout={700}
                        link={t('com.muralis.qcx.url.moduloGeraisServicosModeloNumerario')}
                        openMenu={openService}
                        isSubmenu
                      />
                    </Grid>
                  )}
                  {userHasAnyRole(['modelo-faturamento']) && (
                    <Grid
                      item
                      justify="center"
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      className={classes.active}
                    >
                      <QCXCardMenu
                        title={t('com.muralis.qcx.modelosFaturamento')}
                        icon={PaidIcon}
                        timeout={900}
                        link={t('com.muralis.qcx.url.moduloGeraisServicosModelosFaturamento')}
                        openMenu={openService}
                        isSubmenu
                      />
                    </Grid>
                  )}
                  {userHasAnyRole(['etapa']) && (
                    <Grid
                      item
                      justify="center"
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      className={classes.active}
                    >
                      <QCXCardMenu
                        title={t('com.muralis.qcx.etapa.labelPlural')}
                        icon={AddTaskIcon}
                        timeout={1100}
                        link={t('com.muralis.qcx.url.moduloGeraisServicosEtapas')}
                        openMenu={openService}
                        isSubmenu
                      />
                    </Grid>
                  )}

                  {userHasAnyRole(['ocorrencia']) && (
                    <Grid
                      item
                      justify="center"
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      className={classes.active}
                    >
                      <QCXCardMenu
                        title="Ocorrências"
                        icon={AssignmentIcon}
                        timeout={1100}
                        link={t('com.muralis.qcx.url.moduloGeraisServicosOcorrencias')}
                        openMenu={openService}
                        isSubmenu
                      />
                    </Grid>
                  )}

                  {userHasAnyRole(['desvio']) && (
                    <Grid
                      item
                      justify="center"
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      className={classes.active}
                    >
                      <QCXCardMenu
                        title="Desvios"
                        icon={AltRouteIcon}
                        timeout={1100}
                        link={t('com.muralis.qcx.url.moduloGeraisServicosDesvios')}
                        openMenu={openService}
                        isSubmenu
                      />
                    </Grid>
                  )}

                  {userHasAnyRole(['camposadicionais']) && (
                    <Grid
                      item
                      justify="center"
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      className={classes.active}
                    >
                      <QCXCardMenu
                        title="Campos Adicionais"
                        icon={AddchartIcon}
                        timeout={1100}
                        link={t('com.muralis.qcx.url.moduloGeraisServicosCamposAdicionais')}
                        openMenu={openService}
                        isSubmenu
                      />
                    </Grid>
                  )}

                  {userHasAnyRole(['modelo-follow-up']) && (
                    <Grid
                      item
                      justify="center"
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      className={classes.active}
                    >
                      <QCXCardMenu
                        title={t('com.muralis.qcx.modelosFollowUp')}
                        icon={ModelTrainingIcon}
                        timeout={1300}
                        link={t('com.muralis.qcx.url.moduloGeraisServicosModeloFollowUp')}
                        openMenu={openService}
                        isSubmenu
                      />
                    </Grid>
                  )}
                  {userHasAnyRole(['tabela-sda']) && (
                    <Grid
                      item
                      justify="center"
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      className={classes.active}
                    >
                      <QCXCardMenu
                        title={t('com.muralis.qcx.tabelaSDA.label')}
                        icon={TableChartIcon}
                        timeout={1500}
                        link={t('com.muralis.qcx.url.modulosGeraisTabelaSDA')}
                        openMenu={openService}
                        isSubmenu
                      />
                    </Grid>
                  )}
                  {userHasAnyRole(['tabela-sda']) && (
                    <Grid
                      item
                      justify="center"
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      className={classes.active}
                    >
                      <QCXCardMenu
                        title='Modelos SDA'
                        icon={TableChartIcon}
                        timeout={1500}
                        link={t('com.muralis.qcx.url.modulosGeraisModeloTabelaSDA')}
                        openMenu={openService}
                        isSubmenu
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            ) : null}

            {userHasAnyRole(['servico', 'modelo-numerario', 'modelo-faturamento', 'etapa', 'modelo-follow-up', 'tabela-sda']) && !open ? (
              <Grid
                spacing={0}
                item
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={classes.active}
              >
                <QCXCardMenuWithSubmenu
                  title={t('com.muralis.qcx.servico.labelPlural')}
                  icon={ListAltIcon}
                  isActiveOpenMenu={open}
                  onClick={toggleServicoSubMenu}
                />
              </Grid>
            ) : null}

          </Grid>
        </QCXFocusLock>
      </Grid>
    </QCXMainLayout>
  );
}
