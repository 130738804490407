import _, { isFunction } from 'lodash';
import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import {
  Receipt as ReceiptIcon,
  MoreHoriz as MoreHorizIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  CancelOutlined as CancelOutlinedIcon,
  Search as PageviewIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { DONE_STATUS, FAIL_STATUS, SUBMITTING_STATUS } from '../../../../utils/hooks/form/dialog/formDialogUtils';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import QCXJustifiedActionFormDialog from '../../../../shared-components/dialog/QCXJustifiedActionFormDialog';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import { solicitacaoPagamentoAPI } from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoAPI';
import { fetchAllSolicitadasAsync } from '../../../../features/aprovar-recusar-pagamento/aprovarRecusarPagamentoThunks';
import AprovarRecusarPagamentoContext from '../../../../contexts/financial/aprovar-recusar-pagamento/AprovarRecusarPagamentoContext';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import {
  resetRelatedSelectionSolicitacoesList,
  setRelatedSelectionSolicitacoesList,
  setRelatedAprovarRecusarItemModel,
  changeToBatchApprovalMode,
  resetBackgroundMode,
  resetMode,
  resetModel,
  refresh as refreshAction,
  resetRefresh,
  loading,
  failure,
  success,
} from '../../../../features/aprovar-recusar-pagamento/aprovarRecusarPagamentoSlice';
import {
  selectMode,
  selectRefresh,
  selectAprovarRecusarPagamentos,
  selectRelatedSelectionSolicitacoesList,
  selectRelatedAprovarRecusarItemModel,
} from '../../../../features/aprovar-recusar-pagamento/aprovarRecusarPagamentoSelectors';
import SolicitacaoPagamentoUtils from '../../../../utils/general/solicitacao-pagamento/SolicitacaoPagamentoUtils';
import {
  isAlternativeLoadingStatus,
  isNoneMode,
  isApprovalBatchMode,
  isRefresh,
} from '../../../../utils/store/store-utils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import { formatDate, unnormalizeNumeral } from '../../../../utils/general/general-utils';
import { solicitacaoPagamentoActions } from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoSlice';
import { ModeParamCreator } from '../../../../utils/hooks/mode-param/useModeParam';
import { selectUnidadesDeNegocioAssociadas } from '../../../../features/usuario-logado/usuarioLogadoSlice';

export default function AprovarRecusarPagamentoConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const { status } = useContext(AprovarRecusarPagamentoContext);

  const mode = useSelector(selectMode);

  const params = useParams();
  const unidadesNegocio = useSelector(selectUnidadesDeNegocioAssociadas);

  console.log(params, window.href, unidadesNegocio);

  useEffect(() => {
    console.log(window.location);
  }, []);

  const aprovarRecusarPagamentos = useSelector(selectAprovarRecusarPagamentos);
  const relatedAprovarRecusarItem = useSelector(selectRelatedAprovarRecusarItemModel);
  const relatedSelectionSolicitacoesList = useSelector(selectRelatedSelectionSolicitacoesList);
  const refresh = useSelector(selectRefresh);

  const [statusSolicitacao, setStatusSolicitacao] = useState('');

  const { RECUSADO_PELO_APROVADOR, PROCESSO_COM_SALDO_INSUFICIENTE, AG_APROVACAO } = SolicitacaoPagamentoUtils;

  const isProcessoSaldoInsuficiente = statusSolicitacao === PROCESSO_COM_SALDO_INSUFICIENTE;

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isApprovalBatch = useMemo(() => isApprovalBatchMode(mode), [mode]);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionSolicitacoesList ||
      (_.isArrayLikeObject(relatedSelectionSolicitacoesList) && _.isEmpty(relatedSelectionSolicitacoesList)),
    [relatedSelectionSolicitacoesList]
  );

  const fetchAll = useCallback(() => {
    dispatch(fetchAllSolicitadasAsync());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    if (isNone && !isEmptySelectionList) {
      dispatch(resetRelatedSelectionSolicitacoesList());
    }
  }, [isNone, isEmptySelectionList]);

  const handleEnableBatchApprovalMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToBatchApprovalMode());
  }, []);

  const handleSelectionModelChange = useCallback(
    ({ selectionModel }) => {
      if (isApprovalBatch) {
        dispatch(setRelatedSelectionSolicitacoesList(selectionModel));
      }
    },
    [isApprovalBatch]
  );

  const handleConfirmBatchApproval = useCallback(async () => {
    try {
      dispatch(loading());

      const mappedRelatedSelectionSolicitacoes = relatedSelectionSolicitacoesList.map((item) => ({
        id: item,
      }));

      const response = await solicitacaoPagamentoAPI.aprovarLote(mappedRelatedSelectionSolicitacoes);

      if (response?.status === 201) {
        const feedbackMessage = t('com.muralis.qcx.mensagem.loteAprovado');

        dispatch(success());
        dispatch(
          setSuccessFeedback({
            message: feedbackMessage,
          })
        );
        dispatch(refreshAction());
        dispatch(resetMode());
      }
    } catch (error) {
      const errorMessage = error?.message
        ? t('com.muralis.qcx.erro.aprovarLoteEspecifico', { erro: error?.message })
        : t('com.muralis.qcx.erro.aprovarLote');

      dispatch(failure());
      dispatch(
        setErrorFeedback({
          message: errorMessage,
        })
      );
    }
  }, [relatedSelectionSolicitacoesList]);

  const handleCancelBatchApproval = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(solicitacaoPagamentoActions.changeToLockedMode());

        const url = t('com.muralis.qcx.url.financeiroSolicitacaoPagamentoDetalhes', {
          id: data?.id,
        });
        const queryParams = ModeParamCreator.getReadOnly();

        history.push(`${url}?${queryParams}`);
      }
    },
    [t, history]
  );

  const [handleOpenApprovePayment, dialogApprovePaymentStatus, handleConsultApprovePaymentStatus] = useFormDialogSync(
    async (event, data) => {
      if (_.isFunction(event?.stopPropagation)) {
        event.stopPropagation();
        dispatch(setRelatedAprovarRecusarItemModel(data));
      }
    },
    []
  );

  const [handleOpenRefusedPayment, dialogRefusedPaymentStatus, handleConsultRefusedPaymentStatus] = useFormDialogSync(
    async (event, data) => {
      if (_.isFunction(event?.stopPropagation)) {
        event.stopPropagation();
        dispatch(setRelatedAprovarRecusarItemModel(data));
      }
    },
    []
  );

  const handleApprovePaymentSubmit = useCallback(
    async ({ ...values }) => {
      try {
        dispatch(loading());

        let responseRequestAprovacao = null;
        let responseRequestSaldoInsuficiente = null;

        handleConsultApprovePaymentStatus(SUBMITTING_STATUS);

        if (SolicitacaoPagamentoUtils.isAgAprovacao(values?.status)) {
          responseRequestAprovacao = await solicitacaoPagamentoAPI.aprovarSolicitacaoSaldoInsuficiente({
            id: values?.id,
          });
        }

        if (SolicitacaoPagamentoUtils.isSolicitacaoProcessoSaldoInsuficiente(values?.status)) {
          responseRequestSaldoInsuficiente = await solicitacaoPagamentoAPI.aprovarSolicitacaoSaldoInsuficiente({
            id: values?.id,
            situacaoAprovacao: {
              justificativa: values?.justificativa,
            },
          });
        }

        if (responseRequestAprovacao?.status === 201 || responseRequestSaldoInsuficiente?.status === 201) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.solicitacaoPagamentoAprovada');

          handleConsultApprovePaymentStatus(DONE_STATUS);
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
        }
      } catch (error) {
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.aprovarSolicitacaoEspecifico', {
              numero: values?.followUp?.numero,
              erro: error?.message,
            })
          : t('com.muralis.qcx.erro.aprovarSolicitacao');
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        dispatch(failure());
        handleConsultApprovePaymentStatus(FAIL_STATUS);
      }
    },
    [handleConsultApprovePaymentStatus]
  );

  const handleRefusedPaymentSubmit = useCallback(
    async ({ ...values }) => {
      try {
        handleConsultRefusedPaymentStatus(SUBMITTING_STATUS);

        const response = await solicitacaoPagamentoAPI.recusarSolicitacao({
          id: values?.id,
          situacaoAprovacao: {
            justificativa: values?.justificativa,
          },
        });

        if (response?.status === 201) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.solicitacaoPagamentoRecusada');

          handleConsultRefusedPaymentStatus(DONE_STATUS);
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
        }
      } catch (error) {
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.recusarSolicitacaoEspecifico', {
              numero: values?.followUp?.numero,
              erro: error?.message,
            })
          : t('com.muralis.qcx.erro.recusarSolicitacao');
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        handleConsultRefusedPaymentStatus(FAIL_STATUS);
      }
    },
    [handleConsultRefusedPaymentStatus]
  );

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.NumeroProcesso'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.followUp?.numero || '-',
      },
      {
        field: 'solicitacao',
        headerName: t('com.muralis.qcx.solicitacao'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 180,
        valueGetter: ({ row }) => formatDate(row?.dataSolicitacao) || '-',
      },
      {
        field: 'cliente',
        headerName: t('com.muralis.qcx.cliente.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 150,
        type: 'string',
        valueGetter: ({ row }) => row?.followUp?.importador?.pessoa?.nome || '-',
      },
      {
        field: 'solicitante',
        headerName: t('com.muralis.qcx.solicitante'),
        headerAlign: 'center',
        align: 'center',
        flex: 150,
        type: 'string',
        valueGetter: ({ row }) =>
          row?.solicitante ? `${row?.solicitante?.nomeSolicitante} ${row?.solicitante?.sobrenomeSolicitante}` : '-',
      },
      {
        field: 'valorSolicitado',
        headerName: t('com.muralis.qcx.valorSolicitado'),
        headerAlign: 'center',
        align: 'center',
        flex: 120,
        valueGetter: ({ row }) =>
          row?.valorPagarMoeda ? unnormalizeNumeral(row?.valorPagarMoeda, formatBrazilianNumericDecimal(2)) : '-',
      },
      {
        field: 'valorRecebido',
        headerName: t('com.muralis.qcx.valorRecebido'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 150,
        valueGetter: ({ row }) =>
          row?.valorRecebido ? unnormalizeNumeral(row?.valorRecebido, formatBrazilianNumericDecimal(2)) : '-',
      },
      {
        field: 'saldo',
        headerName: t('com.muralis.qcx.saldo'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 150,
        valueGetter: ({ row }) => (row?.saldo ? unnormalizeNumeral(row?.saldo, formatBrazilianNumericDecimal(2)) : '-'),
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.status.replaceAll('_', ' ') || '-',
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        renderCell: ({ row }) => (
          <>
            <QCXPopupState popupId="popup-menu-aprovar-recusar-pagamento">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${row?.id}`}
                    name={`btn-more-options-${row?.id}`}
                    disabled={isApprovalBatch}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color={isApprovalBatch ? 'disabled' : 'secondary'} size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      key="btn-option-show-solicitacao"
                      name="btn-option-show-solicitacao"
                      onClick={(event) => {
                        popupState.close(event);
                        handleConsultarClick(event, row, row?.id);
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      key="btn-option-show-solicitacao-aprovar"
                      name="btn-option-show-solicitacao-aprovar"
                      onClick={(event) => {
                        handleOpenApprovePayment(event, row);
                        setStatusSolicitacao(row?.status);
                      }}
                    >
                      <QCXListItemIcon>
                        <CheckCircleIcon fontSize="small" htmlColor="green" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.aprovar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      key="btn-option-show-solicitacao-recusar"
                      name="btn-option-show-solicitacao-recusar"
                      onClick={(event) => handleOpenRefusedPayment(event, row)}
                    >
                      <QCXListItemIcon>
                        <CancelIcon fontSize="small" color="error" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.recusar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [isApprovalBatch, setStatusSolicitacao, handleOpenApprovePayment, handleOpenRefusedPayment]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.financeiro.aprovarRecusarPagamento'),
        },
        default: true,
      },
    ],
    []
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.financeiro.aprovarRecusarPagamento'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        others: [
          ...(isNone
            ? [
                {
                  description: <CheckCircleIcon size={20} color="white" />,
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.aprovarLote'),
                  },
                  ...(_.isEmpty(aprovarRecusarPagamentos || [])
                    ? {
                        disabled: true,
                        color: 'disabled',
                      }
                    : {
                        disabled: false,
                        style: {
                          backgroundColor: 'green',
                        },
                      }),
                  onClick: handleEnableBatchApprovalMode,
                },
              ]
            : []),
          ...(isApprovalBatch
            ? [
                {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  startIcon: <CheckCircleIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  },
                  onClick: handleConfirmBatchApproval,
                  disabled: isEmptySelectionList,
                },
                {
                  description: <CancelOutlinedIcon />,
                  variant: 'outlined',
                  color: 'default',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.cancelar'),
                  },
                  onClick: handleCancelBatchApproval,
                },
              ]
            : []),
        ],
      },
      table: {
        columns,
        pageSize: 15,
        checkboxSelection: isApprovalBatch,
        selectionModel: relatedSelectionSolicitacoesList,
        onSelectionModelChange: handleSelectionModelChange,
        isRowSelectable: (table) => table?.row?.status !== SolicitacaoPagamentoUtils.PROCESSO_COM_SALDO_INSUFICIENTE,
      },
    }),
    [
      breadcrumbs,
      columns,
      isApprovalBatch,
      isEmptySelectionList,
      aprovarRecusarPagamentos,
      handleCancelBatchApproval,
      handleConfirmBatchApproval,
      handleSelectionModelChange,
      handleEnableBatchApprovalMode,
      relatedSelectionSolicitacoesList,
    ]
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={aprovarRecusarPagamentos}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      searchGridSize={isNone ? 11 : null}
      buttonGridSize={isNone ? 1 : null}
      requiredRoles={['aprovar-recusar-pagamento']}
    >
      <QCXJustifiedActionFormDialog
        handleSubmit={handleApprovePaymentSubmit}
        status={dialogApprovePaymentStatus}
        handleStatus={handleConsultApprovePaymentStatus}
        type={isProcessoSaldoInsuficiente ? PROCESSO_COM_SALDO_INSUFICIENTE : AG_APROVACAO}
        initialValues={relatedAprovarRecusarItem}
      />
      <QCXJustifiedActionFormDialog
        handleSubmit={handleRefusedPaymentSubmit}
        status={dialogRefusedPaymentStatus}
        handleStatus={handleConsultRefusedPaymentStatus}
        type={RECUSADO_PELO_APROVADOR}
        initialValues={relatedAprovarRecusarItem}
      />
    </QCXSimpleConsultPageTemplate>
  );
}
