import { Box, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import QCXButton from '../../../../shared-components/button/QCXButton';

const useStyles = makeStyles(() => ({
  paragraph: {
    width: '48%',
    padding: 18,
    border: '1px solid gray',
    borderRadius: 12,
  },
}));

export default function InformacoesComplementaresDiffDialog({ open, handleClose, originalText, diffText }) {
  const classes = useStyles();

  return (
    <Dialog style={{ width: '100%' }} fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle>Aviso</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <p className={classes.paragraph}>{originalText}</p>
          <p className={classes.paragraph} dangerouslySetInnerHTML={{ __html: diffText }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <QCXButton onClick={handleClose} color="secondary">
          Cancelar
        </QCXButton>
        <QCXButton onClick={handleClose} color="primary">
          Atualizar
        </QCXButton>
      </DialogActions>
    </Dialog>
  );
}
