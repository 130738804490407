// src/pages/sda-import/SDAImportPage.jsx
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetStatus,
  clearError,
  selectImportSdaStatus,
  selectImportSdaError,
} from '../../../../features/import-sda/importSdaSlice';
import { LOADING_STATUS, FAILURE_STATUS } from '../../../../features/status';
import QCXPageTemplate from '../../../../templates/page/QCXPageTemplate';
import ImportSdaContext from '../../../../contexts/registrations/import-sda/ImportSdaContext';

export default function SDAExportPage({ children }) {
  const dispatch = useDispatch();
  const status = useSelector(selectImportSdaStatus);
  const error = useSelector(selectImportSdaError);

  const isLoading = useMemo(() => status === LOADING_STATUS, [status]);

  const [successSnackbar, setSuccessSnackbar] = useState({
    open: false,
    message: '',
  });
  const [errorSnackbar, setErrorSnackbar] = useState({
    open: false,
    message: '',
  });

  const handleOpenSuccessSnackbar = useCallback((message) => {
    setSuccessSnackbar({ open: true, message });
  }, []);
  const handleOpenErrorSnackbar = useCallback((message) => {
    setErrorSnackbar({ open: true, message });
  }, []);
  const handleCloseSuccessSnackbar = useCallback(() => {
    setSuccessSnackbar((prev) => ({ ...prev, open: false }));
  }, []);
  const handleCloseErrorSnackbar = useCallback(() => {
    setErrorSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  useEffect(() => {
    if (status === FAILURE_STATUS && error) {
      handleOpenErrorSnackbar(error);
      dispatch(resetStatus());
    }
  }, [status, error, dispatch, handleOpenErrorSnackbar]);

  const providerData = useMemo(
    () => ({
      status,
      isLoading,
      successSnackbar,
      errorSnackbar,
      handleCloseSuccessSnackbar,
      handleCloseErrorSnackbar,
    }),
    [status, isLoading, successSnackbar, errorSnackbar, handleCloseSuccessSnackbar, handleCloseErrorSnackbar]
  );

  return (
    <ImportSdaContext.Provider value={providerData}>
      <QCXPageTemplate contextComponent={ImportSdaContext}>
        {children}
      </QCXPageTemplate>
    </ImportSdaContext.Provider>
  );
}
