import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isArray, isObjectLike } from 'lodash';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalSelectTipoPessoaAutocomplete from '../../shared-components/select-tipo-pessoa/QCXFinalSelectTipoPessoaAutocomplete';
import QCXSelectCnaeAutocomplete from '../../shared-components/final-select-cnae-field/QCXSelectCnaeAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectCidadeAutocomplete from '../../shared-components/select-cidade/QCXSelectCidadeAutocomplete';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import {
  required as requiredValidator,
  validCep,
  validCnpj,
  validCpf,
  maxValue,
  isCurrentOrPastDate,
} from '../../utils/validators/field/validator';
import { paisActions } from '../../features/pais/paisSlice';
import { changeControlTo as changeGrupoControlTo } from '../../features/grupo-unidade-negocio/grupoUnidadeNegocioSlice';
import { estadoActions } from '../../features/estado/estadoSlice';
import { cidadeActions } from '../../features/cidade/cidadeSlice';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { formatCep, formatCnpj, formatCpf, formatNotZero } from '../../utils/hooks/form/field/formatters';
import { numberOnly, parseCep, parseCnpj, parseCpf } from '../../utils/hooks/form/field/parsers';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import useValidators from '../../utils/hooks/useValidators';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import QCXSelectGrupoUnidadeNegocioAutocomplete from '../../shared-components/select-grupo-unidade-negocio/QCXSelectGrupoUnidadeNegocioAutocomplete';
import QCXConfiguracaoMascaraPreviewerFormGroup from '../../shared-components/configuracao-mascara-processo-form-group/QCXConfiguracaoMascaraPreviewerFormGroup';
import ControleContagemUtils from '../../utils/general/controle-contagem/ControleContagemUtils';
import {
  isTipoMascaraCodigoViaTransporteSiglaUnidadeSequencialAno,
  isTipoMascaraProdutoSiglaUnidadeSiglaServicoTracoSequencialBarraAno,
  isTipoMascaraSequencialTracoAno,
  isTipoMascaraSiglaServicoTracoSequencialBarraAno,
  isTipoMascaraSiglaServicoTracoSequencialTracoAno,
  isTipoMascaraSiglaServicoSiglaUnidadeSequencial,
} from '../../shared-components/select-tipo-mascara-follow-up/tipoMascaraFollowUpUtils';
import QCXSelectTipoLogradouroAutocomplete from '../../shared-components/select-tipo-logradouro/QCXSelectTipoLogradouroAutocomplete';
import QCXUnidadeNegocioNfseForm from './QCXUnidadeNegocioNfseForm';
import { userHasRole } from '../perfil/perfil-utils';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';

export default function QCXUnidadeNegocioFinalForm({
  isCreate,
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paisActions.changeControlTo(AUTO_LOAD_CONTROL));
    dispatch(changeGrupoControlTo(AUTO_LOAD_CONTROL));
  }, []);

  const isPessoaFisica = useCallback((tipoPessoa) => tipoPessoa === 'PessoaFisica', []);

  const isPessoaJuridica = useCallback((tipoPessoa) => tipoPessoa === 'PessoaJuridica', []);

  const isPessoaJuridicaBy = useCallback((values) => isPessoaJuridica(values?.pessoa?.tipo), [isPessoaJuridica]);

  const validateCpf = useMemo(() => useValidators([requiredValidator, validCpf]), []);

  const validateCnpj = useMemo(() => useValidators([requiredValidator, validCnpj]), []);

  const validateCep = useMemo(() => useValidators([requiredValidator, validCep(8, 9)]), []);

  const issValidators = useMemo(() => useValidators([requiredValidator, maxValue(10)]), []);

  const dataNascimentoValidators = useMemo(() => useValidators([requiredValidator, isCurrentOrPastDate]), []);

  const handleTipoPessoaChange = useCallback(
    (form) => (value) => {
      if (isPessoaFisica(value)) {
        form.change('pessoa.cnpj', undefined);
        form.change('pessoa.cnae', undefined);
        form.change('pessoa.inscricaoEstadual', undefined);
        form.change('pessoa.inscricaoMunicipal', undefined);

        return;
      }

      if (isPessoaJuridica(value)) {
        form.change('pessoa.cpf', undefined);

        return;
      }

      form.change('pessoa.cpf', '');
      form.change('pessoa.cnpj', undefined);
      form.change('pessoa.cnae', undefined);
      form.change('pessoa.inscricaoEstadual', undefined);
      form.change('pessoa.inscricaoMunicipal', undefined);
    },
    [isPessoaFisica, isPessoaJuridica]
  );

  const handlePaisChange = useCallback(
    (form) => (value) => {
      form.change('endereco.cidade.id', undefined);
      form.change('endereco.cidade.estado.id', undefined);
      form.change('endereco.cidade.estado.pais.id', value);

      dispatch(estadoActions.fetchByFilterAsync([{ name: 'pais', value }]));
    },
    []
  );

  const handleEstadoChange = useCallback(
    (form) => (value) => {
      form.change('endereco.cidade.id', undefined);
      form.change('endereco.cidade.estado.id', value);

      dispatch(cidadeActions.fetchByFilterAsync([{ name: 'estado', value }]));
    },
    []
  );

  const handleCidadeChange = useCallback(
    (form) => (value) => {
      form.change('endereco.cidade.id', value);
    },
    []
  );

  const handleTipoMascaraProcessoChange = useCallback(
    (form) => (value) => {
      if (isTipoMascaraCodigoViaTransporteSiglaUnidadeSequencialAno(value)) {
        form.change('reiniciaContagemTipoProduto', undefined);
        form.change('reiniciaContagemTipoServico', undefined);

        return;
      }

      if (isTipoMascaraSiglaServicoSiglaUnidadeSequencial(value)) {
        return;
      }

      if (
        isTipoMascaraSiglaServicoTracoSequencialTracoAno(value) ||
        isTipoMascaraSiglaServicoTracoSequencialBarraAno(value)
      ) {
        form.change('reiniciaContagemTipoProduto', undefined);

        return;
      }

      if (isTipoMascaraSequencialTracoAno(value)) {
        form.change('reiniciaContagemTipoProduto', undefined);
        form.change('reiniciaContagemTipoServico', undefined);

        return;
      }

      if (isTipoMascaraProdutoSiglaUnidadeSiglaServicoTracoSequencialBarraAno(value)) {
        form.change('reiniciaContagemTipoServico', undefined);

        return;
      }

      form.change('reiniciaContagemAnualmente', undefined);
      form.change('reiniciaContagemTipoProduto', undefined);
      form.change('reiniciaContagemTipoServico', undefined);
    },
    []
  );

  const listeners = useMemo(
    () => [
      {
        name: 'pessoa.tipo',
        fn: handleTipoPessoaChange,
      },
      {
        name: 'endereco.cidade.estado.pais.id',
        fn: handlePaisChange,
      },
      {
        name: 'endereco.cidade.estado.id',
        fn: handleEstadoChange,
      },
      {
        name: 'endereco.cidade.id',
        fn: handleCidadeChange,
      },
      {
        name: 'tipoMascaraFollowUp',
        fn: handleTipoMascaraProcessoChange,
      },
    ],
    [handleTipoPessoaChange, handlePaisChange, handleEstadoChange, handleCidadeChange, handleTipoMascaraProcessoChange]
  );

  const configuracaoMascaraFollowUpFieldProps = useMemo(
    () => ({
      tipoMascara: {
        id: 'autocomplete-select-tipo-mascara',
        key: 'autocomplete-select-tipo-mascara',
        name: 'tipoMascaraFollowUp',
        label: t('com.muralis.qcx.tipoMascara'),
        validate: requiredValidator,
        disabled: isConsult,
        required: true,
      },
      reiniciaContagemAnualmente: {
        id: 'checkbox-reiniciar-contagem-anualmente',
        key: 'checkbox-reiniciar-contagem-anualmente',
        name: 'reiniciaContagemAnualmente',
        label: t('com.muralis.qcx.reiniciarContagemAnualmente'),
        value: ControleContagemUtils.REINICIA,
        disabled: isConsult,
      },
      reiniciaContagemTipoProduto: {
        id: 'checkbox-reiniciar-contagem-tipo-produto',
        key: 'checkbox-reiniciar-contagem-tipo-produto',
        name: 'reiniciaContagemTipoProduto',
        label: t('com.muralis.qcx.reinciarContagemTipoProduto'),
        value: ControleContagemUtils.REINICIA,
        disabled: isConsult,
      },
      reiniciaContagemTipoServico: {
        id: 'checkbox-reiniciar-contagem-tipo-serviço',
        key: 'checkbox-reiniciar-contagem-tipo-serviço',
        name: 'reiniciaContagemTipoServico',
        label: t('com.muralis.qcx.reiniciarContagemTipoServico'),
        value: ControleContagemUtils.REINICIA,
        disabled: isConsult,
      },
      mesmaSequenciaPorGrupoAssociado: {
        id: 'checkbox-mesma-sequencia-por-grupo-associado',
        key: 'checkbox-mesma-sequencia-por-grupo-associado',
        name: 'sequencialPorGrupoAssociado',
        label: 'Mesma sequência por grupo associado',
        disabled: isConsult,
      },
      tamanhoSequencial: {
        id: 'numeric-integer-tamanho-sequencial-field',
        key: 'numeric-integer-tamanho-sequencial-field',
        name: 'tamanhoSequencialMascara',
        label: t('com.muralis.qcx.tamanhoSequencial'),
        allowNegative: false,
        disabled: isConsult,
        maxLength: 1,
        validate: requiredValidator,
        format: formatNotZero,
        required: true,
      },
    }),
    [isConsult]
  );

  // TODO: Implement a util function that check a condition and returns a grid breakpoint number
  // { condition, value }
  const getResultValueBy = useCallback((conditionObjects = []) => {
    const result = conditionObjects.filter((conditionObject) => conditionObject?.returns);

    if (isArray(result) && result.length > 0) {
      const first = result[0];

      if (isObjectLike(first)) {
        return first.value;
      }
    }

    return undefined;
  }, []);

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      onChangeListeners={listeners}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>
          <Grid container item xs={12} spacing={2}>
            {(isConsult || isUpdate) && (
              <Grid item xs={12} sm={12} md={isPessoaJuridicaBy(values) ? 3 : 2}>
                <QCXFinalTextField
                  id="final-text-field-id"
                  key="text-field-id"
                  label={t('com.muralis.qcx.codigo')}
                  name="id"
                  disabled
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 4,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 2,
                },
                {
                  returns: isCreate,
                  value: 3,
                },
              ])}
            >
              <QCXFinalSelectTipoPessoaAutocomplete
                id="select-field-tipo-pessoa"
                key="select-field-tipo-pessoa"
                name="pessoa.tipo"
                label={t('com.muralis.qcx.tipoPessoa.label')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            {!isPessoaJuridicaBy(values) && (
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalOnblurMaskedField
                  id="masked-text-field-pessoa-cpf"
                  key="masked-text-field-pessoa-cpf"
                  name="pessoa.cpf"
                  label={t('com.muralis.qcx.CPF')}
                  maxLength={14}
                  format={formatCpf}
                  parse={parseCpf}
                  validate={validateCpf}
                  disabled={isConsult}
                  required
                />
              </Grid>
            )}
            {isPessoaJuridicaBy(values) && (
              <Grid item xs={12} sm={6} md={isCreate ? 4 : 5}>
                <QCXFinalOnblurMaskedField
                  id="masked-text-field-pessoa-cnpj"
                  key="masked-text-field-pessoa-cnpj"
                  name="pessoa.cnpj"
                  label={t('com.muralis.qcx.empresa.CNPJ')}
                  maxLength={18}
                  format={formatCnpj}
                  parse={parseCnpj}
                  validate={validateCnpj}
                  disabled={isConsult}
                  required
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={(isPessoaJuridicaBy(values) && (isConsult || isUpdate)) || isCreate ? 5 : 4}>
              <QCXFinalTextField
                id="text-field-pessoa-nome"
                key="text-field-pessoa-nome"
                label={
                  isPessoaJuridicaBy(values) ? t('com.muralis.qcx.empresa.razaoSocial') : t('com.muralis.qcx.nome')
                }
                name="pessoa.nome"
                maxLength={100}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={5}>
              <QCXFinalTextField
                id="text-field-pessoa-nome-resumido"
                key="text-field-pessoa-nome-resumido"
                label={t('com.muralis.qcx.nomeResumido')}
                name="pessoa.nomeResumido"
                maxLength={50}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <QCXFinalTextField
                id="text-field-sigla"
                key="text-field-sigla"
                label={t('com.muralis.qcx.sigla')}
                name="sigla"
                maxLength={3}
                disabled={isConsult}
              />
            </Grid>
            {!isPessoaJuridicaBy(values) && (
              <Grid item xs={12} sm={6} md={3}>
                <QCXFinalDatePickerField
                  id="date-picker-pessoa-data-nascimento-field"
                  key="date-picker-pessoa-data-nascimento-field"
                  label={t('com.muralis.qcx.dataNascimento')}
                  name="pessoa.dataNascimento"
                  validate={!isConsult && dataNascimentoValidators}
                  disabled={isConsult}
                  disableFuture
                  required
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={2}>
              <QCXFinalOnblurMaskedField
                id="masked-text-field-endereco-cep"
                key="masked-text-field-endereco-cep"
                label={t('com.muralis.qcx.endereco.CEP')}
                name="endereco.cep"
                maxLength={9}
                format={formatCep}
                parse={parseCep}
                validate={validateCep}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 4,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 6,
                },
                {
                  returns: isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && isCreate,
                  value: 6,
                },
              ])}
            >
              <QCXSelectTipoLogradouroAutocomplete
                name="endereco.tipoLogradouro"
                label={t('com.muralis.qcx.tipoLogradouro.label')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 6,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 6,
                },
                {
                  returns: isPessoaJuridicaBy(values) && isCreate,
                  value: 6,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && isCreate,
                  value: 6,
                },
              ])}
            >
              <QCXFinalTextField
                id="text-field-endereco-logradouro"
                key="text-field-endereco-logradouro"
                label={t('com.muralis.qcx.endereco.logradouro')}
                name="endereco.logradouro"
                maxLength={150}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 3,
                },
                {
                  returns: isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
              ])}
            >
              <QCXFinalTextField
                id="text-field-endereco-numero"
                key="text-field-endereco-numero"
                label={t('com.muralis.qcx.endereco.numero')}
                name="endereco.numero"
                maxLength={10}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 3,
                },
                {
                  returns: isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
              ])}
            >
              <QCXFinalTextField
                id="text-field-endereco-complemento"
                key="text-field-endereco-complemento"
                label={t('com.muralis.qcx.endereco.complemento')}
                name="endereco.complemento"
                maxLength={50}
                disabled={isConsult}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 3,
                },
                {
                  returns: isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
              ])}
            >
              <QCXFinalTextField
                id="text-field-endereco-bairro"
                key="text-field-endereco-bairro"
                label={t('com.muralis.qcx.endereco.bairro')}
                name="endereco.bairro"
                maxLength={70}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 3,
                },
                {
                  returns: isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
              ])}
            >
              <QCXSelectPaisAutocomplete
                id="select-endereco-pais"
                key="select-endereco-pais"
                name="endereco.cidade.estado.pais.id"
                label={t('com.muralis.qcx.endereco.pais')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 3,
                },
                {
                  returns: isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && isCreate,
                  value: 3,
                },
              ])}
            >
              <QCXSelectEstadoAutocomplete
                id="select-endereco-estado"
                key="select-endereco-estado"
                name="endereco.cidade.estado.id"
                label={t('com.muralis.qcx.endereco.estado')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={isPessoaJuridicaBy(values) && isCreate ? 3 : 4}>
              <QCXSelectCidadeAutocomplete
                name="endereco.cidade.id"
                label={t('com.muralis.qcx.endereco.cidade')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            {isPessoaJuridicaBy(values) && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={getResultValueBy([
                    {
                      returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                      value: 5,
                    },
                    {
                      returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                      value: 4,
                    },
                    {
                      returns: isPessoaJuridicaBy(values) && isCreate,
                      value: 4,
                    },
                    {
                      returns: !isPessoaJuridicaBy(values) && isCreate,
                      value: 4,
                    },
                  ])}
                >
                  <QCXSelectCnaeAutocomplete
                    id="autocomplete-select-pessoa-juridica-cnae"
                    key="autocomplete-select-pessoa-juridica-cnae"
                    label={t('com.muralis.qcx.empresa.CNAE')}
                    name="pessoa.cnae.id"
                    initialValues={values}
                    fieldProps={{ validate: requiredValidator }}
                    disabled={isConsult}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={getResultValueBy([
                    {
                      returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                      value: 4,
                    },
                    {
                      returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                      value: 4,
                    },
                    {
                      returns: isPessoaJuridicaBy(values) && isCreate,
                      value: 4,
                    },
                    {
                      returns: !isPessoaJuridicaBy(values) && isCreate,
                      value: 4,
                    },
                  ])}
                >
                  <QCXFinalTextField
                    id="text-field-pessoa-inscricao-estadual"
                    key="text-field-pessoa-inscricao-estadual"
                    label={t('com.muralis.qcx.empresa.inscricaoEstadual')}
                    name="pessoa.inscricaoEstadual"
                    parse={numberOnly}
                    maxLength={15}
                    disabled={isConsult}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={getResultValueBy([
                    {
                      returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                      value: 4,
                    },
                    {
                      returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                      value: 4,
                    },
                    {
                      returns: isPessoaJuridicaBy(values) && isCreate,
                      value: 4,
                    },
                    {
                      returns: !isPessoaJuridicaBy(values) && isCreate,
                      value: 4,
                    },
                  ])}
                >
                  <QCXFinalTextField
                    id="text-field-pessoa-inscricao-municipal"
                    key="text-field-pessoa-inscricao-municipal"
                    label={t('com.muralis.qcx.empresa.inscricaoMunicipal')}
                    name="pessoa.inscricaoMunicipal"
                    parse={numberOnly}
                    maxLength={15}
                    disabled={isConsult}
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 4,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 5,
                },
                {
                  returns: isPessoaJuridicaBy(values) && isCreate,
                  value: 4,
                },
                {
                  returns: !isPessoaJuridicaBy(values) && isCreate,
                  value: 5,
                },
              ])}
            >
              <QCXSelectGrupoUnidadeNegocioAutocomplete
                name="grupo.id"
                label={t('com.muralis.qcx.grupoAssociado')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult || !userHasRole(authInfo, 'grupo-alterar')}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 2,
                },
                {
                  returns:
                    (!isPessoaJuridicaBy(values) && (isConsult || isUpdate)) ||
                    (isPessoaJuridicaBy(values) && isCreate),
                  value: 2,
                },
                {
                  returns: isCreate,
                  value: 2,
                },
              ])}
            >
              <QCXFinalPercentualField
                id="numeric-decimal-percentuais-field"
                key="numeric-decimal-percentuais-field"
                name="percentualIss"
                label={t('com.muralis.qcx.empresa.ISS')}
                scale={2}
                validate={issValidators}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={getResultValueBy([
                {
                  returns: isPessoaJuridicaBy(values) && (isConsult || isUpdate),
                  value: 2,
                },
                {
                  returns:
                    (!isPessoaJuridicaBy(values) && (isConsult || isUpdate)) ||
                    (isPessoaJuridicaBy(values) && isCreate),
                  value: 2,
                },
                {
                  returns: isCreate,
                  value: 2,
                },
              ])}
            >
              <QCXFinalPercentualField
                id="numeric-decimal-ir-field"
                key="numeric-decimal-ir-field"
                name="aliquotaIr"
                label={t('com.muralis.qcx.irpercentual')}
                scale={2}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <QCXFinalCheckboxField
                id="checkbox-field-possui-alcada"
                key="checkbox-field-possui-alcada"
                name="possuiAlcada"
                label="Possui alçada?"
                disabled={isConsult}
              />
            </Grid>
          </Grid>
          <QCXUnidadeNegocioNfseForm initialValues={values} isConsult={isConsult} />
          <QCXConfiguracaoMascaraPreviewerFormGroup
            fieldProps={configuracaoMascaraFollowUpFieldProps}
            maskPreviewerProps={{
              disabled: isConsult,
            }}
          />
        </>
      )}
    </QCXFinalForm>
  );
}
