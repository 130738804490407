import _, { isFunction } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IconButton, useTheme, Chip } from '@material-ui/core';
import {
  Receipt as ReceiptIcon,
  MoreHoriz as MoreHorizIcon,
  CheckCircle as CheckCircleIcon,
  CancelOutlined as CancelOutlinedIcon,
  Refresh as RefreshIcon,
  RotateLeft as RotateLeftIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { selectUnidadeSelecionada } from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { CLOSED_STATUS, DONE_STATUS } from '../../../../utils/hooks/form/dialog/formDialogUtils';

import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import QCXRelatorioConciliacaoBancariaDialogForm from '../../../../components/conciliacao-bancaria/QCXRelatorioConciliacaoBancariaDialogForm';
import {
  refresh as refreshAction,
  resetRefresh,
  changeToBatchApprovalMode,
  changeToCreateMode,
  failure,
  loading,
  resetBackgroundMode,
  resetMode,
  resetModel,
  setRelatedReportModel,
  resetRelatedSelectionConciliacaoBancariaList,
  setRelatedSelectionConciliacaoBancariaList,
  success,
  conciliacaoBancariaActions,
} from '../../../../features/conciliacao-bancaria/conciliacaoBancariaSlice';
import {
  fetchAllAsync,
  fetchAllViewAsync,
  generateReportByDateAsync,
} from '../../../../features/conciliacao-bancaria/conciliacaoBancariaThunks';
import {
  selectStatus,
  selectMode,
  selectRefresh,
  selectRelatedReportModel,
  selectConciliacoesBancariasView,
  selectRelatedSelectionConciliacaoBancariaList,
} from '../../../../features/conciliacao-bancaria/conciliacaoBancariaSelectors';
import {
  isAlternativeLoadingStatus,
  isApprovalBatchMode,
  isNoneMode,
  isRefresh,
} from '../../../../utils/store/store-utils';
import { conciliacaoBancariaAPI } from '../../../../features/conciliacao-bancaria/conciliacaoBancariaAPI';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { OperacaoMovimentacaoUtils, OPERACAO_ESTORNO } from '../../../../utils/general/movimentacao/movimentacaoUtils';
import { numerarioImportacaoAPI } from '../../../../features/numerario-importacao/numerarioImportacaoAPI';
import { solicitacaoPagamentoAPI } from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoAPI';
import {
  formatDate,
  normalizeData,
  normalizeNumeral,
  unnormalizeNumeral,
} from '../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import ConciliacaoBancariaUtils from '../../../../utils/general/conciliacao-bancaria/ConciliacaoBancariaUtils';
import TituloUtils from '../../../../utils/general/titulo/TituloUtils';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXTransferenciaBancariaDialogForm from '../../../../components/conciliacao-bancaria/QCXTransferenciaBancariaDialogForm';
import SimpleConsultPageTemplate from '../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import ConciliacaoBancariaFilterComponent from './components/ConciliacaoBancariaFilterComponent';

export default function ConciliacaoBancariaConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const theme = useTheme();

  const dispatch = useDispatch();

  const [fetchFilterParams, setFetchFilterParams] = useState([]);

  const [pageConfig, setPageConfig] = useState({
    currentPage: 0,
    pageSize: 10,
    filterInputValue: '',
  }); 

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const refresh = useSelector(selectRefresh);
  const conciliacoes = useSelector(selectConciliacoesBancariasView);
  const relatedSelectionConciliacaoBancariaList = useSelector(selectRelatedSelectionConciliacaoBancariaList);
  const reportRelatedModel = useSelector(selectRelatedReportModel);
  const selectedUnit = useSelector(selectUnidadeSelecionada);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isApprovalBatch = useMemo(() => isApprovalBatchMode(mode), [mode]);

  const fetchAll = useCallback(() => {
    dispatch(
      fetchAllViewAsync([
        {
          name: 'page',
          value: pageConfig.currentPage,
        },
        {
          name: 'size',
          value: pageConfig.pageSize,
        },
        {
          name: 'filter',
          value: pageConfig.filterInputValue,
        },
      ])
    );
  }, [pageConfig]);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const [conciliarDialogOpen, setConciliarDialogOpen] = useState(false);

  const handleConciliarDialogClose = useCallback(() => {
    setConciliarDialogOpen(false);
  }, []);

  const handleReverseErrorResponse = useCallback((reason) => {
    let responseMessage = reason?.response?.data?.message;

    if (!responseMessage && reason?.response.data) {
      responseMessage = reason?.response.data;
    }
    
    const mensagem = responseMessage ? `: ${responseMessage}.` : '.';

    const errorMessage = t('com.muralis.qcx.erro.erroEstornoConciliacaoBancaria', { mensagem });

    dispatch(failure());
    dispatch(
      setErrorFeedback({
        message: errorMessage,
      })
    );
  }, []);

  useEffect(() => {
    resetModes();
    resetModels();
  }, []);

  useEffect( () => {
    fetchAll();
  }, [fetchAll]);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionConciliacaoBancariaList ||
      (_.isArrayLikeObject(relatedSelectionConciliacaoBancariaList) &&
        _.isEmpty(relatedSelectionConciliacaoBancariaList)),
    [relatedSelectionConciliacaoBancariaList]
  );

  const handleEnableBatchApprovalMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToBatchApprovalMode());
  }, []);

  const handleSelectionModelChange = useCallback(
    ({ selectionModel }) => {
      if (isApprovalBatch) {
        dispatch(setRelatedSelectionConciliacaoBancariaList(selectionModel));
      }
    },
    [isApprovalBatch]
  );

  const numeroProcessoByTitulos = useCallback((titulos) => {
    const processos = (titulos || []).map((titulo) => titulo?.followUp?.numero);
    const distinctProcessos = [...new Set(processos)];

    return distinctProcessos.reduce((previous, current, index, array) => {
      if (index === 0) {
        return current;
      }
      if (array?.length > 1 && index === array?.length - 1) {
        return `${previous} e ${current}`;
      }

      return `${previous}, ${current}`;
    }, []);
  }, []);

  const favorecidoByTitulos = useCallback((titulos) => {
    const favorecidos = (titulos || []).map((titulo) => titulo?.favorecido?.pessoa?.nome);
    const distinctFavorecidos = [...new Set(favorecidos)];

    return distinctFavorecidos.reduce((previous, current, index, array) => {
      if (index === 0) {
        return current;
      }
      if (array?.length > 1 && index === array?.length - 1) {
        return `${previous} e ${current}`;
      }

      return `${previous}, ${current}`;
    }, []);
  }, []);

  const [handleEstornarClickMiddleware, operationOfEstornarById] = useOperationMiddleware(async (data) => {
    dispatch(loading());

    const payload = [
      {
        operacao: {
          description: OPERACAO_ESTORNO,
        },
        movimentacao: {
          id: data?.id,
        },
      },
    ];

    let response;

    if (data?.tipo === OperacaoMovimentacaoUtils.ENTRADA) {
      response = await numerarioImportacaoAPI.reverseMovimentacao(payload).catch(handleReverseErrorResponse);
    } else {
      response = await solicitacaoPagamentoAPI.reverseMovimentacao(payload).catch(handleReverseErrorResponse);
    }

    if (response?.status === 201) {
      dispatch(success());
      dispatch(
        setSuccessFeedback({
          message: t('com.muralis.qcx.mensagem.conciliacaoBancariaEstornada'),
        })
      );

      dispatch(conciliacaoBancariaActions.refresh());
    }
  }, []);

  const handleEstornarClick = useCallback(
    (row, popupState) => {
      const { id, tipo, titulos } = row;

      const processo = numeroProcessoByTitulos(titulos);

      const handleEstornarByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoEstorno').toUpperCase(),
              message: t('com.muralis.qcx.mensagem.desejaEstornarConciliacaoBancaria', {
                processo,
              }),
            },
          });

          const dataOfOperationEstornar = {
            id,
            tipo,
          };

          handleEstornarClickMiddleware(configureOperation, dataOfOperationEstornar);
        }
      };

      return handleEstornarByIdClick;
    },
    [handleEstornarClickMiddleware]
  );

  const [handleConciliarClickMiddleware, operationOfConciliarById] = useOperationMiddleware(async (data) => {
    dispatch(loading());

    const response = await conciliacaoBancariaAPI.conciliar(data?.id).catch((reason) => {
      const responseMessage = reason?.response?.data?.message;

      const mensagem = responseMessage ? `: ${responseMessage}.` : '.';

      const errorMessage = t('com.muralis.qcx.erro.erroConciliarConciliacaoBancaria', {
        mensagem,
        processo: data?.processo,
      });

      dispatch(failure());
      dispatch(
        setErrorFeedback({
          message: errorMessage,
        })
      );
    });

    if (response?.status === 200) {
      dispatch(success());
      dispatch(
        setSuccessFeedback({
          message: t('com.muralis.qcx.mensagem.conciliacaoBancariaConciliada'),
        })
      );

      dispatch(conciliacaoBancariaActions.refresh());
    }
  }, []);

  const handleConciliarClick = useCallback(
    (row, popupState) => {
      const { id, titulos } = row;

      const processo = numeroProcessoByTitulos(titulos);

      const handleConciliarByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoConciliacao').toUpperCase(),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoConcilarConciliacaoBancaria', {
                processo,
              }),
            },
          });

          const dataOfOperationConciliar = {
            id,
            processo,
          };

          handleConciliarClickMiddleware(configureOperation, dataOfOperationConciliar);
        }
      };

      return handleConciliarByIdClick;
    },
    [handleConciliarClickMiddleware]
  );

  const [handleDesconciliarClickMiddleware, operationOfDesconciliarById] = useOperationMiddleware(async (data) => {
    dispatch(loading());

    const response = await conciliacaoBancariaAPI.desconciliar(data?.id).catch((reason) => {
      let responseMessage = reason?.response?.data?.message;
      
      if (!responseMessage && reason?.response?.data) {
        responseMessage = reason?.response?.data
      }

      const mensagem = responseMessage ? `: ${responseMessage}.` : '.';

      const errorMessage = t('com.muralis.qcx.erro.erroDesconciliarConciliacaoBancaria', {
        mensagem,
        processo: data?.processo,
      });

      dispatch(failure());
      dispatch(
        setErrorFeedback({
          message: errorMessage,
        })
      );
    });

    if (response?.status === 200) {
      dispatch(success());
      dispatch(
        setSuccessFeedback({
          message: t('com.muralis.qcx.mensagem.conciliacaoBancariaDesconciliada'),
        })
      );

      dispatch(conciliacaoBancariaActions.refresh());
    }
  }, []);

  const handleDesconciliarClick = useCallback(
    (row, popupState) => {
      const { id, titulos } = row;

      const processo = numeroProcessoByTitulos(titulos);

      const handleDesconciliarByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoDesconciliacao').toUpperCase(),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoDesconcilarConciliacaoBancaria', {
                processo,
              }),
            },
          });

          const dataOfOperationDesconciliar = {
            id,
            processo,
          };

          handleDesconciliarClickMiddleware(configureOperation, dataOfOperationDesconciliar);
        }
      };

      return handleDesconciliarByIdClick;
    },
    [handleDesconciliarClickMiddleware]
  );

  const printValue = (value) => {
    console.log(value);
    return true;
  }

  const handleConfirmConciliarEmLote = useCallback(
    async (values) => {
      try {
        dispatch(loading());

        let selectedConciliacoesBancarias = relatedSelectionConciliacaoBancariaList.map((selectedConciliacaoId) => ({
          id: selectedConciliacaoId,
        }));

        if (values?.dataConciliacao) {
          selectedConciliacoesBancarias = selectedConciliacoesBancarias.map((c) => ({
            ...c,
            dataConciliacao: moment(values.dataConciliacao).toISOString(),
          }));
        }

        const response = await solicitacaoPagamentoAPI.conciliarEmLote(selectedConciliacoesBancarias);

        if (response?.status === 201) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.loteConciliacoesBancariasConciliado');

          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
          dispatch(resetMode());
          dispatch(resetRelatedSelectionConciliacaoBancariaList());
        }
      } catch (error) {
        const errorMessage = t('com.muralis.qcx.erro.conciliarConciliacaoBancariaLote');

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      } finally {
        setConciliarDialogOpen(false);
      }
    },
    [relatedSelectionConciliacaoBancariaList]
  );

  const handleCancelConciliarEmLote = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
    dispatch(resetRelatedSelectionConciliacaoBancariaList());
  }, []);

  const [handleImprimirClick, formDialogImpressaoStatus, handleFormDialogImpressaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: ConciliacaoBancariaUtils.CONCILIACAO_BANCARIA,
          })
        );
      }
    },
    []
  );
  const handleImprimirByDateSubmit = async (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    const dataInicial = normalizeData(event?.data?.dataInicial);
    const dataFinal = normalizeData(event?.data?.dataFinal);

    const handlers = {
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = t('com.muralis.qcx.mensagem.nenhumRelatorioGerar');

        dispatch(
          setWarningFeedback({
            message: infoMessage,
          })
        );
      },
      onError: () => {
        dispatch(failure());

        const errorMessage = t('com.muralis.qcx.mensagem.nenhumaConciliacaoBancariaEncontrada', {
          dataInicial,
          dataFinal,
        });

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    dispatch(
      generateReportByDateAsync({
        dataInicial,
        dataFinal,
        selectedUnit,
        handlers,
      })
    );

    handleFormDialogImpressaoStatus(DONE_STATUS);
  };


  const [origem, setOrigem] = useState('TODOS');

  const [extrato, setExtrato] = useState('TODOS');

  const [consolidada, setConsolidada] = useState('TODOS');

  const handleOrigemChange = (event) => {
    setOrigem(event.target.value);
  };

  const handleExtratoChange = (event) => {
    setExtrato(event.target.value);
  };

  const handleConsolidadaChange = (event) => {
    setConsolidada(event.target.value);
  };

  const [handleTransferirClick, formDialogTransferenciaStatus, handleFormDialogTransferenciaStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: ConciliacaoBancariaUtils.CONCILIACAO_BANCARIA,
          })
        );
      }
    },
    []
  );

  const handleTransferirSubmit = async (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    dispatch(loading());

    try {
      const dataTransferencia = normalizeData(event?.dataTransferencia);
      const valorTransferencia = normalizeNumeral(event?.valorTransferencia);

      const response = await conciliacaoBancariaAPI.efetuarTransferenciaBancaria({
        ...event,
        dataTransferencia,
        valorTransferencia,
      });

      if (response.status === 204) {
        dispatch(success());
        handleFormDialogTransferenciaStatus(CLOSED_STATUS);
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message ?? 'Erro ao efetuar transferência bancária';

      dispatch(failure());
      dispatch(
        setErrorFeedback({
          message: errorMessage,
        })
      );
    } finally {
      fetchAll();
    }
  };

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue, advancedFilter, ...props}) => {
    
    const { 
      contaBancariaPagamento, 
      cliente,
      valor_receber: valorReceber, 
      saldo, 
      data_vencimento: dataVencimento,
    } = advancedFilter;
    
    const filter = filterInputValue;

    const pageParams = [
      { name: 'page', value: currentPage },
      { name: 'size', value: pageSize },
    ];
    
    const filterParams = [
      { name: 'filter', value: filter },
      { name: 'origem', value: origem },
      { name: 'conta_bancaria_pagamento', value: contaBancariaPagamento },
      { name: 'cliente', value: cliente },
      { name: 'valor_receber', value: valorReceber },
      { name: 'extrato', value: extrato },
      { name: 'consolidada', value: consolidada },
      { name: 'saldo', value: valorReceber },
      { name: 'data_vencimento', value: dataVencimento },
    ];

    const params = pageParams.concat(filterParams);

    setFetchFilterParams(filterParams);

    dispatch(fetchAllViewAsync(params));

  }, [origem,extrato,consolidada])

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.NumeroProcesso'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.numeroProcesso || '-',
      },
      {
        field: 'origem',
        headerName: t('com.muralis.qcx.origem'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 160,
        valueGetter: ({ row }) => row?.origem || '-',
      },
      {
        field: 'bancoOrigem',
        headerName: t('com.muralis.qcx.contaBancaria.contaBancariaOrigem'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 160,
        valueGetter: ({ row }) => row?.banco || '-',
      },
      {
        field: 'favorecidoCliente',
        headerName: t('com.muralis.qcx.favorecidoCliente'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 120,
        valueGetter: ({ row }) => row?.fornecedorCliente || '-',
      },
      {
        field: 'solicitante',
        headerName: t('com.muralis.qcx.solicitante'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 140,
        valueGetter: ({ row }) => row?.solicitacaoPagamentante || '-',
      },
      {
        field: 'extrato',
        headerName: t('com.muralis.qcx.extrato'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 140,
        renderCell: ({ row }) => {
          const isEntrada = row?.tipo === OperacaoMovimentacaoUtils.ENTRADA;
          return (
            <Chip
              size="small"
              label={isEntrada ? t('com.muralis.qcx.modoPlano.credito') : t('com.muralis.qcx.modoPlano.debito')}
              style={{
                backgroundColor: isEntrada ? theme.palette.success.main : theme.palette.error.dark,
                color: isEntrada ? theme.palette.success.contrastText : theme.palette.error.contrastText,
              }}
            />
          );
        },
      },
      {
        field: 'valor',
        headerName: t('com.muralis.qcx.valor'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 150,
        valueGetter: ({ row }) => unnormalizeNumeral(row?.valor, formatBrazilianNumericDecimal(2)),
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) =>
          (
            ConciliacaoBancariaUtils.getLabelByValue(row?.conciliada) ||
            ConciliacaoBancariaUtils.getLabelByValue(ConciliacaoBancariaUtils.NAO_CONCILIADA)
          ).toUpperCase(),
      },
      {
        field: 'conciliacao',
        headerName: t('com.muralis.qcx.conciliacao'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 150,
        valueGetter: ({ row }) => formatDate(row?.dataConciliacao) || '-',
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 150,
        renderCell: ({ row: { id, tipo, conciliada, numeroProcesso } }) => (
          <>
            <QCXPopupState popupId="popup-conciliacao-bancaria">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${id}`}
                    name={`btn-more-options-${id}`}
                    disabled={isApprovalBatch}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color={isApprovalBatch ? 'disabled' : 'secondary'} size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={handleConciliarClick(
                        {
                          id,
                          numeroProcesso,
                        },
                        popupState
                      )}
                      disabled={
                        ConciliacaoBancariaUtils.isConciliada(conciliada) ||
                        TituloUtils.isOrigemTransferencia(tipo)
                      }
                    >
                      <QCXListItemIcon>
                        <CheckCircleIcon
                          style={{
                            color: 'green',
                          }}
                          fontSize="small"
                        />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.conciliar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleDesconciliarClick(
                        // TODO: MÉTODO DESCONCILIAR
                        {
                          id,
                          numeroProcesso,
                        },
                        popupState
                      )}
                      disabled={
                        !ConciliacaoBancariaUtils.isConciliada(conciliada) ||
                        TituloUtils.isOrigemTransferencia(tipo)
                      }
                    >
                      <QCXListItemIcon>
                        <RefreshIcon color="primary" fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.desconciliar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleEstornarClick(
                        {
                          id,
                          tipo,
                          numeroProcesso,
                        },
                        popupState
                      )}
                      disabled={
                        TituloUtils.isOrigemTransferencia(tipo) ||
                        ConciliacaoBancariaUtils.isConciliada(conciliada)
                      }
                    >
                      <QCXListItemIcon>
                        <RotateLeftIcon fontSize="small" color="error" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.estornar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [isApprovalBatch, numeroProcessoByTitulos]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.financeiro.conciliacaoBancaria'),
        },
        default: true,
      },
    ],
    []
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.financeiro.conciliacaoBancaria'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        buttonNew: {
          description: t('com.muralis.qcx.acoes.nova'),
          path: t('com.muralis.qcx.url.moduloFinanceiroConciliacaoBancariaNovo'),
          onClick: handleClickOnButtonNew,
        },
        others: [
          ...(isNone
            ? [
                {
                  description: 'Relatório',
                  color: 'primary',
                  onClick: handleImprimirClick,
                },
                {
                  description: 'Transferência',
                  color: 'primary',
                  onClick: handleTransferirClick,
                },
                {
                  description: <CheckCircleIcon size={20} color="white" />,
                  ...(_.isEmpty(conciliacoes || [])
                    ? {
                        disabled: true,
                        color: 'disabled',
                      }
                    : {
                        disabled: false,
                        style: {
                          backgroundColor: 'green',
                        },
                      }),
                  onClick: handleEnableBatchApprovalMode,
                },
              ]
            : []),
          ...(isApprovalBatch
            ? [
                {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  startIcon: <CheckCircleIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  },
                  onClick: () => setConciliarDialogOpen(true),
                  disabled: isEmptySelectionList,
                },
                {
                  description: <CancelOutlinedIcon />,
                  variant: 'outlined',
                  color: 'default',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.cancelar'),
                  },
                  onClick: handleCancelConciliarEmLote,
                },
              ]
            : []),
        ],
      },
      table: {
        ignoreControlButtonsLimit: true,
        columns,
        onConfigChangeCallback: handleConfigChange,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        rowsCount: conciliacoes.totalElements,
        checkboxSelection: isApprovalBatch,
        selectionModel: relatedSelectionConciliacaoBancariaList,
        onSelectionModelChange: handleSelectionModelChange,
        isRowSelectable: (content) =>
          printValue(content) &&
          !ConciliacaoBancariaUtils.isConciliada(content?.row?.conciliada) &&
          !TituloUtils.isOrigemTransferencia(content?.row?.origem),
      },
    }),
    [
      isNone,
      columns,
      breadcrumbs,
      conciliacoes,
      isApprovalBatch,
      isEmptySelectionList,
      handleSelectionModelChange,
      handleCancelConciliarEmLote,
      handleConfirmConciliarEmLote,
      handleEnableBatchApprovalMode,
    ]
  );

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={conciliacoes.content}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['conciliacao-bancaria']}
      advancedFilterFormChildren={(props) => (
        <ConciliacaoBancariaFilterComponent
          origem={origem}
          handleOrigemChange={handleOrigemChange}
          extrato={extrato}
          handleExtratoChange={handleExtratoChange}
          consolidada={consolidada}
          handleConsolidadaChange={handleConsolidadaChange}
          {...props} 
        />)}
      advancedFilterKeyAccessors={['data_solicitacao_pagamento','cliente','valor_receber','desconto','data_vencimento']}
    >
      <QCXConfirmDialog
        key="form-dialog-estorno-conciliacao-bancaria"
        id="form-dialog-estorno-conciliacao-bancaria"
        open={operationOfEstornarById?.active}
        title={operationOfEstornarById?.title}
        content={operationOfEstornarById?.message}
        endContent={operationOfEstornarById?.endMessage}
        onConfirm={operationOfEstornarById.confirm}
        onClose={operationOfEstornarById?.reset}
      />
      <QCXConfirmDialog
        key="form-dialog-conciliar-conciliacao-bancaria"
        id="form-dialog-conciliar-conciliacao-bancaria"
        open={operationOfConciliarById?.active}
        title={operationOfConciliarById?.title}
        content={operationOfConciliarById?.message}
        endContent={operationOfConciliarById?.endMessage}
        onConfirm={operationOfConciliarById.confirm}
        onClose={operationOfConciliarById?.reset}
      />
      <QCXConfirmDialog
        key="form-dialog-desconciliar-conciliacao-bancaria"
        id="form-dialog-desconciliar-conciliacao-bancaria"
        open={operationOfDesconciliarById?.active}
        title={operationOfDesconciliarById?.title}
        content={operationOfDesconciliarById?.message}
        endContent={operationOfDesconciliarById?.endMessage}
        onConfirm={operationOfDesconciliarById.confirm}
        onClose={operationOfDesconciliarById?.reset}
      />
      <QCXFinalFormDialog
        key="conciliacao-data-dialog"
        id="conciliacao-data-dialog"
        title="Confirmar"
        open={conciliarDialogOpen}
        onSubmit={handleConfirmConciliarEmLote}
        onClose={handleConciliarDialogClose}
      >
        {() => (
          <QCXFinalDatePickerField name="dataConciliacao" label="Data da Conciliação" required fullWidth autoFocus />
        )}
      </QCXFinalFormDialog>
      <QCXRelatorioConciliacaoBancariaDialogForm
        handleFormStatus={handleFormDialogImpressaoStatus}
        handleOnSubmit={handleImprimirByDateSubmit}
        formDialogStatus={formDialogImpressaoStatus}
        initialValues={reportRelatedModel}
      />
      <QCXTransferenciaBancariaDialogForm
        handleFormStatus={handleFormDialogTransferenciaStatus}
        handleOnSubmit={handleTransferirSubmit}
        formDialogStatus={formDialogTransferenciaStatus}
        initialValues={reportRelatedModel}
      />
    </SimpleConsultPageTemplate>
  );
}
