import { useState, useMemo } from 'react';
import { Button, Grid, FormControl } from '@mui/material';
import FormHeaderMedium from '../../../common/components/forms/formHeaderMedium';
import SelectStyled from '../../../common/components/input/selectStyled';
import LoadingIndicator from '../../../common/components/loadingIndicator';
import { useGetRequest, QueriesTypes } from '../../../common/hooks/queries';
import PageLayout from '../../../common/layouts/pageLayout';
import { generateSpreadsheet } from './relatorioLaPage.helpers';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MultiSelectStyled, { SimpleMultiSelectOption } from '../../../common/components/input/multiSelectStyled';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { Form, Field } from 'react-final-form';

const RelatorioLaPage = () => {
  const [processo, setProcesso] = useState<string | undefined>(undefined);
  const [inicioVigencia, setInicioVigencia] = useState<string | null>(null);
  const [fimVigencia, setFimVigencia] = useState<string | null>(null);
  const [selectedClients, setSelectedClients] = useState<any[]>([]);

  // Simulated fetching of data
  const {
    data: relatorioData,
    isError: isRelatorioError,
  } = useGetRequest(
    QueriesTypes.RelatorioLa, 
    undefined, 
    `processo=${processo}&inicio=${inicioVigencia}&fim=${fimVigencia}&clientes=${selectedClients.map(c => c.id).join(',')}`
  );

  const {
    data: danfeData,
  } = useGetRequest(QueriesTypes.Danfe);

  const {
    data: clientes = [],
  } = useGetRequest(QueriesTypes.Clientes);

  const processos = useMemo(() => {
    if (!danfeData || typeof danfeData !== 'object') {
      return [];
    }
    const filteredDanfeData = danfeData.filter((danfe: any) => danfe.tipoDanfe === 'ENTRADA');
    const processos: string[] = filteredDanfeData.map((danfe: any) => danfe.di.atributosAdicionais.processo);
    return processos.filter((processo, index) => processos.indexOf(processo) === index);
  }, [danfeData]);

  const handleSubmit = () => {
    if (relatorioData) {
      const inicioData = new Date(inicioVigencia ?? '');
      const fimData = new Date(fimVigencia ?? '');
      let inicioVigenciaDate;
      let fimVigenciaDate;
      if (inicioVigencia !== null && fimVigencia !== null) {
        inicioVigenciaDate = inicioData.toISOString().slice(0, 10);
        fimVigenciaDate = fimData.toISOString().slice(0, 10);
      } else {
        inicioVigenciaDate = null;
        fimVigenciaDate = null;
      }
      
      generateSpreadsheet(relatorioData, processo, selectedClients, inicioVigenciaDate, fimVigenciaDate);
    }
  };

  const handleClientsChange = (event: React.SyntheticEvent<Element, Event>, value: SimpleMultiSelectOption[] | null) => {
    // Update clients without reloading
    setSelectedClients(value || []);
  };

  return (
    <PageLayout
      title="Relatório LA"
      icon={<InsertDriveFileIcon color="secondary" />}
    >
      {false? (
        <LoadingIndicator message="Carregando Processos e Clientes" />
      ) : (
        <Form
          onSubmit={handleSubmit} // Handle form submission
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>

              {/* Avoid form auto-submit on input changes */}
              <Grid container spacing={2}>
                <FormHeaderMedium>Aguarde o botão de gerar ficar amarelo! Filtros são opcionais.</FormHeaderMedium>

                {/* Process Selection */}
                <Grid item sm={6} md={5} lg={3}>
                  <SelectStyled
                    options={processos.map((proc, index) => ({
                      id: index,
                      value: proc,
                    }))}
                    label="Processo"
                    required={false}
                    onChangeAction={(event, newValue) => {
                      setProcesso(newValue?.value);  // Update state without triggering re-render
                    }}
                  />
                </Grid>

                                {/* Date Range Pickers */}
                <Grid item xs={12} sm={4} md={2}>
                  <Field name="inicioVigencia" render={({ input }) => (
                    <QCXFinalDatePickerField
                      required={false} 
                      validate={false} 
                      id="date-picker-inicio"
                      key="date-picker-inicio"
                      label="Início da vigência"
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/YYYY"   
                      {...input}
                      onChange={(date: string | null) => {
                        setInicioVigencia(date); // Update the state with the selected date
                        input.onChange(date); // Sync the date with react-final-form
                      }}
                    />
                  )} />
                </Grid>

                <Grid item xs={12} sm={4} md={2}>
                  <Field name="fimVigencia" render={({ input }) => (
                    <QCXFinalDatePickerField
                      required={false} 
                      validate={false}
                      id="date-picker-fim"
                      key="date-picker-fim"
                      label="Fim da vigência"
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/YYYY"
                      {...input}
                      onChange={(date: string | null) => {
                        setFimVigencia(date); // Update the state with the selected date
                        input.onChange(date); // Sync the date with react-final-form
                      }}
                    />
                  )} />
                </Grid>

                {/* Clientes Multi-select */}
                <Grid item sm={12} style={{ margin: '25px 0 0 0' }}>
                  <FormControl fullWidth>
                    <MultiSelectStyled
                      options={clientes.map((client: { id: { toString: () => any; }; pessoa: { cnpj: any; nome: any; }; }) => ({
                        id: client.id?.toString(),
                        value: `(${client.pessoa.cnpj}) ${client.pessoa.nome}`,
                      }))}
                      onChangeAction={handleClientsChange} // Update state without triggering reload
                      label="Clientes"
                    />
                  </FormControl>
                </Grid>

                {/* Submit Button */}
                <Grid item sm={2} textAlign="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit" // Submit button for the form
                    fullWidth
                    disabled={isRelatorioError || !relatorioData}
                  >
                    Gerar Relatório
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      )}
    </PageLayout>
  );
};

export default RelatorioLaPage;
