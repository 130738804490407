import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../i18n';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  LOCKED_MODE,
  NONE_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_UPDATE_MODE,
  BACKGROUND_DELETE_MODE,
  NONE_BACKGROUND_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CONSULT_MODE,
  NONE_SUB_BACKGROUND_MODE,
  EXTERNAL_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  PERFORMING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  buscarNovosLancamentosAsync,
  consultarNFSeByFaturamentoIdAsync,
  fetchAllAsync,
  fetchAllReduzidoAsync,
  fetchByIdAsync,
  generateReportExtratoContaCorrenteAsync,
  removeDespesaAsync,
} from './faturamentoThunks';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
    subBackground: NONE_SUB_BACKGROUND_MODE,
  },
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {},
  list: [],
  listReduzido: [],
  countReduzido: 0,
  pagosRecebidos: [],
  model: {},
  related: {
    model: {
      report: {},
    },
  },
};

const faturamentoSlice = createSlice({
  name: 'faturamento',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    performingAction: (state) => {
      state.status = PERFORMING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToLockedMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    changeToExternalMode: (state) => {
      state.mode.main = EXTERNAL_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setPagosRecebidos: (state, action) => {
      state.pagosRecebidos = action.payload;
    },
    setImpostos: (state, action) => {
      state.impostos = action.payload;
    },
    resetPagosRecebidos: (state) => {
      state.pagosRecebidos = [];
    },
    resetImpostos: (state) => {
      state.impostos = [];
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    changeLastHistoryModel: (state, action) => {
      state.historyModel.last = action.payload;
    },
    resetHistoryModel: (state) => {
      state.historyModel = {};
    },
    resetLastHistoryModel: (state) => {
      state.historyModel.last = {};
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    setRelatedReportModel: (state, action) => {
      state.related.model.report = {
        ...action.payload,
      };
    },
    resetRelatedReportModel: (state) => {
      state.related.model.report = initialState.related.model.report;
    },
    setRelatedPagosRecebidos: (state, action) => {
      state.pagosRecebidos = action.payload;
    },
    setRelatedPagosPeloCliente: (state, action) => {
      state.pagosPeloCliente = action.payload;
    },
    setRelatedFaturamentoCapaId: (state, action) => {
      state.relatedFaturamentoCapaId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;

          return;
        }

        state.status = IDLE_STATUS;
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarFaturamento', { erro: action.error.message }),
        };
      })
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFaturamentos', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(generateReportExtratoContaCorrenteAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(generateReportExtratoContaCorrenteAsync.fulfilled, (state) => {
        state.status = SUCCESS_STATUS;
      })
      .addCase(generateReportExtratoContaCorrenteAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroGerarPDF', { erro: action.error.message }),
        };
      })
      .addCase(consultarNFSeByFaturamentoIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(consultarNFSeByFaturamentoIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;

        const nfseList = action?.payload?.response?.data;

        if (nfseList?.length > 0) {
          state.listReduzido = state.listReduzido.map((f) =>
            f.id === action.payload?.faturamentoId ? { ...f, nfseList } : f
          );
        }
      })
      .addCase(consultarNFSeByFaturamentoIdAsync.rejected, (state) => {
        state.status = FAILURE_STATUS;
      })
      .addCase(fetchAllReduzidoAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllReduzidoAsync.fulfilled, (state, action) => {
        state.listReduzido = action.payload.data.itens;
        state.countReduzido = action.payload.data.totalNumberOfItens;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllReduzidoAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFaturamentos', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(buscarNovosLancamentosAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(buscarNovosLancamentosAsync.fulfilled, (state, action) => {
        const novosPagosRecebidos = action?.payload?.response?.data;

        if (novosPagosRecebidos) {
          state.pagosRecebidos = novosPagosRecebidos;
        }

        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(buscarNovosLancamentosAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFaturamentos', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(removeDespesaAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(removeDespesaAsync.fulfilled, (state, action) => {
        const { despesaId, status } = action?.payload?.response;

        if (status === 204 || status === 200) {
          state.pagosRecebidos = state.pagosRecebidos.filter((p) => p.id !== despesaId);
        }

        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(removeDespesaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFaturamentos', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  performingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToLockedMode,
  changeToExternalMode,
  resetMode,
  setPagosRecebidos,
  resetPagosRecebidos,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  resetRelatedEtapasList,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setResponse,
  clearResponse,
  setError,
  clearError,
  setRelatedReportModel,
  resetRelatedReportModel,
  setRelatedPagosRecebidos,
  setRelatedPagosPeloCliente,
  setImpostos,
  resetImpostos,
  setRelatedFaturamentoCapaId,
} = faturamentoSlice.actions;

const faturamentoActions = faturamentoSlice.actions;

export {
  faturamentoSlice,
  faturamentoActions,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  performingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToLockedMode,
  changeToExternalMode,
  resetMode,
  setPagosRecebidos,
  resetPagosRecebidos,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  resetRelatedEtapasList,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setResponse,
  clearResponse,
  setError,
  clearError,
  setRelatedReportModel,
  resetRelatedReportModel,
  setImpostos,
  resetImpostos,
  setRelatedPagosRecebidos,
  setRelatedPagosPeloCliente,
  setRelatedFaturamentoCapaId,
};

export default faturamentoSlice.reducer;
