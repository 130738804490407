import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { despesaReceitaAPI } from '../../features/despesa-receita/despesaReceitaAPI';
import {
  fetchAllAsync,
  selectDespesasReceitasOperacional,
  setList,
} from '../../features/despesa-receita/despesaReceitaSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectDespesaReceitaOperacionalAutoComplete({
  label,
  name,
  initialValues,
  filterParams,
  onlyDespesa=false, // nova prop para filtrar somente 'DESPESA'
  ...restProps
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectDespesasReceitasOperacional);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    async function fetchData() {
      if (!isEmpty(filterParams)) {
        const response = await despesaReceitaAPI.fetchByFilter(filterParams);
        if (response?.status === 200) {
          dispatch(setList({ data: response?.data }));
        }
        return;
      }
      dispatch(fetchAllAsync());
    }
    fetchData();
  }, [filterParams]);

  useEffect(() => {
    // Aplica o filtro se a prop onlyDespesa estiver true
    const filteredList = onlyDespesa
      ? list.filter((item) => item.tipo === 'DESPESA')
      : list;

    const value = filteredList?.find(
      (item) => item?.id === _.get(initialValues, name)
    );

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }

    const fieldDescription =
      'ignorableFields.lancamento.despesaReceita.description';
    if (
      !value &&
      _.isNumber(_.get(initialValues, name)) &&
      _.get(initialValues, fieldDescription)
    ) {
      const valueEdit = _.get(
        initialValues,
        'ignorableFields.lancamento.despesaReceita'
      );
      setDefaultValue(valueEdit);
    }
  }, [list, initialValues, onlyDespesa]);

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option?.id} - ${option?.description}`,
  });

  // Aplica o filtro na lista de opções a ser passada ao componente de autocomplete
  const filteredList = onlyDespesa
    ? list.filter((item) => item.tipo === 'DESPESA')
    : list;

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={filteredList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) =>
        item?.id && item?.description
          ? `${item?.id} - ${item?.description}`
          : ''
      }
      {...restProps}
    />
  );
}
