import { IconButton } from '@material-ui/core';
import { Search as PageviewIcon } from '@material-ui/icons';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setErrorFeedback } from '../../../../../features/feedback/feedbackSlice';
import { followUpAPI } from '../../../../../features/follow-up/followUpAPI';
import QCXFinalTextField from '../../../../../shared-components/final-text-field/QCXFinalTextField';
import QCXInputAdornment from '../../../../../shared-components/input-adornment/QCXInputAdornment';

const SearchProcessoTextField = ({ onSearchCallback = [] }) => {
  const { t } = useTranslation();
  const form = useForm();
  const dispatch = useDispatch();

  const searchFollowUp = async () => {
    const { numeroProcesso } = form.getState().values;

    const response = await followUpAPI.fetchByFilter([
      { name: 'processo', value: numeroProcesso },
      { name: 'servicoProduto', value: 'EXPORTACAO' },
    ]);

    const followUp = response?.data?.[0];

    if (!followUp) {
      dispatch(setErrorFeedback({ message: 'Processo não encontrado.' }));
      return;
    }

    onSearchCallback?.(followUp);
  };

  return (
    <QCXFinalTextField
      id="text-field-numero-processo"
      key="text-field-numero-processo"
      name="numeroProcesso"
      label={t('com.muralis.qcx.numeroProcesso')}
      InputProps={{
        endAdornment: (
          <QCXInputAdornment position="end">
            <IconButton onClick={searchFollowUp}>
              <PageviewIcon htmlColor="#31278385" fontSize="small" />
            </IconButton>
          </QCXInputAdornment>
        ),
      }}
      required
    />
  );
};

export default SearchProcessoTextField;
