// src/components/modelo-tabela-sda/QCXModeloTabelaSdaInformacoesFinalFormTable.jsx

import React, { useCallback, useMemo } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { useFormState } from 'react-final-form';
import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import QCXFinalTextFieldAlt from '../../shared-components/final-text-field/QCXFinalTextFieldAlt';

/**
 * Componente que exibe e gerencia a lista de "Informações" (Ordem, Descrição),
 * usando o BondManager + Final Form.
 */
export default function QCXModeloTabelaSdaInformacoesFinalFormTable({
  list = [],
  isConsult = false,
  handleSubmit,
  handleChange,
}) {
  const { values } = useFormState();

  /** Colunas do dataGrid dentro do BondManager */
  const listProps = useMemo(() => ({
    name: 'informacoes',
    columns: [
      {
        field: 'ordem',
        headerName: 'Ordem',
        headerAlign: 'center',
        align: 'center',
        width: 200,
        valueGetter: ({ row }) => row?.ordem || '',
      },
      {
        field: 'descricao',
        headerName: 'Informação Atual',
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        valueGetter: ({ row }) => row?.descricao || '',
      },
    ],
  }), []);

  /** Campos do "formulário" interno do BondManager */
  const formProps = useMemo(() => ({
    rootName: 'novaInformacao',
    fields: [
      { name: 'ordem', label: 'Ordem' },
      { name: 'descricao', label: 'Informação Atual' },
    ],
  }), []);

  /** Adicionar item => gera id, atualiza lista */
  const handleAdd = useCallback(
    (newItem, currentList, handleSuccessAdd) => {
      const itemComId = {
        ...newItem,
        id: uuid(),
      };
      const updated = [...currentList, itemComId];
      handleSuccessAdd(updated);

      if (handleChange) handleChange(updated);
    },
    [handleChange]
  );

  const handleUpdate = useCallback(
    (updatedItem, currentList, handleSuccessUpdate) => {
      const updated = currentList.map((info) =>
        (info.id === updatedItem.id ? updatedItem : info)
      );
      handleSuccessUpdate(updated);
      if (handleChange) handleChange(updated);
    },
    [handleChange]
  );

  /** Verifica duplicado => mesma ordem ou mesma descricao, id diferente */
  const handleAlreadyExists = useCallback(
    (currentItem, existingList) =>
      existingList.some(
        (inf) =>
          inf.ordem === currentItem.ordem ||
          inf.descricao === currentItem.descricao &&
          inf.id !== currentItem.id
      ),
    []
  );

  return (
    <QCXFinalBondManager
      isParentConsult={isConsult}
      listProps={listProps}
      formProps={formProps}
      handleAdd={handleAdd}
      handleUpdate={handleUpdate}
      handleAlreadyExists={handleAlreadyExists}
      onSubmit={handleSubmit}
    >
      <Grid item xs={12} sm={4}>
        <QCXFinalTextFieldAlt
          label="Ordem"
          name="novaInformacao.ordem"
          select
          disabled={isConsult}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8].map((val) => (
            <MenuItem key={`ordem-${val}`} value={val}>
              {val}
            </MenuItem>
          ))}
        </QCXFinalTextFieldAlt>
      </Grid>

      <Grid item xs={12} sm={8}>
        <QCXFinalTextFieldAlt
          label="Informação Atual"
          name="novaInformacao.descricao"
          select
          disabled={isConsult}
          variant="outlined"
        >
          <MenuItem value="datachegada">Data de chegada</MenuItem>
          <MenuItem value="dataregistro">Data de Registro</MenuItem>
          <MenuItem value="numeroregistro">Número de registro</MenuItem>
          <MenuItem value="numerohouse">Número de House</MenuItem>
          <MenuItem value="numeromaster">Número de Master</MenuItem>
          <MenuItem value="referenciaprocesso">Referência processo</MenuItem>
          <MenuItem value="referenciacliente">Referência Cliente</MenuItem>
          <MenuItem value="valorcif">Valor CIF</MenuItem>
        </QCXFinalTextFieldAlt>
      </Grid>
    </QCXFinalBondManager>
  );
}
