import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createFilterOptions } from '@material-ui/lab';
import _ from 'lodash';
import QCXAutocompleteSelect from '../../../../../../shared-components/autocomplete-select/QCXAutocompleteSelect';
import { fetchAllAsync, fetchAllByEtapaIdAsync, selectOcorrencias } from '../../../../registrations/general/ocorrencias/api/ocorrenciaSlice';

function EtapaOcorrenciaAutoComplete({
  initialValues,
  name,
  etapaId,
  ...restProps
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (!etapaId) {
      dispatch(fetchAllAsync());
    } else {
      dispatch(fetchAllByEtapaIdAsync(etapaId));
    }
  }, [etapaId]);

  const ocorrencias = useSelector(selectOcorrencias);

  const getOptionLabel = useCallback(
    (item) => item.description,
    []
  );

  const getOptionValue = useCallback(
    (item) => item,
    []
  );

  useEffect(() => {
    const value = ocorrencias.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [ocorrencias, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item.description}`,
  });

  return (
    <QCXAutocompleteSelect
      label={t('com.muralis.qcx.listaFollowUp.ocorrencia')}
      id={name}
      name={name}
      options={ocorrencias}
      defaultValue={defaultValue}
      filterOptions={filterOptions}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...restProps}
    />
  );
}

export default EtapaOcorrenciaAutoComplete;
