import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function SelectNumeroPoEasy({ capa = false, ...props }) {
  const list = useMemo(() => {
    const options = [
      {
        id: 'NUMERO_CONHECIMENTO',
        label: 'Número do Conhecimento',
        value: 'NUMERO_CONHECIMENTO',
      },
      {
        id: 'NUMERO_FATURA',
        label: 'Número da Fatura',
        value: 'NUMERO_FATURA',
      },
      {
        id: 'NUMERO_PROCESSO',
        label: 'Número do Processo',
        value: 'NUMERO_PROCESSO',
      },
      {
        id: 'REFERENCIA_CLIENTE',
        label: 'Referência do Cliente',
        value: 'REFERENCIA_CLIENTE',
      },
    ];

    if (!capa) {
      options.push({
        id: 'NUMERO_PEDIDO',
        label: 'Número do Pedido',
        value: 'NUMERO_PEDIDO',
      });
    }

    return options;
  }, []);

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectNumeroPoEasy;
