import { isFunction } from 'lodash';
import React, { useCallback } from 'react';
import { parseToString } from '../../utils/general/parse-utils';
import QCXTextField from '../text-field/QCXTextField';
import QCXFinalFieldWrapperAlt from '../final-field/QCXFinalFieldWrapperAlt';

export default function QCXFinalTextFieldAlt({ parse = undefined, noSpacesAllowed = false, ...restProps }) {
  const decoratedParseWithTrimStart = useCallback(
    (...args) => {
      const [value, ...restArgs] = args;
      let sanitizedValue = parseToString(value).trimStart();

      if (noSpacesAllowed) {
        sanitizedValue = sanitizedValue.replace(/\s/g, '');
      }

      if (isFunction(parse)) {
        sanitizedValue = parse(sanitizedValue, ...restArgs);
      }

      return sanitizedValue;
    },
    [parseToString, isFunction]
  );

  return <QCXFinalFieldWrapperAlt component={QCXTextField} parse={decoratedParseWithTrimStart} {...restProps} />;
}
