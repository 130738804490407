import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import QCXPerfilWizardFinalForm from '../../../../../components/perfil/QCXPerfilWizardFinalForm';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import { register, save } from '../../../../../features/perfil/perfilAPI';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  selectBackgroundMode,
  selectMode,
  selectPerfil,
  selectStatus,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
} from '../../../../../features/perfil/perfilSlice';
import { changeControlTo as changeControlUnidadeDeNegocioTo } from '../../../../../features/unidade-negocio/unidadeNegocioSlice';
import { changeControlTo as changeControlGrupoUnidadeDeNegocioTo } from '../../../../../features/grupo-unidade-negocio/grupoUnidadeNegocioSlice';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import PerfilUtils from '../../../../../utils/general/perfil/PerfilUtils';
import { normalizeNumeral } from '../../../../../utils/general/general-utils';

export default function PerfilRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const perfil = useSelector(selectPerfil);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  useEffect(() => {
    dispatch(changeControlUnidadeDeNegocioTo(IDLE_CONTROL));
    dispatch(changeControlGrupoUnidadeDeNegocioTo(IDLE_CONTROL));
  }, []);

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? perfil?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((unnormalizedData) => {
    let valorLimite = null;

    if (unnormalizedData?.atributos?.valorLimite?.[0]) {
      valorLimite = unnormalizedData?.atributos?.valorLimite?.[0];
    }

    const normalizedData = {
      ...unnormalizedData,
      atributos: {
        ...unnormalizedData?.atributos,
        visualizaEtapaPrivada: PerfilUtils.parseToSingletonEtapaList(
          unnormalizedData?.atributos?.visualizaEtapaPrivada
        ),
        visualizaTipoDocumentoPrivado: PerfilUtils.parseToSingletonTipoDocumentoList(
          unnormalizedData?.atributos?.visualizaTipoDocumentoPrivado
        ),
        valorLimite: [String(normalizeNumeral(valorLimite))],
      },
    };

    return normalizedData;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
      atributos: {
        ...normalizedData?.atributos,
        visualizaEtapaPrivada: PerfilUtils.getSingletonEtapaListBy(normalizedData?.atributos?.visualizaEtapaPrivada),
        visualizaTipoDocumentoPrivado: PerfilUtils.getSingletonTipoDocumentoListBy(
          normalizedData?.atributos?.visualizaTipoDocumentoPrivado
        ),
      },
    };

    return unnormalizedData;
  }, []);

  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 1;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));
            dispatch(fetchByIdAsync(created?.id));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await save(data);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(t('com.muralis.qcx.url.moduloGeraisPerfis'));

              dispatch(success());

              const saved = response?.data;

              dispatch(setModel(saved));

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.perfilCadastrado', { nome: data?.nome }),
                })
              );

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await save(data);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }

            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }
        }
      } catch (error) {
        dispatch(failure());

        let errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosPerfil');

        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarPerfilEspecifico', { erro: error?.response.data.message });
        }

        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.perfilSalvo', { nome: saved?.nome }),
              })
            );

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosPerfil');

        if (error?.response?.data?.hasError && error?.response?.data?.message) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarPerfilEspecifico', {
            erro: error?.response.data.message,
          });
        }

        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);
    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event) {
      event.stopPropagation();
    }

    const normalizedData = normalize(perfil);
    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const model = useMemo(
    () =>
      isCreated || !isCreate
        ? unnormalize(perfil)
        : {
            id: null,
            nome: null,
            atributos: {
              unidadesDeNegocio: [],
              clientes: [],
              followups: [],
              grupo: [{ id: '' }],
              padrao: [{ id: '' }],
            },
            permissoesAcesso: [],
          },
    [isCreate, isCreated, perfil, unnormalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.cadastros'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
          name: t('com.muralis.qcx.gerais'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloGeraisPerfis'),
          name: t('com.muralis.qcx.perfis.labelPlural'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.perfis.novoPerfil')
        : t('com.muralis.qcx.perfis.perfilEspecifico', { identificacao: perfil?.nome || perfil?.id || '-' }),
    [isNone, isCreate, isBackgroundCreate, perfil]
  );

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXPerfilWizardFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleAlternativeSave={handleAlternativeSave}
          authInfo={authInfo}
          requiredRoles={['perfil']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
