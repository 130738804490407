import { DespesasCapa, DespesasItem } from './totvsEscritaDespesasPage.types';
import { CorrespondenciasListItem, ICorrelation } from '../../totvs.types';
import { fillAndAlignString, formatDateToDDMMAAAA, saveTxtFile } from '../totvsEscritaPage.helpers';

export const generateDespesasTXT = (
  despesasCapa: DespesasCapa[],
  despesasItens: DespesasItem[],
  fileName: string | undefined
) => {
  const despesasCapaFirst = despesasCapa[0];

  let numerosDanfeString;
  if (despesasCapa.length > 1)
    numerosDanfeString = `${despesasCapaFirst?.numero_danfe} a ${despesasCapa[despesasCapa.length - 1]?.numero_danfe}`;
  else numerosDanfeString = despesasCapaFirst?.numero_danfe?.toString() || '';

  const totalNota = despesasCapa.map((d) => Number(d.valor_total_danfe)).reduce((a, b) => a + b, 0);

  const despesasCapaTXT = despesasCapaFirst
    ? flattenDespesasCapa(despesasCapaFirst, numerosDanfeString, totalNota)
    : '';
  const despesasItensTXT = despesasItens
    .map((despesasItem) => flattenDespesasItem(despesasItem, despesasCapaFirst))
    .join('');
  const despesasTXT = [despesasCapaTXT, despesasItensTXT].join('');
  saveTxtFile(despesasTXT, 'DI' + fileName);
};

const flattenDespesasCapa = (despesasCapa: DespesasCapa, numerosDanfeString: string, totalNota: number) => {
  let despesasCapaTXT = '';
  despesasCapaTXT += fillAndAlignString(despesasCapa.tipo_do_registro || '', 2, 'left');
  despesasCapaTXT += fillAndAlignString(
    (despesasCapa.numero_processo_easy ?? despesasCapa.referencia_despachante) || '',
    18,
    'left'
  );
  despesasCapaTXT += fillAndAlignString(formatDateToDDMMAAAA(despesasCapa.data_chegada) || '', 8, 'left');
  despesasCapaTXT += fillAndAlignString(
    formatDateToDDMMAAAA(despesasCapa.data_recebimento_documentos) || '',
    8,
    'left'
  );
  despesasCapaTXT += fillAndAlignString(despesasCapa.codigo_despachante || '', 3, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.agente_transportador_id || '', 3, 'left');
  despesasCapaTXT += fillAndAlignString(formatDateToDDMMAAAA(despesasCapa.data_pagamento_impostos) || '', 8, 'left');

  if (despesasCapa.is_duimp) {
    despesasCapaTXT += fillAndAlignString(' ', 10, 'left');
  } else {
    despesasCapaTXT += fillAndAlignString(
      despesasCapa.numero_de_registro_di ? despesasCapa.numero_de_registro_di.replace(/[-\/]/g, '') : '',
      10,
      'left'
    );
  }

  despesasCapaTXT += fillAndAlignString(Number(despesasCapa.taxa_mercadoria || 0).toFixed(8), 15, 'left', true, 8);
  despesasCapaTXT += fillAndAlignString(formatDateToDDMMAAAA(despesasCapa.data_desembaraco) || '', 8, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.moeda_frete || '', 3, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.valor_frete_moeda, 15, 'left', true, 2, '0');
  despesasCapaTXT += fillAndAlignString(Number(despesasCapa.taxa_frete || 0).toFixed(8), 15, 'left', true, 8);
  despesasCapaTXT += fillAndAlignString(Number(despesasCapa.valor_seguro_moeda || 0).toFixed(2), 15, 'left', true);
  despesasCapaTXT += fillAndAlignString(despesasCapa.moeda_seguro || '', 3, 'left');
  despesasCapaTXT += fillAndAlignString(Number(despesasCapa.taxa_seguro || 0).toFixed(8), 15, 'left', true, 8);
  despesasCapaTXT += fillAndAlignString(Number(despesasCapa.taxa_dolar_di || 0).toFixed(8), 15, 'left', true, 8);
  despesasCapaTXT += fillAndAlignString(despesasCapa.referencia_despachante || '', 15, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.id_master || '', 18, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.tipo_declaracao || '', 2, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.urf_despacho || '', 7, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.urf_entrada || '', 7, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.recinto || '', 7, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.modalidade_de_despacho || '', 1, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.tipo_conhecimento || '', 2, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.tipo_documento || '', 2, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.utilizacao || '', 1, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.identificacao_documento_chegada_carga || '', 15, 'left');
  despesasCapaTXT += fillAndAlignString(Number(despesasCapa.peso_bruto || 0).toFixed(4), 15, 'left', true, 4);
  despesasCapaTXT += fillAndAlignString(Number(despesasCapa.fob_moeda || 0).toFixed(2), 15, 'left', true);
  despesasCapaTXT += fillAndAlignString(despesasCapa.fatura_servicos || '', 6, 'left');
  despesasCapaTXT += fillAndAlignString(numerosDanfeString, 20, 'left');
  despesasCapaTXT += fillAndAlignString(formatDateToDDMMAAAA(despesasCapa.data_danfe) || '', 8, 'left');
  despesasCapaTXT += fillAndAlignString(totalNota.toFixed(2), 15, 'left', true);
  despesasCapaTXT += fillAndAlignString(formatDateToDDMMAAAA(despesasCapa.date_entrega_mercadoria) || '', 8, 'left');
  despesasCapaTXT += fillAndAlignString(formatDateToDDMMAAAA(despesasCapa.data_registro_di) || '', 8, 'left');
  despesasCapaTXT += fillAndAlignString(Number(despesasCapa.valor_moeda_collect || 0).toFixed(2), 15, 'left', true);
  despesasCapaTXT += fillAndAlignString(
    Number(despesasCapa.valor_territorio_nacional_moeda || 0).toFixed(2),
    15,
    'left',
    true
  );
  despesasCapaTXT += fillAndAlignString(despesasCapa.observacoes?.slice(0, 250) || '', 250, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.local_desembaraco || '', 30, 'left');
  despesasCapaTXT += fillAndAlignString(despesasCapa.uf_desembaraco || '', 2, 'left');

  if (despesasCapa.is_duimp) {
    despesasCapaTXT += fillAndAlignString(despesasCapa.numero_duimp || ' ', 15, 'left');
    despesasCapaTXT += fillAndAlignString('0001', 4, 'left'); // versao hardcoded por enquanto
  }

  despesasCapaTXT += '\n';
  const isSameCurrency =
    despesasCapa.moeda_mle === despesasCapa.moeda_frete && despesasCapa.moeda_mle === despesasCapa.moeda_seguro;
  if (isSameCurrency) {
    despesasCapaTXT += fillAndAlignString(despesasCapa.tipo_registro_tx, 2, 'left');
    despesasCapaTXT += fillAndAlignString(
      despesasCapa.numero_processo_easy || despesasCapa.numero_do_processo,
      18,
      'left'
    );
    despesasCapaTXT += fillAndAlignString(despesasCapa.moeda_mle, 3, 'left');
    despesasCapaTXT += fillAndAlignString(Number(despesasCapa.taxa_mercadoria).toFixed(8), 15, 'left', true, 8);
  } else {
    despesasCapaTXT += fillAndAlignString(despesasCapa.tipo_registro_tx, 2, 'left');
    despesasCapaTXT += fillAndAlignString(
      despesasCapa.numero_processo_easy || despesasCapa.numero_do_processo,
      18,
      'left'
    );
    despesasCapaTXT += fillAndAlignString(despesasCapa.moeda_mle, 3, 'left');
    despesasCapaTXT += fillAndAlignString(Number(despesasCapa.taxa_mercadoria).toFixed(8), 15, 'left', true, 8);
    if (despesasCapa.moeda_mle != despesasCapa.moeda_frete) {
      despesasCapaTXT += fillAndAlignString('\n' + despesasCapa.tipo_registro_tx, 3, 'left');
      despesasCapaTXT += fillAndAlignString(
        despesasCapa.numero_processo_easy || despesasCapa.numero_do_processo,
        18,
        'left'
      );
      despesasCapaTXT += fillAndAlignString(despesasCapa.moeda_frete, 3, 'left');
      despesasCapaTXT += fillAndAlignString(Number(despesasCapa.taxa_frete).toFixed(8), 15, 'left', true, 8);
    }
    if (despesasCapa.moeda_mle != despesasCapa.moeda_seguro && Number(despesasCapa.valor_seguro_moeda) > 0) {
      despesasCapaTXT += fillAndAlignString('\n' + despesasCapa.tipo_registro_tx, 3, 'left');
      despesasCapaTXT += fillAndAlignString(
        despesasCapa.numero_processo_easy || despesasCapa.numero_do_processo,
        18,
        'left'
      );
      despesasCapaTXT += fillAndAlignString(despesasCapa.moeda_seguro, 3, 'left');
      despesasCapaTXT += fillAndAlignString(Number(despesasCapa.taxa_seguro).toFixed(8), 15, 'left', true, 8);
    }
  }
  return despesasCapaTXT;
};

const flattenDespesasItem = (despesasItem: DespesasItem, despesasCapa: DespesasCapa) => {
  let despesasItemTXT = '\n';
  despesasItemTXT += despesasItem.tipo_do_registro;
  despesasItemTXT += fillAndAlignString(despesasCapa.numero_processo_easy, 18, 'left');
  despesasItemTXT += fillAndAlignString(formatDateToDDMMAAAA(despesasItem.data_pagamento), 8, 'left');
  despesasItemTXT += fillAndAlignString(despesasItem.codigo_despesa, 3, 'left');
  despesasItemTXT += fillAndAlignString(Number(despesasItem.valor_despesa).toFixed(2), 17, 'right', true);
  despesasItemTXT += fillAndAlignString(despesasItem.efetivo, 1, 'left');
  despesasItemTXT += fillAndAlignString(despesasItem.despesas_paga_por, 1, 'left');
  despesasItemTXT += fillAndAlignString(despesasItem.adiantamento, 1, 'left');
  despesasItemTXT += fillAndAlignString(despesasItem.filler, 277, 'left');
  return despesasItemTXT;
};

export const verifyDespesasCorrelations = (
  despesasCapa: DespesasCapa[],
  despesasItens: DespesasItem[],
  correspondencia: CorrespondenciasListItem | undefined
) => {
  let errors: string[] = [];
  despesasCapa.forEach((despesaCapa) => {
    const despachantes = correspondencia?.correspondenciasGerais.filter((item) => item.tipo === 'Despachante');
    if (despachantes) {
      const despachante = despachantes.find((d) => d.quickcomexId == despesaCapa.codigo_despachante);
      if (despachante) {
        despesaCapa.codigo_despachante = despachante.totvsId;
      } else {
        errors.push(`Correspondência de Despachante ${despesaCapa.codigo_despachante} não encontrada.`);
      }
    } else {
      errors.push(`Correspondência de Despachante ${despesaCapa.codigo_despachante} não encontrada.`);
    }
    if (despesaCapa.agente_transportador_id) {
      const agentesDeCarga = correspondencia?.correspondenciasGerais.filter((item) => item.tipo === 'Agente de Carga');
      if (agentesDeCarga) {
        const agenteDeCarga = agentesDeCarga.find((d) => d.quickcomexId == despesaCapa.agente_transportador_id);
        if (agenteDeCarga) {
          despesaCapa.agente_transportador_id = agenteDeCarga.totvsId;
        } else {
          despesaCapa.agente_transportador_id = '   ';
        }
      } else {
        despesaCapa.agente_transportador_id = '   ';
      }
    }
  });
  despesasItens.forEach((despesaItem) => {
    const despesas = correspondencia?.correspondenciasGerais.filter((item) => item.tipo === 'Despesas/Receitas');
    if (despesas) {
      const despesa = despesas.find((d) => d.quickcomexId == despesaItem.codigo_despesa);
      if (despesa) {
        despesaItem.codigo_despesa = despesa.totvsId;
      } else {
        errors.push(`Correspondência de Despesa ${despesaItem.codigo_despesa} não encontrada.`);
      }
    } else {
      errors.push(`Correspondência de Despesa ${despesaItem.codigo_despesa} não encontrada.`);
    }
  });
  return errors;
};
