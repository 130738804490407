// src/components/faturamento/QCXPagosPeloClienteFormTable.jsx

import React, { useCallback, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';

import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import { maxValue } from '../../utils/validators/field/validator';
import { isStrictEquals, normalizeNumeral, unnormalizeNumeral, formatDate } from '../../utils/general/general-utils';

import { convertLocalDateTimeStringToUTC } from '../../utils/general/dateTime-utils';
import { vinculoPagosPeloClienteSelectors, vinculoPagosPeloClienteActions } from '../../features/vinculo-pagos-pelo-cliente/vinculoPagosPeloClienteSlice';
import QCXSelectDespesaReceitaOperacionalAutoComplete from '../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaOperacionalAutoComplete';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import { despesaReceitaAPI } from '../../features/despesa-receita/despesaReceitaAPI';
import { fetchById } from '../../features/fornecedor-favorecido/FornecedorAPI';
import QCXSelectFornecedorAutocomplete from '../../shared-components/select-fornecedor-favorecido-autocomplete/QCXSelectFornecedorAutocomplete';
import QCXFinalDateTimePickerField from '../../shared-components/final-date-time-picker-field/QCXFinalDateTimePickerField';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '16px 0',
  },
}));

export default function QCXPagosPeloClienteFormTable({
  isEdicaoBloqueada,
  isPreparingAction,
  isPerformingAction,
  isSubUpdate,
  formValues,
  isCreate,
  formState,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const status = formValues?.status;
  const isSolicitado = status==='SOLICITADO'

  const pagosPeloClienteReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoPagosPeloClienteSelectors.selectStatus,
        selectMode: vinculoPagosPeloClienteSelectors.selectMode,
        selectModel: vinculoPagosPeloClienteSelectors.selectModel,
      },
      actions: {
        loading: vinculoPagosPeloClienteActions.loading,
        resetStatus: vinculoPagosPeloClienteActions.resetStatus,
        changeToUpdateMode: vinculoPagosPeloClienteActions.changeToUpdateMode,
        changeToCreateMode: vinculoPagosPeloClienteActions.changeToCreateMode,
        resetMode: vinculoPagosPeloClienteActions.resetMode,
        setModel: vinculoPagosPeloClienteActions.setModel,
        resetModel: vinculoPagosPeloClienteActions.resetModel,
      },
    }),
    []
  );

  const pagosPeloClienteFormProps = useMemo(
    () => ({
      rootName: 'draftFields.current.pagoPeloCliente',
      fields: [
        {
          name: 'despesaReceita.id',
          label: t('com.muralis.qcx.despesa.despesaReceita'),
        },
        { name: 'totalMoeda', label: t('com.muralis.qcx.moeda.valorMoeda') },
        { name: 'totalReal', label: t('com.muralis.qcx.moeda.valorReais') },
        { name: 'fornecedor.id', label: 'Fornecedor' },
        { name: 'dataPagamento', label: t('com.muralis.qcx.dataPagamento') },
      ],
    }),
    [t]
  );

  const pagosPeloClienteListProps = useMemo(
    () => ({
      name: 'draftFields.current.pagosPeloCliente',
      columns: [
        {
          field: 'description',
          headerName: t('com.muralis.qcx.descricao'),
          flex: 90,
          headerAlign: 'center',
          align: 'center',
          valueGetter: ({ row }) => row?.despesaReceita?.description || row?.solicitacaoPagamento?.despesaReceita?.description || row?.titulo?.description || '',
        },
        {
          field: 'totalMoeda',
          headerName: t('com.muralis.qcx.moeda.valorMoeda'),
          flex: 90,
          headerAlign: 'center',
          align: 'center',
          valueGetter: ({ row }) => row?.totalMoeda || '',
        },
        {
          field: 'totalReal',
          headerName: t('com.muralis.qcx.moeda.valorReais'),
          flex: 90,
          headerAlign: 'center',
          align: 'center',
          valueGetter: ({ row }) => row?.totalReal || '',
        },
        {
          field: 'fornecedor',
          headerName: 'Fornecedor',
          flex: 90,
          headerAlign: 'center',
          align: 'center',
          valueGetter: ({ row }) => row?.fornecedor?.pessoa?.nomeResumido || row?.fornecedor?.pessoa?.nome || '',
        },
        {
          field: 'dataPagamento',
          headerName: t('com.muralis.qcx.dataPagamento'),
          flex: 90,
          headerAlign: 'center',
          align: 'center',
          valueGetter: ({ row }) => row?.dataPagamento ? formatDate(convertLocalDateTimeStringToUTC(row?.dataPagamento)) : '',
        },
      ],
    }),
    [t]
  );

  const handleAddDespesaReceitaVinculo = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(currentVinculo?.despesaReceita?.id);
      const fornecedorResponse = await fetchById(currentVinculo?.fornecedor?.id);

      if (despesaReceitaResponse?.status === 200) {
        const vinculo = {
          id: uuid(),
          ...currentVinculo,
          despesaReceita: {
            ...despesaReceitaResponse?.data,
          },
          fornecedor: {
            ...fornecedorResponse?.data,
          },
        };

        const updatedVinculos = [...currentVinculos, vinculo];

        handleSuccessAddVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleUpdateDespesaReceitaVinculo = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(currentVinculo?.despesaReceita?.id);

      if (despesaReceitaResponse?.status === 200) {
        const updatedVinculo = {
          ...currentVinculo,
          despesaReceita: {
            ...despesaReceitaResponse?.data,
          },
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );


  const handlePagoPeloClienteAlreadyExists = useCallback(() => false, []);

  const handleValorSolicitadoMoedaOnBlur = useCallback(
    (form, values) => async () => {
      const despesaReceitaId = Number(_.get(values, 'draftFields.current.pagoPeloCliente.despesaReceita.id'));

      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(despesaReceitaId);

      if (despesaReceitaResponse?.status !== 200) {
        return;
      }

      const foundDespesaReceita = despesaReceitaResponse?.data;

      const moedaId = foundDespesaReceita?.moeda?.id;

      const valorSolicitadoMoeda = normalizeNumeral(_.get(values, 'draftFields.current.pagoPeloCliente.totalMoeda'));

      if (moedaId && (valorSolicitadoMoeda || valorSolicitadoMoeda === 0)) {
        const taxaConversao = normalizeNumeral(values?.taxaCambio);

        const valorEmReaisPath = 'draftFields.current.pagoPeloCliente.totalReal';

        if (!taxaConversao) {
          form.change(valorEmReaisPath, '0,00');

          return;
        }

        const valorEmReais = (valorSolicitadoMoeda * taxaConversao).toFixed(2);

        const formattedValorEmReais = unnormalizeNumeral(valorEmReais, formatBrazilianNumericDecimal(2));

        form.change(valorEmReaisPath, formattedValorEmReais);
      }
    },
    [unnormalizeNumeral, formatBrazilianNumericDecimal]
  );

  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle className={classes.subtitle} title={t('com.muralis.qcx.pagosPeloCliente')} />
      </Grid>

      <QCXFinalBondManagerOld
        listProps={pagosPeloClienteListProps}
        formProps={pagosPeloClienteFormProps}
        reducerConfig={pagosPeloClienteReducerConfig} // O slice que criamos
        handleAdd={handleAddDespesaReceitaVinculo}
        handleUpdate={handleUpdateDespesaReceitaVinculo}
        handleAlreadyExists={handlePagoPeloClienteAlreadyExists}
        disableButtons={isPreparingAction || isPerformingAction || !isSubUpdate || (!isSolicitado && !isCreate)}
        disableRemove={!isSolicitado && !isCreate}
        readOnlyMode={isEdicaoBloqueada}
      >
        {/* Campos do "form" interno para adicionar/editar um item */}
        <>
          <Grid item xs={12} sm={12} md={6}>
            <QCXSelectDespesaReceitaOperacionalAutoComplete
              id="autocomplete-select-despesa-receita-capa-field"
              key="autocomplete-select-despesa-receita-capa-field"
              name="draftFields.current.pagoPeloCliente.despesaReceita.id"
              label={t('com.muralis.qcx.despesa.despesaReceita')}
              onlyDespesa
              initialValues={formValues}
              disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
            />
          </Grid>

          <Grid item className={classes.gridFields} xs={12} sm={12} md={3}>
            <QCXFinalAlternativeCurrencyField
              id="totalMoeda"
              key="totalMoeda"
              name="draftFields.current.pagoPeloCliente.totalMoeda"
              label={t('com.muralis.qcx.moeda.valorMoeda')}
              onBlur={handleValorSolicitadoMoedaOnBlur(formState, formValues)}
              validate={maxValue(999999999999)}
              disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
              formatOnBlur
            />
          </Grid>

          <Grid item className={classes.gridFields} xs={12} sm={12} md={3}>
            <QCXFinalAlternativeCurrencyField
              id="totalReal"
              key="totalReal"
              name="draftFields.current.pagoPeloCliente.totalReal"
              label={t('com.muralis.qcx.moeda.valorReais')}
              intl={BRAZILIAN}
              validate={maxValue(999999999999)}
              adorned
              disabled
              formatOnBlur
            />
          </Grid>

          <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
            <QCXSelectFornecedorAutocomplete
              id="autocomplete-select-fornecedor-field"
              key="autocomplete-select-fornecedor-field"
              name="draftFields.current.pagoPeloCliente.fornecedor.id"
              label="Fornecedor"
              initialValues={formValues}
              disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
            />
          </Grid>

          <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
            <QCXFinalDateTimePickerField
              id="dataPagamento"
              key="dataPagamento"
              name="draftFields.current.pagoPeloCliente.dataPagamento"
              label={t('com.muralis.qcx.dataPagamento')}
              disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
              formatOnBlur
            />
          </Grid>
        </>
      </QCXFinalBondManagerOld>
    </>
  );
}
