// API_BASE_URL
const QCX_API_BASE_URL = process.env.REACT_APP_QCX_API_BASE_URL;

// COMMON
const QCX_ACTIVATE_ENDPOINT = process.env.REACT_APP_QCX_API_ACTIVATE_ENDPOINT;
const QCX_INACTIVATE_ENDPOINT = process.env.REACT_APP_QCX_API_INACTIVATE_ENDPOINT;
const QCX_CALCULATE_ENDPOINT = process.env.REACT_APP_QCX_API_CALCULATE_ENDPOINT;
const QCX_CONFERIR_ENDPOINT = process.env.REACT_APP_QCX_API_CONFERIR_ENDPOINT;
const QCX_DUPLICAR_ENDPOINT = process.env.REACT_APP_QCX_API_DUPLICAR_ENDPOINT;
const QCX_RETIFICAR_ENDPOINT = process.env.REACT_APP_QCX_API_RETIFICAR_ENDPOINT;
const QCX_LIBERAR_ENDPOINT = process.env.REACT_APP_QCX_API_LIBERAR_ENDPOINT;
const QCX_RETRANSMITIR_ENDPOINT = process.env.REACT_APP_QCX_API_RETRANSMITIR_ENDPOINT;
const QCX_STORAGE_ENDPOINT = process.env.REACT_APP_QCX_API_STORAGE_ENDPOINT;
const QCX_DOWNLOAD_ENDPOINT = process.env.REACT_APP_QCX_API_DOWNLOAD_ENDPOINT;
const QCX_FILES_ENDPOINT = process.env.REACT_APP_QCX_API_FILES_ENDPOINT;
const QCX_EMAIL_ENDPOINT = process.env.REACT_APP_QCX_API_EMAIL_ENDPOINT;
const QCX_TRANSFERENCIA_ENDPOINT = process.env.REACT_APP_QCX_API_TRANSFERENCIA_ENDPOINT;
const QCX_ESTORNO_ENDPOINT = process.env.REACT_APP_QCX_API_ESTORNO_ENDPOINT;
const QCX_MERCADORIA_MOVIMENTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_MERCADORIA_MOVIMENTACAO_ENDPOINT;
const QCX_DANFE_ADICAO_MOVIMENTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_DANFE_ADICAO_MOVIMENTACAO_ENDPOINT;
const QCX_CONTA_ENDPOINT = process.env.REACT_APP_QCX_API_CONTA_ENDPOINT;
const QCX_SALDO_ENDPOINT = process.env.REACT_APP_QCX_API_SALDO_ENDPOINT;
const QCX_CANCELAMENTO_ENDPOINT = process.env.REACT_APP_QCX_API_CANCELAMENTO_ENDPOINT;
const QCX_RELATORIO_ENDPOINT = process.env.REACT_APP_QCX_API_RELATORIO_ENDPOINT;
const REPORT_ENDPOINT = process.env.REACT_APP_QCX_API_REPORT_ENDPOINT;
const CALCULO_ENDPOINT = process.env.REACT_APP_QCX_API_CALCULO_ENDPOINT;
const ITENS_ENDPOINT = process.env.REACT_APP_QCX_API_ITENS_ENDPOINT;
const PROCESS_XML_ENDPOINT = process.env.REACT_APP_QCX_API_PROCESS_XML_ENDPOINT;
const SOLICITAR_ENDPOINT = process.env.REACT_APP_QCX_API_SOLICITAR_ENDPOINT;
const QCX_CANCELAR_ENDPOINT = process.env.REACT_APP_QCX_API_CANCELAR_ENDPOINT;
const QCX_LISTAGEM_ENDPOINT = process.env.REACT_APP_QCX_API_LISTAGEM_ENDPOINT;
const QCX_APROVAR_ENDPOINT = process.env.REACT_APP_QCX_API_APROVAR_ENDPOINT;
const QCX_RECUSAR_ENDPOINT = process.env.REACT_APP_QCX_API_RECUSAR_ENDPOINT;
const QCX_APROVAR_LOTE_ENDPOINT = process.env.REACT_APP_QCX_API_APROVAR_LOTE_ENDPOINT;
const QCX_SOLICITADAS_ENDPOINT = process.env.REACT_APP_QCX_API_SOLICITADAS_ENDPOINT;
const QCX_VIEW_ENDPOINT = process.env.REACT_APP_VIEW;
const QCX_TITULOS_ENDPOINT = process.env.REACT_APP_QCX_API_TITULO;
const QCX_TITULOS_MOVIMENTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_TITULO_MOVIMENTACAO;
const QCX_PENDENTE_ENDPOINT = process.env.REACT_APP_QCX_API_PENDENTE;
const QCX_DATA_INICIAL_ENDPOINT = process.env.REACT_APP_QCX_API_DATA_INICIAL_ENDPOINT;
const QCX_DATA_FINAL_ENDPOINT = process.env.REACT_APP_QCX_API_DATA_FINAL_ENDPOINT;
const FATURAR_ENDPOINT = process.env.REACT_APP_QCX_API_FATURAR_ENDPOINT;
const DEMONSTRACAO_DESPESA_ENDPOINT = process.env.REACT_APP_QCX_API_DEMONSTRACAO_DESPESA_ENDPOINT;
const DESTINO_ENDPOINT = process.env.REACT_APP_QCX_API_DESTINO_ENDPOINT;
const ORIGEM_ENDPOINT = process.env.REACT_APP_QCX_API_ORIGEM_ENDPOINT;
const TRANSFERIR_ENDPOINT = process.env.REACT_APP_QCX_API_TRANSFERIR_ENDPOINT;
const EXTRATO_ENDPOINT = process.env.REACT_APP_QCX_API_EXTRATO_ENDPOINT;
const NOTIFICACAO_ENDPOINT = process.env.REACT_APP_QCX_API_NOTIFICACAO_ENDPOINT;
const EMITIR_ENDPOINT = process.env.REACT_APP_QCX_API_EMITIR_ENDPOINT;
const NFSE_ENDPOINT = process.env.REACT_APP_QCX_API_NFSE_ENDPOINT;
const CONSULTAR_NFSE_ENDPOINT = process.env.REACT_APP_QCX_API_CONSULTAR_NFSE_ENDPOINT;

// CADASTROS
const FORNECEDOR_ENDPOINT = process.env.REACT_APP_QCX_API_FORNECEDOR_FAVORECIDO;
const COTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_COTACAO_ENDPOINT;
const MOEDA_ENDPOINT = process.env.REACT_APP_QCX_API_MOEDA_ENDPOINT;
const TABELA_SDA_ENDPOINT = process.env.REACT_APP_QCX_API_TABELA_SDA_ENDPOINT;
const MODELO_TABELA_SDA_ENDPOINT = process.env.REACT_APP_QCX_API_MODELO_TABELA_SDA_ENDPOINT;
const PLANO_DE_CONTA_ENDPOINT = process.env.REACT_APP_QCX_API_PLANO_DE_CONTA_ENDPOINT;
const DESPACHANTE_ENDPOINT = process.env.REACT_APP_QCX_API_DESPACHANTE_ENDPOINT;
const ETAPA_ENDPOINT = process.env.REACT_APP_QCX_API_ETAPA_ENDPOINT;
const ETAPA_DTO_ENDPOINT = process.env.REACT_APP_QCX_API_ETAPA_DTO_ENDPOINT;
const ETAPAS_ENDPOINT = process.env.REACT_APP_QCX_API_ETAPAS_ENDPOINT;
const DESVIO_ENDPOINT = process.env.REACT_APP_QCX_API_DESVIO_ENDPOINT;
const OCORRENCIA_ENDPOINT = process.env.REACT_APP_QCX_API_OCORRENCIA_ENDPOINT;
const PAIS_ENDPOINT = process.env.REACT_APP_QCX_API_PAIS_ENDPOINT;
const ESTADO_ENDPOINT = process.env.REACT_APP_QCX_API_ESTADO_ENDPOINT;
const CIDADE_ENDPOINT = process.env.REACT_APP_QCX_API_CIDADE_ENDPOINT;
const UNIDADE_NEGOCIO_ENDPOINT = process.env.REACT_APP_QCX_API_UNIDADE_NEGOCIO_ENDPOINT;
const TIPO_DOCUMENTO_ENDPOINT = process.env.REACT_APP_QCX_API_TIPO_DOCUMENTO_ENDPOINT;
const MODELO_DE_FOLLOWUP = process.env.REACT_APP_QCX_API_MODELO_DE_FOLLOWUP_ENDPOINT;
const MODELO_NUMERARIO_ENDPOINT = process.env.REACT_APP_QCX_API_MODELO_NUMERARIO_ENDPOINT;
const MODELO_FATURAMENTO_ENDPOINT = process.env.REACT_APP_QCX_API_MODELO_FATURAMENTO_ENDPOINT;
const MODELO_DANFE_ENDPOINT = process.env.REACT_APP_QCX_API_MODELO_DANFE_ENDPOINT;
const SERVICO_ENDPOINT = process.env.REACT_APP_QCX_API_SERVICO_ENDPOINT;
const SERVICOS_ENDPOINT = process.env.REACT_APP_QCX_API_SERVICOS_ENDPOINT;
const CLIENTE_ENDPOINT = process.env.REACT_APP_QCX_API_CLIENTE_ENDPOINT;
const IMPORTADOR_ENDPOINT = process.env.REACT_APP_QCX_API_IMPORTADOR_ENDPOINT;
const EXPORTADOR_FABRICANTE_ENDPOINT = process.env.REACT_APP_QCX_API_EXPORTADOR_FABRICANTE;
const VIA_DE_TRANSPORTE = process.env.REACT_APP_QCX_API_VIA_DE_TRANSPORTE_ENDPOINT;
const FILTER_ENDPOINT = process.env.REACT_APP_QCX_API_FILTER_ENDPOINT;
const FIND_ENDPOINT = process.env.REACT_APP_QCX_API_FIND_ENDPOINT;
const CUSTOM_ENDPOINT = process.env.REACT_APP_QCX_API_CUSTOM_ENDPOINT;
const TIPO_DE_DECLARACAO = process.env.REACT_APP_QCX_API_TIPO_DE_DECLARACAO_ENDPOINT;
const TIPO_DE_CALCULO_ENDPOINT = process.env.REACT_APP_QCX_API_TIPO_DE_CALCULO_ENDPOINT;
const EMBARCACAO_ENDPOINT = process.env.REACT_APP_QCX_API_EMBARCACAO_ENDPOINT;
const CFOP_OPERACAO_FISCAL_ENDPOINT = process.env.REACT_APP_QCX_API_CFOP_OPERACAO_FISCAL_ENDPOINT;
const UNIDADE_DE_MEDIDA_ENDPOINT = process.env.REACT_APP_QCX_API_UNIDADE_DE_MEDIDA_ENDPOINT;
const ARMAZEM_ENDPOINT = process.env.REACT_APP_QCX_API_ARMAZEM_ENDPOINT;
const RECINTO_ADUANEIRO_ENDPOINT = process.env.REACT_APP_QCX_API_RECINTO_ADUANEIRO_ENDPOINT;
const UNIDADE_LOCAL_RFB_ENDPOINT = process.env.REACT_APP_QCX_API_UNIDADE_LOCAL_RFB_ENDPOINT;
const SETOR_LOTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_SETOR_LOTACAO_ENDPOINT;
const TABELA_ARMAZENAGEM_ENDPOINT = process.env.REACT_APP_QCX_API_TABELA_DE_ARMAZENAGEM_ENDPOINT;
const TIPO_DE_CONTAINER_ENDPOINT = process.env.REACT_APP_QCX_API_TIPO_DE_CONTAINER_ENDPOINT;
const INCOTERM_CONDICAO_DE_VENDA_ENDPOINT = process.env.REACT_APP_QCX_API_INCOTERM_CONDICAO_VENDA_ENDPOINT;
const NALADI_SH_ENDPOINT = process.env.REACT_APP_QCX_API_NALADI_SH_ENDPOINT;
const NALADI_NCCA_ENDPOINT = process.env.REACT_APP_QCX_API_NALADI_NCCA_ENDPOINT;
const CATALOGO_PRODUTOS_ENDPOINT = process.env.REACT_APP_QCX_API_CATALOGO_PRODUTOS_ENDPOINT;
const NVE_ENDPOINT = process.env.REACT_APP_QCX_API_NVE_ENDPOINT;
const NVE_ATRIBUTO_ENDPOINT = process.env.REACT_APP_QCX_API_NVE_ATRIBUTO_ENDPOINT;
const NVE_ESPECIFICACAO_ENDPOINT = process.env.REACT_APP_QCX_API_NVE_ESPECIFICACAO_ENDPOINT;
const CAMPOS_ADICIONAIS_ENDPOINT = process.env.REACT_APP_QCX_API_CAMPOS_ADICIONAIS_ENDPOINT;

const NCM_EXPORTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_NCM_EXPORTACAO_ENDPOINT;

const NCM_ATRIBUTO_EXPORTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_NCM_ATRIBUTO_EXPORTACAO_ENDPOINT;

const NCM_DOMINIO_ATRIBUTO_EXPORTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_NCM_ATRIBUTO_DOMINIO_EXPORTACAO_ENDPOINT;

const NCM_SUBITEM = process.env.REACT_APP_QCX_API_NCM_SUBITEM_ENDPOINT;
const TAXA_CONVERSAO_CAMBIO_ENDPOINT = process.env.REACT_APP_QCX_API_TAXA_CONVERSAO_CAMBIO_ENDPOINT;
const CNAE_ENDPOINT = process.env.REACT_APP_QCX_API_CNAE_ENDPOINT;
const MODELO_INFORMACOES_COMPLEMENTARES_ENDPOINT =
  process.env.REACT_APP_QCX_API_MODELO_INFORMACOES_COMPLEMENTARES_ENDPOINT;
const METODO_DE_VALORACAO_ADUANEIRA_ENDPOINT = process.env.REACT_APP_QCX_API_METODO_DE_VALORACAO_ADUANEIRA_ENDPOINT;
const DESPESA_RECEITA_ENDPOINT = process.env.REACT_APP_QCX_API_DESPESA_RECEITA_ENDPOINT;
const GRUPO_DESPESA_RECEITA_ENDPOINT = process.env.REACT_APP_QCX_API_GRUPO_DESPESA_RECEITA_ENDPOINT;
const DISPOSITIVO_LEGAL_ENDPOINT = process.env.REACT_APP_QCX_DISPOSITIVO_LEGAL_ENDPOINT;
const TRANSPORTADOR_INTERNACIONAL_ENDPOINT = process.env.REACT_APP_QCX_TRANSPORTADOR_INTERNACIONAL_ENDPOINT;
const TRANSPORTADORA_ENDPOINT = process.env.REACT_APP_QCX_TRANSPORTADORA_ENDPOINT;
const PORTO_AEROPORTO_ENDPOINT = process.env.REACT_APP_QCX_PORTO_AEROPORTO_ENDPOINT;
const BANCO_ENDPOINT = process.env.REACT_APP_QCX_BANCO_ENDPOINT;
const CONTA_BANCARIA_ENDPOINT = process.env.REACT_APP_QCX_API_CONTA_BANCARIA_ENDPOINT;
const REGIME_TRIBUTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_REGIME_TRIBUTACAO_ENDPOINT;
const ACORDO_ALADI_ENDPOINT = process.env.REACT_APP_QCX_API_ACORDO_ALADI_ENDPOINT;
const INSTITUICAO_FINANCIADORA_ENDPOINT = process.env.REACT_APP_QCX_API_INSTITUICAO_FINANCIADORA_ENDPOINT;
const FUNDAMENTO_LEGAL_REGIME_TRIBUTACAO_II_ENDPOINT =
  process.env.REACT_APP_QCX_API_FUNDAMENTO_LEGAL_REGIME_TRIBUTACAO_II_ENDPOINT;
const FUNDAMENTO_LEGAL_PIS_COFINS_ENDPOINT = process.env.REACT_APP_QCX_API_FUNDAMENTO_LEGAL_PIS_COFINS_ENDPOINT;
const FUNDAMENTO_LEGAL_REDUCAO_PIS_COFINS_ENDPOINT =
  process.env.REACT_APP_QCX_API_FUNDAMENTO_LEGAL_REDUCAO_PIS_COFINS_ENDPOINT;
const FUNDAMENTACAO_ESPECIFICA_ENDPOINT = process.env.REACT_APP_QCX_API_FUNDAMENTACAO_ESPECIFICA_ENDPOINT;
const ORGAO_ANUENTE_ENDPOINT = process.env.REACT_APP_QCX_API_ORGAO_ANUENTE_ENDPOINT;
const ORGAO_EMISSOR_ATO_LEGAL_ENDPOINT = process.env.REACT_APP_QCX_API_ORGAO_EMISSOR_ATO_LEGAL_ENDPOINT;
const TIPO_ATO_LEGAL_ENDPOINT = process.env.REACT_APP_QCX_API_TIPO_ATO_LEGAL_ENDPOINT;
const TIPO_RECIPIENTE_ENDPOINT = process.env.REACT_APP_QCX_API_TIPO_RECIPIENTE_ENDPOINT;
const TRATAMENTO_TRIBUTARIO_ENDPOINT = process.env.REACT_APP_QCX_API_TRATAMENTO_TRIBUTARIO_ENDPOINT;
const MOTIVO_IMPORTACAO_SEM_COBERTURA_CAMBIAL_ENDPOINT =
  process.env.REACT_APP_QCX_API_MOTIVO_IMPORTACAO_SEM_COBERTURA_CAMBIAL_ENDPOINT;
const MOTIVO_ADMISSAO_TEMPORARIA_ENDPOINT = process.env.REACT_APP_QCX_API_MOTIVO_ADMISSAO_TEMPORARIA_ENDPOINT;
const MODALIDADE_PAGAMENTO_ENDPOINT = process.env.REACT_APP_QCX_API_MODALIDADE_PAGAMENTO_ENDPOINT;
const PERFIL_ENDPOINT = process.env.REACT_APP_QCX_API_PERFIL_ENDPOINT;
const USUARIO_ENDPOINT = process.env.REACT_APP_QCX_API_USUARIO_ENDPOINT;
const DOCUMENTO_INSTRUCAO_DESPACHO_ENDPOINT = process.env.REACT_APP_QCX_API_DOCUMENTO_INSTRUCAO_DESPACHO_ENDPOINT;
const TIPO_EMBALAGEM_NACIONAL_ENDPOINT = process.env.REACT_APP_QCX_API_TIPO_EMBALAGEM_NACIONAL_ENDPOINT;

const ACRESCIMO_VALORACAO_ADUANEIRA_ENDPOINT = process.env.REACT_APP_QCX_API_ACRESCIMO_VALORACAO_ADUANEIRA_ENDPOINT;

const DEDUCAO_VALORACAO_ADUANEIRA_ENDPOINT = process.env.REACT_APP_QCX_API_DEDUCAO_VALORACAO_ADUANEIRA_ENDPOINT;

const CODIGO_RECEITA_ENDPOINT = process.env.REACT_APP_QCX_API_CODIGO_RECEITA_ENDPOINT;
const GRUPO_UNIDADE_NEGOCIO_ENDPOINT = process.env.REACT_APP_QCX_API_GRUPO_UNIDADE_NEGOCIO_ENDPOINT;

// FOLLOW_UP
const FOLLOW_UP_ENDPOINT = process.env.REACT_APP_QCX_API_FOLLOW_UP_ENDPOINT;
const ETAPA_FOLLOW_UP_ENDPOINT = process.env.REACT_APP_QCX_API_ETAPA_FOLLOW_UP_ENDPOINT;

// DADOS_EMBARQUE
const DADOS_EMBARQUE_ENDPOINT = process.env.REACT_APP_QCX_API_DADOS_EMBARQUE_ENDPOINT;

// CUSTEIOS
const CUSTEIOS_ENDPOINT = process.env.REACT_APP_QCX_API_CUSTEIOS_ENDPOINT;

// FATURA
const FATURA_ENDPOINT = process.env.REACT_APP_QCX_API_FATURA_ENDPOINT;

// REABRIR
const REABRIR_ENDPOINT = process.env.REACT_APP_QCX_API_REABRIR_ENDPOINT;

// LICENCA_IMPORTACAO
const LICENCA_IMPORTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_LICENCA_IMPORTACAO_ENDPOINT;

// DECLARACAO_IMPORTACAO
const DECLARACAO_IMPORTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_DECLARACAO_IMPORTACAO_ENDPOINT;
const DI_ENDPOINT = process.env.REACT_APP_QCX_API_DI_ENDPOINT;
const EMISSAO_ICMS_ENDPOINT = process.env.REACT_APP_QCX_API_DI_EMISSAO_ICMS_RIO_ENDPOINT;
const EMISSAO_GLME_SP_ENDPOINT = process.env.REACT_APP_QCX_API_DI_EMISSAO_GLME_SP_ENDPOINT;

// ADICAO
const ADICAO_ENDPOINT = process.env.REACT_APP_QCX_API_ADICAO_ENDPOINT;

// DANFE
const DANFE_ENDPOINT = process.env.REACT_APP_QCX_API_DANFE_ENDPOINT;

const IMPORT_SDA_ENDPOINT = process.env.REACT_APP_QCX_API_IMPORT_SDA_ENDPOINT;

// NUMERARIO_IMPORTACAO
const NUMERARIO_IMPORTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_NUMERARIO_IMPORTACAO_ENDPOINT;
const NOTIFICAR_FILA_ENDPOINT = process.env.REACT_APP_QCX_API_NOTIFICACAO_FILA_ENDPOINT;

// EXPORTACAO

const DETALHAMENTO_OPERACAO_SEM_NOTA_FISCAL_ENDPOINT =
  process.env.REACT_APP_QCX_API_DETALHAMENTO_OPERACAO_SEM_NOTA_FISCAL_ENDPOINT;

const SITUACAO_ESPECIAL_DESPACHO_ENDPOINT = process.env.REACT_APP_QCX_API_SITUACAO_ESPECIAL_DESPACHO_ENDPOINT;

const TIPO_DOCUMENTO_FISCAL_ENDPOINT = process.env.REACT_APP_QCX_API_TIPO_DOCUMENTO_FISCAL_ENDPOINT;

const FORMA_EXPORTACAO_ENDPOINT = process.env.REACT_APP_QCX_API_FORMA_EXPORTACAO_ENDPOINT;

const VIA_ESPECIAL_TRANSPORTE_ENDPOINT = process.env.REACT_APP_QCX_API_VIA_ESPECIAL_TRANSPORTE_ENDPOINT;

const TRATAMENTO_PRIORITARIO_ENDPOINT = process.env.REACT_APP_QCX_API_TRATAMENTO_PRIORITARIO_ENDPOINT;

const ENQUADRAMENTO_OPERACAO_ENDPOINT = process.env.REACT_APP_QCX_API_ENQUADRAMENTO_OPERACAO_ENDPOINT;

const MODELO_NOTA_FISCAL_ENDPOINT = process.env.REACT_APP_QCX_API_MODELO_NOTA_FISCAL_ENDPOINT;

const TIPO_DOCUMENTO_DUE_ENDPOINT = process.env.REACT_APP_QCX_API_TIPO_DOCUMENTO_DUE_ENDPOINT;

const TIPO_ATO_CONCESSORIO_ENDPOINT = process.env.REACT_APP_QCX_API_TIPO_ATO_CONCESSORIO_ENDPOINT;

const TIPO_CE_ENDPOINT = process.env.REACT_APP_QCX_API_TIPO_CE_ENDPOINT;

// FINANCEIRO
const QCX_SOLICITACAO_PAGAMENTO_ENDPOINT = process.env.REACT_APP_QCX_API_SOLICITACAO_PAGAMENTO_ENDPOINT;

const QCX_CONTAS_A_PAGAR_ENDPOINT = process.env.REACT_APP_QCX_API_CONTAS_A_PAGAR;

const QCX_FINANCEIRO_A_PAGAR_ENDPOINT = process.env.REACT_APP_QCX_API_CONTAS_PAGAR;

const QCX_CONCILIACAO_ENDPOINT = process.env.REACT_APP_QCX_API_CONCILIACAO;

const QCX_CONCILIACAO_BANCARIA_ENDPOINT = process.env.REACT_APP_QCX_API_CONCILIACAO_BANCARIA;

const QCX_CONCILIAR_ENDPOINT = process.env.REACT_APP_QCX_API_CONCILIAR;

const QCX_CONTAS_RECEBER_ENDPOINT = process.env.REACT_APP_QCX_API_CONTAS_RECEBER;

const QCX_CONTAS_A_RECEBER_ENDPOINT = process.env.REACT_APP_QCX_API_CONTAS_A_RECEBER;

const QCX_DESCONCILIAR_ENDPOINT = process.env.REACT_APP_QCX_API_DESCONCILIAR;

const QCX_FATURAMENTO_ENDPOINT = process.env.REACT_APP_QCX_API_FATURAMENTO_ENDPOINT;

// INTEGRAÇÃO
const DEMANDA_OCR_ENDPOINT = process.env.REACT_APP_QCX_API_DEMANDA_OCR_ENDPOINT;

export {
  QCX_API_BASE_URL,
  QCX_ACTIVATE_ENDPOINT,
  QCX_INACTIVATE_ENDPOINT,
  QCX_CALCULATE_ENDPOINT,
  QCX_CONFERIR_ENDPOINT,
  QCX_DUPLICAR_ENDPOINT,
  QCX_RETIFICAR_ENDPOINT,
  QCX_LIBERAR_ENDPOINT,
  QCX_RETRANSMITIR_ENDPOINT,
  QCX_STORAGE_ENDPOINT,
  QCX_DOWNLOAD_ENDPOINT,
  QCX_FILES_ENDPOINT,
  QCX_EMAIL_ENDPOINT,
  QCX_TRANSFERENCIA_ENDPOINT,
  QCX_ESTORNO_ENDPOINT,
  QCX_MERCADORIA_MOVIMENTACAO_ENDPOINT,
  QCX_DANFE_ADICAO_MOVIMENTACAO_ENDPOINT,
  QCX_CONTA_ENDPOINT,
  QCX_SALDO_ENDPOINT,
  QCX_RELATORIO_ENDPOINT,
  QCX_CANCELAMENTO_ENDPOINT,
  REPORT_ENDPOINT,
  CALCULO_ENDPOINT,
  ITENS_ENDPOINT,
  PROCESS_XML_ENDPOINT,
  SOLICITAR_ENDPOINT,
  QCX_CANCELAR_ENDPOINT,
  QCX_LISTAGEM_ENDPOINT,
  QCX_APROVAR_ENDPOINT,
  QCX_APROVAR_LOTE_ENDPOINT,
  QCX_RECUSAR_ENDPOINT,
  QCX_SOLICITADAS_ENDPOINT,
  QCX_VIEW_ENDPOINT,
  QCX_TITULOS_ENDPOINT,
  QCX_TITULOS_MOVIMENTACAO_ENDPOINT,
  QCX_PENDENTE_ENDPOINT,
  QCX_DATA_INICIAL_ENDPOINT,
  QCX_DATA_FINAL_ENDPOINT,
  FATURAR_ENDPOINT,
  REABRIR_ENDPOINT,
  DEMONSTRACAO_DESPESA_ENDPOINT,
  DESTINO_ENDPOINT,
  ORIGEM_ENDPOINT,
  TRANSFERIR_ENDPOINT,
  EXTRATO_ENDPOINT,
  NOTIFICACAO_ENDPOINT,
  EMITIR_ENDPOINT,
  NFSE_ENDPOINT,
  CONSULTAR_NFSE_ENDPOINT,
};

export {
  NOTIFICAR_FILA_ENDPOINT,
  COTACAO_ENDPOINT,
  MOEDA_ENDPOINT,
  TABELA_SDA_ENDPOINT,
  MODELO_TABELA_SDA_ENDPOINT,
  PLANO_DE_CONTA_ENDPOINT,
  DESPACHANTE_ENDPOINT,
  ETAPA_ENDPOINT,
  ETAPA_DTO_ENDPOINT,
  ETAPAS_ENDPOINT,
  CAMPOS_ADICIONAIS_ENDPOINT,
  OCORRENCIA_ENDPOINT,
  DESVIO_ENDPOINT,
  PAIS_ENDPOINT,
  ESTADO_ENDPOINT,
  CIDADE_ENDPOINT,
  UNIDADE_NEGOCIO_ENDPOINT,
  TIPO_DOCUMENTO_ENDPOINT,
  MODELO_DE_FOLLOWUP,
  MODELO_NUMERARIO_ENDPOINT,
  MODELO_FATURAMENTO_ENDPOINT,
  MODELO_DANFE_ENDPOINT,
  SERVICO_ENDPOINT,
  SERVICOS_ENDPOINT,
  CLIENTE_ENDPOINT,
  IMPORTADOR_ENDPOINT,
  EXPORTADOR_FABRICANTE_ENDPOINT,
  VIA_DE_TRANSPORTE,
  FILTER_ENDPOINT,
  FIND_ENDPOINT,
  CUSTOM_ENDPOINT,
  TIPO_DE_DECLARACAO,
  TIPO_DE_CALCULO_ENDPOINT,
  EMBARCACAO_ENDPOINT,
  CFOP_OPERACAO_FISCAL_ENDPOINT,
  UNIDADE_DE_MEDIDA_ENDPOINT,
  ARMAZEM_ENDPOINT,
  RECINTO_ADUANEIRO_ENDPOINT,
  UNIDADE_LOCAL_RFB_ENDPOINT,
  SETOR_LOTACAO_ENDPOINT,
  TABELA_ARMAZENAGEM_ENDPOINT,
  TIPO_DE_CONTAINER_ENDPOINT,
  TIPO_RECIPIENTE_ENDPOINT,
  TRATAMENTO_TRIBUTARIO_ENDPOINT,
  INCOTERM_CONDICAO_DE_VENDA_ENDPOINT,
  NALADI_SH_ENDPOINT,
  NALADI_NCCA_ENDPOINT,
  CATALOGO_PRODUTOS_ENDPOINT,
  NVE_ENDPOINT,
  NVE_ATRIBUTO_ENDPOINT,
  NVE_ESPECIFICACAO_ENDPOINT,
  NCM_SUBITEM,
  TAXA_CONVERSAO_CAMBIO_ENDPOINT,
  CNAE_ENDPOINT,
  METODO_DE_VALORACAO_ADUANEIRA_ENDPOINT,
  MODELO_INFORMACOES_COMPLEMENTARES_ENDPOINT,
  DESPESA_RECEITA_ENDPOINT,
  GRUPO_DESPESA_RECEITA_ENDPOINT,
  DISPOSITIVO_LEGAL_ENDPOINT,
  TRANSPORTADOR_INTERNACIONAL_ENDPOINT,
  TRANSPORTADORA_ENDPOINT,
  PORTO_AEROPORTO_ENDPOINT,
  BANCO_ENDPOINT,
  CONTA_BANCARIA_ENDPOINT,
  REGIME_TRIBUTACAO_ENDPOINT,
  ACORDO_ALADI_ENDPOINT,
  INSTITUICAO_FINANCIADORA_ENDPOINT,
  FUNDAMENTO_LEGAL_REGIME_TRIBUTACAO_II_ENDPOINT,
  FUNDAMENTO_LEGAL_REDUCAO_PIS_COFINS_ENDPOINT,
  FUNDAMENTO_LEGAL_PIS_COFINS_ENDPOINT,
  FUNDAMENTACAO_ESPECIFICA_ENDPOINT,
  ORGAO_ANUENTE_ENDPOINT,
  ORGAO_EMISSOR_ATO_LEGAL_ENDPOINT,
  TIPO_ATO_LEGAL_ENDPOINT,
  MOTIVO_IMPORTACAO_SEM_COBERTURA_CAMBIAL_ENDPOINT,
  MOTIVO_ADMISSAO_TEMPORARIA_ENDPOINT,
  MODALIDADE_PAGAMENTO_ENDPOINT,
  PERFIL_ENDPOINT,
  USUARIO_ENDPOINT,
  DOCUMENTO_INSTRUCAO_DESPACHO_ENDPOINT,
  TIPO_EMBALAGEM_NACIONAL_ENDPOINT,
  ACRESCIMO_VALORACAO_ADUANEIRA_ENDPOINT,
  DEDUCAO_VALORACAO_ADUANEIRA_ENDPOINT,
  CODIGO_RECEITA_ENDPOINT,
  GRUPO_UNIDADE_NEGOCIO_ENDPOINT,
  FORNECEDOR_ENDPOINT,
  EMISSAO_ICMS_ENDPOINT,
  EMISSAO_GLME_SP_ENDPOINT,
};

export {
  FOLLOW_UP_ENDPOINT,
  ETAPA_FOLLOW_UP_ENDPOINT,
  DADOS_EMBARQUE_ENDPOINT,
  FATURA_ENDPOINT,
  IMPORT_SDA_ENDPOINT,
  LICENCA_IMPORTACAO_ENDPOINT,
  DECLARACAO_IMPORTACAO_ENDPOINT,
  DI_ENDPOINT,
  ADICAO_ENDPOINT,
  DANFE_ENDPOINT,
  NUMERARIO_IMPORTACAO_ENDPOINT,
  CUSTEIOS_ENDPOINT,
};

// EXPORTACAO

export {
  DETALHAMENTO_OPERACAO_SEM_NOTA_FISCAL_ENDPOINT,
  SITUACAO_ESPECIAL_DESPACHO_ENDPOINT,
  TIPO_DOCUMENTO_FISCAL_ENDPOINT,
  FORMA_EXPORTACAO_ENDPOINT,
  VIA_ESPECIAL_TRANSPORTE_ENDPOINT,
  TRATAMENTO_PRIORITARIO_ENDPOINT,
  ENQUADRAMENTO_OPERACAO_ENDPOINT,
  MODELO_NOTA_FISCAL_ENDPOINT,
  TIPO_DOCUMENTO_DUE_ENDPOINT,
  TIPO_ATO_CONCESSORIO_ENDPOINT,
  NCM_DOMINIO_ATRIBUTO_EXPORTACAO_ENDPOINT,
  NCM_ATRIBUTO_EXPORTACAO_ENDPOINT,
  NCM_EXPORTACAO_ENDPOINT,
  TIPO_CE_ENDPOINT,
};

// FINANCEIRO
export {
  QCX_FATURAMENTO_ENDPOINT,
  QCX_CONTAS_A_RECEBER_ENDPOINT,
  QCX_CONTAS_RECEBER_ENDPOINT,
  QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
  QCX_CONTAS_A_PAGAR_ENDPOINT,
  QCX_FINANCEIRO_A_PAGAR_ENDPOINT,
  QCX_CONCILIACAO_ENDPOINT,
  QCX_CONCILIAR_ENDPOINT,
  QCX_DESCONCILIAR_ENDPOINT,
  QCX_CONCILIACAO_BANCARIA_ENDPOINT,
};

// INTEGRAÇÃO
export { DEMANDA_OCR_ENDPOINT };
