import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXGenericDownloader from '../../../../components/downloader/QCXGenericDownloader';
import DeclaracaoImportacaoContext from '../../../../contexts/import/declaracao-importacao/DeclaracaoImportacaoContext';
import QCXSelectFollowUpImportacaoAutocompleteField from '../../../../shared-components/select-follow-up/QCXSelectFollowUpImportacaoAutocompleteField';
import {
  selectStatus,
  selectMode,
  selectSubMode,
  selectBackgroundMode,
  selectSubBackgroundMode,
  selectError,
  selectResponse,
} from '../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import {
  clearError,
  clearResponse,
  resetStatus,
} from '../../../../features/declaracao-importacao/declaracaoImportacaoSlice';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXFinalRadioField from '../../../../shared-components/final-radio-field/QCXFinalRadioField';
import QCXPageTemplate from '../../../../templates/page/QCXPageTemplate';
import { ReportUtils } from '../../../../utils/general/report/reportUtils';
import { isFailureStatus, isLoadingStatus, isSuccessStatus } from '../../../../utils/store/store-utils';
import { required } from '../../../../utils/validators/field/validator';
import useDeclaracaoImportacaoActionsMenu from './DeclaracaoImportacaoActionsMenu/useDeclaracaoImportacaoActionsMenu';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import SelectTipoRateioDespesasAcessorias from '../../../../shared-components/despesas-acessorias-form-group/SelectTipoRateio';
import QCXFinalAlternativeCurrencyField from '../../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalTextField from '../../../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { getCurrentDate } from '../../../../utils/general/general-utils';
import { isNaoCalculadaStatus } from '../../../../utils/general/operations/operationUtils';

export default function DeclaracaoImportacaoPage({ children }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const response = useSelector(selectResponse);

  const actionsMenu = useDeclaracaoImportacaoActionsMenu();

  const defaultDateValue = useMemo(() => getCurrentDate(), []);

  const modeSelectors = useMemo(
    () => ({
      selectMode,
      selectSubMode,
      selectBackgroundMode,
      selectSubBackgroundMode,
    }),
    [selectMode, selectSubMode, selectBackgroundMode, selectSubBackgroundMode]
  );

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const [successSnackbar, setSuccessSnackbar] = useState({
    open: false,
    message: '',
  });

  const [errorSnackbar, setErrorSnackbar] = useState({
    open: false,
    message: '',
  });

  const handleOpenSuccessSnackbar = useCallback((message) => {
    setSuccessSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleOpenErrorSnackbar = useCallback((message) => {
    setErrorSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleCloseSuccessSnackbar = useCallback(() => {
    setSuccessSnackbar({
      ...successSnackbar,
      open: false,
    });
  }, []);

  const handleCloseErrorSnackbar = useCallback(() => {
    setErrorSnackbar({
      ...errorSnackbar,
      open: false,
    });
  }, []);

  useEffect(() => {
    const handleStatus = () => {
      if (isFailureStatus(status) && error && error.message) {
        handleOpenErrorSnackbar(error.message);
        dispatch(resetStatus());
        dispatch(clearError());

        return;
      }
      if (isSuccessStatus(status) && response && response.message) {
        handleOpenSuccessSnackbar(response.message);
        dispatch(resetStatus());
        dispatch(clearResponse());
      }
    };

    handleStatus();
  }, [response, status, error]);

  const providerData = {
    status,
    isLoading,
    successSnackbar,
    errorSnackbar,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
    ...actionsMenu,
  };

  return (
    <DeclaracaoImportacaoContext.Provider value={providerData}>
      <QCXPageTemplate contextComponent={DeclaracaoImportacaoContext} modeSelectors={modeSelectors}>
        {children}

        <QCXFinalFormDialog
          key="form-dialog-impressao-di-duimp"
          id="form-dialog-impressao-di-duimp"
          title={t('com.muralis.qcx.DIDUIMP.relatorioDIDUIMP')}
          content={actionsMenu.formDialogImpressaoContent}
          dialogActionsProps={{
            button: {
              submit: {
                description: t('com.muralis.qcx.acoes.gerar'),
                size: 'small',
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
                size: 'small',
              },
            },
          }}
          open={actionsMenu.isFormDialogImpressaoOpen}
          initialValues={actionsMenu.reportRelatedModel}
          onSubmit={actionsMenu.handleImprimirByIdSubmit}
          onClose={actionsMenu.handleCloseFormDialogImpressao}
          disablePristine
        >
          {() => (
            <Grid container>
              <Grid item xs={12}>
                <QCXFinalRadioField
                  id="checkbox-tipo-relatorio"
                  key="checkbox-tipo-relatorio"
                  name="tipo"
                  label={t('com.muralis.qcx.DIDUIMP.DraftDIDUIMP')}
                  value={ReportUtils.DRAFT_DI_DUIMP}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <QCXFinalRadioField
                  id="checkbox-tipo-relatorio"
                  key="checkbox-tipo-relatorio"
                  name="tipo"
                  label={t('com.muralis.qcx.relatorioCalculoAdicao')}
                  value={ReportUtils.CALCULO_POR_ADICAO}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <QCXFinalRadioField
                  id="checkbox-tipo-relatorio"
                  key="checkbox-tipo-relatorio"
                  name="tipo"
                  label={t('com.muralis.qcx.relatorioItensOrdemDigitacao')}
                  value={ReportUtils.ITENS_ORDEM_DIGITACAO}
                  required
                />
              </Grid>
            </Grid>
          )}
        </QCXFinalFormDialog>

        <QCXFinalFormDialog
          key="form-dialog-processo-transmissao-duimp"
          id="form-dialog-processo-transmissao-duimp"
          title="Transmitir com erros não impeditivos ?"
          dialogActionsProps={{
            button: {
              submit: {
                description: t('com.muralis.qcx.acoes.continuar'),
                size: 'small',
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
                size: 'small',
              },
            },
          }}
          open={actionsMenu.exibirModalTransmissaoDuimp}
          initialValues={{}}
          onSubmit={actionsMenu.registrarDuimp}
          onClose={() => actionsMenu.setExibirModalTransmissaoDuimp(false)}
        >
          {() => (
            <>
              <Grid item xs={12}>
                <QCXFinalRadioField
                  id="checkbox-tipo-relatorio"
                  key="checkbox-tipo-relatorio"
                  name="transmitirComErrosNaoImpeditivos"
                  label="Sim"
                  value="SIM"
                />
              </Grid>

              <Grid item xs={12}>
                <QCXFinalRadioField
                  id="checkbox-tipo-relatorio"
                  key="checkbox-tipo-relatorio"
                  name="transmitirComErrosNaoImpeditivos"
                  label="Não"
                  value="NAO"
                />
              </Grid>
            </>
          )}
        </QCXFinalFormDialog>

        <QCXConfirmDialog
          key="confirm-dialog-conferencia-di-duimp"
          id="confirm-dialog-conferencia-di-duimp"
          open={actionsMenu.operationOfConferirById?.active}
          title={actionsMenu.operationOfConferirById?.title}
          content={actionsMenu.operationOfConferirById?.message}
          endContent={actionsMenu.operationOfConferirById?.endMessage}
          onConfirm={actionsMenu.operationOfConferirById.confirm}
          onClose={actionsMenu.operationOfConferirById?.reset}
        />

        <QCXGenericDownloader
          status={actionsMenu.formDialogImpressaoStatus}
          message={actionsMenu.formDialogImpressaoSubmittingFeedback}
        />

        <QCXConfirmDialog
          key="confirm-dialog-conferir-aliquotas-divergentes"
          id="confirm-dialog-conferir-aliquotas-divergentes"
          open={actionsMenu.aliquotasDiferemPopUpConfig?.isPopUpOpen}
          title={actionsMenu.aliquotasDiferemPopUpConfig?.title}
          content={actionsMenu.aliquotasDiferemPopUpConfig?.message}
          endContent={actionsMenu.aliquotasDiferemPopUpConfig?.endMessage}
          onConfirm={actionsMenu.aliquotasDiferemPopUpConfig.confirmPrimaryAction}
          onClose={actionsMenu.aliquotasDiferemPopUpConfig?.reset}
        />

        <QCXConfirmDialog
          key="confirm-dialog-liberacao-di-duimp"
          id="confirm-dialog-liberacao-di-duimp"
          open={actionsMenu.operationOfLiberarById?.active}
          title={actionsMenu.operationOfLiberarById?.title}
          content={actionsMenu.operationOfLiberarById?.message}
          endContent={actionsMenu.operationOfLiberarById?.endMessage}
          onConfirm={actionsMenu.operationOfLiberarById.confirm}
          onClose={actionsMenu.operationOfLiberarById?.reset}
        />

        <QCXConfirmDialog
          key="confirm-dialog-retransmissao-di-duimp"
          id="confirm-dialog-retransmissao-di-duimp"
          open={actionsMenu.operationOfRetransmitirById?.active}
          title={actionsMenu.operationOfRetransmitirById?.title}
          content={actionsMenu.operationOfRetransmitirById?.message}
          endContent={actionsMenu.operationOfRetransmitirById?.endMessage}
          onConfirm={actionsMenu.operationOfRetransmitirById.confirm}
          onClose={actionsMenu.operationOfRetransmitirById?.reset}
        />

        <QCXFinalFormDialog
          key="form-dialog-duplicacao-di-duimp"
          id="form-dialog-duplicacao-di-duimp"
          title={t('com.muralis.qcx.DIDUIMP.duplicacaoDIDUIMP')}
          content={actionsMenu.formDialogDuplicacaoContent}
          dialogActionsProps={{
            button: {
              submit: {
                description: t('com.muralis.qcx.acoes.duplicar'),
                size: 'small',
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
                size: 'small',
              },
            },
          }}
          open={actionsMenu.isFormDialogDuplicacaoOpen}
          initialValues={actionsMenu.duplicataRelatedModel}
          onSubmit={actionsMenu.handleDuplicarByProcessoSubmit}
          onClose={actionsMenu.handleCloseFormDialogDuplicacao}
        >
          {() => (
            <QCXMoveFocusInside>
              <QCXSelectFollowUpImportacaoAutocompleteField
                key="select-autocomplete-processo-field"
                id="select-autocomplete-processo-field"
                name="followUp.id"
                label={t('com.muralis.qcx.processo')}
                validate={required}
                controlled
                required
              />
            </QCXMoveFocusInside>
          )}
        </QCXFinalFormDialog>

        <QCXFinalFormDialog
          key="form-dialog-incluir-multa-di-duimp"
          id="form-dialog-incluir-multa-di-duimp"
          title="Incluir multa"
          open={actionsMenu.isFormDialogIncluirMultaOpen}
          initialValues={actionsMenu.multaRelatedModel}
          onSubmit={actionsMenu.handleIncluirMultaSubmit}
          onClose={actionsMenu.handleCloseFormDialogIncluirMulta}
          disablePristine
        >
          {({ initialValues }) => (
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <QCXMoveFocusInside>
                  <SelectTipoRateioDespesasAcessorias
                    id="select-tipo-rateio-despesas-acessorias-incluir-multa-field"
                    key="select-tipo-rateio-despesas-acessorias-incluir-multa-field"
                    name="multa.tipoRateioMulta"
                    label="Tipo de Rateio"
                    value={initialValues?.multa?.tipoRateioMulta}
                  />
                </QCXMoveFocusInside>
              </Grid>
              <Grid item xs={12}>
                <QCXFinalAlternativeCurrencyField
                  id="currency-valor-real-multa-field"
                  key="currency-valor-real-multa-field"
                  name="multa.valorMultaReais"
                  label="Valor da multa em Reais"
                  adorned
                  value={initialValues?.multa?.valorMultaReais}
                />
              </Grid>
            </Grid>
          )}
        </QCXFinalFormDialog>

        <QCXFinalFormDialog
          key="form-dialog-emissao-icms-di-duimp"
          id="form-dialog-emissao-icms-di-duimp"
          title={
            actionsMenu.emissaoIcmsRelatedModel?.registro
              ? t('com.muralis.qcx.imposto.emissaoICMSDIDUIMPNumero', {
                  numero: actionsMenu.emissaoIcmsRelatedModel?.registro,
                })
              : t('com.muralis.qcx.imposto.emissaoICMSDIDUIMP')
          }
          content={actionsMenu.formDialogEmissaoIcmsContent}
          dialogActionsProps={{
            button: {
              submit: {
                description: t('com.muralis.qcx.acoes.confirmar'),
                size: 'small',
                disabled: actionsMenu.emissaoIcmsRelatedModel?.geracaoGareEmissaoIcms === 'CONCLUIDO',
              },
              cancel: {
                description:
                  !actionsMenu.emissaoIcmsRelatedModel?.dataVencimentoIcms ||
                  actionsMenu.emissaoIcmsRelatedModel?.confirmacaoPendente
                    ? t('com.muralis.qcx.acoes.cancelar')
                    : t('com.muralis.qcx.acoes.fechar'),
                size: 'small',
              },
            },
          }}
          open={actionsMenu.isFormDialogEmissaoIcmsOpen}
          initialValues={actionsMenu.emissaoIcmsRelatedModel}
          onSubmit={actionsMenu.handleEmissaoIcmsSubmit}
          onClose={actionsMenu.handleCloseFormDialogEmissaoIcms}
          disablePristine
        >
          {({ initialValues }) => (
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <QCXMoveFocusInside>
                  <QCXFinalDatePickerField
                    key="data-vencimento-icms-picker-field"
                    id="data-vencimento-icmss-picker-field"
                    name="dataVencimentoIcms"
                    label={t('com.muralis.qcx.dataVencimento')}
                    defaultValue={defaultDateValue}
                    disablePast
                    disabled={initialValues?.geracaoGareEmissaoIcms === 'CONCLUIDO'}
                    validate={required}
                    required
                  />
                </QCXMoveFocusInside>
              </Grid>
              <Grid item xs={12}>
                <QCXFinalTextField
                  key="numero-convenio-protocolo-especificacao-mercadoria-text-field"
                  id="numero-convenio-protocolo-especificacao-mercadoria-text-field"
                  name="numeroConvenioProtocoloEspecificacaoMercadoria"
                  label={t('com.muralis.qcx.mensagem.numeroConvenioProtocoloEspecificacaoMercadoria')}
                  maxLength={255}
                  helperText={t('com.muralis.qcx.referenteGeracaoGNRE')}
                  disabled={initialValues?.geracaoGareEmissaoIcms === 'CONCLUIDO'}
                />
              </Grid>
            </Grid>
          )}
        </QCXFinalFormDialog>

        <QCXConfirmDialog
          key="confirm-dialog-data-vencimento-emissao-icms-di-duimp"
          id="confirm-dialog-data-vencimento-emissao-icms-di-duimp"
          open={actionsMenu.operationOfEmissaoIcms?.active}
          title={actionsMenu.operationOfEmissaoIcms?.title}
          buttonGroupOptions={{
            confirm: {
              description: t('com.muralis.qcx.expressao.sim'),
            },
            cancel: {
              description: t('com.muralis.qcx.expressao.nao'),
            },
          }}
          content={actionsMenu.operationOfEmissaoIcms?.message}
          endContent={actionsMenu.operationOfEmissaoIcms?.endMessage}
          onConfirm={actionsMenu.operationOfEmissaoIcms.confirm}
          onClose={actionsMenu.handleCloseEmissaoIcmsOperation}
        />

        <QCXConfirmDialog
          key="confirm-dialog-activate-inactivate-di-duimp"
          id="confirm-dialog-activate-inactivate-di-duimp"
          open={actionsMenu.operationOfActivateOrInactivateById?.active}
          title={actionsMenu.operationOfActivateOrInactivateById?.title}
          content={actionsMenu.operationOfActivateOrInactivateById?.message}
          endContent={actionsMenu.operationOfActivateOrInactivateById?.endMessage}
          onConfirm={actionsMenu.operationOfActivateOrInactivateById.confirm}
          onClose={actionsMenu.operationOfActivateOrInactivateById?.reset}
        />

        <QCXFinalFormDialog
          key="form-dialog-calculo-di-duimp"
          id="form-dialog-calculo-di-duimp"
          title={t('com.muralis.qcx.DIDUIMP.calculoDeclaracaoDIDUIMP')}
          content={actionsMenu.formDialogCalculoContent}
          dialogActionsProps={{
            button: {
              submit: {
                description: isNaoCalculadaStatus(actionsMenu.calculoRelatedModel?.calculada)
                  ? t('com.muralis.qcx.acoes.calcular')
                  : t('com.muralis.qcx.acoes.recalcular'),
                size: 'small',
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
                size: 'small',
              },
            },
          }}
          open={actionsMenu.isFormDialogCalculoOpen}
          initialValues={actionsMenu.calculoRelatedModel}
          onSubmit={
            actionsMenu.isCalculate
              ? actionsMenu.handleBulkCalculateSubmit
              : actionsMenu.handleCalcularByDataSubmitAlert
          }
          onClose={actionsMenu.handleCloseFormDialogCalculo}
        >
          {() => (
            <QCXMoveFocusInside>
              <QCXFinalDatePickerField
                id="date-picker-data-calculo-field"
                key="date-picker-data-calculo-field"
                name="atributosAdicionais.dataCalculo"
                label={t('com.muralis.qcx.dataCalculo')}
                defaultValue={defaultDateValue}
                validate={required}
                required
              />
            </QCXMoveFocusInside>
          )}
        </QCXFinalFormDialog>

        <QCXFinalFormDialog
          key="form-dialog-alerta-acrescimos-deducoes"
          id="form-dialog-alerta-acrescimos-deducoes"
          title="AVISO!"
          content="Existem valores de acréscimos/deduções nas adições que não foram lançados na capa da DI/DUIMP. Deseja seguir com o cálculo?"
          dialogActionsProps={{
            button: {
              submit: {
                description: 'CONTINUAR',
                size: 'small',
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
                size: 'small',
              },
            },
          }}
          open={actionsMenu.isCalcularAcrescimosDeducoesAlertOpen}
          initialValues={actionsMenu.calculoRelatedModel}
          onSubmit={actionsMenu.handleCalcularByDataSubmit}
          onClose={actionsMenu.handleCloseAlertAcrescimosDeducoes}
          disablePristine
        />
      </QCXPageTemplate>
    </DeclaracaoImportacaoContext.Provider>
  );
}
