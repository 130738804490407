const selectContasReceber = (state) => state.contasReceber.list;
const selectContasReceberView = (state) => state.contasReceber.view;
const selectContaPagar = (state) => state.contasReceber.model;
const selectStatus = (state) => state.contasReceber.status;
const selectResponse = (state) => state.contasReceber.response;
const selectMode = (state) => state.contasReceber.mode.main;
const selectBackgroundMode = (state) => state.contasReceber.mode.background;
const selectSubMode = (state) => state.contasReceber.mode.sub;
const selectRefresh = (state) => state.contasReceber.refresh;
const selectConfigControl = (state) => state.contasReceber.config.control;
const selectError = (state) => state.contasReceber.error;
const selectRelatedSelectionContasReceberList = (state) => (
  state.contasReceber.related.list.selectionContasReceber
);
const selectRelatedSelectionContasReceberView = (state) => (
  state.contasReceber.related.view.selectionContasReceber
);
const selectRelatedContasReceberItemModel = (state) => (
  state.contasReceber.related.model.contasReceberItem
);
const selectRelatedReportModel = (state) => state.contasReceber.related.model.report;

const solicitacaoPagamentoSelectors = {
  selectContasReceber,
  selectContasReceberView,
  selectContaPagar,
  selectStatus,
  selectRefresh,
  selectResponse,
  selectMode,
  selectError,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectRelatedSelectionContasReceberList,
  selectRelatedSelectionContasReceberView,
  selectRelatedContasReceberItemModel,
  selectRelatedReportModel,
};

export {
  selectContasReceber,
  selectContasReceberView,
  selectContaPagar,
  selectStatus,
  selectResponse,
  selectMode,
  selectRefresh,
  selectError,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectRelatedSelectionContasReceberList,
  selectRelatedSelectionContasReceberView,
  selectRelatedContasReceberItemModel,
  selectRelatedReportModel,
  solicitacaoPagamentoSelectors,
};
