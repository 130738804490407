import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AUTO_LOAD_CONTROL } from '../config-control';

import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';

import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  UPDATE_MODE,
} from '../mode';

import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
  },
  error: null,
  model: {
    isLimparCamposActive: false,
    sizeFieldsGrid: 12,
  },
};



const limparCamposAuxiliarStateSlice = createSlice({
  name: 'limparCampos',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    setModelLimparCamposGrid: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  setModelLimparCamposGrid,
  resetModel,
  setError,
  clearError,
} = limparCamposAuxiliarStateSlice.actions;

const selectIsLimparCamposActive = (state) => state.limparCampos.model.isLimparCamposActive;
const selectSizeFieldGrid = (state) => state.limparCampos.model.sizeFieldsGrid;


export {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  setModelLimparCamposGrid,
  resetModel,
  setError,
  clearError,
  selectIsLimparCamposActive,
  selectSizeFieldGrid,
}


export default limparCamposAuxiliarStateSlice.reducer;
