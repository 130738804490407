const selectContasPagar = (state) => state.contasPagar.list;
const selectContasPagarView = (state) => state.contasPagar.view;
const selectContaPagar = (state) => state.contasPagar.model;
const selectStatus = (state) => state.contasPagar.status;
const selectResponse = (state) => state.contasPagar.response;
const selectMode = (state) => state.contasPagar.mode.main;
const selectBackgroundMode = (state) => state.contasPagar.mode.background;
const selectRefresh = (state) => state.contasPagar.refresh;
const selectSubMode = (state) => state.contasPagar.mode.sub;
const selectConfigControl = (state) => state.contasPagar.config.control;
const selectError = (state) => state.contasPagar.error;
const selectRelatedSelectionContasPagarList = (state) => (
  state.contasPagar.related.list.selectionContasPagar
);
const selectRelatedSelectionContasPagarListView = (state) => (
  state.contasPagar.related.view.selectionContasPagar
);
const selectRelatedContasPagarItemModel = (state) => (
  state.contasPagar.related.model.contasPagarItem
);

const selectRelatedReportModel = (state) => state.contasPagar.related.model.report;

const solicitacaoPagamentoSelectors = {
  selectRefresh,
  selectContasPagar,
  selectContasPagarView,
  selectContaPagar,
  selectStatus,
  selectResponse,
  selectMode,
  selectError,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectRelatedSelectionContasPagarList,
  selectRelatedSelectionContasPagarListView,
  selectRelatedReportModel,
  selectRelatedContasPagarItemModel,
};

export {
  selectRefresh,
  selectContasPagar,
  selectContasPagarView,
  selectContaPagar,
  selectStatus,
  selectResponse,
  selectMode,
  selectError,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectRelatedSelectionContasPagarList,
  selectRelatedSelectionContasPagarListView,
  selectRelatedReportModel,
  solicitacaoPagamentoSelectors,
  selectRelatedContasPagarItemModel,
};
