const selectSolicitacoesPagamento = (state) => state.solicitacaoPagamento.list;
const selectSolicitacoesPagamentoView = (state) => state.solicitacaoPagamento.view;
const selectSolicitacaoPagamento = (state) => state.solicitacaoPagamento.model;
const selectRelatedReportModel = (state) => state.solicitacaoPagamento.related.model.report;
const selectRelatedMercadoriaModel = (state) => (
  state.solicitacaoPagamento.related.model.mercadoria
);

const selectRelatedModelFollowUp = (state) => (
  state.solicitacaoPagamento.related.model.followUp
);
const selectRelatedModelBalances = (state) => (
  state.solicitacaoPagamento.related.model.balances
);

const selectStatus = (state) => state.solicitacaoPagamento.status;
const selectRefresh = (state) => state.solicitacaoPagamento.refresh;
const selectMode = (state) => state.solicitacaoPagamento.mode.main;
const selectBackgroundMode = (state) => state.solicitacaoPagamento.mode.background;
const selectSubMode = (state) => state.solicitacaoPagamento.mode.sub;
const selectSubBackgroundMode = (state) => state.solicitacaoPagamento.mode.subBackground;
const selectType = (state) => state.solicitacaoPagamento.type;
const selectHistoryModel = (state) => state.solicitacaoPagamento.historyModel;
const selectError = (state) => state.solicitacaoPagamento.error;
const selectResponse = (state) => state.solicitacaoPagamento.response;

const solicitacaoPagamentoSelectors = {
  selectSolicitacoesPagamento,
  selectSolicitacaoPagamento,
  selectRelatedMercadoriaModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectType,
  selectHistoryModel,
  selectError,
  selectResponse,
  selectRelatedModelFollowUp,
  selectRelatedReportModel,
  selectRelatedModelBalances,
};

export {
  solicitacaoPagamentoSelectors,
  selectSolicitacoesPagamento,
  selectSolicitacoesPagamentoView,
  selectSolicitacaoPagamento,
  selectRelatedMercadoriaModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectType,
  selectHistoryModel,
  selectError,
  selectResponse,
  selectRelatedModelFollowUp,
  selectRelatedReportModel,
  selectRelatedModelBalances,
};
