/* eslint-disable indent */
import { validateQueryParams } from '../../utils/api/api-utils';

import {
  doGetQCXRequest,
  doPutQCXRequest,
  doPostQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
  QCX_TITULOS_ENDPOINT,
  QCX_CONTAS_A_RECEBER_ENDPOINT,
  QCX_RELATORIO_ENDPOINT,
  QCX_CANCELAR_ENDPOINT,
  NUMERARIO_IMPORTACAO_ENDPOINT,
  QCX_CONTAS_RECEBER_ENDPOINT,
  QCX_VIEW_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([
    QCX_TITULOS_ENDPOINT,
    QCX_CONTAS_A_RECEBER_ENDPOINT,
  ]);
}

async function fetchAllView(params = []) {
  return doGetQCXRequest([
    QCX_TITULOS_ENDPOINT,
    QCX_VIEW_ENDPOINT,
    QCX_CONTAS_A_RECEBER_ENDPOINT,
  ], params);
}

async function generateReportAReceberByDate(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      QCX_RELATORIO_ENDPOINT,
      QCX_CONTAS_A_RECEBER_ENDPOINT,
      ''
    ],
      validatedParams,
      {
        responseType: 'blob',
      });
  }
  return { data: [] };
}

async function cancelar(config = {}) {
  return doPutQCXRequest(
    [
      QCX_TITULOS_ENDPOINT,
      QCX_CANCELAR_ENDPOINT,
    ],
    config
  );
}

async function atualizarJuros(payload = {}) {
  return doPutQCXRequest(['titulo'], payload);
}

async function atualizarJurosView(id,payload) {
  return doPutQCXRequest(
    [QCX_TITULOS_ENDPOINT,'aplicar/juros',id],payload);
}

async function transferencia(config = {}) {
  return doPostQCXRequest(
    [
      NUMERARIO_IMPORTACAO_ENDPOINT,
      QCX_TITULOS_ENDPOINT,
      QCX_CONTAS_RECEBER_ENDPOINT
    ],
    config
  );
}

const contasReceberAPI = {
  fetchAll,
  fetchAllView,
  cancelar,
  atualizarJuros,
  atualizarJurosView,
  generateReportAReceberByDate,
  transferencia,
};

export {
  fetchAll,
  fetchAllView,
  cancelar,
  contasReceberAPI,
  transferencia,
  generateReportAReceberByDate,
};
