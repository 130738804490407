/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Block as BlockIcon } from '@material-ui/icons';
import createDecorator from 'final-form-focus';
import { isFunction } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import useReadOnlyForm from '../../utils/hooks/form/mode/useReadOnlyForm';
import useWizard from '../../utils/hooks/wizard/useWizard';
import useWizardState from '../../utils/hooks/wizard/useWizardState';
import QCXButton from '../button/QCXButton';
import QCXForm from '../form/QCXForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    minWidth: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function QCXWizardFormControlButtons({
  isCreate,
  isConsult,
  isUpdate,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  isSubNone,
  isLastStep,
  isLastRegistrationStep,
  alternativeMode,
  disableSubmitOnNext,
  disableUpdate: disableUpdateProp,
  submitting,
  checkIfIsAlternativeSaveByStep,
  handleAlternativeSave,
  handleChangeToUpdate,
  handleCancelUpdate,
  authInfo = {},
  requiredRoles = [],
  extraConditions = {
    finishButton: {
      disable: false,
    },
  },
  readOnlyFormProps = {},
}) {
  const { t } = useTranslation();
  const wizard = useWizard();
  const { currentStep, ignoreRules } = useWizardState();
  const { disableUpdate: disableUpdateReadonly } = useReadOnlyForm(readOnlyFormProps);

  const disableUpdate = disableUpdateReadonly || disableUpdateProp;

  const createPermission = useMemo(
    () =>
      requiredRoles.every((usrRole) => {
        const role = `${usrRole}-incluir`;
        return authInfo?.roles?.includes(role);
      }),
    [authInfo, requiredRoles]
  );
  const updatePermission = useMemo(
    () =>
      requiredRoles.every((usrRole) => {
        const role = `${usrRole}-alterar`;
        return authInfo?.roles?.includes(role);
      }),
    [authInfo, requiredRoles]
  );
  const classes = useStyles();

  const isAlternativeSave = useMemo(
    () => (isFunction(checkIfIsAlternativeSaveByStep) ? checkIfIsAlternativeSaveByStep(currentStep) : false),
    [checkIfIsAlternativeSaveByStep, currentStep]
  );

  const handleNextStep = useCallback(() => {
    wizard.next();
  }, [wizard]);

  const handlePreviousStep = useCallback(() => {
    wizard.previous();
  }, [wizard]);

  return (
    <Grid item container style={{ marginTop: '5vh' }} xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item container justify="flex-start" xs={6} sm={6} md={6} lg={6} xl={6}>
        <Grid item>
          {!ignoreRules && (
            <>
              {isConsult && ((isSubNone && alternativeMode) || !alternativeMode) && !disableUpdate && (
                <QCXButton
                  className={classes.backButton}
                  variant="contained"
                  color="secondary"
                  onClick={handleChangeToUpdate}
                  disabled={!updatePermission}
                >
                  {t('com.muralis.qcx.acoes.alterar')}
                </QCXButton>
              )}
              {isUpdate && ((isSubNone && alternativeMode) || !alternativeMode) && (
                <>
                  <QCXButton
                    className={classes.backButton}
                    variant="outlined"
                    onClick={handleCancelUpdate}
                    startIcon={<BlockIcon />}
                    disabled={submitting || isSubCreate || isSubUpdate}
                  >
                    {t('com.muralis.qcx.acoes.cancelar')}
                  </QCXButton>
                  {!isAlternativeSave && (
                    <QCXButton
                      className={classes.backButton}
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={submitting || isSubCreate || isSubUpdate || (!createPermission && !updatePermission)}
                    >
                      {t('com.muralis.qcx.acoes.salvar')}
                    </QCXButton>
                  )}
                  {isAlternativeSave && (
                    <QCXButton
                      className={classes.backButton}
                      variant="contained"
                      color="secondary"
                      onClick={handleAlternativeSave}
                      disabled={submitting || isSubCreate || isSubUpdate || (!createPermission && !updatePermission)}
                    >
                      {t('com.muralis.qcx.acoes.salvar')}
                    </QCXButton>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid item container justify="flex-end" xs={6} sm={6} md={6} lg={6} xl={6}>
        <Grid item>
          {(disableSubmitOnNext ||
            !(alternativeMode || isSubCreate || isSubConsult || isSubUpdate) ||
            !(isSubCreate || isSubConsult || isSubUpdate) ||
            (!alternativeMode && (isCreate || isConsult || isUpdate))) &&
            currentStep > 0 && (
              <QCXButton
                onClick={handlePreviousStep}
                className={classes.backButton}
                variant="outlined"
                disabled={isUpdate || isSubCreate || isSubUpdate}
              >
                {t('com.muralis.qcx.acoes.voltar')}
              </QCXButton>
            )}
          {(!(alternativeMode || isSubCreate || isSubConsult || isSubUpdate) ||
            !(isSubCreate || isSubConsult || isSubUpdate) ||
            (!alternativeMode && isCreate)) &&
            isLastRegistrationStep &&
            !isAlternativeSave && (
              <QCXButton
                variant="contained"
                color="secondary"
                type="submit"
                disabled={submitting || isSubCreate || isSubUpdate || extraConditions?.finishButton?.disable}
              >
                {t('com.muralis.qcx.acoes.finalizar')}
              </QCXButton>
            )}
          {(!(alternativeMode || isSubCreate || isSubConsult || isSubUpdate) ||
            !(isSubCreate || isSubConsult || isSubUpdate) ||
            (!alternativeMode && isCreate)) &&
            isLastRegistrationStep &&
            isAlternativeSave && (
              <QCXButton
                variant="contained"
                color="secondary"
                onClick={handleAlternativeSave}
                disabled={submitting || isSubCreate || isSubUpdate || extraConditions?.finishButton?.disable}
              >
                {t('com.muralis.qcx.acoes.finalizar')}
              </QCXButton>
            )}
          {!disableSubmitOnNext &&
            (!(alternativeMode || isSubCreate || isSubConsult || isSubUpdate) ||
              !(isSubCreate || isSubConsult || isSubUpdate) ||
              (!alternativeMode && (isCreate || isConsult || isUpdate))) &&
            !isLastStep &&
            !isAlternativeSave && (
              <QCXButton
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isUpdate || isSubCreate || isSubUpdate}
              >
                {t('com.muralis.qcx.acoes.proximo')}
              </QCXButton>
            )}
          {!disableSubmitOnNext &&
            (!(alternativeMode || isSubCreate || isSubConsult || isSubUpdate) ||
              !(isSubCreate || isSubConsult || isSubUpdate) ||
              (!alternativeMode && (isCreate || isConsult || isUpdate))) &&
            !isLastStep &&
            isAlternativeSave && (
              <QCXButton
                variant="contained"
                color="secondary"
                onClick={handleAlternativeSave}
                disabled={isUpdate || isSubCreate || isSubUpdate}
              >
                {t('com.muralis.qcx.acoes.proximo')}
              </QCXButton>
            )}
          {disableSubmitOnNext && !isLastStep && (
            <QCXButton
              variant="contained"
              color="secondary"
              onClick={handleNextStep}
              disabled={isUpdate || isSubCreate || isSubUpdate}
            >
              {t('com.muralis.qcx.acoes.proximo')}
            </QCXButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function QCXFormStepWizard({
  initialValues,
  onSubmit,
  controlComponentProps,
  children,
  onChangeListeners = [],
  alternativeMode = false,
  disablePristine = false,
  authInfo = {},
  requiredRoles = [],
  disableSubmitOnNext = false,
  disableUpdate = false,
  debugOn,
  extraConditions,
  ...restProps
}) {
  const classes = useStyles();

  const focusOnErrors = createDecorator();

  const decorators = useMemo(() => [focusOnErrors], [focusOnErrors]);

  return (
    <Form
      className={classes.root}
      initialValues={initialValues}
      onSubmit={onSubmit}
      decorators={decorators}
      {...restProps}
    >
      {({ form, values, handleSubmit, pristine, submitting, submitSucceeded, ...restFinalFormProps }) => (
        <QCXForm onSubmit={handleSubmit} debugOn={debugOn}>
          {onChangeListeners.map((item) => (
            <OnChange name={item.name}>{item.fn(form, values)}</OnChange>
          ))}
          <Grid item container className={classes.content}>
            <Grid item container className={classes.content}>
              {typeof children === 'function'
                ? children({
                    form,
                    values,
                    pristine,
                    submitSucceeded,
                    submitting,
                    ...restFinalFormProps,
                  })
                : children}
            </Grid>
            <QCXWizardFormControlButtons
              {...controlComponentProps}
              {...(disablePristine
                ? {}
                : {
                    pristine,
                  })}
              submitting={submitting}
              alternativeMode={alternativeMode}
              disableSubmitOnNext={disableSubmitOnNext}
              disableUpdate={disableUpdate}
              authInfo={authInfo}
              requiredRoles={requiredRoles}
              extraConditions={extraConditions}
            />
          </Grid>
        </QCXForm>
      )}
    </Form>
  );
}
