// src/features/sda-import/importSdaSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IDLE_STATUS,
  LOADING_STATUS,
  FAILURE_STATUS,
  ALTERNATIVE_LOADING_STATUS,
} from '../status';
import fetchAll from './importSdaAPI';

const initialState = {
  status: IDLE_STATUS,
  error: null,
  list: [],
};

export const fetchAllAsync = createAsyncThunk(
  'importSda/fetchAll',
  async (tipo) => {
    const { data } = await fetchAll(tipo);
    return data;
  }
);

const importSdaSlice = createSlice({
  name: 'importSda',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    clearError: (state) => {
      state.error = null;
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = action.error.message || 'Erro ao carregar dados SDA Import';
      });
  },
});

export const { resetStatus, clearError, resetList } = importSdaSlice.actions;

export const selectImportSdaList = (state) => state.importSda.list;
export const selectImportSdaStatus = (state) => state.importSda.status;
export const selectImportSdaError = (state) => state.importSda.error;

export default importSdaSlice.reducer;
