import { Grid, IconButton } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Cancel as CancelIcon,
  Email as EmailIcon,
  FileCopy as FileCopyIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Print as PrintIcon,
  Receipt as ReceiptIcon,
  Send as SendIcon,
} from '@material-ui/icons';
import { isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import { userHasUpdateRole } from '../../../../components/perfil/perfil-utils';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import NumerarioImportacaoContext from '../../../../contexts/import/numerario-importacao/NumerarioImportacaoContext';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { setQuery } from '../../../../features/controller/controllerSlice';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { numerarioImportacaoAPI } from '../../../../features/numerario-importacao/numerarioImportacaoAPI';
import {
  selectNumerariosImportacao,
  selectNumerariosImportacaoCount,
  selectRefresh,
  selectRelatedEmailModel,
} from '../../../../features/numerario-importacao/numerarioImportacaoSelectors';
import {
  changeToConsultMode,
  changeToCreateMode,
  failure,
  loading,
  numerarioImportacaoActions,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRefresh,
  resetSubMode,
  setRelatedEmailModel,
  success,
} from '../../../../features/numerario-importacao/numerarioImportacaoSlice';
import {
  fetchAllImportacaoNumerarioByFilterPaginationAsync,
  notifyByIdAsync,
} from '../../../../features/numerario-importacao/numerarioImportacaoThunks';
import { solicitacaoPagamentoAPI } from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoAPI';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXFinalMultilineTextField from '../../../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import SimpleConsultPageTemplate from '../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import contasReceberUtils from '../../../../utils/general/contas-receber/ContasReceberUtils';
import { formatDate, unnormalizeNumeral } from '../../../../utils/general/general-utils';
import NumerarioImportacaoStatusUtils from '../../../../utils/general/numerario-importacao/NumerarioImportacaoStatusUtils';
import NumerarioImportacaoUtils from '../../../../utils/general/numerario-importacao/NumerarioImportacaoUtils';
import useGenericDownloader from '../../../../utils/hooks/downloader/useGenericDownloader';
import {
  CLOSED_STATUS,
  FAIL_STATUS,
  SUBMITTING_STATUS,
  isOpenStatus,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { isAlternativeLoadingStatus, isRefresh } from '../../../../utils/store/store-utils';
import linguagemUtils from '../../../../utils/general/report/linguagemUtils';

export default function NumerarioConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const { status } = useContext(NumerarioImportacaoContext);

  const dispatch = useDispatch();

  const refresh = useSelector(selectRefresh);
  const numerarios = useSelector(selectNumerariosImportacao);
  const emailRelatedModel = useSelector(selectRelatedEmailModel);

  const genericDownloader = useGenericDownloader({
    autoDownload: true,
  });

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const fetchAll = useCallback(() => {
    dispatch(
      fetchAllImportacaoNumerarioByFilterPaginationAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const configureDependencies = useCallback(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        dispatch(
          fetchAllImportacaoNumerarioByFilterPaginationAsync([
            { name: 'page', value: 1 },
            { name: 'size', value: 10 },
          ])
        );
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeToConsultMode());
        history.push(t('com.muralis.qcx.url.importacaoSolicitacaoNumerarioDetalhes', { id: data?.id }));
      }
    },
    [configureDependencies, history]
  );

  const [handleCancelarByMiddleware, operationOfCancelar] = useOperationMiddleware(async (data) => {
    const formattedIdentificacao = NumerarioImportacaoUtils.formatIdentificacao(data?.identificacao);

    try {
      dispatch(loading());

      const responseOfConsultNumerario = await numerarioImportacaoAPI.fetchById(data?.id);

      if (responseOfConsultNumerario?.status === 200) {
        const foundNumerario = responseOfConsultNumerario?.data;

        const updatedNumerario = {
          ...foundNumerario,
          status: NumerarioImportacaoStatusUtils.CANCELADO,
        };

        const responseOfSaveNumerario = await numerarioImportacaoAPI.save(updatedNumerario);

        if (responseOfSaveNumerario?.status === 200) {
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: t('com.muralis.qcx.mensagem.solicitacaoNumerarioNumeroCancelada', {
                numero: formattedIdentificacao,
              }),
            })
          );
          dispatch(numerarioImportacaoActions.refresh());
          dispatch(setQuery(formattedIdentificacao));
        }
      }
    } catch (error) {
      dispatch(failure());
      dispatch(
        setErrorFeedback({
          message: t('com.muralis.qcx.erro.erroCancelamentoSolicitacaoNumerarioNumero', {
            numero: formattedIdentificacao,
          }),
        })
      );
    }
  }, []);

  const handleCancelarByClick = useCallback(
    (row, popupState) => {
      const { id, identificacao } = row;

      const handleCancelar = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmacaoCancelamentoSolicitacaoNumerarioNumero', {
                numero: NumerarioImportacaoUtils.formatIdentificacao(identificacao),
              }),
              message: t('com.muralis.qcx.acoes.operacaoCancelamentoSolicitacaoNumerario'),
            },
          });

          const dataOfOperationCancelar = {
            id,
            identificacao,
          };

          handleCancelarByMiddleware(configureOperation, dataOfOperationCancelar);
        }
      };

      return handleCancelar;
    },
    [handleCancelarByMiddleware]
  );

  const [handleSolicitarPagamentoByMiddleware, operationOfSolicitarPagamento] = useOperationMiddleware(async (data) => {
    const formattedIdentificacao = NumerarioImportacaoUtils.formatIdentificacao(data?.identificacao);

    try {
      dispatch(loading());

      const responseOfConsultNumerario = await solicitacaoPagamentoAPI.solicitarPagamento(data?.id);

      await numerarioImportacaoAPI.notifyNumerario(data?.id);

      if (responseOfConsultNumerario?.status === 201) {
        dispatch(success());
        dispatch(
          setSuccessFeedback({
            message: t('com.muralis.qcx.mensagem.solicitacaoPagamentoSolicitacaoNumerarioNumeroRealizada', {
              numero: formattedIdentificacao,
            }),
          })
        );
        dispatch(numerarioImportacaoActions.refresh());
        dispatch(setQuery(formattedIdentificacao));
      }
    } catch (error) {
      dispatch(failure());
      dispatch(
        setErrorFeedback({
          message: t('com.muralis.qcx.erro.erroSolicitarPagamentoSolicitacaoNumerarioNumero', {
            numero: formattedIdentificacao,
            erro: error?.response?.data?.message || error?.message || '',
          }),
        })
      );
    }
  }, []);

  const handleSolicitarPagamentoClick = useCallback(
    (row, popupState) => {
      const { id, identificacao } = row;

      const handleSolicitarPagamento = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmacaoSolicitacaoPagamentoSolicitacaoNumerarioNumero', {
                numero: NumerarioImportacaoUtils.formatIdentificacao(identificacao),
              }),
              message: t('com.muralis.qcx.acoes.operacaoSolicitacaoPagamentoSolicitacaoNumerario'),
            },
          });

          const dataOfOperationSolicitarPagamento = {
            id,
            identificacao,
          };

          handleSolicitarPagamentoByMiddleware(configureOperation, dataOfOperationSolicitarPagamento);
        }
      };

      return handleSolicitarPagamento;
    },
    [handleSolicitarPagamentoByMiddleware]
  );

  const [handleEnviarNumerarioByMiddleware, operationOfEnviarNumerario] = useOperationMiddleware(async (data) => {
    const formattedIdentificacao = NumerarioImportacaoUtils.formatIdentificacao(data?.identificacao);

    try {
      dispatch(loading());

      const responseOfConsultNumerario = await numerarioImportacaoAPI.enviarNumerario(data?.id);

      if (responseOfConsultNumerario?.status === 200) {
        dispatch(success());
        dispatch(
          setSuccessFeedback({
            message: t('com.muralis.qcx.mensagem.solicitacaoContasAReceberNumerarioNumeroRealizada', {
              numero: formattedIdentificacao,
            }),
          })
        );
        dispatch(numerarioImportacaoActions.refresh());
        dispatch(setQuery(formattedIdentificacao));
      }
    } catch (error) {
      dispatch(failure());
      dispatch(
        setErrorFeedback({
          message: t('com.muralis.qcx.erro.erroSolicitarContaAReceberNumerarioNumero', {
            numero: formattedIdentificacao,
            erro: error?.response?.data || error?.message || '',
          }),
        })
      );
    }
  }, []);

  const handleEnviarNumerarioClick = useCallback(
    (row, popupState) => {
      const { id, identificacao } = row;

      const handleEnviarNumerario = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmacaoSolicitacaoContasAReceberNumerarioNumero', {
                numero: NumerarioImportacaoUtils.formatIdentificacao(identificacao),
              }),
              message: t('com.muralis.qcx.acoes.operacaoSolicitacaoContasAReceberNumerario'),
            },
          });

          const dataOfOperationEnviarNumerario = {
            id,
            identificacao,
          };

          handleEnviarNumerarioByMiddleware(configureOperation, dataOfOperationEnviarNumerario);
        }
      };

      return handleEnviarNumerario;
    },
    [handleEnviarNumerarioByMiddleware]
  );

  const [handleImprimirByIdMiddleware, operationOfImprimirById] = useOperationMiddleware(async (data) => {
    const fetcher = async () => {

      dispatch(loading());
      
      console.log(data)

      const query = [ { name : "linguagem" , value : data?.linguagem }];

      const response = await numerarioImportacaoAPI.generateReportById(data?.id,query);

      return response;
    };

    const handlers = {
      onSuccess: () => {
        dispatch(success());

        const successMessage = t('com.muralis.qcx.mensagem.relatorioNumerarioGerado', {
          numero: data?.meta?.identificacao,
        });

        dispatch(
          setSuccessFeedback({
            message: successMessage,
          })
        );

        dispatch(numerarioImportacaoActions.refresh());

        dispatch(setQuery(data?.meta?.identificacao));
      },
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = t('com.muralis.qcx.mensagem.relatorioNumerarioNaoFoiGerado', {
          numero: data?.meta?.identificacao,
        });

        dispatch(
          setErrorFeedback({
            message: infoMessage,
          })
        );
      },
      onError: (reason) => {
        dispatch(failure());

        const errorMessage = t('com.muralis.qcx.erro.erroGerarRelatorioNumerario', {
          numero: data?.meta?.identificacao,
          mensagem: reason?.response?.data?.message || '',
        });

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    genericDownloader.downloadFileById(fetcher, handlers);
  }, []);

  const handleImprimirByIdClick = useCallback(
    (row, popupState, linguagem) => {
      const { id, identificacao } = row;

      const handleImprimirById = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const formattedIdentificacao = NumerarioImportacaoUtils.formatIdentificacao(identificacao);

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.mensagem.confirmarOperacaoGerarRelatorioNumerario', {
                numero: formattedIdentificacao,
              }),
            },
          });

          const dataOfOperationImprimirById = {
            id,
            linguagem,
            meta: {
              identificacao: formattedIdentificacao,
            },
          };

          handleImprimirByIdMiddleware(configureOperation, dataOfOperationImprimirById);
        }
      };

      return handleImprimirById;
    },
    [handleImprimirByIdMiddleware]
  );

  const [handleDuplicarByIdMiddleware, operationOfDuplicarById] = useOperationMiddleware(async (data) => {
    const formattedIdentificacao = NumerarioImportacaoUtils.formatIdentificacao(data?.identificacao);

    try {
      dispatch(loading());

      const response = await numerarioImportacaoAPI.duplicarById(data?.id);

      if (response?.status === 201) {
        dispatch(success());

        const duplicata = response?.data;

        const formattedIdentificacaoDuplicata = NumerarioImportacaoUtils.formatIdentificacao(duplicata?.identificacao);

        dispatch(
          setSuccessFeedback({
            message: t('com.muralis.qcx.mensagem.solicitacaoNumerarioNumeroDuplicada', {
              numero: formattedIdentificacaoDuplicata,
              numeroOrigem: formattedIdentificacao,
            }),
          })
        );
        dispatch(numerarioImportacaoActions.refresh());
        dispatch(setQuery(formattedIdentificacaoDuplicata));
      }
    } catch (error) {
      dispatch(failure());
      dispatch(
        setErrorFeedback({
          message: t('com.muralis.qcx.erro.erroDuplicarSolicitacaoNumerario', {
            numero: formattedIdentificacao,
          }),
        })
      );
    }
  }, []);

  const handleDuplicarByIdClick = useCallback(
    (row, popupState) => {
      const { id, identificacao } = row;

      const handleDuplicarById = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.mensagem.confirmarOperacaoDuplicarNumerario', {
                numero: NumerarioImportacaoUtils.formatIdentificacao(identificacao),
              }),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoDuplicarSolicitacaoNumerario'),
            },
          });

          const dataOfOperationDuplicarById = {
            id,
            identificacao,
          };

          handleDuplicarByIdMiddleware(configureOperation, dataOfOperationDuplicarById);
        }
      };

      return handleDuplicarById;
    },
    [handleDuplicarByIdMiddleware]
  );

  const [handleEnviarPorEmailClick, formDialogEnviarPorEmailStatus, handleFormDialogEnviarPorEmailStatus] =
    useFormDialogSync(async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        const formattedIdentificacao = NumerarioImportacaoUtils.formatIdentificacao(data?.identificacao);

        dispatch(
          setRelatedEmailModel({
            id: data?.id,
            meta: {
              identificacao: formattedIdentificacao,
              cliente: data?.importador?.pessoa?.nome,
            },
          })
        );
      }
    }, []);

  const handleEnviarByProcessoSubmit = useCallback(
    async (values) => {
      try {
        dispatch(loading());
        dispatch(setRelatedEmailModel(values));

        handleFormDialogEnviarPorEmailStatus(SUBMITTING_STATUS);

        const payload = {
          id: values?.id,
          mensagem: values?.mensagem,
        };

        const response = await numerarioImportacaoAPI.sendEmailBy(payload);

        if (response?.status === 200) {
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: t('com.muralis.qcx.mensagem.solicitacaoNumerarioNumeroEmailEnviado', {
                numero: values?.meta?.identificacao,
              }),
            })
          );
          dispatch(setQuery(values?.meta?.identificacao));
          dispatch(numerarioImportacaoActions.refresh());
        }
      } catch (error) {
        dispatch(failure());

        handleFormDialogEnviarPorEmailStatus(FAIL_STATUS);

        dispatch(
          setErrorFeedback({
            message: t('com.muralis.qcx.mensagem.erroEnvioEmailSolicitacaoNumerarioNumero', {
              numero: values?.meta?.identificacao,
            }),
          })
        );
      }
    },
    [handleFormDialogEnviarPorEmailStatus]
  );

  const handleCloseFormDialogEnviar = useCallback(
    () => handleFormDialogEnviarPorEmailStatus(CLOSED_STATUS),
    [handleFormDialogEnviarPorEmailStatus]
  );

  const isFormDialogEnviarOpen = useMemo(() => isOpenStatus(formDialogEnviarPorEmailStatus));

  const formDialogTitle = useMemo(
    () =>
      t('com.muralis.qcx.numerarioImportacao.envioRelatorioEmailNumero', {
        numero: emailRelatedModel?.meta?.identificacao,
      }).toUpperCase(),
    [emailRelatedModel]
  );

  const formDialogEnviarBeforeContent = useMemo(
    () => (
      <>
        <Typography variant="caption">
          <Trans i18nKey="com.muralis.qcx.numerarioImportacao.observacaoEnvioEmailClienteSolicitacaoNumerario">
            <em>
              <strong />
              <span />
              <ins>
                {{
                  cliente: emailRelatedModel?.meta?.cliente,
                }}
              </ins>
              .
            </em>
          </Trans>
        </Typography>
      </>
    ),
    [emailRelatedModel]
  );

  const formDialogEnviarContent = useMemo(() => t('com.muralis.qcx.mensagem.preenchaCampoMensagemCorpoEmail'), []);

  const handleButtonNewClick = useCallback(
    (event) => {
      event.stopPropagation();

      configureDependencies();

      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.importacaoSolicitacaoNumerarioNovo'));
    },
    [configureDependencies, history]
  );

  const handleNotificarPrometheus = useCallback((id) => {
    dispatch(notifyByIdAsync(id));
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.numerarioImportacao.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 200,
        valueGetter: ({ row }) => NumerarioImportacaoUtils.formatIdentificacao(row?.identificacao) || '-',
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.cliente.label'),
        flex: 300,
        valueGetter: ({ row }) => row?.importador?.pessoa?.nome || '-',
      },
      {
        field: 'processo',
        headerName: 'Processo',
        flex: 200,
        valueGetter: ({ row }) => row?.capas?.[0]?.followUp?.numero || '-',
      },
      {
        field: 'dataSolicitacao',
        headerName: t('com.muralis.qcx.solicitacao'),
        flex: 150,
        valueGetter: ({ row }) => formatDate(row?.dataSolicitacao) || '-',
      },
      {
        field: 'dataRecebimento',
        headerName: t('com.muralis.qcx.recebimento'),
        flex: 120,
        valueGetter: ({ row }) => formatDate(row?.dataRecebimento) || '-',
      },
      {
        field: 'totalReal',
        headerName: t('com.muralis.qcx.valorSolicitado'),
        flex: 140,
        valueGetter: ({ row }) => unnormalizeNumeral(row?.totalReal, formatBrazilianNumericDecimal(2)) || '-',
      },
      {
        field: 'totalRecebido',
        headerName: t('com.muralis.qcx.valorRecebido'),
        flex: 140,
        valueGetter: ({ row }) => {
          const movimentacoes = NumerarioImportacaoUtils.getMovimentacoesConciliadasFrom(row?.capas);

          return (
            unnormalizeNumeral(
              contasReceberUtils.calculoValorRecebido(movimentacoes),
              formatBrazilianNumericDecimal(2)
            ) || '-'
          );
        },
      },
      {
        field: 'saldo',
        headerName: t('com.muralis.qcx.saldo'),
        flex: 100,
        valueGetter: ({ row }) => {
          const movimentacoes = NumerarioImportacaoUtils.getMovimentacoesConciliadasFrom(row?.capas);

          return (
            unnormalizeNumeral(
              contasReceberUtils.calculoSaldoContasReceber(movimentacoes, row?.totalReal),
              formatBrazilianNumericDecimal(2)
            ) || '-'
          );
        },
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        headerAlign: 'center',
        align: 'center',
        flex: 140,
        valueGetter: ({ row }) => NumerarioImportacaoStatusUtils.getLabelByValue(row?.status) || '-',
        renderCell: ({ row }) => {
          const value = row?.status;
          const label = NumerarioImportacaoStatusUtils.getLabelByValue(value);
          const Icon = NumerarioImportacaoStatusUtils.getRenderIconByValue(value);

          if (Icon === undefined) {
            return '-';
          }

          return (
            <Tooltip arrow title={label}>
              <IconButton aria-label={label} disableRipple disableFocusRipple disableTouchRipple>
                <Icon />
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        renderCell: ({ row }) => (
          <>
            <QCXPopupState popupId="popup-menu-numerario">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${row?.id}`}
                    name={`btn-more-options-${row?.id}`}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color="secondary" size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>

                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarClick(event, {
                          id: row?.id,
                          status,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handleCancelarByClick(row, popupState)}
                      disabled={NumerarioImportacaoStatusUtils.isCancelado(row?.status) || NumerarioImportacaoStatusUtils.isRecebido(row?.status) || NumerarioImportacaoStatusUtils.isSolicitado(row?.status) || NumerarioImportacaoStatusUtils.isEnviado(row?.status) || NumerarioImportacaoStatusUtils.isImpresso(row?.status)}
                    >
                      <QCXListItemIcon>
                        <CancelIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handleImprimirByIdClick(row, popupState, linguagemUtils.PT_BR)}
                      disabled={NumerarioImportacaoStatusUtils.isCancelado(row?.status)}
                    >
                      <QCXListItemIcon>
                        <PrintIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.imprimir').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handleImprimirByIdClick(row, popupState, linguagemUtils.EN)}
                      disabled={NumerarioImportacaoStatusUtils.isCancelado(row?.status)}
                    >
                      <QCXListItemIcon>
                        <PrintIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.imprimirIngles').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handleImprimirByIdClick(row, popupState, linguagemUtils.ES)}
                      disabled={NumerarioImportacaoStatusUtils.isCancelado(row?.status)}
                    >
                      <QCXListItemIcon>
                        <PrintIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.imprimirEspanhol').toUpperCase()}
                      </Typography>
                    </MenuItem>


                    <MenuItem
                      onClick={handleEnviarNumerarioClick(row, popupState)}
                      disabled={NumerarioImportacaoStatusUtils.isCancelado(row?.status) || NumerarioImportacaoStatusUtils.isRecebido(row?.status) || NumerarioImportacaoStatusUtils.isSolicitado(row?.status)}
                    >
                      <QCXListItemIcon>
                        <SendIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography variant="inherit" style={{ fontSize: 12 }}>
                        {t('com.muralis.qcx.numerario.envirNumerarioParaSolicitacao').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    {userHasUpdateRole(authInfo, NumerarioImportacaoUtils.ROLE) && (
                      <>
                        <MenuItem onClick={handleDuplicarByIdClick(row, popupState)}>
                          <QCXListItemIcon>
                            <FileCopyIcon fontSize="small" color="primary" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.duplicar').toUpperCase()}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={(event) => {
                            popupState.close(event);
                            handleEnviarPorEmailClick(event, row);
                          }}
                          disabled={NumerarioImportacaoStatusUtils.isCancelado(row?.status)}
                        >
                          <QCXListItemIcon>
                            <EmailIcon fontSize="small" color="primary" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.enviar').toUpperCase()}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={(event) => {
                            popupState.close(event);
                            handleNotificarPrometheus(row?.id);
                          }}
                          disabled={NumerarioImportacaoStatusUtils.isCancelado(row?.status)}
                        >
                          <QCXListItemIcon>
                            {/* Trocar icone */}
                            <SendIcon fontSize="small" color="primary" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {'Notificar Protheus'.toUpperCase()}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleSolicitarPagamentoClick(row, popupState)}
                          disabled={NumerarioImportacaoStatusUtils.isCancelado(row?.status)}
                        >
                          <QCXListItemIcon>
                            <AccountBalanceWalletIcon fontSize="small" color="primary" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.solicitarPagamento').toUpperCase()}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [handleConsultarClick, handleCancelarByClick, handleDuplicarByIdClick, handleEnviarPorEmailClick]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.numerarioImportacao.labelPlural'),
        },
        default: true,
      },
    ],
    []
  );

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue }) => {
    dispatch(
      fetchAllImportacaoNumerarioByFilterPaginationAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'numero', value: filterInputValue },
      ])
    );
  }, []);

  const rowsCount = useSelector(selectNumerariosImportacaoCount);

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.numerarioImportacao.labelPlural'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        buttonNew: {
          description: t('com.muralis.qcx.numerarioImportacao.novaSolicitacao'),
          onClick: handleButtonNewClick,
        },
      },
      table: {
        columns,
        rowsCount,
        onConfigChangeCallback: handleConfigChange,
      },
    }),
    [breadcrumbs, columns, handleButtonNewClick, rowsCount, handleConfigChange]
  );

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={numerarios}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={[NumerarioImportacaoUtils.ROLE]}
    >
      <QCXConfirmDialog
        key="confirm-dialog-cancelar-numerario"
        id="confirm-dialog-cancelar-numerario"
        open={operationOfCancelar?.active}
        title={operationOfCancelar?.title}
        content={operationOfCancelar?.message}
        endContent={operationOfCancelar?.endMessage}
        onConfirm={operationOfCancelar.confirm}
        onClose={operationOfCancelar?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-imprimir-numerario"
        id="confirm-dialog-imprimir-numerario"
        open={operationOfImprimirById?.active}
        title={operationOfImprimirById?.title}
        content={operationOfImprimirById?.message}
        endContent={operationOfImprimirById?.endMessage}
        onConfirm={operationOfImprimirById.confirm}
        onClose={operationOfImprimirById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-duplicacao-numerario"
        id="confirm-dialog-duplicacao-numerario"
        open={operationOfDuplicarById?.active}
        title={operationOfDuplicarById?.title}
        content={operationOfDuplicarById?.message}
        endContent={operationOfDuplicarById?.endMessage}
        onConfirm={operationOfDuplicarById.confirm}
        onClose={operationOfDuplicarById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-cancelar-numerario"
        id="confirm-dialog-cancelar-numerario"
        open={operationOfSolicitarPagamento?.active}
        title={operationOfSolicitarPagamento?.title}
        content={operationOfSolicitarPagamento?.message}
        endContent={operationOfSolicitarPagamento?.endMessage}
        onConfirm={operationOfSolicitarPagamento.confirm}
        onClose={operationOfSolicitarPagamento?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-enviar-numerario"
        id="confirm-dialog-enviar-numerario"
        open={operationOfEnviarNumerario?.active}
        title={operationOfEnviarNumerario?.title}
        content={operationOfEnviarNumerario?.message}
        endContent={operationOfEnviarNumerario?.endMessage}
        onConfirm={operationOfEnviarNumerario.confirm}
        onClose={operationOfEnviarNumerario?.reset}
      />
      <QCXFinalFormDialog
        key="form-dialog-enviar-email-numerario"
        id="form-dialog-enviar-email-numerario"
        title={formDialogTitle}
        beforeContent={formDialogEnviarBeforeContent}
        content={formDialogEnviarContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.enviar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogEnviarOpen}
        initialValues={emailRelatedModel}
        onSubmit={handleEnviarByProcessoSubmit}
        onClose={handleCloseFormDialogEnviar}
      >
        {({ submitting }) => (
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <QCXMoveFocusInside>
                <QCXFinalMultilineTextField
                  id="multiline-text-field-mensagem-email"
                  key="multiline-text-field-mensagem-email"
                  name="mensagem"
                  label={t('com.muralis.qcx.mensagem.label')}
                  rows={10}
                  disabled={submitting}
                />
              </QCXMoveFocusInside>
            </Grid>
          </Grid>
        )}
      </QCXFinalFormDialog>
    </SimpleConsultPageTemplate>
  );
}
